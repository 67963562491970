
<template>


    <div class="mt-2 ml-4 mr-5" >

      <v-row>  
        <v-col cols="12" sm="12" md="12" class="ml-n3 mt-n2 titleEventTopContainer">
          <h2 class="titleEventTop "> 
              Event {{this.Evento.event_name}} - {{this.Evento.city_event}} - {{this.formatDate(this.Evento.date_event)}}  
          </h2>                           
        </v-col>
      </v-row>

      <v-card id="create" class="mt-12" style="background-color: var(--main-bg-color);" elevation="0" >

          <v-row class="child-flex">

              <v-snackbar
                content-class="snackbar"
                :color="snackbar_color"
                left
                class="snackbar-gen"
                v-model="snackbar"
                :timeout="3000"
              >
                <div style="float: right;">
                    <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
                </div>
                <div>
                  <h3 :style="{ color: snackbar_text_color + '!important' }">
                    {{ snackbar_text }}
                  </h3>
                </div>
              </v-snackbar>

              <v-speed-dial
                  v-model="fab"
                  :top="top"
                  :bottom="bottom"
                  :right="right"
                  :left="left"
                  :direction="direction"
                  :open-on-hover="hover"
                  :transition="transition"
                >
                  <template v-slot:activator>
                    <v-btn
                      v-model="fab"
                      color="blue darken-2"
                      dark
                      fab
                    >
                      <v-icon v-if="fab" @click="toTop">
                         mdi-chevron-up
                      </v-icon>
                      <v-icon v-else>
                        mdi-account-circle
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-btn
                    fab
                    dark
                    small
                    @click="salva"
                    color="green"
                  >
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>

                  <v-btn
                    fab
                    dark
                    small
                    @click="open_form_mail"
                    color="indigo"
                  >
                    <v-icon>mdi-email</v-icon>
                  </v-btn>

                  <v-btn
                    fab
                    dark
                    small
                    @click="closeSubjectEventDett"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-speed-dial>   

              <!--Event-->
              <v-row v-if="this.ID_ROLE==1" class="mt-12" style="background-color:#ffffff;" >
                <v-col cols="12" sm="12" >
                  <v-card class="  ml-4 mr-4" elevation="0">
                      <v-row> 

                            <v-col cols="12" sm="6" md="12" class="mt-2 text-left">
                                <div class="titleLabelForm d-inline-block"> 
                                    FIGHTERS CHECK-IN BELLATOR
                                </div> 
                              <v-switch
                                class="mt-n1 ml-8  switch-gen d-inline-block"
                                label="Mail"
                                v-model="editedItem.subject_event_mail"
                                color="green"
                                inset
                                @click="silent_salva"
                              ></v-switch>
                              <v-switch
                                class="mt-n1 ml-8 switch-gen d-inline-block"
                                label="SMS"
                                v-model="editedItem.subject_event_sms"
                                color="green"
                                inset
                                @click="silent_salva"
                              ></v-switch>          
                            </v-col> 
                            <v-col cols="12" sm="6" md="4"/>


                        <v-col cols="12" class="ml-3 mt-1" >                                           
                          <v-row >
                            <v-col cols="12" sm="2" md="1" class="mt-n1 ml-n2" >
  
                                  <v-switch
                                    class="ml-5 mt-n1 switch-gen"
                                    v-model="editedItem.check_in"
                                    color="green"
                                    inset
                                  ></v-switch>  
                            </v-col>                            
                            <v-col cols="12" sm="6" md="3" class="mt-n3 ">  <!--Check-In Person-->
                                  <v-autocomplete
                                    v-model="editedItem.check_in_idperson" 
                                    item-color="var(--main-active-select-list-color)"
                                    class="textFieldForm mt-n2"
                                    style=" color: var(--main-input-color); font-size: 18px;"   
                                    color="var(--main-border-color)"                                  
                                    :items="array_check_in"
                                    :loading="isLoading_person"
                                    :search-input.sync="search_person"
                                    clearable
                                    clear-icon="mdi-close"
                                    item-text="name"
                                    item-value="id_subject"
                                    label="Check in Person"
                                    :disabled = "check_in_person_val"
                                    outlined
                                  ></v-autocomplete>                
                            </v-col> 
                            <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--weigh-->
                              <v-text-field
                                v-model="editedItem.check_in_weight"
                                label="Weight"
                                style="color: var(--main-input-color); font-size: 18px;"                              
                                class="textFieldForm"    
                                color="var(--main-border-color)"            
                                outlined                                                
                              ></v-text-field> 
                            </v-col> 
                            <v-col cols="12" sm="6" md="2" class="mt-n5"> <!--DATE-->
                              <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="subject_ev_date"
                                    label="Date"
                                    style="color: var(--main-input-color); font-size: 18px;"                              
                                    class="textFieldForm"    
                                    color="var(--main-border-color)"               
                                    outlined
                                    persistent-hint
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="date"
                                  no-title
                                  @input="menu = false"
                                  locale="en"
                                ></v-date-picker>
                              </v-menu> 
                            </v-col>  
                            <v-col cols="12" sm="2" md="4" class="mt-n6 text-left" >                                  
                                  <v-switch
                                  label ="Hands"
                                    
                                    class="ml-8 switch-gen d-inline-block"
                                    v-model="editedItem.hands_wrap"
                                    color="green"
                                    inset
                                  ></v-switch>  
                                  <v-autocomplete
                                    v-model="editedItem.id_cutman" 
                                    item-color="var(--main-active-select-list-color)"
                                    class="ml-10 textFieldForm mt-n0 d-inline-block"
                                    style=" color: var(--main-input-color); font-size: 18px; ;"   
                                    color="var(--main-border-color)"                                  
                                    :items="array_cutman"
                                    :loading="isLoading_cutman"
                                    :search-input.sync="search_cutman"
                                    clearable
                                    item-text="name"
                                    item-value="id_subject"
                                    label="Cutman"
                                    :disabled = "cutman_val"
                                    outlined
                                  ></v-autocomplete>                
                            </v-col>                                                                                                                                 


                          </v-row>                 
                        </v-col>              
                    </v-row>
                  </v-card>  
                </v-col>
              </v-row> 

              <!-- Weight Resume -->
              <v-col v-if="this.ID_ROLE==1" cols="12" sm="12" md="12" class=" ml-n3"  >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" md="9" >
                    <WeightResume 
                        :ID_SUBJECT= this.ID_SUBJECT
                        :ID_EVENT= this.ID_EVENT                    
                    />            
                  </v-col>
                  <v-col cols="12" sm="12" md="3" class="mt-11 text-left" > <!--Weight Category-->
                      <v-select
                        class="textFieldForm mr-16"
                        style=" color: var(--main-input-color); font-size: 18px;"
                        item-color="var(--main-active-select-list-color)"
                        color="var(--main-border-color)" 
                        :items="weight_division"
                        v-model="editedItem.fk_id_weight_category"
                        outlined
                        menu-props="auto"
                        label="Weight Category"
                        item-text="des"
                        item-value="id"   
                      ></v-select>  
                    <!-- 
                      <div class="text-center mt-10">
                            <v-btn
                              color="red lighten-2"
                              dark
                              @click="stampa_evento()"
                            >
                              Click Me
                            </v-btn>
                           
                            <a style="color:black;" target="_blank" href="mailto:someone@yoursite.com?cc=john@yoursite.com&amp;amp;amp;amp;bcc=bob@yoursite.com&amp;amp;amp;amp;subject=Requested%20documents&amp;amp;amp;amp;ni_email_cfg_base64json=ub2xvZ3kiXSwNCg">Example link</a>
                            
                      </div>
                    -->

                  </v-col>    



                </v-row>     
              </v-col>   

              <!-- Licensed Corner -->
              <v-col v-if="this.ID_ROLE==1" cols="12" sm="12" md="12" class="mt-8 ml-n2" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <LicensedCorner 
                        :ID_SUBJECT= this.ID_SUBJECT
                        :ID_EVENT= this.ID_EVENT                    
                    />            
                  </v-col>
                </v-row>     
              </v-col>               

              <!-- Pre Event Zoom Call-->           
              <v-col cols="12" sm="12" md="12" class="mt-8 ml-n3" style="background-color:#ffffff;" >
                
                    <v-row > 
                      <v-col cols="12" sm="12" class="mt-2 ml-8" >
                          <p class="titleLabelForm ml-n2" > 
                              PRE EVENT ZOOM CALL
                          </p>
                      </v-col>                                 
                      <v-col cols="12" class="ml-3 " >                                           
                        <v-row >
                          <v-col cols="12" sm="2" md="2" class="mt-n5 ml-3" >
                              <p class="labelFormHead"  > 
                                  Date
                              </p>    
                              <p   class="labelFormText">                           
                                {{ formatDate(this.Evento.prezoomcall_date)}}
                              </p>
                          </v-col>
                          <v-col cols="12" sm="2" md="2" class="mt-n5">
                              <p class="labelFormHead"> 
                                  Time
                              </p> 
                              <p  class="labelFormText">                               
                                  {{  this.Evento.prezoomcall_time != null ? this.Evento.prezoomcall_time.substring(0, 5) : "" }} 
                              </p>
                          </v-col>   
                          <v-col cols="12" sm="4" md="6" class="mt-n5" >
                              <p class="labelFormHead"> 
                                  Zoom Meeting Link
                              </p>       
                              <p  class="labelFormText">                         
                                {{this.Evento.prezoomcall_link}}
                              </p>
                          </v-col>   
                                                                                  
                        </v-row>                 
                      </v-col>              
                  </v-row>
                
              </v-col>
            
              <v-col cols="12" sm="12" md="12" class="mt-8 ml-n3" style="background-color:#ffffff; "  >
                <v-col cols="12" sm="12" md="12">
                <p class="titleLabelForm"> 
                  Plan Activities
                </p>
               </v-col> 
                <v-tabs vertical style="min-height: 600px;">
                  <v-navigation-drawer
                    v-model="drawer"
                    :mini-variant.sync="mini"
                    permanent
                    class="mt-3 pt-1"
                  >   
                    <v-tab class="subj-event-tab" style=" " v-if="!mini && this.ID_ROLE==1">
                      Photo Shooting
                    </v-tab>
                    <v-tab class="subj-event-tab" style=" " v-if="!mini && this.ID_ROLE==1">
                      Check In
                    </v-tab >
<!-- 
                    <v-tab class="subj-event-tab" style="" v-if="!mini && this.ID_ROLE==1">
                      LOCKER ROOM
                    </v-tab>
                     -->
                    <v-tab class="subj-event-tab" style="" v-if="!mini">
                      Social Interview
                    </v-tab> 
                    <v-tab class="subj-event-tab" style="" v-if="!mini">
                      Zoom Interview
                    </v-tab>      
                    <v-tab class="subj-event-tab" style="" v-if="!mini">
                      Media Day
                    </v-tab>     
                    <v-tab class="subj-event-tab" v-if="!mini && this.ID_ROLE==1">
                      Commentator Interview
                    </v-tab>  
                    <v-tab class="subj-event-tab" style="" v-if="!mini">
                      Covid Test
                    </v-tab>    
                    <v-tab class="subj-event-tab" style="" v-if="!mini">
                      Appointment
                    </v-tab>    
                    <v-tab class="subj-event-tab" style="" v-if="!mini">
                      Free Tickets
                    </v-tab>                                                                                                                             
                  </v-navigation-drawer>
                  <v-tab-item :transition="false" :reverse-transition="false" v-if="this.ID_ROLE==1">
                      <PhotoShooting class="pl-2" 
                          :ID_SUBJECT= this.ID_SUBJECT
                          :ID_EVENT= this.ID_EVENT                    
                      />  
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false" v-if="this.ID_ROLE==1">
                      <CheckIn class="pl-2"
                          :ID_SUBJECT= this.ID_SUBJECT
                          :ID_EVENT= this.ID_EVENT                    
                      /> 
                  </v-tab-item>
<!--               <v-tab-item  :transition="false" :reverse-transition="false" v-if="this.ID_ROLE==1">
                      <Training class="pl-2"
                          :ID_SUBJECT= this.ID_SUBJECT
                          :ID_EVENT= this.ID_EVENT                    
                      /> 
                  </v-tab-item> -->
                  <v-tab-item  :transition="false" :reverse-transition="false">
                      <SocialInterview class="pl-2"
                          :ID_SUBJECT= this.ID_SUBJECT
                          :ID_EVENT= this.ID_EVENT                    
                      />   
                  </v-tab-item>   
                  <v-tab-item  :transition="false" :reverse-transition="false">
                      <ZoomInterview class="pl-2"
                          :ID_SUBJECT= this.ID_SUBJECT
                          :ID_EVENT= this.ID_EVENT                    
                      />   
                  </v-tab-item>    
                  <v-tab-item  :transition="false" :reverse-transition="false">
                      <MediaDay class="pl-2"
                          :ID_SUBJECT= this.ID_SUBJECT
                          :ID_EVENT= this.ID_EVENT                    
                      />   
                  </v-tab-item>     
                  <v-tab-item  :transition="false" :reverse-transition="false" v-if="this.ID_ROLE==1">
                      <CommentatorInterview class="pl-2"
                          :ID_SUBJECT= this.ID_SUBJECT
                          :ID_EVENT= this.ID_EVENT                    
                      />  
                  </v-tab-item>    
                  <v-tab-item  :transition="false" :reverse-transition="false">
                    <CovidTest class="pl-2"
                        :ID_SUBJECT= this.ID_SUBJECT
                        :ID_EVENT= this.ID_EVENT                    
                    />    
                  </v-tab-item>     
                  <v-tab-item  :transition="false" :reverse-transition="false">
                      <Appointment class="pl-2"
                          :ID_SUBJECT= this.ID_SUBJECT
                          :ID_EVENT= this.ID_EVENT    
                          :VALID_CONTACT = this.valid_contact              
                      />     
                  </v-tab-item>      
                  <v-tab-item  :transition="false" :reverse-transition="false">
                      <FreeTicket class="pl-2"
                          :ID_SUBJECT= this.ID_SUBJECT
                          :ID_EVENT= this.ID_EVENT                    
                      />     
                  </v-tab-item>                                                                                                                              
                </v-tabs>
              </v-col>
                          
              <!-- Photo Shooting 
              <v-col v-if="this.ID_ROLE==1" cols="12" sm="12" md="12" class="mt-8 ml-n3" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <PhotoShooting 
                        :ID_SUBJECT= this.ID_SUBJECT
                        :ID_EVENT= this.ID_EVENT                    
                    />            
                  </v-col>
                </v-row>     
              </v-col>   

             CheckIn 
              <v-col v-if="this.ID_ROLE==1" cols="12" sm="12" md="12" class="mt-8 ml-n3" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <CheckIn 
                        :ID_SUBJECT= this.ID_SUBJECT
                        :ID_EVENT= this.ID_EVENT                    
                    />            
                  </v-col>
                </v-row>     
              </v-col>                 

              Training 
              <v-col v-if="this.ID_ROLE==1" cols="12" sm="12" md="12" class="mt-8 ml-n3" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <Training 
                        :ID_SUBJECT= this.ID_SUBJECT
                        :ID_EVENT= this.ID_EVENT                    
                    />            
                  </v-col>
                </v-row>     
              </v-col>   

             Social Interview 
              <v-col cols="12" sm="12" md="12" class="mt-8 ml-n3" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <SocialInterview 
                        :ID_SUBJECT= this.ID_SUBJECT
                        :ID_EVENT= this.ID_EVENT                    
                    />            
                  </v-col>
                </v-row>     
              </v-col>      

               Zoom Interview 
              <v-col cols="12" sm="12" md="12" class="mt-8 ml-n3" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <ZoomInterview 
                        :ID_SUBJECT= this.ID_SUBJECT
                        :ID_EVENT= this.ID_EVENT                    
                    />            
                  </v-col>
                </v-row>     
              </v-col>                                  

              Media Day
              <v-col cols="12" sm="12" md="12" class="mt-8 ml-n3" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <MediaDay 
                        :ID_SUBJECT= this.ID_SUBJECT
                        :ID_EVENT= this.ID_EVENT                    
                    />            
                  </v-col>
                </v-row>     
              </v-col>    

              Commentator Interview
              <v-col v-if="this.ID_ROLE==1" cols="12" sm="12" md="12" class="mt-8 ml-n3" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <CommentatorInterview 
                        :ID_SUBJECT= this.ID_SUBJECT
                        :ID_EVENT= this.ID_EVENT                    
                    />            
                  </v-col>
                </v-row>     
              </v-col> 
              -->
              <!-- Press Conference -->
              <v-row>
                <v-col cols="12" sm="12" md="12" class="mt-10" style="background-color:#ffffff;" >
                  <v-card class=" ml-4 mr-4" elevation="0" >
                      <v-row > 
                        <v-col cols="12" sm="12" class="mt-2 ml-4" >
                            <p class="titleLabelForm ml-n2" > 
                                PRESS CONFERENCE
                            </p>
                        </v-col>                                 
                        <v-col cols="12" class="ml-3 mt-n2" >                                           
                          <v-row >
                            <v-col cols="12" sm="2" md="2" class="mt-n5" >
                                <p class="labelFormHead"  > 
                                    Date
                                </p>    
                                <p  class="labelFormText" >                           
                                  {{ formatDate(this.Evento.conference_date)}}
                                </p>
                            </v-col>
                            <v-col cols="12" sm="2" md="2" class="mt-n5">
                                <p class="labelFormHead"> 
                                    Time
                                </p> 
                                <p  class="labelFormText" >                               
                                   {{  this.Evento.conference_time != null ? this.Evento.conference_time.substring(0, 5) : "" }} 
                                </p>
                            </v-col>   
                            <v-col cols="12" sm="4" md="4" class="mt-n5" >
                                <p class="labelFormHead"> 
                                    Zoom Meeting Link
                                </p>       
                                <p  class="labelFormText" >                         
                                  {{this.Evento.conference_place}}
                                </p>
                            </v-col>   
                            <v-col cols="12" sm="4" md="4" class="mt-n5" >
                              <p class="labelFormHead"> 
                                </p> 
                                  <v-switch
                                    label="Partecipation"
                                    class="ml-10 mt-n1 switch-gen"
                                    v-model="editedItem.press_conference_partecipation"
                                    color="green"
                                    inset
                                  ></v-switch>  
                            </v-col>                                                                                  
                          </v-row>                 
                        </v-col>              
                    </v-row>
                  </v-card>  
                </v-col>
              </v-row>  

              <!-- Locker Room -->
              <v-col cols="12" sm="12" md="12" class="mt-10 ml-n3" style="background-color:#ffffff;" >
                  <v-row > 
                    <v-col cols="12" sm="12" md="12" class="mt-2 ml-8" >
                        <p class="titleLabelForm ml-n2" > 
                            LOCKER ROOM
                        </p>
                    </v-col>                                 
                    <v-col cols="12" class="ml-3 " >                                           
                      <v-row >
                        <v-col cols="12" sm="2" md="2" class="mt-n5 ml-3" >
                            <p class="labelFormHead"  > 
                                Date
                            </p>    
                            <p  class="labelFormText"  >                           
                              {{ formatDate(this.Evento.locker_room_date)}}
                            </p>
                        </v-col>
                        <v-col cols="12" sm="2" md="2" class="mt-n5">
                            <p class="labelFormHead"> 
                                Time From
                            </p> 
                            <p  class="labelFormText"  >                               
                                {{  this.Evento.locker_room_time_from != null ? this.Evento.locker_room_time_from.substring(0, 5) : "-" }} 
                            </p>
                        </v-col>   
                        <v-col cols="12" sm="2" md="2" class="mt-n5">
                            <p class="labelFormHead"> 
                                Time To
                            </p> 
                            <p  class="labelFormText"  >                               
                                {{  this.Evento.locker_room_time_to != null ? this.Evento.locker_room_time_to.substring(0, 5) : "-" }} 
                            </p>
                        </v-col>                              
                        <v-col cols="12" sm="4" md="2" class="mt-n5" >
                            <p class="labelFormHead"> 
                                Place
                            </p>       
                            <p  class="labelFormText" >                         
                              {{this.Evento.locker_room_place}}
                            </p>
                        </v-col>   
                          <v-col cols="12" sm="4" md="3" class="mt-n5" >
                            <p class="labelFormHead"> 
                                Note
                            </p>       
                            <p  class="labelFormText"  >                         
                              {{this.Evento.locker_room_note}}
                            </p>
                        </v-col>                                                   
                      </v-row>                 
                    </v-col>              
                </v-row>
              </v-col>

              <!-- Official Weigh Ins -->
              <v-col cols="12" sm="12" md="12" class="mt-10 ml-n3" style="background-color:#ffffff;" >
                  <v-row > 
                    <v-col cols="12" sm="12" md="12" class="mt-2 ml-8" >
                        <p class="titleLabelForm ml-n2" > 
                            OFFICIAL WEIGH INS
                        </p>
                    </v-col>                                 
                    <v-col cols="12" class="ml-3 " >                                           
                      <v-row >
                        <v-col cols="12" sm="2" md="2" class="mt-n5 ml-3" >
                            <p class="labelFormHead"  > 
                                Date
                            </p>    
                            <p  class="labelFormText" >                           
                              {{ formatDate(this.Evento.weigh_date)}}
                            </p>
                        </v-col>
                        <v-col cols="12" sm="2" md="2" class="mt-n5">
                            <p class="labelFormHead"> 
                                Time From
                            </p> 
                            <p  class="labelFormText" >                               
                                {{  this.Evento.weigh_time_from != null ? this.Evento.weigh_time_from.substring(0, 5) : "-" }} 
                            </p>
                        </v-col>   
                        <v-col cols="12" sm="2" md="2" class="mt-n5">
                            <p class="labelFormHead"> 
                                Time To
                            </p> 
                            <p  class="labelFormText" >                               
                                {{  this.Evento.weigh_time_to != null ? this.Evento.weigh_time_to.substring(0, 5) : "-" }} 
                            </p>
                        </v-col>                              
                        <v-col cols="12" sm="4" md="2" class="mt-n5" >
                            <p class="labelFormHead"> 
                                Place
                            </p>       
                            <p  class="labelFormText" >                         
                              {{this.Evento.weigh_place}}
                            </p>
                        </v-col>   
                          <v-col cols="12" sm="4" md="3" class="mt-n5" >
                            <p class="labelFormHead"> 
                                Note
                            </p>       
                            <p  class="labelFormText" >                         
                              {{this.Evento.weigh_note}}
                            </p>
                        </v-col>                                                   
                      </v-row>                 
                    </v-col>              
                </v-row>
              </v-col>

              <!-- Fighters Face-off -->
              <v-col cols="12" sm="12" md="12" class="mt-7 ml-n3" style="background-color:#ffffff;" >
                    <v-row > 
                      <v-col cols="12" sm="12" class="mt-2 ml-8" >
                          <p class="titleLabelForm ml-n2" > 
                              FIGHTERS FACE OFF
                          </p>
                      </v-col>                                 
                      <v-col cols="12" class="ml-3 " >                                           
                        <v-row >
                          <v-col cols="12" sm="2" md="2" class="mt-n5 ml-3" >
                              <p class="labelFormHead"  > 
                                  Date
                              </p>    
                              <p  class="labelFormText" >                           
                                {{ formatDate(this.Evento.fighterfaceoff_date)}}
                              </p>
                          </v-col>
                          <v-col cols="12" sm="2" md="2" class="mt-n5">
                              <p class="labelFormHead"> 
                                  Time
                              </p> 
                              <p  class="labelFormText" >                               
                                  {{  this.Evento.fighterfaceoff_time != null ? this.Evento.fighterfaceoff_time.substring(0, 5) : "-" }} 
                              </p>
                          </v-col>   
                          <v-col cols="12" sm="2" md="2" class="mt-n5">
                              <p class="labelFormHead"> 
                                  Lobby Call Time
                              </p> 
                              <p  class="labelFormText" >                               
                                  {{  this.Evento.fighterfaceofflobby_time != null ? this.Evento.fighterfaceofflobby_time.substring(0, 5) : "-" }} 
                              </p>
                          </v-col>                              
                          <v-col cols="12" sm="6" md="5" class="mt-n5" >
                              <p class="labelFormHead">
                                  Place
                              </p>       
                              <p  class="labelFormText" >                         
                                {{this.Evento.fighterfaceoff_place}}
                              </p>
                          </v-col>   
                        
                                                                            
                        </v-row>                 
                      </v-col>              
                  </v-row>
              </v-col>

              <!-- Rules Meeting -->
              <v-col cols="12" sm="12" md="12" class="mt-7 ml-n3" style="background-color:#ffffff;" >
                    <v-row > 
                      <v-col cols="12" sm="12" class="mt-2 ml-8" >
                          <p class="titleLabelForm ml-n2" > 
                              RULES MEETING
                          </p>
                      </v-col>                                 
                      <v-col cols="12" class="ml-3 " >                                           
                        <v-row >
                          <v-col cols="12" sm="2" md="2" class="mt-n5 ml-3" >
                              <p class="labelFormHead"  > 
                                  Date
                              </p>    
                              <p  class="labelFormText" >                           
                                {{ formatDate(this.Evento.rulesmeeting_date)}}
                              </p>
                          </v-col>
                          <v-col cols="12" sm="2" md="2" class="mt-n5">
                              <p class="labelFormHead"> 
                                  Time
                              </p> 
                              <p  class="labelFormText" >                               
                                  {{  this.Evento.rulesmeeting_time != null ? this.Evento.rulesmeeting_time.substring(0, 5) : "-" }} 
                              </p>
                          </v-col>   
                          <v-col cols="12" sm="4" md="4" class="mt-n5" >
                              <p class="labelFormHead"> 
                                  Place
                              </p>       
                              <p  class="labelFormText" >                         
                                {{this.Evento.rulesmeeting_place}}
                              </p>
                          </v-col>  
                          <v-col cols="12" sm="4" md="4" class="mt-n5" />                                                                                 
                        </v-row>                 
                      </v-col>              
                  </v-row>
              </v-col>

              <!-- Lobby Call to Venue -->
              <v-col cols="12" sm="12" md="12" class="mt-7 ml-n3" style="background-color:#ffffff;" >
                    <v-row > 
                      <v-col cols="12" sm="12" class="mt-2 ml-8" >
                          <p class="titleLabelForm ml-n2" > 
                              LOBBY CALL TO VENUE
                          </p>
                      </v-col>                                 
                      <v-col cols="12" class="ml-3" >                                           
                        <v-row >
                          <v-col cols="12" sm="2" md="2" class="mt-n5 ml-3" >
                              <p class="labelFormHead"  > 
                                  Date
                              </p>    
                              <p  class="labelFormText" >                           
                                {{ formatDate(this.Evento.lobbycall_date)}}
                              </p>
                          </v-col>
                          <v-col cols="12" sm="2" md="2" class="mt-n5">
                              <p class="labelFormHead"> 
                                  Time
                              </p> 
                              <p  class="labelFormText" >                               
                                  {{  this.Evento.lobbycall_time != null ? this.Evento.lobbycall_time.substring(0, 5) : "-" }} 
                              </p>
                          </v-col>   
                          <v-col cols="12" sm="4" md="4" class="mt-n5" >
                              <p class="labelFormHead"> 
                                  Place
                              </p>       
                              <p  class="labelFormText" >                         
                                {{this.Evento.lobbycall_place}}
                              </p>
                          </v-col>   
                          <v-col cols="12" sm="4" md="4" class="mt-n5" />                                                     
                        </v-row>                 
                      </v-col>              
                  </v-row>
              </v-col>

              <!-- Lobby Call to airport Da Completare ...--> 
              <v-col cols="12" sm="12" md="12" class="mt-7 ml-n3" style="background-color:#ffffff;" >
                    <v-row > 
                      <v-col cols="12" sm="12" class="mt-2 ml-8" >
                          <p class="titleLabelForm ml-n2" > 
                                LOBBY CALL TO AIRPORT
                          </p>
                      </v-col>                                 
                      <v-col cols="12" class="ml-3 " >                                           
                        <v-row >
                          <v-col cols="12" sm="2" md="2" class="mt-n5 ml-3" >
                              <p class="labelFormHead"  > 
                                  Date
                              </p>    
                              <p  class="labelFormText" >                           
                                {{"-"}}
                              </p>
                          </v-col>
                          <v-col cols="12" sm="2" md="2" class="mt-n5">
                              <p class="labelFormHead"> 
                                  Time
                              </p> 
                              <p  class="labelFormText" >                               
                                  {{ "-" }} 
                              </p>
                          </v-col>   
                          <v-col cols="12" sm="4" md="4" class="mt-n5" >
                              <p class="labelFormHead"> 
                                  Place
                              </p>       
                              <p  class="labelFormText" >                         
                                {{"-"}}
                              </p>
                          </v-col>   
                          <v-col cols="12" sm="4" md="4" class="mt-n5" />                                                     
                        </v-row>                 
                      </v-col>              
                  </v-row>
              </v-col>

              <!--  Global NOTE TO EVENT...--> 
              <v-col cols="12" sm="12" md="12" class="mt-7 ml-n3" style="background-color:#ffffff;" v-if="note_array.length>0">
                    <v-row > 
                      <v-col cols="12" sm="12" class="mt-2 ml-8" >
                          <p class="titleLabelForm ml-n2" > 
                                NOTE TO EVENT PACK 
                          </p>
                      </v-col>                                 
                      <v-col cols="12" class="ml-3 mt-n2" >                                           
                        <v-row >
                          <v-col cols="12" sm="11"  class="mt-n5" >
                            <template>
                              <v-data-table
                                :headers="headers"
                                :items="note_array"
                                hide-default-footer
                                :items-per-page="12000"                    
                              >
                                  <template v-slot:[`header.title`]="{}">
                                    <a class="textHeader" style="color:var(--main-dth-color);"> Title </a>
                                  </template>                                                                                  
                                  <template v-slot:[`header.description`]="{}">
                                    <a class="textHeader" style="color:var(--main-dth-color);"> Description </a>
                                  </template>

                              </v-data-table>  
                                              
                            </template>
                          </v-col>                                                    
                        </v-row>                 
                      </v-col>              
                  </v-row>
              </v-col>   

              <!--  Global NOTE TO EVENT...--> 
              <v-col cols="12" sm="12" md="12" class="mt-7 ml-n3" style="background-color:#ffffff;" v-if="appointment_array.length>0">
                    <v-row > 
                      <v-col cols="12" sm="12" class="mt-2 ml-8" >
                          <p class="titleLabelForm ml-n2" > 
                                EVENT APPOINTMENT 
                          </p>
                      </v-col>                                 
                      <v-col cols="12" class="ml-3 mt-n2" >                                           
                        <v-row >
                          <v-col cols="12" sm="11"  class="mt-n5" >



                            <v-data-table
                              :headers="headers_appointment"
                              :items="appointment_array"
                              hide-default-footer
                              :items-per-page="12000"                    
                            >
                                <template v-slot:[`header.data`]="{}">
                                  <a class="textHeader" style="color:var(--main-dth-color);"> Date </a>
                                </template>                   
                                <template v-slot:[`header.place`]="{}">
                                  <a class="textHeader" style="color:var(--main-dth-color);"> Place </a>
                                </template>                                      
                                <template v-slot:[`header.time`]="{}">
                                  <a class="textHeader" style="color:var(--main-dth-color);"> Time </a>
                                </template>
                                <template v-slot:[`header.description`]="{}">
                                  <a class="textHeader" style="color:var(--main-dth-color);"> Description </a>
                                </template>

                              <template v-slot:[`item.data`]="{ item }">
                                    {{formatDate(item.data)}} 
                              </template>   
                              <template v-slot:[`item.time`]="{ item }">
                                    {{  item.time != null ? item.time.substring(0, 5) : "" }} 
                              </template>       
                            </v-data-table>                            

                          </v-col>                                                    
                        </v-row>                 
                      </v-col>              
                  </v-row>
              </v-col>                 


              <!-- Covid Test 
              <v-col cols="12" sm="12" md="12" class="mt-8 ml-n3" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <CovidTest 
                        :ID_SUBJECT= this.ID_SUBJECT
                        :ID_EVENT= this.ID_EVENT                    
                    />            
                  </v-col>
                </v-row>     
              </v-col>           
              -->
              <!-- Appointment 
              <v-col cols="12" sm="12" md="12" class="mt-8 ml-n2" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <Appointment 
                        :ID_SUBJECT= this.ID_SUBJECT
                        :ID_EVENT= this.ID_EVENT                    
                    />            
                  </v-col>
                </v-row>     
              </v-col> 
              -->

              <!-- Personal  Note -->
              <v-col cols="12" sm="12" md="12" class="mt-7 ml-n3" style="background-color:#ffffff;" >
                    <v-row > 
                      <v-col cols="12" sm="12" class="mt-2 ml-8" >
                          <p class="titleLabelForm ml-n2" > 
                                ADD PERSONAL NOTE TO EVENT PACK
                          </p>
                      </v-col>                                 
                      <v-col cols="12" class="ml-2" >                                           
                        <v-row >
                          <v-col cols="12" sm="12" md="11" class="mt-n5 ml-3" >
                              <p class="labelFormHead"  > 
                                  Description
                              </p>    
                              <v-textarea
                                  v-model="editedItem.note_description"
                                  label="Text"
                                  outlined
                                  color="grey"
                                  no-resize
                                  rows="1"
                              ></v-textarea>
                          </v-col>
                                                                            
                        </v-row>                 
                      </v-col>              
                  </v-row>
              </v-col>                         

              <!-- FreeTicket
              <v-col cols="12" sm="12" md="12" class="mt-8 ml-n2" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <FreeTicket 
                        :ID_SUBJECT= this.ID_SUBJECT
                        :ID_EVENT= this.ID_EVENT                    
                    />            
                  </v-col>
                </v-row>     
              </v-col> 
               -->
              <!-- Deduction -->
              <v-col cols="12" sm="12" md="12" class="mt-8 ml-n2" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <Deduction 
                        :ID_SUBJECT= this.ID_SUBJECT
                        :ID_EVENT= this.ID_EVENT                    
                    />            
                  </v-col>
                </v-row>     
              </v-col> 
              
              <!--Salva e Cancel-->
              <v-col cols="12" sm="12" >
                <v-row  class="mt-2"  >
                  <v-col cols="12" sm="12" >
                    <v-card class="mt-5" elevation="0" style="background-color: var(--main-bg-color);">
                      <v-row style=" var(--main-bg-color);">
                        <v-col cols="12" class="text-center" >
                          <v-btn
                            color="var(--main-primary-color)"
                            dark
                            @click="salva"
                            elevation="0"
                          >
                            Save
                          </v-btn>
                          <v-btn
                            class="ml-2"
                            color="trasparent"
                            elevation="0"
                            @click="closeSubjectEventDett"
                          >
                            Cancel
                          </v-btn>                        
                          
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>

          </v-row>
           <br> 
          <v-dialog  v-model="pdfdialog" width="unset">
              <v-card>
                  <v-toolbar
                      class="white--text"
                      color="blue"
                      dense
                      style="position: sticky; top: 0px; z-index: 1"
                      >
                      <v-toolbar-title> Stampa Cartellino </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn
                          class="red--text"
                          @click="pdfdialog = false"
                          color="blue-grey lighten-5"
                          small
                      >
                          <v-icon>mdi-close</v-icon>
                      </v-btn>
                  </v-toolbar>
                  <v-row>
                      <v-col cols="12" sm="6" md="12">
                          <iframe
                              :src="this.selectedPdf"
                              frameborder="0"
                              style="height: 90vh !important; width: 90vw !important"
                          >
                          </iframe>
                      </v-col>
                  </v-row>
              </v-card>
              
          </v-dialog>      

          <v-dialog
            v-model="dialogMail"
            max-width="1000px"
          >
              <Mail
                :EMAIL= this.EMAIL      
                :ID_SUBJECT= this.ID_SUBJECT
                :ID_EVENT= this.ID_EVENT
                :TRIGGER = this.modifica
                v-on:closeEmail="valori_di_ritorno_mail"
              />
          </v-dialog>

      </v-card> 

    </div>

</template>
<style>
  /* Style for Bottom menu  */
  #create .v-speed-dial {
    position: fixed;
    z-index: 2;
  }

  #create .v-btn--floating {
    position: relative;
  }

</style>


<script>
import WeightResume from "./Card/EvWeight.vue";
import PhotoShooting from "./Card/EvPhotoShooting.vue";
import CovidTest from "./Card/EvCovidTest.vue";
//import Training from "./Card/EvTraining.vue";
import CheckIn from "./Card/EvCheckIn.vue";
import SocialInterview from "./Card/EvSocialInterview.vue";
import ZoomInterview from "./Card/EvZoomInterview.vue";
import MediaDay from "./Card/EvMediaDay.vue";
import CommentatorInterview from "./Card/EvCommentatorInterview.vue";
import FreeTicket from "./Card/EvFreeTicket.vue";
import Deduction  from "./Card/EvDeduction.vue";
import Appointment  from "./Card/EvAppointment.vue";
import LicensedCorner from './Card/EvLicensedCorner.vue';
import Mail from "@/components/EMail.vue";

export default {
    components: {
       WeightResume,
       PhotoShooting,
       CovidTest,
      // Training,
       CheckIn,
       SocialInterview,
       ZoomInterview,
       MediaDay,
       CommentatorInterview,
       FreeTicket,
       Deduction,
       Appointment,
       LicensedCorner,
       Mail,
    }, 
  data() {
    return {
      dialog: false,
      dialogMail:false,

      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",     
     
     //x Bottom menu
      direction: 'top',
      fab: false,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
      //x Bottom to Top
      scTimer: 0,
      scY: 0,

      disabled_check_in_person_stato: true,

      pdfdialog:false,
      Evento:[],
      venues:[],
      viewCode:true,

      array_check_in:[],
      array_cutman:[],
      note_array:[],
      appointment_array:[],
      selectedPdf: null,
      isLoading_cutman: false,
      search_cutman: null,
      isLoading_person: false,
      search_person: null,

      valid_contact:[],
      modifica:0,

      date: null,
      menu:false,
      
      weight_category:null,
      overlay: false,  

        drawer: true,
        items: [
          { title: 'Home', icon: 'mdi-home-city' },
          { title: 'My Account', icon: 'mdi-account' },
          { title: 'Users', icon: 'mdi-account-group-outline' },
        ],
        mini: false,


      editedItem:{           
            check_in: false,
            check_in_date: null,
            check_in_idperson: null,
            check_in_weight: null,
            hands_wrap: false,
            id_cutman: null,

            fk_id_weight_category: null,
            zoom_call_partecipation: null,
            press_conference_partecipation: null,
            note_description: null },

      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Description', value: 'description' },                        
      ], 
      
      headers_appointment: [
        { text: 'Date', value: 'data' },
        { text: 'Place', value: 'place' },
        { text: 'Time', value: 'time' },
        { text: 'Description', value: 'description' },                        
      ],
      /*
        that.editedItem: Object
            id_subject_event: 1S
            fk_id_event: 1
            fk_id_role: 1
            fk_id_subject: 1
            city_event: null
            date_event: "2022-03-29"
            des_role: "Athlete"
            event_name: "B465"

            check_in: null,
            check_in_date: null,
            check_in_idperson: null,
            check_in_weight: null,
            hands_wrap: null,
            id_cutman: null,

            fk_id_weight_category: null,
            zoom_call_partecipation: null,
            press_conference_partecipation: null,
            note_description: null
            

            validita: 1
            id_utente: 1
            date_mod: null
            date_ins: "2022-04-05"

      */

      /*
          Evento
            city_event: null
            conference_date: "2022-03-29"  PressConference
            conference_place: "Room 34"
            conference_time: "02:11:00"
            conference_transportation: 1

            country_event: "112"
            date_event: "2022-03-29"
            date_ins: "2022-03-24 23:15:17"
            date_mod: "2022-03-28 02:12:07"
            diem: null
            event_name: "B465"

            fighterfaceoff_date: null
            fighterfaceoff_place: null
            fighterfaceoff_time: null

            fighterfaceoff_transportation: null
            fighterfaceofflobby_date: null
            fighterfaceofflobby_place: null

            fighterfaceofflobby_time: null

            fighterfaceofflobby_transportation: null
            fk_id_venue: null
            id_currency: null
            id_diemticket: null
            id_event: 1
            id_mealday: null
            id_utente: 1
            
            lobbycall_date: null
            lobbycall_place: null
            lobbycall_time: null
            lobbycall_transportation: null
            main_card_time: null
            open_door_time: null
            prelim_start_time: null

            rulesmeeting_date: null
            rulesmeeting_place: null
            rulesmeeting_time: null
            rulesmeeting_transportation: null

            state_event: null
            status_boot_sheet: null
            status_broadcast: null
            status_graphics: 1
            status_info: 1
            status_media: null
            status_photo: null
            status_social: 1
            status_trip: null
            validita: 1
            weigh_date: null
            weigh_place: null
            weigh_time_from: null
            weigh_time_to: null
            weigh_transportation: null
      */


    };
  },
  props: {
      ID_SUBJECT: Number,
      ID_EVENT: Number,
      ID_ROLE: Number,
      EMAIL: String,
  },  
  computed: {
      country_currency(){
         return this.alasql(
            "SELECT id, Country +  ' - ' + Cod AS  des FROM ? ",
            [this.currency]
        );    
      },    
      
    subject_ev_date () {
        return this.formatDate(this.date)
      },


    check_in_person_val () {  
        if (this.editedItem.check_in == true){
          return !this.editedItem.check_in
        }else {
          return !this.editedItem.check_in
        }
      },

    cutman_val () {
        if (this.editedItem.hands_wrap == true){
          return !this.editedItem.hands_wrap
        }else return !this.editedItem.hands_wrap
      },
 
        
  },
  watch: {
    
    ID_EVENT: {
        immediate: true,
        handler() {
          this.getEvents();
        }
    },

   check_in_person_val: {
        immediate: true,
        handler() { 
            if (this.check_in_person_val)
              this.editedItem.check_in_idperson = null
        }
    },
    cutman_val: {
        immediate: true,
        handler() {
            if (this.cutman_val)
              this.editedItem.id_cutman = null
        }
    }

  },  
  mounted() {
    //Gestione Go Bottom to TOP-----------------
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {

      getEvents(){
        this.overlay = true
        let request = {
            controller: "Bellator",
            method: "POST",
            richiesta: {
                action: "getevents",
                idevent: this.ID_EVENT,
                token: localStorage.getItem("user_token"),
            },
        }
        this.$store.dispatch("api", request).then((res) => {   
            if (res.status == 200){
              this.Evento = res.data.events[0];
              this.overlay = false 
              this.getSubjectEvent(); // Carico lo staff e i cutman associati all'evento
              this.getnote();
              this.getappointment();
            } else {
              this.colore_snack = "error";
              this.messaggio_snackbar = "Events not loaded.";
              this.snackbar = true;
              this.overlay = false
            }     
        })
        .catch((ex) => {
  
            this.overlay = false
            this.snackbar = true;
            this.snackbar_color = "error";
            this.snackbar_icon = "mdi-close-outline";
            this.snackbar_text = ex;
            this.snackbar_icon_color = "var(--main-primary_text-color)";
            this.overlay = false
          
        });
      },

      getSubjectEvent(){
        this.overlay = true
        this.events_Subject=[]
        
        let request = {
            controller: "Bellator",
            method: "POST",
            richiesta: {
                action: "getsubjectevent",
                //idsubject:this.ID_SUBJECT,
                idevent:this.ID_EVENT,
                token: localStorage.getItem("user_token"),
            },
        }
        this.$store.dispatch("api", request).then((res) => {   
            if (res.status == 200){
              let item = this.alasql(
                  "SELECT FROM ? where fk_id_subject = " + this.ID_SUBJECT ,
              [res.data.subject_event]
              );
              
              
              this.valid_contact = this.alasql(
                  "SELECT FROM ? where fk_id_subject != " + this.ID_SUBJECT ,
              [res.data.subject_event]
              );
              this.editedItem =item[0]
              console.log("editedItem:", this.editedItem)
              if (this.editedItem.check_in_weight == null) this.editedItem.check_in_weight = this.editedItem.des_weight_category 
              
              this.date = this.editedItem.check_in_date

              //Carico lo Staff associato all'evento
              res.data.subject_event.forEach(element => {
                if (element.fk_id_role == 3) {
                  let field = element.surname  + " " +  element.name 
                  this.array_check_in.push({  
                      name: field,
                      id_subject: element.fk_id_subject, 
                      id_role: element.fk_id_role, 
                      validita: element.validita  
                    });
                }
               });

              //Carico i Cutman associati all'evento
              res.data.subject_event.forEach(element => {
                if (element.fk_id_role == 7) {
                  let field = element.surname  + " " +  element.name 
                  this.array_cutman.push({  
                      name: field,
                      id_subject: element.fk_id_subject, 
                      id_role: element.fk_id_role, 
                      validita: element.validita  
                    });
                }
               });

              this.overlay = false 
            } else {
              this.snackbar_color = "error";
              this.messaggio_snackbar = "Events not loaded.";
              this.snackbar = true;
              this.overlay = false
            }     
        })
        .catch((ex) => {
            this.overlay = false
            this.snackbar_color = "error";
            this.snackbar_text = ex;
            this.snackbar_icon_color = "var(--main-primary_text-color)";
            this.overlay = false
        });
      },  

      getnote(){
        let that = this
        this.overlay = true
        this.note_array=[]
        let request = {
            controller: "Bellator",
            method: "POST",
            richiesta: {
                action: "getnote",
                idevent: this.ID_EVENT,
                token: localStorage.getItem("user_token"),
            },
        }
        this.$store.dispatch("api", request).then((res) => {   
            if (res.status == 200){
              that.note_array = res.data.note;
              this.overlay = false 
            } else {
              this.snackbar_color = "error";
              this.snackbar_test = "Table Data not loaded.";
              this.snackbar = true;
              this.overlay = false
            }     
        })
        .catch((ex) => {
            console.log(ex)
            this.overlay = false
        });
      },

      getappointment(){
        let that = this
        this.overlay = true
        this.appointment_array=[]
        let request = {
            controller: "Bellator",
            method: "POST",
            richiesta: {
                action: "getappointment",
                idevent: this.ID_EVENT,
                token: localStorage.getItem("user_token"),
            },
        }
        this.$store.dispatch("api", request).then((res) => {   
            if (res.status == 200){
              that.appointment_array = res.data.appointment;
              this.overlay = false 
            } else {
              this.snackbar_color = "error";
              this.snackbar_test = "Table Data not loaded.";
              this.snackbar = true;
              this.overlay = false
            }     
        })
        .catch((ex) => {
            console.log(ex)
            this.overlay = false
        });
      },      
    
      salva(){
        this.editedItem.check_in_date= this.date
          this.overlay = true
          let request = {
              controller: "Bellator",
              method: "PUT",
              richiesta: {
                  action: "setsubjectevent",
                  subject_event: [this.editedItem],
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {  
                 
                 if (res.status == 200){
                    this.overlay = false
                   // let item = res.data[0];
                    this.snackbar_color = "success";
                    this.snackbar_text = "data saved successfully. ";
                    this.snackbar_icon = "mdi-window-close";
                    this.snackbar = true;
                  } else {
                    this.snackbar_color = "error";
                    this.snackbar_text = "Error save data. ";
                    this.snackbar_icon = "mdi-window-close";
                    this.snackbar = true;
                    this.overlay = false
                  }           
          });
      },    
      
      silent_salva(){
        this.editedItem.check_in_date= this.date
          this.overlay = true
          let request = {
              controller: "Bellator",
              method: "PUT",
              richiesta: {
                  action: "setsubjectevent",
                  subject_event: [this.editedItem],
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {  
                 
                 if (res.status == 200){
                    this.overlay = false
                  } else {
                    this.overlay = false
                  }           
          });
      }, 

      closeSubjectEventDett(){
         this.$emit("closeDett", "");
      },

      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },



      async stampa_evento(){
          await this.getStampaEvento();
          const byteCharacters = atob(this.file_b64);
          const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          const blobUrl = URL.createObjectURL(blob);
          this.selectedPdf = blobUrl;
          this.pdfdialog = true;   
      },

      getStampaEvento() {
          return new Promise((resolve, reject) => {
              this.overlay = true;
              let disegnoReq = {
                  controller: "Bellator",
                  method: "POST",
                  richiesta: {
                  action: "geteventspdf",
                  token: localStorage.getItem("user_token"),
                  },
              };
              this.file_b64 = '';
              this.$store.dispatch("api", disegnoReq).then((res) => {
                  this.file_b64 = res.data
                  this.overlay = false;
                  resolve(res);
                  return;
              })
              .catch((e) => {
                  this.overlay = false;
                  reject(e);
                  console.log(e);
              });
          });
      },  

      open_form_mail(){
        this.modifica = Math.round(Math.random()*100);
        this.dialogMail=true
      },

      valori_di_ritorno_mail(){
        this.dialogMail=false
      },

      //Gestione Go Bottom to TOP----------------------------

      handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
      toTop: function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },      

      //--------------------------------------------

     
  },
};
</script>