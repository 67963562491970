<template>
  <v-col cols="12">
    <v-card class="mx-auto" max-width="1350">
      <v-toolbar dense color="rgb(63,81,181)">
        <v-toolbar-title class="white--text"> Gestione Email</v-toolbar-title>
        <v-spacer></v-spacer>

        <!-- PULSANTE AGGIUNGI -->

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-5"
              @click="aggiungiconnessione"
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="rgb(25, 118, 210)"> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Aggiungi</span>
        </v-tooltip>
        <v-spacer></v-spacer>

        <!-- PULSANTE INDIETRO -->


      </v-toolbar>

      <!-- TABELLA DIAOLOG INSERIMENTO DATI -->

      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card class="mx-auto">
          <v-toolbar dense color="rgb(63,81,181)">
            <v-toolbar-title class="white--text"> Dati Email</v-toolbar-title>

            <v-spacer></v-spacer>

            <!-- BOTTONE SALVA IN TABELLA-->

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small @click="salvaconnessione" v-bind="attrs" v-on="on">
                  <v-icon color="rgb(76, 175, 80)"> mdi-floppy </v-icon>
                </v-btn>
              </template>
              <span>Salva</span>
            </v-tooltip>

            <!-- BOTTONE CHIUDI TABELLA -->

            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="dialog = false" small v-bind="attrs" v-on="on">
                  <v-icon color="rgb(255, 82, 82)">
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Chiudi</span>
            </v-tooltip>

             <!-- TABELLA -->
          
          </v-toolbar>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-checkbox
                  v-model="connessione_sel.ssl"
                  true-value="1"
                  false-value="0"
                  label="Connesione SSL"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="SMTP-host"
                  v-model="connessione_sel.host"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="SMTP-port"
                  v-model="connessione_sel.port"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Descrizione"
                  v-model="connessione_sel.des_smtp"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Username"
                  v-model="connessione_sel.user"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Password"
                  v-model="connessione_sel.psw"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- FINE DIALOG TABELLA -->

      <template>
        <v-data-table
          fixed-header
          height="300px"
          :items="items_connessioni"
          :headers="headers_connessioni"
        >

      <!-- BOTTONE MODIFICA -->

          <template v-slot:[`item.modifica`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
            <v-btn small @click="modifica(item)" v-bind="attrs" v-on="on">
              <v-icon color="primary"> mdi-pencil</v-icon>
            </v-btn>
              </template>
              <span>Modifica</span>
            </v-tooltip>
          </template>

      <!-- BOTTONE CANCELLA -->

          <template v-slot:[`item.cancella`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
            <v-btn  small @click="clickElimina(item)" v-bind="attrs" v-on="on" >
              <v-icon color="error"> mdi-delete</v-icon>
            </v-btn>
              </template>
              <span>Cancella</span>
            </v-tooltip>
          </template>

      <!-- BOTTONE APERTUTA DIALOG DI TEST -->

          <template v-slot:[`item.test`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
            <v-btn small @click="test(item)" v-bind="attrs" v-on="on">
              <v-icon color="primary"> mdi-email-check </v-icon>
            </v-btn>
              </template>
              <span>Test</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </v-card>

      <!-- TABELLA DIALOG DI TEST -->

    <v-dialog v-model="dialogtest" persistent max-width="600px">
      <v-card class="mx-auto">
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"> Test </v-toolbar-title>
      
      <!-- BOTTONE INVIA EMAIL TEST -->

          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small @click="inviatest" v-bind="attrs" v-on="on">
                <v-icon color="rgb(76, 175, 80)"> mdi-send </v-icon>
              </v-btn>
            </template>
            <span>Invia Test</span>
          </v-tooltip>
      
      <!-- BOTTONE CHIUDI DIALOG DI TEST -->

          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="chiudiDialogTest" small v-bind="attrs" v-on="on">
                <v-icon color="rgb(255, 82, 82)">
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>

      <!-- TABELLA DI TEST -->

        <v-container fluid>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Mail mittente"
                v-model="mail_test_mittente"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Mail destinatario"
                v-model="mail_test_destinatario"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="start">
            <v-col cols="12" sm="8">
              <v-text-field
                label="Oggetto"
                v-model="mail_test_oggetto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-textarea
                label="Corpo"
                v-model="mail_test_corpo"
                rows="2"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
      <!-- FINE TABELLA  DI TEST -->

      <!-- DIALOG DI CONFERMA ELIMINAZIONE DI UNA CONNESSIONE -->

    <v-dialog v-model="conferma_eliminazione" width="unset">
      <v-card style="max-width: 500px">
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"> Eliminazione </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5" style="overflow-y: auto">
          <span style="color: rgb(0, 0, 0); font-size: 18px">
            Confermi di voler eliminare la connessione selezionata?
          </span>
          <v-divider class="my-5"></v-divider>
          <v-container>
            <v-row align="center" justify="center">
              <v-col class="my-0 py-0" justify="center" cols="12">
                <v-btn
                  class="mr-5"
                  @click="conferma_eliminazione = false"
                  small
                >
                  <v-icon color="error"> mdi-close </v-icon>
                </v-btn>
                <v-btn @click="elimina" small>
                  <v-icon color="success"> mdi-check </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

      <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-col>
</template>

<script>
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";
class Connessione {
  constructor(arg = undefined) {
    if (arg != undefined) {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = arg[c.nome] != undefined ? arg[c.nome] : c.default_val;
      });
    } else {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = c.default_val;
      });
    }
  }

  // Metodo per resettare tutte le campi
  reset() {
    const campi = this.setCampi();

    campi.forEach((c) => {
      this[c.nome] = c.default_val;
    });
  }

  setCampi() {
    const campi = [
      { nome: "des_smtp", default_val: "" },
      { nome: "host", default_val: "" },
      { nome: "id_smtp", default_val: null },
      { nome: "port", default_val: "" },
      { nome: "ssl", default_val: 0 },
      { nome: "user", default_val: "" },
      { nome: "psw", default_val: "" },
      { nome: "validita", default_val: 1 },
    ];

    return campi;
  }
}
export default {
  data() {
    return {
      conferma_eliminazione: false,
      dialog: false,
      dialogtest: false,
      connessione_sel: new Connessione(),
      mail_test_mittente: "",
      mail_test_destinatario: "",
      mail_test_oggetto: "",
      mail_test_corpo: "",
      headers_connessioni: [
        { text: "Modifica", value: "modifica", sortable: false },
        { text: "Test", value: "test", sortable: false },
        { text: "Descrizione", value: "des_smtp", sortable: false },
        { text: "SMTP-host", value: "host", sortable: false },
        { text: "SMTP-port", value: "port", sortable: false },
        { text: "Conn. SSL", value: "ssl", sortable: false },
        { text: "Username", value: "user", sortable: false },
        { text: "Password", value: "psw", sortable: false },
        { text: "Cancellazione", value: "cancella", sortable: false },
      ],
      items_connessioni: [],
    };
  },

  methods: {
    modifica(arg) {
      this.connessione_sel = new Connessione(arg);

      this.dialog = true;
    },
    aggiungiconnessione() {
      this.connessione_sel = new Connessione();
      this.dialog = true;
    },
    chiudiDialogTest(){
      this.mail_test_mittente= "";
      this.mail_test_destinatario= "";
      this.mail_test_oggetto= "";
      this.mail_test_corpo= "";
      this.dialogtest= false;
    },
    elimina() {
      const connessione_tmp = new Connessione(this.connessione_sel);
      connessione_tmp.validita = 0;
      let request = {
        controller: "global",
        method: "PUT",
        richiesta: {
          action: "setsmtp",
          token: localStorage.user_token,
          smtp_list: [connessione_tmp],
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200) {
          this.conferma_eliminazione = false;
          this.getConnessioni();
        }
      });
    },
    clickElimina(arg) {
      this.connessione_sel = new Connessione(arg);
      this.conferma_eliminazione = true;
    },

    clickIndietro() {
      this.$router.push("/home/invioemail");
    },
    getConnessioni() {
      let request = {
        controller: "global",
        method: "POST",
        richiesta: {
          action: "getsmtp",
          token: localStorage.user_token,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200) {
          this.items_connessioni = res.data;
        }
      });
    },
    salvaconnessione() {
      if (this.connessione_sel.host === "") {
        this.$refs.SnackbarQV.print("orange", "Host non presente.");
        return;
      }
      if (this.connessione_sel.des_smtp === "") {
        this.$refs.SnackbarQV.print("orange", "Descrizione non presente.");
        return;
      }
      if (this.connessione_sel.port === "") {
        this.$refs.SnackbarQV.print("orange", "Porta SMTP non presente.");
        return;
      }
      if (this.connessione_sel.user === "") {
        this.$refs.SnackbarQV.print("orange", "Username non presente.");
        return;
      }
      if (this.connessione_sel.psw === "") {
        this.$refs.SnackbarQV.print("orange", "Password non presente.");
        return;
      }
      let request = {
        controller: "global",
        method: "PUT",
        richiesta: {
          action: "setsmtp",
          token: localStorage.user_token,
          smtp_list: [this.connessione_sel],
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200) {
          this.getConnessioni();
        }
      });
      this.dialog = false;
    },
    test(arg) {
      this.connessione_sel = new Connessione(arg);
      this.dialogtest = true;
    },
    inviatest() {
      if (this.mail_test_mittente === "") {
        this.$refs.SnackbarQV.print("orange", "Mittente non presente.");
        return;
      }
      if (this.mail_test_destinatario === "") {
        this.$refs.SnackbarQV.print("orange", "Destinatario non presente.");
        return;
      }
      if (this.mail_test_oggetto === "") {
        this.$refs.SnackbarQV.print("orange", "Oggetto non presente.");
        return;
      }
      if (this.mail_test_corpo === "") {
        this.$refs.SnackbarQV.print("orange", "Corpo non presente.");
        return;
      }
      let request = {
        controller: "global",
        method: "PUT",
        richiesta: {
          action: "setsmtptest",
          token: localStorage.user_token,
          id_smtp: this.connessione_sel.id_smtp,
          mittente: this.mail_test_mittente,
          destinatario: this.mail_test_destinatario,
          oggetto: this.mail_test_oggetto,
          body: this.mail_test_corpo,
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status == 200) {
            this.$refs.SnackbarQV.print("success", "Mail di test inviate.");
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              "Errore di comunicazione col server, non è stato possibile salvare i dati."
            );
          }
        })
        .catch((err) => {
          console.error(err);
          this.$refs.SnackbarQV.print(
            "error",
            "Errore di comunicazione col server, non è stato possibile salvare i dati."
          );
        });
    },
  },
  mounted() {
    this.getConnessioni();
  },
  components: {
    SnackbarQV,
  },
};
</script>