<template>
    <div class="mt-10 ml-6 mr-6">
      <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>

        <!-- TABS di dettaglio -->
        <div v-if="pagetabs">
            <subjectTabsDetail 
              :obj_subject = this.items_subject  
              :val_subject_sms = this.item_subject_sms
              :val_subject_mail = this.item_subject_mail
              :id_subject_selected = this.id_subjectSelected
              :modifica = this.modifica
              tab = "medical"
              v-on:closeTabsDetail="valori_di_ritorno_TabsDetail"
            /> 
        </div>      

        <div v-if="!pagetabs" style="background-color:var(--main-bg-color);">
          <v-card class="mt-1 ml-4 mr-4" elevation="0" style="background-color:var(--main-bg-color);"  ><!--First Row Filtri-->
            <v-row>
              
              <v-col cols="12" sm="6" md="4">
                  <v-row>
                      <v-col class="mt-5" cols="12" sm="6" md="3" width="100"
                        style="letter-spacing: 1px; font-weight: 600; 
                         font-family: Titillium Web; font-size: 18px"
                      >
                          Filter for
                      </v-col>
                      <v-col cols="12" sm="9">
                        <v-autocomplete
                          v-model="filter_event"
                          :items="valid_events"
                          :item-text="(item) => item.event_name + '-' + item.city_event"  
                          item-value='id_event'            
                          label="Event"
                          prepend-inner-icon="mdi-filter-variant"
                          outlined
                          clearable
                          clear-icon="mdi-close"
                          color="var(--main-border-color)" 
                          item-color="var(--main-active-select-list-color)"
                          style=" color: var(--main-input-color); font-size: 18px;"   
                          class="textFieldForm"
                          :loading="isLoading_event"
                          :search-input.sync="search_event"                                          
                        ></v-autocomplete>
                      </v-col>                                
                  </v-row>                                

              </v-col>

              <!-- Fine Campi Filter -->

              <!-- pulsanti Filtro-->
              <v-col cols="12" sm="6" md="2" class="ml-n2 ">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn  
                              :loading="loading"
                              :disabled="loading"
                              class="mt-3 btnForm "  
                              elevation="0" color="var(--main-primary-color)"  v-bind="attrs" v-on="on" 
                              @click="getEvent()"
                            > Apply</v-btn>
                        </template>
                        <span>Apply Selected Filter</span>
                    </v-tooltip>
                    
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="valid_events.length>0"
                                class="mt-3 btn_bg nohover " elevation="0" 
                                color="var(--main-bg-color)"  dark v-bind="attrs" v-on="on" @click="clear_filter"
                            > Clear
                            </v-btn>
                            <v-btn v-else
                                class="mt-3 ml-1 " elevation="0" 
                                color="var(--main-bg-color)"  dark v-bind="attrs" v-on="on" @click="clear_filter"
                                disabled
                            > 
                            </v-btn>
                        </template>
                        <span>Clear All Filter</span>
                    </v-tooltip>                            
              </v-col>
            </v-row>               
          </v-card>  

          <v-card class="mt-n8 ml-4 " elevation="0"  v-if="vediBoutsheet" > <!--Gestione Tabella-->
                      <boutsheet
                        :ITEM=this.obj_event  
                        :TRIGGER = this.modifica
                        v-on:closeDettEvent="clear_filter"
                      />
          </v-card> 
        </div> 

    </div>
</template>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
<script>
  
import subjectTabsDetail from "@/components/Subject/SubjectTabsDetail.vue";
import Boutsheet from "@/components/Events/Boutsheet";

  export default {
    components: {
       subjectTabsDetail,
       Boutsheet,
    },
    data () {
      return {

        snackbar: false,
        snackbar_color: "",
        snackbar_text: "",

        pagetabs:false,
        vediBoutsheet:false,
        overlay: false,   
        loading: false,
        
        obj_event:null,
        modifica: 0, 

        search:'',

        events:[],
        filter_event:null,
        isLoading_event: false,
        search_event: null,  

        items_subject:null,
        item_subject_sms:null,
        item_subject_mail:null,
        id_subjectSelected:null,

       }
    },
    props: {
    },     
    mounted() {
      this.getEvents()
    }, 
    computed: {
      valid_events() {
        return this.events.filter((i) => {
            return (i.validita === null) || (i.validita === 1) || (i.validita === "");
        })
      },
     
    }, 
    watch: {
    },  
    methods: {

        getEvents(){
          var filter_page =  ''
          this.overlay = true
          this.events=[]
          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "getevents",
                  search: filter_page,
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                this.events = res.data.events;
                this.overlay = false 
              } else {
                this.snackbar_color = "error";
                this.messaggio_snackbar = "Events not loaded.";
                this.snackbar = true;
                this.overlay = false
              }     
          })
          .catch((ex) => {
            if (ex.response.status == 404 || ex.response.status == undefined) {
              this.overlay = false
              this.snackbar = true;
              this.snackbar_color = "error";
              this.snackbar_text = ex;
              this.overlay = false
            }
          });
        },

        getEvent(){
          if (this.filter_event == "" ||  this.filter_event == null) return
          this.overlay = true
          this.loading = true

          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "getevents",
                  idevent: this.filter_event,
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                this.modifica = Math.round(Math.random()*100);
                this.obj_event = res.data.events[0];
                
                setTimeout(() => (this.loading = false), 4000)
                this.overlay = false 
                this.vediBoutsheet = true
              } else {
                this.snackbar_color = "error";
                this.snackbar_text = "Events not loaded.";
                this.snackbar = true;
                this.overlay = false
              }     
          })
          .catch((ex) => {
            if (ex.response.status == 404 || ex.response.status == undefined) {
              this.overlay = false
              this.snackbar = true;
              this.snackbar_color = "error";
              this.snackbar_text = ex;
              this.overlay = false
            }
          });
        },    
               

        valori_di_ritorno_TabsDetail(value){
           // console.log(value)
            this.pagetabs =  false
        },  
        clear_filter(){
          this.filter_event = null
          this.overlay = false
          this.vediBoutsheet = false
        },

        formatDate (date) {
          if (!date) return null
          let new_date = date.substring(0,10)
          const [year, month, day] = new_date.split('-')
          return `${month}/${day}/${year}`
        },          



    }
  }
</script>
