<template>
      <v-card class="mt-5 ml-4 mr-4" elevation="0">
            <v-snackbar
              content-class="snackbar"
              :color="snackbar_color"
              right
              v-model="snackbar"
              :timeout="3000"
            >
              <div style="float: right;">
                  <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
              </div>
              <div>
              <h3 :style="{ color: snackbar_text_color + '!important' }">
                {{ snackbar_text }}
              </h3>
              </div>
            </v-snackbar>
            <v-row > 
              <v-col cols="12" sm="12" md="12">
                  <p class="titleLabelForm"> 
                      LICENSED CORNER
                  </p>
              </v-col> 
                                      
              <v-col cols="12" sm="6" md="5" class="mt-n5 ">  <!--Selec Contact-->
                  <p class="labelForm"> 
                      FIND A LICENSED CORNER TO MATCH
                  </p>              
                    <v-autocomplete
                      v-model="subject_ev_licensedcorner_id_contact"
                      item-color="var(--main-active-select-list-color)"
                      class="textFieldForm "
                      style=" color: var(--main-input-color); font-size: 18px;"   
                      color="var(--main-border-color)"                                  
                      :items="valid_contact"
                      :loading="isLoading_contact"
                      :search-input.sync="search_contact"
                      clearable
                      clear-icon="mdi-close"
                      item-text="name_surname"
                      item-value="id_subject"
                      label="Contact"
                      outlined
                    ></v-autocomplete>                
              </v-col>     


              <v-col cols="12" sm="6" md="1" class="mt-n2"> <!--Button Add-->
                  <p class="labelForm"> 
                  </p>  
                  <v-btn
                    class="mt-5 btn_plus"
                    color="var(--main-primary-color)"
                    style="color:var(--main-primary_text-color);"
                    @click="add_item"
                    elevation="0"
                    :disabled="subject_ev_licensedcorner_id_contact <= 0"
                  >
                    <v-icon dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn> 
              </v-col>   
              <v-col cols="12" sm="6" md="4" class="mt-n2"/>
              <v-col v-if="subject_ev_licensedcorner_array.length<=0" cols="12" sm="8" md="12" class="mt-n5"/>
              <p class="ml-3 mt-n8 text-gen-nocontent" v-if="subject_ev_licensedcorner_array.length<=0">
                Select contacts
              </p>
              <!-- Table-->
              <v-col cols="12" sm="6" md="6" class="mt-n8"  v-if="subject_ev_licensedcorner_array_length>0">
                <template>
                  <v-data-table
                    :headers="headers"
                    :items="subject_ev_licensedcorner_array"
                    hide-default-footer
                    :items-per-page="12000"   
                  >
                    <template v-slot:[`header.name_surmame`]="{}">
                      <a class="textHeader" style="color:var(--main-dth-color);"> Corner Name </a>
                    </template>                                            
                    <template v-slot:[`item.actions`]="{ item }">  
                      <v-icon
                        color="var(--main-primary-color)"
                        @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>

                  </v-data-table>
                  <v-dialog v-model="dialogDelete" max-width="1024px" >
                    <v-card class="dialog-del">                      
                      <div class="alert-top">!</div>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color=" darken-1" class="btn_bg nohover" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color=" " class="btnForm" dark elevation="0" @click="deleteItemConfirm">Yes, proceed</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                                  
                </template>
              </v-col>
              <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
              </v-overlay> 
            </v-row>
      </v-card>   
      
</template>

<script>

export default {   
	data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",     

      subject_ev_licensedcorner_array:[],
      subject_ev_licensedcorner_array_length:0,
      edited_subject_ev_licensedcorner: {},
      editedIndex_subject_ev_licensedcorner: -1,

      valid_contact:[],
      
      isLoading_contact: false,
      search_contact: null,

      subject_ev_licensedcorner_id_contact:null,

      overlay: false,  

      dialogDelete: false,
      headers: [
        
        { text: 'Contact', value: 'name_surmame' },
        { text: '', value: 'actions', sortable: false, width:"8%" },
        
      ],
     
    };		
	},
  props: {
      ID_SUBJECT: Number,
      ID_EVENT: Number,
  },
  computed: {

    },

  watch: {
        ID_EVENT: {
          immediate: true,
          handler() {
          this.getSubjectEvLicensedCorner()
          },

      },
  },  	
  mounted() {
    
  },	
    
 
	methods:{

				getSubjectEvLicensedCorner(){
					let that = this
          
					that.overlay = true
					that.subject_ev_licensedcorner_array=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getsubjectevlicensedcorner",
									id_subject: this.ID_SUBJECT,
                  id_event: this.ID_EVENT,
									token: localStorage.getItem("user_token"),
							},
					}
					that.$store.dispatch("api", request).then((res) => {   
              
							if (res.status == 200){
								that.subject_ev_licensedcorner_array = res.data.subjectevlicensedcorner;
                that.subject_ev_licensedcorner_array_length = that.subject_ev_licensedcorner_array.length
								that.overlay = false 
                that.getSubjectEvent()
							} else {
								that.snackbar_color = "error";
								that.snackbar_test = "Table Data not loaded.";
								that.snackbar = true;
								that.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							this.overlay = false
					});
				},

        getSubjectEvent(){
          this.overlay = true
          this.events_Subject=[]
          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "getsubjectevent",
                  //idsubject:this.ID_SUBJECT,
                  idevent:this.ID_EVENT,
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                //Carico i Coach associati all'evento
                res.data.subject_event.forEach(element => {
                  if (element.fk_id_role == 2) {
                    let field = element.surname  + " " +  element.name 
                    this.valid_contact.push({  
                        name_surname: field,
                        name: element.name,
                        surname: element.surname,
                        id_subject: element.fk_id_subject, 
                        id_role: element.fk_id_role, 
                        validita: element.validita  
                      });
                  }
                });
               // console.log("Valid Contact", this.valid_contact)
                this.overlay = false 
              } else {
                this.snackbar_color = "error";
                this.messaggio_snackbar = "Events not loaded.";
                this.snackbar = true;
                this.overlay = false
              }     
          })
          .catch((ex) => {
              this.snackbar = true;
              this.snackbar_color = "error";
              this.snackbar_text = ex;
              this.overlay = false
          });
        },       

				deleteItem (item) {
						this.editedIndex_subject_ev_licensedcorner = this.subject_ev_licensedcorner_array.indexOf(item)
						this.edited_subject_ev_licensedcorner = Object.assign({}, item)
						this.dialogDelete = true
				},

				deleteItemConfirm () {
					this.subject_ev_licensedcorner_array.splice(this.editedIndex_subject_ev_licensedcorner, 1)
					this.delete(this.edited_subject_ev_licensedcorner)
					this.closeDelete()
				},

				closeDelete () {
					this.dialogDelete = false
					this.$nextTick(() => {
						this.edited_subject_ev_licensedcorner = {}
						this.editedIndex_subject_ev_licensedcorner = -1
					})
				},      

				add_item(){
            let new_subjectevlicensedcorner = [{id_subject_ev_licensedcorner:-1,  fk_id_subject: this.ID_SUBJECT, fk_id_event: this.ID_EVENT, id_contact: this.subject_ev_licensedcorner_id_contact }]
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setsubjectevlicensedcorner",
                    subjectevlicensedcorner: new_subjectevlicensedcorner,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {     
                  if (res.status == 200){
                      this.date = null
                      this.subject_ev_licensedcorner_id_contact = null
                      this.overlay = false
                      this.snackbar_color = "success";
                      this.snackbar_text = "Entry saved successfully. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.getSubjectEvLicensedCorner();
                    } else {
                      this.snackbar_color = "error";
                      this.snackbar_text = "Data Saving Error. ";
                      this.snackbar = true;
                      this.overlay = false
                    }           
            });           
				},

				delete(item){
					this.overlay = true
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action:"delsubjectevlicensedcorner",
                  token: localStorage.getItem("user_token"),
                  id_subject_ev_licensed_corner:item.id_subject_ev_licensed_corner,
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
                  this.	getSubjectEvLicensedCorner();
									this.overlay = false   
									this.snackbar_color = "success";
									this.snackbar_text = "Item deleted!";
                  this.snackbar_icon = "mdi-window-close";
									this.snackbar = true;                    
							}
					});
				},				   

	}
}

</script>
