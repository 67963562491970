<template>
  <v-app>  
    <v-card class="pa-1" elevation="0">
          <v-toolbar dense  color="white" elevation="1">
                <GlobalToolBar 
                    :NOME_AZIENDA = this.nome_azienda
                    :NOME_UTENTE = this.nome_utente  
                    :DES_PROFILO = this.des_profilo
                /> 
          </v-toolbar>
        <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="3" v-for="(item,i) in routes" :key="i" v-show="item.enabled==true" >
                  <v-card class="mx-auto" width="100%" outlined>
                    <v-card-text>
                      <p :title="item.text" >{{item.text}} </p>
                    </v-card-text>
                    <v-card-actions v-if="item.text=='Gestione Aziende'" >
                      <v-btn outlined color='grey' rounded @click="dialogGestioneAziende = true" style="width: 100%" >
                        <v-icon>{{item.icon}}</v-icon>
                      </v-btn>                     
                    </v-card-actions>
                    <v-card-actions v-if="item.text=='Gestione Moduli'" >
                      <v-btn outlined color='grey' rounded @click="dialogGestioneModuli = true" style="width: 100%" >
                        <v-icon>{{item.icon}}</v-icon>
                      </v-btn>                     
                    </v-card-actions>
                    <v-card-actions v-if="item.text=='Gestione Moduli Aziende'" >
                      <v-btn outlined color='grey' rounded @click="dialogGestioneModuliAziende = true" style="width: 100%" >
                        <v-icon>{{item.icon}}</v-icon>
                      </v-btn>                     
                    </v-card-actions>
                    <v-card-actions v-if="item.text=='Gestione Profili'" >
                      <v-btn outlined color='grey' rounded @click="dialogGestioneProfili = true" style="width: 100%" >
                        <v-icon>{{item.icon}}</v-icon>
                      </v-btn>                     
                    </v-card-actions>       
                    <v-card-actions  v-if="item.text=='Gestione Utenti'" >
                      <v-btn outlined color='grey' rounded @click="dialogGestioneUtenti = true" style="width: 100%" >
                        <v-icon>{{item.icon}}</v-icon>
                      </v-btn>                     
                    </v-card-actions>    
                    <v-card-actions  v-if="item.text=='Gestione Mail'" >
                      <v-btn outlined color='grey' rounded @click="dialogGestioneMail = true" style="width: 100%" >
                        <v-icon>{{item.icon}}</v-icon>
                      </v-btn>                     
                    </v-card-actions>                                                                       
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
        </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialogGestioneAziende" persistent>
      <v-card>
          <v-toolbar dense  color="white" elevation="1">
          <span>Gestione Aziende</span>
          <v-spacer></v-spacer>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn  small v-bind="attrs" v-on="on" @click="dialogGestioneAziende=false">
                        <v-icon color="red"
                        >mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </template>
                <span>Chiudi.</span>
            </v-tooltip>            
          </v-toolbar>
          <GestioneAziende/> 
      </v-card>
    </v-dialog>    

    <v-dialog
      v-model="dialogGestioneModuli" persistent>
      <v-card>
          <v-toolbar dense  color="white" elevation="1">
          <span>Gestione Moduli</span>
          <v-spacer></v-spacer>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn  small v-bind="attrs" v-on="on" @click="dialogGestioneModuli=false">
                        <v-icon color="red"
                        >mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </template>
                <span>Chiudi.</span>
            </v-tooltip>            
          </v-toolbar>
          <GestioneModuli/> 
      </v-card>
    </v-dialog> 

    <v-dialog
      v-model="dialogGestioneModuliAziende" persistent>
      <v-card>
          <v-toolbar dense  color="white" elevation="1">
          <span>Gestione Moduli Aziende</span>
          <v-spacer></v-spacer>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn  small v-bind="attrs" v-on="on" @click="dialogGestioneModuliAziende=false">
                        <v-icon color="red"
                        >mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </template>
                <span>Chiudi.</span>
            </v-tooltip>            
          </v-toolbar>
          <GestioneModuliAziende/> 
      </v-card>
    </v-dialog> 

    <v-dialog
      v-model="dialogGestioneProfili" persistent>
      <v-card>
          <v-toolbar dense  color="white" elevation="1">
          <span>Gestione Profili</span>
          <v-spacer></v-spacer>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn  small v-bind="attrs" v-on="on" @click="dialogGestioneProfili=false">
                        <v-icon color="red"
                        >mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </template>
                <span>Chiudi.</span>
            </v-tooltip>            
          </v-toolbar>
          <GestioneProfili/> 
      </v-card>
    </v-dialog> 

    <v-dialog
      v-model="dialogGestioneUtenti" persistent>
      <v-card>
          <v-toolbar dense  color="white" elevation="1">
          <span>Gestione Utenti</span>
          <v-spacer></v-spacer>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn  small v-bind="attrs" v-on="on" @click="dialogGestioneUtenti=false">
                        <v-icon color="red"
                        >mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </template>
                <span>Chiudi.</span>
            </v-tooltip>            
          </v-toolbar>
          <GestioneUtenti/> 
      </v-card>
    </v-dialog>     

    <v-dialog
      v-model="dialogGestioneMail" persistent>
      <v-card>
          <v-toolbar dense  color="white" elevation="0">
          <span></span>
          <v-spacer></v-spacer>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn  small v-bind="attrs" v-on="on" @click="dialogGestioneMail=false">
                        <v-icon color="red"
                        >mdi-close
                        </v-icon>
                    </v-btn>
                </template>
                <span>Chiudi.</span>
            </v-tooltip>            
          </v-toolbar>
          <GestioneMail/> 
      </v-card>
    </v-dialog> 


  </v-app>
 
</template>


<script>
import GlobalToolBar from "@/components/GlobalToolBar.vue";
import GestioneModuliAziende from './componenti/GestioneModuliAziende.vue';
import GestioneAziende from './componenti/GestioneAziende.vue';
import GestioneModuli from './componenti/GestioneModuli.vue';
import GestioneProfili from './componenti/GestioneProfili.vue';
import GestioneUtenti from './componenti/GestioneUtenti.vue';
import GestioneMail from './componenti/GestioneMail.vue';

export default {
  components: {
    GlobalToolBar,
    GestioneAziende,
    GestioneModuli,
    GestioneModuliAziende,
    GestioneProfili,
    GestioneUtenti,
    GestioneMail,
  },
  name : 'Amministrazione',
  data() {
    return {
      nome_azienda: "Pannello di Amministrazione",
      des_profilo: localStorage.des_profilo,
      nome_utente: "Gestione Moduli",

      dialogGestioneAziende:false,
      dialogGestioneModuli:false,
      dialogGestioneModuliAziende:false,
      dialogGestioneProfili:false,
      dialogGestioneUtenti:false,
      dialogGestioneMail:false,


      routes : [
        {text:'Gestione Aziende', path:'/amministrazione/gestioneaziende', enabled:false, icon:'mdi-factory'},
        {text:'Gestione Moduli', path:'/amministrazione/gestionemoduli', enabled:false, icon:'mdi-hexagon-multiple'},
        {text:'Gestione Moduli Aziende', path:'/amministrazione/gestionemoduliaziende', enabled:false, icon:'mdi-checkbox-marked-outline'},
        {text:'Gestione Profili', path:'/amministrazione/gestioneprofili', enabled:false, icon:'mdi-account-star'},
        {text:'Gestione Utenti', path:'/amministrazione/gestioneutenti', enabled:false, icon:'mdi-account'},
        {text:'Gestione Mail', path:'/home/amministrazione/gestionemail.vue', enabled:false, icon:'mdi-mail'},
      //  {text:'Gestione Permessi', path:'/home/amministrazione/authmenu', enabled:false, icon:'mdi-account-lock'},
      //  {text:'Gestione Progetti Ticket', path:'/home/amministrazione/progettiticket', enabled:false, icon:'mdi-account-hard-hat'},
      //  {text:'Gestione Ambiti Aziende Ticket', path:'/home/amministrazione/ambititicket', enabled:false, icon:'mdi-account-details'},
      ]
    };
  },
  mounted() { 
    const auth_level = localStorage.auth_level;
    for(let i = 0; i < this.routes.length; i++){ this.routes[i].enabled = false; }
    if(auth_level == -1){
      for(let i = 0; i < this.routes.length; i++){ this.routes[i].enabled = true; }
    }else if(auth_level == 1){
      this.routes[3].enabled = true; 
      this.routes[4].enabled = true; 
    }else if(auth_level > 1){
     for(let i = 0; i < this.routes.length; i++){ this.routes[i].enabled = false; }
    }
  },
  methods: {
      chiudi(){
          this.$router.push("/home/dashboard");
      }
  }

};
</script>
<style scoped>


</style>