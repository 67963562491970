<template>
      <v-card class="mt-5 ml-4 mr-4" elevation="0">
            <v-snackbar
              content-class="snackbar"
              :color="snackbar_color"
              left
              class="snackbar-gen"
              v-model="snackbar"
              :timeout="3000"
            >
              <div style="float: right;">
                  <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
              </div>
              <div>
              <h3 :style="{ color: snackbar_text_color + '!important' }">
                {{ snackbar_text }}
              </h3>
              </div>
            </v-snackbar>
            <v-row> 
              <v-col cols="12" sm="12" md="12">
                  <p class="titleLabelForm"> 
                      TICKET ALLOTMENT
                  </p>
              </v-col> 
              <v-col cols="12" sm="6" md="4" class="mt-n5 ">
                <v-autocomplete
                  v-model="ticket_type"
                  class="textFieldForm"
                  :items="tickettype_select"
                  item-color="var(--main-active-select-list-color)"
                  style=" color: var(--main-input-color); font-size: 18px;"
                  color="var(--main-border-color)"
                  clearable
                  clear-icon="mdi-close"
                  item-text="nome"
                  item-value="id_ticket_type"
                  outlined
                  label="Tickets Types"
                >
                <template v-slot:label>
                    <span class="">Tickets Types</span>
                    <a @click.stop ="FlagCrud=true"
                     style="margin-left:5px; pointer-events: all">
                    </a>
                     <v-icon
                      @click.stop ="FlagCrud=true"
                      >mdi-dots-vertical
                    </v-icon>
                </template>

                </v-autocomplete>
              </v-col> 
              <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--Quantity-->
                  <v-text-field
                    v-model="ticket_quantity"
                    label="Quantity"
                    outlined
                    color="var(--main-border-color)" 
                    style=" color: var(--main-input-color); font-size: 18px;"   
                    class="textFieldForm"
                    type="number"
                  ></v-text-field>
              </v-col>             

              <v-col cols="12" sm="6" md="1" class="mt-n2"> <!--Button Add-->
                  <v-btn
                    class="btn_plus"
                    color="var(--main-primary-color)"
                    style="color:var(--main-primary_text-color);"
                    @click="add_item"
                    elevation="0"
                  >
                    <v-icon dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn> 
              </v-col>   
              <v-col cols="12" sm="6" md="5" />
              <v-col cols="12" sm="12" md="12" class="mb-4" v-if="FlagCrud" style="border: 1px solid grey;" > <!--Gestione Ticket Type INSIDE-->
                  <v-toolbar dense  class="" color="white" elevation="0">
                    <span class="titleLabelForm ml-n3">TICKET TYPE</span>
                    <v-spacer></v-spacer>
                      <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                              <v-btn  small v-bind="attrs" v-on="on" @click="FlagCrud=false">
                                  <v-icon color="grey"
                                  >mdi-close-circle-outline
                                  </v-icon>
                              </v-btn>
                          </template>
                          <span>Chiudi.</span>
                      </v-tooltip>            
                    </v-toolbar>
                    <Crud
                        :ID_EVENT= this.ID_EVENT
                        v-on:reload_crud="getticket_type_select"  
                    />
              </v-col>                   
          
              <!-- Table -->
              <v-col cols="12" sm="6" md="5"  class="mt-n5" v-if="ticket_array.length>0">
                <template>
                  <v-data-table
                    :headers="headers"
                    :items="ticket_array"
                    hide-default-footer
                    :items-per-page="12000"                    
                  >
                      <template v-slot:[`header.id_type`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Ticket Type </a>
                      </template>                   
                      <template v-slot:[`header.quantity`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Quantity </a>
                      </template>                                      


                    <template v-slot:[`item.id_type`]="{ item }">
                        <v-autocomplete
                          v-if="item.id_ticket === editedItem.id_ticket" 
                          v-model="item.id_type"
                          class="textFieldForm"
                          :items="tickettype_select"
                          item-color="var(--main-active-select-list-color)"
                          style=" color: var(--main-input-color); font-size: 18px;"
                          color="var(--main-border-color)"
                          item-text="nome"
                          item-value="id_ticket_type"
                          :hide-details="true" 
                          dense 
                          single-line                        
                        ></v-autocomplete> 
                        <span v-else>                        
                          <v-autocomplete
                          v-model="item.id_type"
                          class="textFieldForm"
                          :items="tickettype_select"
                          item-color="var(--main-active-select-list-color)"
                          style=" color: var(--main-input-color); font-size: 18px;"
                          color="var(--main-border-color)"
                          item-text="nome"
                          item-value="id_ticket_type"
                          :hide-details="true" 
                          dense
                          readonly                       
                        ></v-autocomplete> </span>                          
                    </template>   
                    <template v-slot:[`item.quantity`]="{ item }">
                        <v-text-field  
                          v-if="item.id_ticket === editedItem.id_ticket" 
                          v-model="item.quantity" 
                          :hide-details="true" 
                          dense 
                          color="var(--main-border-color)" 
                          style=" color: var(--main-input-color); font-size: 18px;"   
                          class="textFieldForm mt-1"                          
                          single-line 
                          type="number"
                        ></v-text-field>
                        <span v-else>
                          <v-text-field  
                            v-model="item.quantity" 
                            :hide-details="true" 
                            dense 
                            color="var(--main-border-color)" 
                            style=" color: var(--main-input-color); font-size: 18px;"   
                            class="textFieldForm mt-1"                            
                            single-line 
                            readonly
                          ></v-text-field>
                        </span>                      
                    </template>       
                    <template v-slot:[`item.actions`]="{ item }">
                        <div v-if="item.id_ticket === editedItem.id_ticket">
                          <v-icon color="var(--main-primary-color)" class="mr-3" @click="close">
                            mdi-window-close
                          </v-icon>
                          <v-icon color="var(--main-primary-color)"  @click="save(item)">
                            mdi-content-save
                          </v-icon>
                        </div>
                        <div v-else>
                          <v-icon color="var(--main-primary-color)" class="mr-3" @click="editItem(item)">
                            mdi-pencil
                          </v-icon>
                          <v-icon color="var(--main-primary-color)" @click="deleteItem(item)">
                            mdi-delete
                          </v-icon>
                        </div>
                      </template>                    

                  </v-data-table>

                  <v-dialog v-model="dialogDelete" max-width="1024px" >
                    <v-card class="dialog-del">                      
                      <div class="alert-top">!</div>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color=" darken-1" class="btn_bg nohover" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color=" " class="btnForm" dark elevation="0" @click="deleteItemConfirm">Yes, proceed</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>   
                  <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                  </v-overlay>                                   
                </template>
              </v-col>
              <v-col cols="12" sm="6" md="2"  class="mt-n1" v-if="ticket_array.length>0">
                  <p><a class="textHeader" style="color:var(--main-dth-color);"> Total </a> </p>
                  <a class="textHeader" style="color:var(--main-dth-color);"> {{ sum_quantity }} </a>
              </v-col>

            </v-row>
      </v-card>   
</template>

<script>
import Crud from "@/components/CRUD/Crud_TicketType.vue";

export default { 
  components: {
    Crud,
     },  
	data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",     

      ticket_array:[],
      edited_ticket: {},
      editedIndex_ticket: -1,

      ticket_quantity:null,

      
      FlagCrud:false,
      tickettype:[], 
      tickettype_select:[], 
      ticket_type:null,      

      editedIndex: -1,
      editedItem: { id_ticket:0, id_type: 0, quantity: 0 },
      defaultItem: { id_ticket:0, id_type: 0, quantity: 0 },
      
      sum_quantity:0,

      date: null,
      menu:false,

      overlay: false,  

      dialogDelete: false,
      headers: [
        { text: 'Tickets Type', value: 'id_type' },
        { text: 'Quantity', value: 'quantity', width: "30%" },                        
        { text: '', value: 'actions', width: "20%", sortable: false },
      ],
     
    };		
	},
  props: {
      ID_EVENT: Number,
  },
  computed: {
      ticket_date () {
        return this.formatDate(this.date)
      },      

    },

  watch: {
        ID_EVENT: {
          immediate: true,
          handler() {
            this.getticket()
          //  this.getticket_type();
          //  this.getticket_type_select()
          },

      },
  },  	
  mounted() {
    
    
  },	
    

	methods:{

        getticket_type_select(){  
					this.overlay = true
					this.tickettype=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getticket_type",
									idevent: this.ID_EVENT,
                  tipo:'crud',
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
								this.tickettype_select = res.data.ticket_type;
								this.overlay = false 
							} else {
								this.snackbar_color = "error";
								this.snackbar_test = "Table Data not loaded.";
								this.snackbar = true;
								this.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							this.overlay = false
					});
				},    

        getticket_type(){  
					this.overlay = true
					this.tickettype=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getticket_type",
									idevent: this.ID_EVENT,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
								this.tickettype = res.data.ticket_type;
								this.overlay = false 
							} else {
								this.snackbar_color = "error";
								this.snackbar_test = "Table Data not loaded.";
								this.snackbar = true;
								this.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							this.overlay = false
					});
				},         

				getticket(){
					let that = this
					this.overlay = true
					this.ticket_array=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getticket",
									idevent: this.ID_EVENT,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
								that.ticket_array = res.data.ticket;
                this.somma_quantita();
								this.overlay = false 
                this.getticket_type();
                this.getticket_type_select()                
							} else {
								this.snackbar_color = "error";
								this.snackbar_test = "Table Data not loaded.";
								this.snackbar = true;
								this.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							this.overlay = false
					});
				},

				deleteItem (item) {
						this.editedIndex_ticket = this.ticket_array.indexOf(item)
						this.edited_ticket = Object.assign({}, item)
						this.dialogDelete = true
				},

				deleteItemConfirm () {
					this.ticket_array.splice(this.editedIndex_ticket, 1)
					this.delete(this.edited_ticket)
					this.closeDelete()
				},

				closeDelete () {
					this.dialogDelete = false
					this.$nextTick(() => {
						this.edited_ticket = {}
						this.editedIndex_ticket = -1
					})
				},      

				add_item(){
            let new_ticket = [{id_ticket:-1, fk_id_event: this.ID_EVENT , id_type: this.ticket_type, quantity: this.ticket_quantity }]
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setticket",
                    ticket: new_ticket,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {     
                  if (res.status == 200){
                      this.ticket_quantity = null
                      this.ticket_type = null                    
                      this.overlay = false
                      this.snackbar_color = "success";
                      this.snackbar_text = "Entry saved successfully. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.getticket();
                    } else {
                      this.snackbar_color = "error";
                      this.snackbar_text = "Data Saving Error. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.overlay = false
                    }           
            });           
				},

				delete(item){
					this.overlay = true
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action: "delticket",
									id_ticket: item.id_ticket,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
                  this.somma_quantita();
									this.overlay = false   
									this.snackbar_color = "success";
									this.snackbar_text = "Item deleted! ";
                  this.snackbar_icon = "mdi-window-close";
									this.snackbar = true;                    
							}
					});
				},				

        editItem (item) {
          this.editedIndex = this.ticket_array.indexOf(item);
          this.editedItem = Object.assign({}, item);
        },    

        close () {
            
          setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
          }, 300)
          this.getticket()
        },

        save(item){
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setticket",
                    ticket: [item] ,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {     
                  if (res.status == 200){
                      this.somma_quantita();
                      this.ticket_quantity = null
                      this.ticket_type = null                    
                      this.overlay = false
                      this.snackbar_color = "success";
                      this.snackbar_text = "Entry saved successfully. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.editedItem = Object.assign({}, this.defaultItem);
                      this.editedIndex = -1;                      
                      this.getticket();
                    } else {
                      this.snackbar_color = "error";
                      this.snackbar_text = "Data Saving Error. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.overlay = false
                    }           
            });            
        },

        somma_quantita () {
          let somma = this.alasql(
              "SELECT SUM (CAST(quantity AS INT)) as Tot FROM ? ",
              [this.ticket_array]
          );
          this.sum_quantity = somma[0].Tot
        }

	}
}

</script>
