<template>
      <v-card class="mt-5 ml-4 mr-4" elevation="0">
            <v-snackbar
              content-class="snackbar"
              :color="snackbar_color"
              left 
              class="snackbar-gen"
              v-model="snackbar"
              :timeout="3000"
            >
              <div style="float: right;">
                  <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
              </div>
              <div>
              <h3 :style="{ color: snackbar_text_color + '!important' }">
                {{ snackbar_text }}
              </h3>
              </div>
            </v-snackbar>
            <v-row> 
              <v-col cols="12" sm="12" md="12">
                  <p class="titleLabelForm"> 
                      ADD NOTE TO EVENT PACK FOR ALL SUBJECT
                  </p>
              </v-col> 
  
              <v-col cols="12" sm="6" md="3" class="mt-n5 "> <!--place-->
                  <v-text-field
                    v-model="note_title"
                    label="Title"
                    outlined
                    color="var(--main-border-color)" 
                    style=" color: var(--main-input-color); font-size: 18px;"  
                    maxlength=16
                    class="textFieldForm">
                  ></v-text-field>
              </v-col>   
 
              <v-col cols="12" sm="6" md="8" class="mt-n5 "> <!--time-->
                <v-text-field
                  v-model="note_description"
                  label="Description"
                  style="color: var(--main-input-color); font-size: 18px;"                              
                  class="textFieldForm"    
                  color="var(--main-border-color)"            
                  outlined                                                
                ></v-text-field> 
              </v-col>  

              <v-col cols="12" sm="6" md="1" class="mt-n2"> <!--Button Add-->
                  <v-btn
                    class="btn_plus"
                    color="var(--main-primary-color)"
                    style="color:var(--main-primary_text-color);"
                    @click="add_item"
                    elevation="0"
                  >
                    <v-icon dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn> 
              </v-col>   
      
          
              <!-- Table -->
              <v-col cols="12" sm="9"  class="mt-n5" v-if="note_array.length>0">
                <template>
                  <v-data-table
                    :headers="headers"
                    :items="note_array"
                    hide-default-footer
                    :items-per-page="12000"                    
                  >
                      <template v-slot:[`header.title`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Title </a>
                      </template>                                                                                  
                      <template v-slot:[`header.description`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Description </a>
                      </template>


                    <template v-slot:[`item.actions`]="{ item }">  
                      <v-icon
                        color="var(--main-primary-color)"
                        @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>

                  </v-data-table>

                  <v-dialog v-model="dialogDelete" max-width="1024px" >
                    <v-card class="dialog-del">                      
                      <div class="alert-top">!</div>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color=" darken-1" class="btn_bg nohover" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color=" " class="btnForm" dark elevation="0" @click="deleteItemConfirm">Yes, proceed</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>  
                                  
                </template>
              </v-col>
                  <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                  </v-overlay> 
            </v-row>
      </v-card>   
</template>

<script>

export default {   
	data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",     

      note_array:[],
      edited_note: {},
      editedIndex_note: -1,

      note_title:null,
      note_description:null,

     // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date: null,
      menu:false,

      overlay: false,  

      dialogDelete: false,
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Description', value: 'description' },                        
        { text: '', value: 'actions', sortable: false },
      ],
     
    };		
	},
  props: {
      ID_EVENT: Number,
  },
  computed: {
      note_date () {
        return this.formatDate(this.date)
      },
    },

  watch: {
        ID_EVENT: {
          immediate: true,
          handler() {
            this.getnote()
          },

      },
  },  	
  mounted() {
  },	
    

	methods:{

				getnote(){
					let that = this
					this.overlay = true
					this.note_array=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getnote",
									idevent: this.ID_EVENT,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
								that.note_array = res.data.note;
								this.overlay = false 
							} else {
								this.snackbar_color = "error";
								this.snackbar_test = "Table Data not loaded.";
								this.snackbar = true;
								this.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							this.overlay = false
					});
				},

				deleteItem (item) {
						this.editedIndex_note = this.note_array.indexOf(item)
						this.edited_note = Object.assign({}, item)
						this.dialogDelete = true
				},

				deleteItemConfirm () {
					this.note_array.splice(this.editedIndex_note, 1)
					this.delete(this.edited_note)
					this.closeDelete()
				},

				closeDelete () {
					this.dialogDelete = false
					this.$nextTick(() => {
						this.edited_note = {}
						this.editedIndex_note = -1
					})
				},      

				add_item(){
            let new_note = [{id_note:-1, fk_id_event: this.ID_EVENT ,   title: this.note_title, description: this.note_description, }]
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setnote",
                    note: new_note,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {     
                  if (res.status == 200){
                      this.note_title = null
                      this.note_description = null                    
                      this.overlay = false
                      this.snackbar_color = "success";
                      this.snackbar_text = "Entry saved successfully. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.getnote();
                    } else {
                      this.snackbar_color = "error";
                      this.snackbar_text = "Data Saving Error. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.overlay = false
                    }           
            });           
				},

				delete(item){
					this.overlay = true
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action: "delnote",
									id_note: item.id_note,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
									this.overlay = false   
									this.snackbar_color = "success";
									this.snackbar_text = "Item deleted! ";
                  this.snackbar_icon = "mdi-window-close";
									this.snackbar = true;                    
							}
					});
				},				
				
        parseDate (date) {
          if (!date) return null
          const [month, day, year] = date.split('/')
          return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        }, 
        format_Date (date) {
          if (!date) return null
          let new_date = date.substring(0,10)
          const [month,day,year] = new_date.split('/')
          return `${year}-${month}-${day}`
        }, 

        formatDate (date) {
          if (!date) return null
          const [year, month, day] = date.split('-')
          return `${month}/${day}/${year}`
        },      

	}
}

</script>
