<template>
        <v-toolbar dense  color="white" elevation="0">
            <v-toolbar-title>{{ NOME_AZIENDA }} — {{ NOME_UTENTE }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn @click="apriDashboard" icon align="center">
              <v-icon >mdi-home</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
            <v-menu
              :close-on-content-click="true"
              :nudge-width="200"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  
                  color="white"
                  light
                  v-bind="attrs"
                  v-on="on"
                  class="ml-4 mr-1 pr-1 pl-1"
                  elevation= 0
                >
                  <v-avatar size="30">
                    <v-icon>mdi-account</v-icon>
                  </v-avatar>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <!-- CARD UTENTE -->
              <v-card>
                <v-card-title>{{ NOME_UTENTE }}</v-card-title>
                <v-card-subtitle class="text-left">{{
                  DES_PROFILO
                }}</v-card-subtitle>
                <v-divider />
                <v-card-actions>
                  <!-- IMPOSTAZIONI -->
                  <v-btn
                    icon
                    x-small
                    title="Impostazioni Account"
                    @click="dialogGestioneAccount = true"
                  >
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                  <!-- AMMINISTRAZIONE -->
                  <v-btn
                    icon
                    x-small
                    title="Amministrazione"
                    v-if="show_admin_tools == true"
                    @click="$router.push('/amministrazione/dashboard')"
                  >
                    <v-icon>mdi-shield-account</v-icon>
                  </v-btn>
                  <!--
                    <v-btn
                      icon
                      x-small
                      title="Synch DB"
                      v-if="show_admin_tools == true"
                      @click="dialog_sync_db = true"
                    >
                      <v-icon>mdi-database-sync</v-icon>
                    </v-btn>
                  -->
                  <v-spacer />
                  <v-btn color="error" text @click="logout">LOGOUT</v-btn>
                </v-card-actions>

              </v-card>
            </v-menu>  

            <v-dialog
              v-model="dialogGestioneAccount" persistent width="600px">
              <v-card>
                  <v-toolbar dense  color="primary" elevation="3">
                  <span>Gestione Utente</span>
                  <v-spacer></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn  small v-bind="attrs" v-on="on" @click="dialogGestioneAccount=false">
                                <v-icon color="red"
                                >mdi-close-circle-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi.</span>
                    </v-tooltip>            
                  </v-toolbar>
                  <GestioneAccount/> 
              </v-card>
            </v-dialog>    

        </v-toolbar>

          
</template>


<script>
   // import * as fb from "../plugins/firebase";
    import GestioneAccount from "@/components/AMMINISTRAZIONE/GestioneAccount.vue";
    export default {
        components: {
          GestioneAccount,
        },
        data: () => ({
          dialogGestioneAccount:false,
          // SET UP GENERALI
          show_admin_tools: localStorage.show_admin_tools == "true",
        }),

        props: {
            NOME_AZIENDA: String,
            NOME_UTENTE: String,
            DES_PROFILO: String,
        },
        

        methods: { 

            logout() {
              localStorage.removeItem("user_token");
              localStorage.removeItem("id");
              localStorage.removeItem("nominativo");
              localStorage.removeItem("des_profilo");
              localStorage.removeItem("id_profilo");
              localStorage.removeItem("des_azienda");
              localStorage.removeItem("id_azienda");
              this.$router.replace("/login");

            },
            
            apriDashboard() {
              this.$router.push("/admin/events").catch(() => {});
            },            
          
        }

    }
</script>
