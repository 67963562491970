<template>
      <v-card class="mt-3 mr-4"  elevation="0">
        

            <v-snackbar
              content-class="snackbar"
              :color="snackbar_color"
              left 
              class="snackbar-gen"
              v-model="snackbar"
              :timeout="3000"
            >
              <div style="float: right;">
                  <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
              </div>
              <div>
              <h3 :style="{ color: snackbar_text_color + '!important' }">
                {{ snackbar_text }}
              </h3>
              </div>
            </v-snackbar>
            <v-row> 
              <v-col cols="12" sm="12" md="12">
                  <p class="titleLabelForm"> 
                  </p> 
              </v-col> 

              <v-col cols="12" sm="6" md="3" class="mt-n5 "> <!--Nome-->
                  <v-text-field
                    v-model="ticket_type_nome"
                    label="Nome"
                    outlined
                    color="var(--main-border-color)" 
                    style=" color: var(--main-input-color); font-size: 18px;"   
                    class="textFieldForm"
                  ></v-text-field>
              </v-col>   
              <v-col cols="12" sm="6" md="4" class="mt-n5 "> <!--Descrizione-->
                  <v-text-field
                    v-model="ticket_type_descrizione"
                    label="Descrizione"
                    outlined
                    color="var(--main-border-color)" 
                    style=" color: var(--main-input-color); font-size: 18px;"   
                    class="textFieldForm"
                  ></v-text-field>
              </v-col>               

              <v-col cols="12" sm="6" md="1" class="mt-n2"> <!--Button Add-->
                  <v-btn
                    class="btn_plus"
                    color="var(--main-primary-color)"
                    style="color:var(--main-primary_text-color);"
                    @click="add_item"
                    elevation="0"
                  >
                    <v-icon dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn> 
              </v-col>   
              <v-col cols="12" sm="6" md="4" />
          
              <!-- Table -->
              <v-col cols="12" sm="6" md="8"  class="mt-n5" v-if="ticket_type_array.length>0">
                <template>
                  <v-data-table
                    :headers="headers"
                    :items="ticket_type_array"
                    hide-default-footer
                    :items-per-page="12000"                    
                  >
                      <template v-slot:[`header.nome`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Type of Ticket </a>
                      </template>                   
                      <template v-slot:[`header.descrizione`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Description </a>
                      </template>                                      


                    <template v-slot:[`item.nome`]="{ item }">
                        <v-text-field  
                          v-if="item.id_ticket_type === editedItem.id_ticket_type" 
                          v-model="item.nome" 
                          :hide-details="true" 
                          dense 
                          color="var(--main-border-color)" 
                          style=" color: var(--main-input-color); font-size: 18px;"   
                          class="textFieldForm mt-1"                          
                          single-line 
                        ></v-text-field>
                        <span v-else>
                          <v-text-field  
                            v-model="item.nome" 
                            :hide-details="true" 
                            dense 
                            color="var(--main-border-color)" 
                            style=" color: var(--main-input-color); font-size: 18px;"   
                            class="textFieldForm mt-1"                            
                            single-line 
                            readonly
                          ></v-text-field>
                        </span>                      
                    </template>   

                    <template v-slot:[`item.descrizione`]="{ item }">
                        <v-text-field  
                          v-if="item.id_ticket_type === editedItem.id_ticket_type" 
                          v-model="item.descrizione" 
                          :hide-details="true" 
                          dense 
                          color="var(--main-border-color)" 
                          style=" color: var(--main-input-color); font-size: 18px;"   
                          class="textFieldForm mt-1"                          
                          single-line 
                        ></v-text-field>
                        <span v-else>
                          <v-text-field  
                            v-model="item.descrizione" 
                            :hide-details="true" 
                            dense 
                            color="var(--main-border-color)" 
                            style=" color: var(--main-input-color); font-size: 18px;"   
                            class="textFieldForm mt-1"                            
                            single-line 
                            readonly
                          ></v-text-field>
                        </span>                      
                    </template>   

                    <template v-slot:[`item.actions`]="{ item }">
                        <div v-if="item.id_ticket_type === editedItem.id_ticket_type">
                          <v-icon color="var(--main-primary-color)" class="mr-3" @click="close">
                            mdi-window-close
                          </v-icon>
                          <v-icon color="var(--main-primary-color)"  @click="save(item)">
                            mdi-content-save
                          </v-icon>
                        </div>
                        <div v-else>
                          <v-icon color="var(--main-primary-color)" class="mr-3" @click="editItem(item)">
                            mdi-pencil
                          </v-icon>
                          <v-icon color="var(--main-primary-color)" @click="deleteItem(item)">
                            mdi-delete
                          </v-icon>
                        </div>
                      </template>                    

                  </v-data-table>

                  <v-dialog v-model="dialogDelete" max-width="1024px" >
                    <v-card class="dialog-del">                      
                      <div class="alert-top">!</div>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color=" darken-1" class="btn_bg nohover" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color=" " class="btnForm" dark elevation="0" @click="deleteItemConfirm">Yes, proceed</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>  

                  <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                  </v-overlay>  

                </template>
              </v-col>


            </v-row>
      </v-card>   
</template>

<script>

export default { 
  components: {
     },  
	data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",     

      ticket_type_array:[],
      edited_ticket_type: {},
      editedIndex_ticket_type: -1,

      ticket_type_descrizione:null,
      ticket_type_nome:null,
      
      ticket_typetype:[],

      editedIndex: -1,
      editedItem: { id_ticket_type:0, fk_id_event:0, nome: "", descrizione: "" },
      defaultItem: { id_ticket_type:0, fk_id_event:0, id_type: 0, quantity: 0 },
      
      sum_quantity:0,

      date: null,
      menu:false,

      overlay: false,  

      dialogDelete: false,
      headers: [
        { text: 'Ticket Type', value: 'nome', width: "30%" },
        { text: 'Description', value: 'descrizione', width: "50%" },                        
        { text: '', value: 'actions', width: "20%", sortable: false },
      ],
     
    };		
	},
  props: {
      ID_EVENT: Number,
  },
  computed: {
      ticket_type_date () {
        return this.formatDate(this.date)
      },      

    },

  watch: {
        ID_EVENT: {
          immediate: true,
          handler() {
            this.getticket_type()
          },

      },
  },  	
  mounted() {
  },	
    

	methods:{

				getticket_type(){
					this.overlay = true
					this.ticket_type_array=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getticket_type",
									idevent: this.ID_EVENT,
                  tipo:'crud',
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
								this.ticket_type_array = res.data.ticket_type;
								this.overlay = false 
							} else {
								this.snackbar_color = "error";
								this.snackbar_test = "Table Data not loaded.";
								this.snackbar = true;
								this.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							this.overlay = false
					});
				},

				deleteItem (item) {
						this.editedIndex_ticket_type = this.ticket_type_array.indexOf(item)
						this.edited_ticket_type = Object.assign({}, item)
						this.dialogDelete = true
				},

				deleteItemConfirm () {
					this.ticket_type_array.splice(this.editedIndex_ticket_type, 1)
					this.delete(this.edited_ticket_type)
					this.closeDelete()
				},

				closeDelete () {
					this.dialogDelete = false
					this.$nextTick(() => {
						this.edited_ticket_type = {}
						this.editedIndex_ticket_type = -1
					})
				},      

				add_item(){
            let new_ticket_type = [{id_ticket_type:-1, fk_id_event: this.ID_EVENT , nome: this.ticket_type_nome, descrizione: this.ticket_type_descrizione }]
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setticket_type",
                    ticket_type: new_ticket_type,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {     
                  if (res.status == 200){
                      this.ticket_type_descrizione = null
                      this.ticket_type_nome = null                    
                      this.overlay = false
                      this.snackbar_color = "success";
                      this.snackbar_text = "Entry saved successfully. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.getticket_type();
                      this.$emit("reload_crud")
                    } else {
                      this.snackbar_color = "error";
                      this.snackbar_text = "Data Saving Error. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.overlay = false
                    }           
            });           
				},

				delete(item){
					this.overlay = true
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action: "delticket_type",
									id_ticket_type: item.id_ticket_type,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
									this.overlay = false 
                  this.$emit("reload_crud")  
									this.snackbar_color = "success";
									this.snackbar_text = "Item deleted! ";
                  this.snackbar_icon = "mdi-window-close";
									this.snackbar = true;                    
							}
					});
				},				
				
        getTabelleticket_typeTypes() {
          let request = {
            controller: "Global",
            method: "POST",
            richiesta: {
              action: "gettabelle",
              token: localStorage.getItem("user_token"),
              tipo: "ticket_type-TYPES",
              filter: "validita = 1",
            },
          };
          this.$store.dispatch("api", request).then((res) => {
            this.ticket_typetype = res.data;
          });
        },

        editItem (item) {
          this.editedIndex = this.ticket_type_array.indexOf(item);
          this.editedItem = Object.assign({}, item);
        },    

        close () {
            
          setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
          }, 300)
          this.getticket_type()
        },

        save(item){
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setticket_type",
                    ticket_type: [item] ,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {     
                  if (res.status == 200){
                      this.ticket_type_descrizione = null
                      this.ticket_type_nome = null                    
                      this.overlay = false
                      this.snackbar_color = "success";
                      this.snackbar_text = "Entry saved successfully. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.editedItem = Object.assign({}, this.defaultItem);
                      this.editedIndex = -1;                      
                      this.getticket_type();
                      this.$emit("reload_crud")  
                    } else {
                      this.snackbar_color = "error";
                      this.snackbar_text = "Data Saving Error. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.overlay = false
                    }           
            });            
        },



	}
}

</script>
