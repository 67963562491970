<template>
  <div>
    <v-card class="mt-8">
      <v-toolbar color="indigo" dense>
        <v-toolbar-title class="white--text"> Email Lista </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-5" v-on="on" v-bind="attrs" small light @click="openDiloag">
              <v-icon color="indigo">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Crea una nuova email</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" small light @click="goToInvioEmail">
              <v-icon color="indigo">mdi-cog</v-icon>
            </v-btn>
          </template>
          <span>Configurazione SMTP</span>
        </v-tooltip>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small @click="goBack" v-bind="attrs" v-on="on">
              <v-icon color="error"> mdi-keyboard-backspace </v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>
      </v-toolbar>

      <v-card-text>
        <v-data-table :headers="mailHeader" :items="mailLista">
          <template v-slot:[`item.edita`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  small
                  @click="apriDettaglio(item)"
                >
                  <v-icon color="indigo"> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>Dettaglio Email</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.delete`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  small
                  @click="deleteEmail(item)"
                >
                  <v-icon color="error"> mdi-window-close </v-icon>
                </v-btn>
              </template>
              <span>Cancella Email</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="email_dialog"
      persistent
      width="800"
      style="overflow-x: hidden !important"
    >
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"> Invio Email </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                small
                @click="SalvaEmail"
                ><v-icon color="success">mdi-floppy</v-icon></v-btn
              >
            </template>
            <span>Salva Email</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                class="ml-2"
                small
                @click="SendEmail"
              >
                <v-icon  color="indigo"> mdi-email-send</v-icon>
              </v-btn>
            </template>
            <span>Invio Email</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                @click="email_dialog = false"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col lg="4" md="4" sm="4">
              <v-text-field
                v-model="email_sel.des_modello"
                label="Nome del modello"
                class="pl-2"
              ></v-text-field>
            </v-col>
            <v-col lg="4" md="4" sm="4">
              <v-text-field
                v-model="email_sel.riferimento"
                label="Modulo di riferimento"
                class="pr-2"
              ></v-text-field>
            </v-col>
            <v-col lg="4" md="4" sm="4">
              <v-select
                v-model="email_sel.id_smtp"
                label="SMTP di riferimento"
                class="pl-2"
                :items="SmtpList"
                item-text="des_smtp"
                item-value="id_smtp"
              >
              </v-select>
              <!-- <v-text-field
              v-model="rif_smtp"
              label="SMTP di riferimento"
              class="pl-2"
            ></v-text-field> -->
            </v-col>
            <v-col lg="6" md="6" sm="6">
              <v-text-field
                v-model="email_sel.mittente"
                label="Nome mittente"
                class="pl-2"
              ></v-text-field>
            </v-col>
            <v-col lg="6" md="6" sm="6">
              <v-text-field
                v-model="email_sel.destinatari"
                label="Mail dei destinatari"
                class="pr-2"
              ></v-text-field>
            </v-col>
            <v-col lg="12" md="12" sm="12">
              <v-text-field
                v-model="email_sel.oggetto"
                label="Oggetto mail"
                class="pl-2"
              ></v-text-field>
            </v-col>

            <!-- <v-col lg="4" md="4" sm="4">
            <v-text-field
              v-model="uscita_smtp"
              label="SMTP di uscita"
              class="pr-2"
            ></v-text-field>
          </v-col> -->
            <div v-html="html"> </div>
            <v-col class="" md="12" lg="12" sm="12">
              <tiptap-vuetify
               style="text-align: left;"
                v-model="email_sel.body"
                :extensions="extensions"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </div>
</template>

<script>
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";
import {
  TiptapVuetify,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  // HorizontalRule,
  History,

} from "tiptap-vuetify";
class EmailObject {
  constructor(arg = undefined) {
    if (arg != undefined) {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = arg[c.nome] != undefined ? arg[c.nome] : c.default_val;
      });
    } else {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = c.default_val;
      });
    }
  }
  // Metodo per resettare tutte le campi
  reset() {
    const campi = this.setCampi();

    campi.forEach((c) => {
      this[c.nome] = c.default_val;
    });
  }

  setCampi() {
    const campi = [
      { nome: "body", default_val: "" },
      { nome: "des_modello", default_val: "" },
      { nome: "destinatari", default_val: null },
      { nome: "id_email_modello", default_val: -1 },
      { nome: "id_smtp", default_val: "" },
      { nome: "id_utente", default_val: localStorage.id },
      { nome: "mittente", default_val: "" },
      { nome: "oggetto", default_val: null },
      { nome: "riferimento", default_val: "" },
      { nome: "validita", default_val: 1 },
      { nome: "data_ins", default_val: "" },
      { nome: "data_mod", default_val: "" },
    ];

    return campi;
  }
}
export default {
  name: "Email",

  components: {
    TiptapVuetify,
    SnackbarQV,
  },

  data() {
    return {
      SmtpList: [],
      email_sel: new EmailObject(),
      email_dialog: false,
      mailHeader: [
        {
          text: "Modifica",
          align: "start",
          sortable: false,
          value: "edita",
        },
        {
          text: "Modello",
          value: "des_modello",
          sortable: false,
        },
        {
          text: "Modulo riferimento",
          value: "riferimento",
        },
        {
          text: "Rif SMTP",
          value: "id_smtp",
        },
        {
          text: "Mittente",
          value: "mittente",
        },
        {
          text: "Destinatari",
          value: "destinatari",
        },
        {
          text: "Oggetto mail",
          value: "oggetto",
        },
        {
          text: "mail",
          value: "body",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "delete",
        },
      ],
      mailLista: [],

      // rules: {
      //   email: (value) => {
      //     const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //     return pattern.test(value) || "Invalid e-mail.";
      //   },
      // },

      extensions: [
                Table,
        TableHeader,
        TableCell,
        TableRow,
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        // HorizontalRule,
        Paragraph,
        HardBreak,

      ],

      html: ` <p style="text-align: center;"><strong>Titolo</strong></p>
<p style="text-align: center;"><strong>Tabella Dati:</strong></p>
<table style="width: 98%; margin-left: calc(2%);">
    <thead>
        <tr>
            <th>evento</th>
            <th>test</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td style="width: 23.9389%; text-align: center;">pippo</td>
            <td style="width: 75.9762%; text-align: center;">pluto</td>
        </tr>
        <tr>
            <td style="width: 23.9389%; text-align: center;">paperino</td>
            <td style="width: 75.9762%; text-align: center;">aaaa</td>
        </tr>
    </tbody>
</table>`,

    };
  },
  mounted() {
    this.getTableData();
    this.getSMTPLIST();
  },
  methods: {
    goBack() {
      this.$router.push("/home");
    },
    deleteEmail(item){
       let email_data = new EmailObject(item);
       email_data.validita = 0
      const request = {
        controller: "global",
        method: "PUT",
        richiesta: {
          action: "setemailmodello",
          token: localStorage.user_token,
          email_list: [email_data],
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.$refs.SnackbarQV.print(
              "success",
              "Dati cancellati con successo."
            );
            this.getTableData();
          }
        })
        .catch(() => {
          this.$refs.SnackbarQV.print(
            "error",
            "Errore di comunicazione col server, non è stato possibile cancellare i dati."
          );
        });
    },
    goToInvioEmail() {
      this.$router.push("/home/setupsmtp");
    },
    openDiloag() {
      this.email_sel = new EmailObject();
      this.email_sel.body =this.html
      this.email_dialog = true;
    },
    apriDettaglio(item) {
      this.email_sel = new EmailObject(item);
      this.email_dialog = true;
    },
    getTableData() {
      {
        const request = {
          controller: "global",
          method: "POST",
          richiesta: {
            action: "getemailmodello",
            id_email_modello: "",
            token: localStorage.user_token,
          },
        };
        this.$store
          .dispatch("api", request)
          .then((res) => {
            this.mailLista = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getSMTPLIST() {
      const request = {
        controller: "global",
        method: "POST",
        richiesta: {
          action: "getsmtp",
          token: localStorage.user_token,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.SmtpList = res.data;
      });
    },
    SendEmail() {
      let email_data = new EmailObject(this.email_sel);

      const request = {
        controller: "global",
        method: "PUT",
        richiesta: {
          action: "setsmtptest",
          token: localStorage.user_token,
          id_smtp: email_data.id_smtp,
          mittente: email_data.mittente,
          destinatario: email_data.destinatari,
          oggetto: email_data.oggetto,
          body: email_data.body,
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.$refs.SnackbarQV.print(
              "success",
              "Email inviata con successo."
            );
            // this.email_dialog = false;
            // this.email_sel = new EmailObject();
          }
        })
        .catch(() => {
          this.$refs.SnackbarQV.print(
            "error",
            "Errore di comunicazione col server, Non è possibile inviare e-mail."
          );
        });
    },
    SalvaEmail() {
      let email_data = new EmailObject(this.email_sel);

      const request = {
        controller: "global",
        method: "PUT",
        richiesta: {
          action: "setemailmodello",
          token: localStorage.user_token,
          email_list: [email_data],
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.$refs.SnackbarQV.print(
              "success",
              "Dati aggiornati con successo."
            );
            this.email_dialog = false;
            this.email_sel = new EmailObject();
            this.getTableData();
          }
        })
        .catch(() => {
          this.$refs.SnackbarQV.print(
            "error",
            "Errore di comunicazione col server, non è stato possibile aggiornare i dati."
          );
        });
    },
  },
};
</script>


<style scoped>
.tiptap-vuetify-editor .ProseMirror {
  height: 400px !important;
}
</style>
