<template>
    <div class="ml-9 mt-2 mr-5" >
      <v-row>  
        <v-col cols="12" sm="12" md="12" class="ml-n3 mt-n2 titleEventTopContainer">
          <h2 class="titleEventTop "> 
            Event {{this.editedItem.event_name}} - {{this.editedItem.city_event}} - {{ this.formattedEventData }}
          </h2>                           
        </v-col>
      </v-row>
      <v-card id="create" class="" style="background-color: var(--main-bg-color);" elevation="0" >
          <v-row class="child-flex mt-12">

              <v-snackbar
                content-class="snackbar"
                :color="snackbar_color"
                left
                class="snackbar-gen"
                v-model="snackbar"
                :timeout="3000"
              >
                <div style="float: right;">
                    <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
                </div>
                <div>
                <h3 :style="{ color: snackbar_text_color + '!important' }">
                  {{ snackbar_text }}
                </h3>
                </div>
              </v-snackbar>

              <v-speed-dial
                  v-model="fab"
                  :top="top"
                  :bottom="bottom"
                  :right="right"
                  :left="left"
                  :direction="direction"
                  :open-on-hover="hover"
                  :transition="transition"
                >
                  <template v-slot:activator>
                    <v-btn
                      v-model="fab"
                      color="blue darken-2"
                      dark
                      fab
                    >
                      <v-icon v-if="fab" @click="toTop">
                         mdi-chevron-up
                      </v-icon>
                      <v-icon v-else>
                        mdi-account-circle
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-btn
                    fab
                    dark
                    small
                    @click="salva"
                    color="green"
                  >
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>

                  <v-btn
                    fab
                    dark
                    small
                    @click="closeEventDett"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-speed-dial>     

              <!--Event-->
              <v-row  class="mt-10" style="background-color:#ffffff;" >
                <v-col cols="12" sm="12" >
                  <v-card class="mt-3  ml-4 mr-4" elevation="0">
                      <v-row> 
                        <v-col cols="12" sm="12" class="ml-4">
                            <p class="titleLabelForm"> 
                                EVENT
                            </p>
                        </v-col>                                 
                        <v-col cols="12" class="ml-3 mt-n6" >                                           
                          <v-row >

                            <v-col cols="12" sm="6" md="6" class="mt-n1" >

                                <v-row class="mt-1">

                                    <!-- Event Name--> 
                                    <v-col cols="12" sm="6" md="6"  class="" > <!-- Event Name--> 
                                      <v-text-field
                                        v-model="editedItem.event_name"
                                        style="color: var(--main-input-color); font-size: 18px;"                              
                                        class="textFieldForm"    
                                        color="var(--main-border-color)"            
                                        outlined
                                        label="Event Name"
                                      ></v-text-field>
                                    </v-col>                                                                          

                                    <v-col cols="12" sm="6" md="6" class="">
                                        <v-text-field
                                          v-model="editedItem.city_event"
                                          label="City"
                                          prepend-inner-icon="mdi-filter-variant"
                                          outlined
                                          color="var(--main-border-color)" 
                                          style=" color: var(--main-input-color); font-size: 18px;"   
                                          class="textFieldForm">
                                        ></v-text-field>
                                    </v-col>                                
                                </v-row>                                

                            </v-col>
                            <v-col cols="12" sm="6" md="2" class="mt-3 ml-n3"> <!--DATE-->
                                <v-menu
                                    v-model="menueventdata"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field 
                                        v-model="formattedEventData"
                                        label="Date"
                                        style="color: var(--main-input-color); font-size: 18px;"                           
                                        class="textFieldForm"    
                                        color="var(--main-border-color)"               
                                        outlined
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        readonly
                                    >
                                        <template slot="label">
                                        Date
                                            <v-icon 
                                                v-if="formattedEventData!= null"
                                                class="mb-2"
                                                @click ="editedItem.date_event = null; menueventdata = true" 
                                            >mdi-close</v-icon>
                                        </template>
                                    </v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="editedItem.date_event"
                                    no-title
                                    @input="menueventdata = false"
                                    locale="en"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" md="2" class="mt-3 ml-n3"> <!--Country-->
                              <v-autocomplete
                                v-model="editedItem.country_event"
                                class="textFieldForm"
                                :items="country_flags"
                                :loading="isLoading_country"
                                :search-input.sync="search_country"
                                item-color="var(--main-active-select-list-color)"
                                style=" color: var(--main-input-color); font-size: 18px;"
                                color="var(--main-border-color)"
                                clearable
                                clear-icon="mdi-close"
                                label="Country"
                                hide-details
                                item-text="name"
                                item-value="id"
                                outlined
                              ></v-autocomplete>     
                            </v-col>  
                            <v-col cols="12" sm="6" md="2" class="mt-3 ml-n3"> <!--STATE-->
                              <v-text-field
                                v-model="editedItem.state_event"
                                style=" color: var(--main-input-color); font-size: 18px;"  
                                class= "textFieldForm"
                                color="var(--main-border-color)" 
                                label="State" 
                                outlined
                              ></v-text-field>    
                            </v-col>    

                            <v-col cols="12" sm="6" md="6" class="mt-n5" >
                                <v-row class="mt-1">
                                    <v-col cols="12" sm="6" md="6" class="" > <!--CITY--> 
                                      <v-text-field
                                        v-model="editedItem.additional_desc"
                                          style="color: var(--main-input-color); font-size: 18px;"                              
                                          class="textFieldForm"    
                                          color="var(--main-border-color)"            
                                          outlined
                                          label="City"
                                      ></v-text-field>
                                    </v-col> 
                                                            
                                    <v-col cols="12" sm="6" md="6" class="">
                                        <v-autocomplete
                                          v-model="editedItem.fk_id_venue"
                                          class="textFieldForm"
                                          :items="venues"
                                          item-color="var(--main-active-select-list-color)"
                                          style=" color: var(--main-input-color); font-size: 18px;"
                                          color="var(--main-border-color)"
                                          clearable
                                          clear-icon="mdi-close"
                                          item-text="name"
                                          item-value="id_address_book"
                                          outlined
                                          label="Venue Name"
                                          @change="onChangeVenue"
                                        ><!-- click on label
                                          <template #label>
                                            <span class="">Venue Name</span>
                                            <a @click.stop style="margin-left:5px; pointer-events: all">
                                              <Modules
                                                v-on:dialogModules="getVenue"
                                                tipo="VENUE"
                                                nome="Venue"
                                                visualizzaCodice="viewCode"
                                                setaction="settabelle"
                                                getaction="gettabelle"
                                                controller="Global"
                                                filter="validita = 1"
                                                :dati="[{ des: '', additional_desc: '' }]"
                                              />
                                            </a>
                                          </template>       
                                          -->                                               
                                        </v-autocomplete>
                                    </v-col>                                
                                </v-row>                                

                            </v-col>
                            <v-col cols="12" sm="6" md="2" class="mt-n1 ml-n3"> <!--Opening Door-->
                              <v-text-field
                                v-model="editedItem.open_door_time"
                                label="Opening Door"
                                value="00:00"
                                style="color: var(--main-input-color); font-size: 18px;"                              
                                class="textFieldForm"    
                                color="var(--main-border-color)"            
                                outlined                                                
                                type="time"
                              ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="6" md="2" class="mt-n1 ml-n3"> <!--Prelim Start-->
                              <v-text-field
                                v-model="editedItem.prelim_start_time"
                                label="Prelim Start"
                                value="00:00"
                                style="color: var(--main-input-color); font-size: 18px;"                              
                                class="textFieldForm"    
                                color="var(--main-border-color)"            
                                outlined                                                
                                type="time"
                              ></v-text-field>    
                            </v-col>  
                            <v-col cols="12" sm="6" md="2" class="mt-n1 ml-n3"> <!--Main Card Start -->                                            
                              <v-text-field
                                v-model="editedItem.main_card_time"
                                label="Main Card Start"
                                value="00:00"
                                style="color: var(--main-input-color); font-size: 18px;"                              
                                class="textFieldForm"    
                                color="var(--main-border-color)"            
                                outlined                                                
                                type="time"
                              ></v-text-field> 
                            </v-col>                                                                                                                                 


                          </v-row>                 
                        </v-col>              
                    </v-row>
                  </v-card>  
                </v-col>
              </v-row>   

              <!-- Row Contact Event -->
              <v-row  class="mt-10" style="background-color:#ffffff;" >
                <v-col cols="12" sm="12" >
                  <CardContactEvent 
                    :ID_EVENT=ITEM.id_event 
                  />
                </v-col>
              </v-row>                                

              <!--  Pre Event Zoom Call  -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <v-card class="mt-5 ml-4 mr-4" elevation="0">
                      <v-row> 
                        <v-col cols="12" sm="6" md="12" class="text-left">
                            <div class="titleLabelForm d-inline-block" > 
                                PRE EVENT ZOOM CALL
                            </div> 
                          <v-switch
                            class="ml-8 mt-n1 switch-gen d-inline-block"
                            label="Mail"
                            v-model="editedItem.prezoomcall_mail"
                            color="green"
                            inset
                          ></v-switch>
                          <v-switch
                            class="ml-8 mt-n1 switch-gen d-inline-block"
                            label="SMS"
                            v-model="editedItem.prezoomcall_sms"
                            color="green"
                            inset
                          ></v-switch>          
                        </v-col> 

                        <v-col cols="12" sm="6" md="2" class="mt-n5"> <!--DATE-->
                        <v-menu
                          v-model="menu_data_prezoomcall"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                              v-model="formattedPreZoomCallDate"
                              label="Date"
                              style="color: var(--main-input-color); font-size: 18px; "                              
                              class="textFieldForm"    
                              color="var(--main-border-color)"               
                              outlined
                              persistent-hint
                              prepend-inner-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                            >
                              <template slot="label">
                                Date
                                  <v-icon 
                                      v-if="formattedPreZoomCallDate!= null"
                                      class="mb-2"
                                      @click ="editedItem.prezoomcall_date = null; menu_data_prezoomcall = true" 
                                  >mdi-close</v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.prezoomcall_date"
                            no-title
                            @input="menu_data_prezoomcall = false"
                            locale="en"
                          ></v-date-picker>
                        </v-menu> 
                        </v-col>    
                        <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--Time-->
                          <v-text-field
                            v-model="editedItem.prezoomcall_time"
                            label="Time"
                            value="00:00"
                            style="color: var(--main-input-color); font-size: 18px;"                              
                            class="textFieldForm"    
                            color="var(--main-border-color)"   
                            outlined                                                
                            type="time"
                          ></v-text-field> 
                        </v-col>  
                        <v-col cols="12" sm="6" md="6" class="mt-n5 "> <!--Zoom Meeting Link-->
                            <v-text-field
                              v-model="editedItem.prezoomcall_link"
                              label="Zoom Meeting Link"
                              outlined
                              color="var(--main-border-color)" 
                              style=" color: var(--main-input-color); font-size: 18px;"   
                              class="textFieldForm">
                            ></v-text-field>
                        </v-col>                                    
                      </v-row>
                    </v-card>           
                  </v-col>
                </v-row>     
              </v-col> 
              
              <!-- Photo Shooting -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <PhotoShooting 
                      :ID_EVENT=ITEM.id_event 
                    />            
                  </v-col>
                </v-row>     
              </v-col>     

              <!-- CheckIn -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <CheckIn 
                      :ID_EVENT=ITEM.id_event 
                    />            
                  </v-col>
                </v-row>     
              </v-col>                      

              <!-- Training 
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <Training 
                      :ID_EVENT=ITEM.id_event 
                    />            
                  </v-col>
                </v-row>     
              </v-col>                      
             -->
              <!-- Social Interview -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                  <v-row   style="background-color: #ffffff; " >
                    <v-col cols="12" sm="12" >
                      <SocialInterview 
                        :ID_EVENT=ITEM.id_event 
                      />            
                    </v-col>
                  </v-row>     
              </v-col> 

              <!-- Zoom Interview -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <ZoomInterview 
                      :ID_EVENT=ITEM.id_event 
                    />            
                  </v-col>
                </v-row>     
              </v-col> 

              <!-- Media Day -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                  <v-row   style="background-color: #ffffff; " >
                    <v-col cols="12" sm="12" >
                      <MediaDay 
                        :ID_EVENT=ITEM.id_event 
                      />            
                    </v-col>
                  </v-row>     
              </v-col>              

              <!-- Commentator Interview -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                  <v-row   style="background-color: #ffffff; " >
                    <v-col cols="12" sm="12" >
                      <CommentatorInterview 
                        :ID_EVENT=ITEM.id_event 
                      />            
                    </v-col>
                  </v-row>     
              </v-col> 

              <!-- Press Conference -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <v-card class="mt-5 ml-4 mr-4" elevation="0">
                      <v-row style="align-items: center;"> 
                        <v-col cols="12" sm="12" md="12" class="text-left">
                          <div class="titleLabelForm d-inline-block"> 
                                PRESS CONFERENCE
                          </div> 
                          <v-switch
                          class="mt-n1 ml-8 switch-gen d-inline-block"
                            label="Mail"
                            v-model="editedItem.conference_mail"
                            color="green"
                            inset
                          ></v-switch>
                          <v-switch
                          class="mt-n1 ml-8 switch-gen d-inline-block"
                            label="SMS"
                            v-model="editedItem.conference_sms"
                            color="green"
                            inset
                          ></v-switch>          
                        </v-col> 

                        <v-col cols="12" sm="6" md="2" class="mt-n5"> <!--DATE-->
                        <v-menu
                          v-model="menu_data_conference"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                              v-model="formattedConferenceDate"
                              label="Date"
                              style="color: var(--main-input-color); font-size: 18px; "                              
                              class="textFieldForm"    
                              color="var(--main-border-color)"               
                              outlined
                              persistent-hint
                              prepend-inner-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                            >
                              <template slot="label">
                                Date
                                  <v-icon 
                                      v-if="formattedConferenceDate!= null"
                                      class="mb-2"
                                      @click ="editedItem.conference_date = null; menu_data_conference = true" 
                                  >mdi-close</v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.conference_date"
                            no-title
                            @input="menu_data_conference = false"
                            locale="en"
                          ></v-date-picker>
                        </v-menu> 
                        </v-col>    
                        <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--Local Time-->
                          <v-text-field
                            v-model="editedItem.conference_time"
                            label="Local Time"
                            value="00:00"
                            style="color: var(--main-input-color); font-size: 18px;"                              
                            class="textFieldForm"    
                            color="var(--main-border-color)"   
                            outlined                                                
                            type="time"
                          ></v-text-field> 
                        </v-col>  
                        <v-col cols="12" sm="6" md="6" class="mt-n5 "> <!--Place-->
                            <v-text-field
                              v-model="editedItem.conference_place"
                              label="Place"
                              outlined
                              color="var(--main-border-color)" 
                              style=" color: var(--main-input-color); font-size: 18px;"   
                              class="textFieldForm">
                            ></v-text-field>
                        </v-col>                                    
                        <v-col cols="12" sm="6" md="2" class="mt-n10"> <!--Button Add-->
                            
                            <v-switch
                              label="Transportation"
                              class="ml-8 switch-gen"
                              v-model="editedItem.conference_transportation"
                              color="green"
                              inset
                            ></v-switch>                          
                        </v-col>   


                      </v-row>
                    </v-card>           
                  </v-col>
                </v-row>     
              </v-col> 

              <!-- Locker Room -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                  <v-row   style="background-color: #ffffff;  align-items: center;" >
                    <v-col cols="12" sm="12" >
                      <v-card class="mt-5 ml-4 mr-4" elevation="0">
                        <v-row style="align-items: center;"> 
                          <v-col cols="12" sm="6" md="12" class="text-left">
                            <div class="titleLabelForm mr-7" style="display: inline-block;"> 
                                  LOCKER ROOM
                            </div> 
                            <v-switch
                            class="mt-n1 ml-8 switch-gen d-inline-block"
                              label="Mail"
                              v-model="editedItem.locker_room_mail"
                              color="green"
                              inset
                            ></v-switch>
                            <v-switch
                            class="mt-n1 ml-8 switch-gen d-inline-block"
                              label="SMS"
                              v-model="editedItem.locker_room_sms"
                              color="green"
                              inset
                            ></v-switch>          
                          </v-col> 

                          <v-col cols="12" sm="6" md="2" class="mt-n5"> <!--DATE-->
                          <v-menu
                            v-model="menu_data_locker_room"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field 
                                v-model="formattedlocker_roomDate"
                                label="Date"
                                style="color: var(--main-input-color); font-size: 18px; "                              
                                class="textFieldForm"    
                                color="var(--main-border-color)"               
                                outlined
                                persistent-hint
                                prepend-inner-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                readonly
                              >
                                <template slot="label">
                                  Date
                                    <v-icon 
                                        v-if="formattedlocker_roomDate!= null"
                                        class="mb-2"
                                        @click ="editedItem.locker_room_date = null; menu_data_locker_room = true" 
                                    >mdi-close</v-icon>
                                </template>
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.locker_room_date"
                              no-title
                              @input="menu_data_locker_room = false"
                              locale="en"
                            ></v-date-picker>
                          </v-menu> 
                          </v-col>    
                          <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--Local Time-->
                            <v-text-field
                              v-model="editedItem.locker_room_time_from"
                              label="Local Time From"
                              value="00:00"
                              style="color: var(--main-input-color); font-size: 18px;"                              
                              class="textFieldForm"    
                              color="var(--main-border-color)"   
                              outlined                                                
                              type="time"
                            ></v-text-field> 
                          </v-col>  
                          <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--Local Time-->
                            <v-text-field
                              v-model="editedItem.locker_room_time_to"
                              label="Local Time To"
                              value="00:00"
                              style="color: var(--main-input-color); font-size: 18px;"                              
                              class="textFieldForm"    
                              color="var(--main-border-color)"   
                              outlined                                                
                              type="time"
                            ></v-text-field> 
                          </v-col>                        
                          <v-col cols="12" sm="6" md="6" class="mt-n5 "> <!--Place-->
                              <v-text-field
                                v-model="editedItem.locker_room_place"
                                label="Place"
                                outlined
                                color="var(--main-border-color)" 
                                style=" color: var(--main-input-color); font-size: 18px;"   
                                class="textFieldForm">
                              ></v-text-field>
                          </v-col>                                    
                          <v-col cols="12" sm="6" md="2" class="mt-n8"> <!--Button Add-->
                              <v-switch
                                label="Transportation"
                                class="ml-8 mt-0 switch-gen"
                                v-model="editedItem.locker_room_transportation"
                                color="green"
                                inset
                              ></v-switch>                          
                          </v-col>   
                          <v-col cols="12" sm="6" md="10" class="mt-n5 "> <!--Place-->
                              <v-text-field
                                v-model="editedItem.locker_room_note"
                                label="Note"
                                outlined
                                color="var(--main-border-color)" 
                                style=" color: var(--main-input-color); font-size: 18px;"   
                                class="textFieldForm">
                              ></v-text-field>
                          </v-col>


                        </v-row>
                      </v-card>           
                    </v-col>
                  </v-row>     
              </v-col>                 

              <!-- Official weigh ins -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                  <v-row   style="background-color: #ffffff; align-items:center" >
                    <v-col cols="12" sm="12" >
                      <v-card class="mt-5 ml-4 mr-4" elevation="0">
                        <v-row style="align-items: center;"> 
                          <v-col cols="12" sm="12" md="12" class="text-left">
                               <P class="titleLabelForm d-inline-block"> 
                                  OFFICIAL WEIGH INS
                              </p> 
                            <v-switch
                            class="mt-n1 ml-8 switch-gen d-inline-block"
                              label="Mail"
                              v-model="editedItem.weigh_mail"
                              color="green"
                              inset
                            ></v-switch>
  
                            <v-switch
                            class="mt-n1 ml-8 switch-gen d-inline-block"
                              label="SMS"
                              v-model="editedItem.weigh_sms"
                              color="green"
                              inset
                            ></v-switch>          
                          </v-col> 

                          <v-col cols="12" sm="6" md="2" class="mt-n5"> <!--DATE-->
                          <v-menu
                            v-model="menu_data_weigh"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field 
                                v-model="formattedWeighDate"
                                label="Date"
                                style="color: var(--main-input-color); font-size: 18px; "                              
                                class="textFieldForm"    
                                color="var(--main-border-color)"               
                                outlined
                                persistent-hint
                                prepend-inner-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                readonly
                              >
                                <template slot="label">
                                  Date
                                    <v-icon 
                                        v-if="formattedWeighDate!= null"
                                        class="mb-2"
                                        @click ="editedItem.weigh_date = null; menu_data_weigh = true" 
                                    >mdi-close</v-icon>
                                </template>
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.weigh_date"
                              no-title
                              @input="menu_data_weigh = false"
                              locale="en"
                            ></v-date-picker>
                          </v-menu> 
                          </v-col>    
                          <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--Local Time-->
                            <v-text-field
                              v-model="editedItem.weigh_time_from"
                              label="Local Time From"
                              value="00:00"
                              style="color: var(--main-input-color); font-size: 18px;"                              
                              class="textFieldForm"    
                              color="var(--main-border-color)"   
                              outlined                                                
                              type="time"
                            ></v-text-field> 
                          </v-col>  
                          <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--Local Time-->
                            <v-text-field
                              v-model="editedItem.weigh_time_to"
                              label="Local Time To"
                              value="00:00"
                              style="color: var(--main-input-color); font-size: 18px;"                              
                              class="textFieldForm"    
                              color="var(--main-border-color)"   
                              outlined                                                
                              type="time"
                            ></v-text-field> 
                          </v-col>                        
                          <v-col cols="12" sm="6" md="6" class="mt-n5 "> <!--Place-->
                              <v-text-field
                                v-model="editedItem.weigh_place"
                                label="Place"
                                outlined
                                color="var(--main-border-color)" 
                                style=" color: var(--main-input-color); font-size: 18px;"   
                                class="textFieldForm">
                              ></v-text-field>
                          </v-col>                                    
                          <v-col cols="12" sm="6" md="2" class="mt-n8"> <!--Button Add-->
                        
                              <v-switch
                                label="Transportation"
                                class="ml-8 mt-0 switch-gen"
                                v-model="editedItem.weigh_transportation"
                                color="green"
                                inset
                              ></v-switch>                          
                          </v-col>   
                          <v-col cols="12" sm="6" md="10" class="mt-n5 "> <!--Place-->
                              <v-text-field
                                v-model="editedItem.weigh_note"
                                label="Note"
                                outlined
                                color="var(--main-border-color)" 
                                style=" color: var(--main-input-color); font-size: 18px;"   
                                class="textFieldForm">
                              ></v-text-field>
                          </v-col>


                        </v-row>
                      </v-card>           
                    </v-col>
                  </v-row>     
              </v-col>   

              <!-- MOCK WEIGH INS Fighters faceoff  -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                  <v-row   style="background-color: #ffffff; align-items: center; " >
                    <v-col cols="12" sm="12" >
                      <v-card class="mt-5 ml-4 mr-4" elevation="0">
                        <v-row> 

                          <v-col cols="12" sm="12" md="12" class="text-left">
                              <P class="titleLabelForm d-inline-block"> 
                                  MOCK WEIGH INS
                              </p> 
                            <v-switch
                            class="mt-n1 ml-8 switch-gen d-inline-block"
                              label="Mail"
                              v-model="editedItem.fighterfaceoff_mail"
                              color="green"
                              inset
                            ></v-switch>
                            <v-switch
                            class="mt-n1 ml-8 switch-gen d-inline-block"
                              label="SMS"
                              v-model="editedItem.fighterfaceoff_sms"
                              color="green"
                              inset
                            ></v-switch>          
                          </v-col> 


                          <v-col cols="12" sm="6" md="2" class="mt-n5"> <!--DATE-->
                          <v-menu
                            v-model="menu_data_fighterfaceoff"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field 
                                v-model="formattedFighterfaceoffDate"
                                label="Date"
                                style="color: var(--main-input-color); font-size: 18px; "                              
                                class="textFieldForm"    
                                color="var(--main-border-color)"               
                                outlined
                                persistent-hint
                                prepend-inner-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                readonly
                              >
                                <template slot="label">
                                  Date
                                    <v-icon 
                                        v-if="formattedFighterfaceoffDate!= null"
                                        class="mb-2"
                                        @click ="editedItem.fighterfaceoff_date = null; menu_data_fighterfaceoff = true" 
                                    >mdi-close</v-icon>
                                </template>
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.fighterfaceoff_date"
                              no-title
                              @input="menu_data_fighterfaceoff = false"
                              locale="en"
                            ></v-date-picker>
                          </v-menu> 
                          </v-col>    
                          <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--Local Time-->
                            <v-text-field
                              v-model="editedItem.fighterfaceoff_time"
                              label="Local Time"
                              value="00:00"
                              style="color: var(--main-input-color); font-size: 18px;"                              
                              class="textFieldForm"    
                              color="var(--main-border-color)"   
                              outlined                                                
                              type="time"
                            ></v-text-field> 
                          </v-col>  
                          <v-col cols="12" sm="6" md="6" class="mt-n5 "> <!--Place-->
                              <v-text-field
                                v-model="editedItem.fighterfaceoff_place"
                                label="Place"
                                outlined
                                color="var(--main-border-color)" 
                                style=" color: var(--main-input-color); font-size: 18px;"   
                                class="textFieldForm">
                              ></v-text-field>
                          </v-col>                                    
                          <v-col cols="12" sm="6" md="2" class="mt-n1"> <!--Button Add-->
                              
                              <v-switch
                                label="Transportation"
                                class="ml-8 mt-n1 switch-gen"
                                v-model="editedItem.fighterfaceoff_transportation"
                                color="green"
                                inset
                              ></v-switch>                          
                          </v-col>   

                            <v-col v-if="editedItem.fighterfaceoff_transportation" cols="12" sm="6" md="12" class="text-left">
                                <p class="titleLabelForm d-inline-block"> 
                                    FIGHTERS FACE-OFF LOBBY CALL
                                </p> 
                              <v-switch
                                class="mt-n1 ml-8 switch-gen d-inline-block"
                                label="Mail"
                                v-model="editedItem.fighterfaceofflobby_mail"
                                color="green"
                                inset
                              ></v-switch>
                              <v-switch
                                class="mt-n1 ml-8 switch-gen d-inline-block" 
                                label="SMS"
                                v-model="editedItem.fighterfaceofflobby_sms"
                                color="green"
                                inset
                              ></v-switch>          
                            </v-col> 

                            <v-col v-if="editedItem.fighterfaceoff_transportation" cols="12" sm="6" md="2" class="mt-n5"> <!--DATE-->
                            <v-menu
                              v-model="menu_data_fighterfaceofflobby"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field 
                                  v-model="formattedFighterfaceofflobbyDate"
                                  label="Date"
                                  style="color: var(--main-input-color); font-size: 18px; "                              
                                  class="textFieldForm"    
                                  color="var(--main-border-color)"               
                                  outlined
                                  persistent-hint
                                  prepend-inner-icon="mdi-calendar"
                                  v-bind="attrs"
                                  v-on="on"
                                  readonly
                                >
                                  <template slot="label">
                                    Date
                                      <v-icon 
                                          v-if="formattedFighterfaceofflobbyDate!= null"
                                          class="mb-2"
                                          @click ="editedItem.fighterfaceofflobby_date = null; menu_data_fighterfaceofflobby= true" 
                                      >mdi-close</v-icon>
                                  </template>
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.fighterfaceofflobby_date"
                                no-title
                                @input="menu_data_fighterfaceofflobby = false"
                                locale="en"
                              ></v-date-picker>
                            </v-menu> 
                            </v-col>    
                            <v-col v-if="editedItem.fighterfaceoff_transportation" cols="12" sm="6" md="2" class="mt-n5 "> <!--Local Time-->
                              <v-text-field
                                v-model="editedItem.fighterfaceofflobby_time"
                                label="Local Time"
                                value="00:00"
                                style="color: var(--main-input-color); font-size: 18px;"                              
                                class="textFieldForm"    
                                color="var(--main-border-color)"   
                                outlined                                                
                                type="time"
                              ></v-text-field> 
                            </v-col>  
                            <v-col v-if="editedItem.fighterfaceoff_transportation" cols="12" sm="6" md="6" class="mt-n5 "> <!--Place-->
                                <v-text-field
                                  v-model="editedItem.fighterfaceofflobby_place"
                                  label="Place"
                                  outlined
                                  color="var(--main-border-color)" 
                                  style=" color: var(--main-input-color); font-size: 18px;"   
                                  class="textFieldForm">
                                ></v-text-field>
                            </v-col>                                    

 
                        </v-row>
                      </v-card>           
                    </v-col>
                  </v-row>     
              </v-col>               

              <!-- RULES MEETING -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                <v-row   style="background-color: #ffffff; align-items: center; " >
                  <v-col cols="12" sm="12" >
                    <v-card class="mt-5 ml-4 mr-4" elevation="0">
                      <v-row style="align-items:center"> 

                        <v-col cols="12" sm="12" md="12" class="text-left">
                            <p class="titleLabelForm d-inline-block"> 
                                RULES MEETING
                            </p> 
                          <v-switch
                          class="mt-n1 ml-8 switch-gen d-inline-block"
                            label="Mail"
                            v-model="editedItem.rulesmeeting_mail"
                            color="green"
                            inset
                          ></v-switch>

                          <v-switch
                          class="mt-n1 ml-8 switch-gen d-inline-block"
                            label="SMS"
                            v-model="editedItem.rulesmeeting_sms"
                            color="green"
                            inset
                          ></v-switch>          
                        </v-col> 
                        <v-col cols="12" sm="6" md="2" class="mt-n5"> <!--DATE-->
                        <v-menu
                          v-model="menu_data_rulesmeeting"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                              v-model="formattedRulesmeetingDate"
                              label="Date"
                              style="color: var(--main-input-color); font-size: 18px; "                              
                              class="textFieldForm"    
                              color="var(--main-border-color)"               
                              outlined
                              persistent-hint
                              prepend-inner-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                            >
                              <template slot="label">
                                Date
                                  <v-icon 
                                      v-if="formattedRulesmeetingDate!= null"
                                      class="mb-2"
                                      @click ="editedItem.rulesmeeting_date = null; menu_data_rulesmeeting = true" 
                                  >mdi-close</v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.rulesmeeting_date"
                            no-title
                            @input="menu_data_rulesmeeting = false"
                            locale="en"
                          ></v-date-picker>
                        </v-menu> 
                        </v-col>    
                        <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--Local Time-->
                          <v-text-field
                            v-model="editedItem.rulesmeeting_time"
                            label="Local Time"
                            value="00:00"
                            style="color: var(--main-input-color); font-size: 18px;"                              
                            class="textFieldForm"    
                            color="var(--main-border-color)"   
                            outlined                                                
                            type="time"
                          ></v-text-field> 
                        </v-col>  
                        <v-col cols="12" sm="6" md="6" class="mt-n5 "> <!--Place-->
                            <v-text-field
                              v-model="editedItem.rulesmeeting_place"
                              label="Place"
                              outlined
                              color="var(--main-border-color)" 
                              style=" color: var(--main-input-color); font-size: 18px;"   
                              class="textFieldForm">
                            ></v-text-field>
                        </v-col>                                    
                        <v-col cols="12" sm="6" md="2" class="mt-n8"> <!--Button Add-->
                          
                            <v-switch
                              label="Transportation"
                              class="ml-8 mt-0 switch-gen"
                              v-model="editedItem.rulesmeeting_transportation"
                              color="green"
                              inset
                            ></v-switch>                          
                        </v-col>   


                      </v-row>
                    </v-card>           
                  </v-col>
                </v-row>     
              </v-col>     

              <!-- DEFAULT LOBBY CALL TO VENUE -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                <v-row   style="background-color: #ffffff; align-items: center; " >
                  <v-col cols="12" sm="12" >
                    <v-card class="mt-5 ml-4 mr-4" elevation="0">
                      <v-row style="align-items: center;"> 

                        <v-col cols="12" sm="12" md="12" class="text-left">
                            <p class="titleLabelForm d-inline-block"> 
                                DEFAULT LOBBY CALL TO VENUE
                            </p> 
   
                          <v-switch
                          class="mt-n1 ml-8 switch-gen d-inline-block"
                            label="Mail"
                            v-model="editedItem.lobbycall_mail"
                            color="green"
                            inset
                          ></v-switch>

                          <v-switch
                          class="mt-n1 ml-8 switch-gen d-inline-block"
                            label="SMS"
                            v-model="editedItem.lobbycall_sms"
                            color="green"
                            inset
                          ></v-switch>          
                        </v-col> 



                        <v-col cols="12" sm="6" md="2" class="mt-n5"> <!--DATE-->

                        <v-menu
                          v-model="menu_data_lobbycall"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                              v-model="formattedLobbycallDate"
                              label="Date"
                              style="color: var(--main-input-color); font-size: 18px; "                              
                              class="textFieldForm"    
                              color="var(--main-border-color)"               
                              outlined
                              persistent-hint
                              prepend-inner-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                            >
                              <template slot="label">
                                Date
                                  <v-icon 
                                      v-if="formattedLobbycallDate!= null"
                                      class="mb-2"
                                      @click ="editedItem.lobbycall_date = null; menu_data_lobbycall = true" 
                                  >mdi-close</v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.lobbycall_date"
                            no-title
                            @input="menu_data_lobbycall = false"
                            locale="en"
                          ></v-date-picker>
                        </v-menu> 

                        </v-col>    
                        <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--Local Time-->
                          <v-text-field
                            v-model="editedItem.lobbycall_time"
                            label="Local Time"
                            value="00:00"
                            style="color: var(--main-input-color); font-size: 18px;"                              
                            class="textFieldForm"    
                            color="var(--main-border-color)"   
                            outlined                                                
                            type="time"
                          ></v-text-field> 
                        </v-col>  
                        <v-col cols="12" sm="6" md="6" class="mt-n5 "> <!--Place-->
                            <v-text-field
                              v-model="editedItem.lobbycall_place"
                              label="Place"
                              outlined
                              color="var(--main-border-color)" 
                              style=" color: var(--main-input-color); font-size: 18px;"   
                              class="textFieldForm">
                            ></v-text-field>
                        </v-col>                                    
                        <v-col cols="12" sm="6" md="2" class="mt-n8"><!--Button Add-->
                          <v-switch
                              label="Transportation"
                              class="ml-8 mt-0 switch-gen"
                              v-model="editedItem.lobbycall_transportation"
                              color="green"
                              inset
                            ></v-switch>                          
                        </v-col>   


                      </v-row>
                    </v-card>           
                  </v-col>
                </v-row>     
              </v-col> 

              <!--CovidTest -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
              <v-row   style="background-color: #ffffff; " >
                <v-col cols="12" sm="12" >
                  <CovidTest 
                    :ID_EVENT=ITEM.id_event 
                  />            
                </v-col>
              </v-row>     
              </v-col>  

              <!--Appointment -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
              <v-row   style="background-color: #ffffff; " >
                <v-col cols="12" sm="12" >
                  <Appointment 
                    :ID_EVENT=ITEM.id_event 
                  />            
                </v-col>
              </v-row>     
              </v-col>  

              <!--Note -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <Note 
                      :ID_EVENT=ITEM.id_event 
                    />            
                  </v-col>
                </v-row>     
              </v-col> 

              <!--Ticket -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
              <v-row   style="background-color: #ffffff; " >
                <v-col cols="12" sm="12" >
                  <Ticket
                    :ID_EVENT=ITEM.id_event 
                  />            
                </v-col>
              </v-row>     
              </v-col>  

              <!-- FOOD / PER DIEM -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <v-card class="mt-5 ml-4 mr-4" elevation="0">
                      <v-row> 
                        <v-col cols="12" sm="12" md="12">
                            <p class="titleLabelForm"> 
                                FOOD / PER DIEM
                            </p>
                        </v-col> 
    
                        <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--Local Time-->
                            <v-select
                              class="textFieldForm ml-1"
                              style=" color: var(--main-input-color); font-size: 18px;"
                              item-color="var(--main-active-select-list-color)"
                              color="var(--main-border-color)" 
                              :items="diem_ticket"
                              v-model="editedItem.id_diemticket"
                              outlined
                              menu-props="auto"
                              label="Diem/Tks"
                              item-text="des"
                              item-value="id"   
                            ></v-select>  
                        </v-col>  

                        <v-col cols="12" sm="6" md="4" class="mt-n5">  <!--CURRENCY-->
                            <v-autocomplete
                              item-color="var(--main-active-select-list-color)"
                              v-model="editedItem.id_currency"
                              class="textFieldForm"
                              :items="country_currency"
                              :loading="isLoading_currency"
                              :search-input.sync="search_currency"
                              style=" font-size: 18px;"
                              color="var(--main-border-color)"
                              clearable
                              label="Currency"
                              hide-details
                              item-text="des"
                              item-value="id"
                              outlined
                            ></v-autocomplete>                
                        </v-col>      
                        <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--Local Time-->
                            <v-select
                              class="textFieldForm ml-1"
                              style=" color: var(--main-input-color); font-size: 18px;"
                              item-color="var(--main-active-select-list-color)"
                              color="var(--main-border-color)" 
                              :items="meal_day"
                              v-model="editedItem.id_mealday"
                              outlined
                              menu-props="auto"
                              label="Meal/Day"
                              item-text="des"
                              item-value="id"   
                            ></v-select>  
                        </v-col>                                           

                        <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--Place-->
                            <v-text-field
                              v-model="editedItem.diem"
                              label="Diem"
                              outlined
                              color="var(--main-border-color)" 
                              style=" color: var(--main-input-color); font-size: 18px;"   
                              class="textFieldForm">
                            ></v-text-field>
                        </v-col>                                    


                      </v-row>
                    </v-card>           
                  </v-col>
                </v-row>     
              </v-col> 

              <!-- Info Hotel -->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                <v-row   style="background-color: #ffffff; " >

                  <v-col cols="12" sm="12" >
                    <v-card class="mt-5 ml-4 mr-4" elevation="0">
                      <v-row> 
                        <v-col cols="12" sm="12" md="12">
                            <p class="titleLabelForm"> 
                                INFO HOTEL
                            </p>
                        </v-col> 
    
                        <v-col cols="12" sm="6" md="4" class="mt-n5 "> <!--Hotel Name-->
                          <v-text-field
                              v-model="editedItem.hotel_name"
                              label="Hotel Name"
                              outlined
                              color="var(--main-border-color)" 
                              style=" color: var(--main-input-color); font-size: 18px;"   
                              class="textFieldForm">
                            ></v-text-field> 
                        </v-col>  

                        <v-col cols="12" sm="6" md="8" class="mt-n5">  <!--Hotel Address-->
                          <v-text-field
                              v-model="editedItem.hotel_address"
                              label="Address"
                              outlined
                              color="var(--main-border-color)" 
                              style=" color: var(--main-input-color); font-size: 18px;"   
                              class="textFieldForm">
                            ></v-text-field>                
                        </v-col>     
                        <v-col cols="12" sm="6" md="4" class="mt-n5">  
                          <v-text-field
                              v-model="editedItem.hotel_city"
                              label="City"
                              outlined
                              color="var(--main-border-color)" 
                              style=" color: var(--main-input-color); font-size: 18px;"   
                              class="textFieldForm">
                            ></v-text-field>
                        </v-col>                                           

                        <v-col cols="12" sm="6" md="4" class="mt-n5 "> <!--Place-->
                            <v-text-field
                              v-model="editedItem.hotel_state"
                              label="Country"
                              outlined
                              color="var(--main-border-color)" 
                              style=" color: var(--main-input-color); font-size: 18px;"   
                              class="textFieldForm">
                            ></v-text-field>
                        </v-col>                                    


                      </v-row>
                    </v-card>           
                  </v-col>
                </v-row>     
              </v-col> 

              <!--Salva e Cancel-->
              <v-col cols="12" sm="12" >
                <v-row  class="mt-2"  >
                  <v-col cols="12" sm="12" >
                    <v-card class="mt-5" elevation="0" style="background-color: var(--main-bg-color);">
                      <v-row style=" var(--main-bg-color);">
                        <v-col cols="12" class="text-center" >
                          <v-btn
                            class="btnForm"
                            color="var(--main-primary-color)"
                            dark
                            @click="salva"
                            elevation="0"
                          >
                            Save
                          </v-btn>
                          <v-btn
                            class="ml-1 btn_bg nohover"
                            color="trasparent"
                            elevation="0"
                            @click="closeEventDett"
                          >
                            Cancel
                          </v-btn>                        
                          
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>

          </v-row>
           <br> 
      </v-card> 

    </div>
</template>
<style>
  /* Style for Bottom menu  */
  #create .v-speed-dial {
    position: fixed;
    z-index: 2;
  }

  #create .v-btn--floating {
    position: relative;
  }

</style>


<script>
import MyLib from "@/components/Lib/Lib.js";
//import Modules from "@/components/TOOLS/Modules.vue";
import CardContactEvent from "./Card/ContactEvent.vue"; 
import PhotoShooting from "./Card/PhotoShooting.vue";
import CheckIn from "./Card/CheckIn.vue";
//import Training from "./Card/Training.vue";
import SocialInterview from "./Card/SocialInterview.vue";
import ZoomInterview from "./Card/ZoomInterview.vue";
import MediaDay from "./Card/MediaDay.vue";
import CommentatorInterview from "./Card/CommentatorInterview.vue";
import CovidTest from "./Card/CovidTest.vue";
import Appointment from './Card/Appointment.vue';
import Note from './Card/Note.vue';
import Ticket from './Card/Ticket.vue';

export default {
    components: {
      // Modules,
       CardContactEvent,
       PhotoShooting,
       //Training,
       CheckIn,
       SocialInterview,
       ZoomInterview,
       MediaDay,
       CommentatorInterview,
       CovidTest,
       Appointment,
       Note,
       Ticket,
    }, 
  data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",     
     
     //x Bottom menu
      direction: 'top',
      fab: false,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
      //x Bottom to Top
      scTimer: 0,
      scY: 0,

      //Country Autocomplete
      isLoading_country: false,
      search_country: null, 

      //Currency Autocomplete
      isLoading_currency: false,
      search_currency: null, 

      venues:[],
      viewCode:true,
      
      overlay: false,  

      editedIndex: -1,
      editedItem:{},

      menueventdata: false, //apertura chiusura datapicker
      menu_data_prezoomcall:false,
      menu_data_conference:false,   
      menu_data_locker_room: false, 
      menu_data_weigh: false,
      menu_data_fighterfaceoff: false,
      menu_data_fighterfaceofflobby: false,
      menu_data_rulesmeeting: false,
      menu_data_lobbycall: false,

    };
  },
  props: {
      ITEM: Object,
      TRIGGER: Number,
  },  
  computed: {
      formattedEventData() {
              return MyLib.formatDate(this.editedItem.date_event)
      }, 
      formattedPreZoomCallDate() {
        return MyLib.formatDate(this.editedItem.prezoomcall_date)
      },        
      formattedConferenceDate() {
        return MyLib.formatDate(this.editedItem.conference_date)
      }, 
      formattedlocker_roomDate() {
        return MyLib.formatDate(this.editedItem.locker_room_date)
      },     
      formattedWeighDate() {
        return MyLib.formatDate(this.editedItem.weigh_date)
      }, 
      formattedFighterfaceoffDate() {
        return MyLib.formatDate(this.editedItem.fighterfaceoff_date)
      }, 
      formattedFighterfaceofflobbyDate() {
        return MyLib.formatDate(this.editedItem.fighterfaceofflobby_date)
      }, 
      formattedRulesmeetingDate() {
        return MyLib.formatDate(this.editedItem.rulesmeeting_date)
      },                          
      formattedLobbycallDate() {
        return MyLib.formatDate(this.editedItem.lobbycall_date)
      },       
      country_currency(){
         return this.alasql(
            "SELECT id, Country +  ' - ' + Cod AS  des FROM ? ",
            [this.currency]
        );    
      },                               
        
  },
  watch: {
    
    TRIGGER: {
        immediate: true,
        handler() {
          this.getVenue();
          this.editedItem =  this.ITEM
          console.log(this.editedItem)
        }
    },
  },  
  mounted() {
    //Gestione Go Bottom to TOP-----------------
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {

      salva(){

          this.overlay = true
          let request = {
              controller: "Bellator",
              method: "PUT",
              richiesta: {
                  action: "setevent",
                  event: [this.editedItem],
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {  
                 
                 if (res.status == 200){
                    this.overlay = false
                   // let item = res.data[0];
                    this.snackbar_color = "success";
                    this.snackbar_text = "data saved successfully. ";
                    this.snackbar_icon = "mdi-window-close";
                    this.snackbar = true;
                  } else {
                    this.snackbar_color = "error";
                    this.snackbar_text = "Error save data. ";
                    this.snackbar_icon = "mdi-window-close";
                    this.snackbar = true;
                    this.overlay = false
                  }           
          });
      },    
      
      closeEventDett(){
         this.$emit("closeDettEvent", "");
      },

    /*
      getTabelleVenue() {
        let request = {
          controller: "Global",
          method: "POST",
          richiesta: {
            action: "gettabelle",
            token: localStorage.getItem("user_token"),
            tipo: "VENUE",
            filter: "validita = 1",
          },
        };
        this.$store.dispatch("api", request).then((res) => {
          this.venues = res.data;
        });
      },
      onChangeVenue_(){
        let row_venue = this.alasql(
              "SELECT * FROM ? WHERE id_tabella = " + this.editedItem.fk_id_venue ,
                  [this.venues]
              );
          this.editedItem.additional_desc = row_venue[0].additional_desc
      },    
    */      

      getVenue() {
        let request = {
          controller: "Bellator",
          method: "POST",
          richiesta: {
            action: "getaddressbook",
            token: localStorage.getItem("user_token"),
            id_type: '3', //Venue
          },
        };
        this.$store.dispatch("api", request).then((res) => {
          this.venues = res.data.address_book;

        this.onChangeVenue()
          
        });
      },

      onChangeVenue(){
        let row_venue = this.alasql(
              "SELECT * FROM ? WHERE id_address_book = " + this.editedItem.fk_id_venue ,
                  [this.venues]
              );
        if (row_venue != null && row_venue.length > 0){              
          this.editedItem.additional_desc = row_venue[0].city
          this.editedItem.utc = row_venue[0].utc_decimale
        }
      },       



      //Gestione Go Bottom to TOP----------------------------

      handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
      toTop: function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },      

      //--------------------------------------------

     
  },
};
</script>