<template>
    <div class="mt-10 ml-6 mr-6" >
      <v-snackbar
        content-class="snackbar"
        :color="snackbar_color"
        class="snackbar-gen"
        left
        v-model="snackbar"
        :timeout="3000"
      >
        <div style="float: right;">
            <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
        </div>
        <div>
        <h3 :style="{ color: snackbar_text_color + '!important' }">
          {{ snackbar_text }}
        </h3>
        </div>
      </v-snackbar>

      <!-- TABS di dettaglio -->
      <div v-if="pagetabs">
          <subjectTabsDetail 
            :obj_subject = this.items_subject  
            :val_subject_sms = this.item_subject_sms
            :val_subject_mail = this.item_subject_mail
            :id_subject_selected = this.id_subjectSelected
            :modifica = this.modifica
            tab = "data"
            v-on:closeTabsDetail="valori_di_ritorno_TabsDetail"
           /> 
      </div>

      <v-row v-if="!pagetabs" style="position:fixed; z-index: 1; width:85%; "> <!--Gestione Filtri Subject pagina principale--> 
            <v-col cols="12" md="12 " class="mt-n2"  style=" background-color: var(--main-bg-color);"  >
              <v-toolbar dense  elevation="0" style="height: 80px; padding-top: 20px;  background-color: var(--main-bg-color);">   
                 <div  v-if="$vuetify.breakpoint.sm ? style='width:100px; max-width:300px;' : style='width:55%;' "  >    
                  <v-tabs
                      v-model="modalTab_subject"
                      grow
                      slider-size="3"
                      slider-color="red"
                      color="var(--main-primary-color)"
                      background-color="var(--main-bg-color)"
                  >
                      <v-tab :href="`#activesubject`" class="tab_style" style= "max-width: 250px; font-size: 1.0rem"  @click="loadData()" 
                      >Active</v-tab >
                      <v-tab :href="`#eventsubject`" class="tab_style" style="max-width: 250px; margin-left: 15px; font-size: 1.0rem;" @click="loadData()"
                      >In Event</v-tab>                

                      <v-tab :href="`#disabledsubject`" class="tab_style" style="max-width: 300px;margin-left: 15px; font-size: 1.0rem;"  @click="loadData()"
                      >Disabled</v-tab>

                      <!-- eventulamente inserire altro qui ...-->
                  </v-tabs>
                </div>
              </v-toolbar>
            </v-col>
      </v-row>      
      
      <v-row v-if="!pagetabs" >  <!--Gestione TAB ..Event Subject / Active Subject qui le tabelle sono integrate -->
          <v-col cols="12" style="margin-top: 60px; "  >
                  <v-tabs-items v-model="modalTab_subject" class="no-transition "  style="background-color:var(--main-bg-color);" touchless>

                      <!-- Tab Active Subject -->
                      <v-tab-item
                        :value="`activesubject`"
                        transition="false"
                        class="mt-3 no-transition"
                      >
                      <v-row>
        <v-col cols="12" sm="12" class="ml-6" align="left">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn  
                    class="mt-3 ml-1 btnForm"  elevation="0" color="var(--main-primary-color)" dark v-bind="attrs" v-on="on" @click="newSubject=true"> New Subject</v-btn>
                </template>
                <span>Add New Subject</span>
              </v-tooltip>   
            </v-col>
      </v-row>           
      <v-col cols="12" sm="12" class="ml-6" align="left">
        
      </v-col>
      <v-card v-if="newSubject" class="ml-4 mr-4" elevation="0"> <!--Gestione New Subject-->
                            <v-container style="background-color: var(--main-bg-form-color);" >
                              <v-row >
                                <v-col cols="12" sm="12" class="mt-3 ml-4">
                                    <p class="titleLabelForm"> 
                                        CREATE NEW SUBJECT
                                    </p>
                                </v-col>                                 
                                <v-col cols="12" class="ml-3 mt-n5" > 
                                          <v-row >
                                            <v-col cols="12" sm="2"> <!--Selec ROLE-->
                                              <p class="labelForm"> 
                                              </p>
                                                <v-select
                                                  class="textFieldForm ml-1"
                                                  style=" color: var(--main-input-color); font-size: 18px;"
                                                  item-color="var(--main-active-select-list-color)"
                                                  color="var(--main-border-color)" 
                                                  :items="roles"
                                                  v-model="new_subject.id_role"
                                                  outlined
                                                  menu-props="auto"
                                                  label="ROLE"
                                                  item-text="des_role"
                                                  item-value="id_role"   
                                                ></v-select>
                                            </v-col> 

                                            <v-col cols="12" sm="2" md="3">  <!--Text Name-->
                                              <p class="labelForm"> 
                                              </p> 
                                              <v-text-field
                                                style=" color: var(--main-input-color); font-size: 18px;"  
                                                v-model="new_subject.name"
                                                class= "textFieldForm"
                                                color="var(--main-border-color)" 
                                                label="NAME" 
                                                outlined
                                              ></v-text-field>  
                                            </v-col>

                                            <v-col cols="12" sm="3"><!--Text Name-->
                                              <p class="labelForm"> 
                                              </p> 
                                              <v-text-field
                                                v-model="new_subject.middle_name"
                                                style="color: var(--main-input-color); font-size: 18px;"                              
                                                class="textFieldForm"    
                                                color="var(--main-border-color)"            
                                                outlined
                                                label="MIDDLE NAME"
                                              ></v-text-field>
                                            </v-col>

                                            <v-col cols="12" sm="3"><!--Text Surname-->
                                              <p class="labelForm"> 
                                              </p> 
                                              <v-text-field
                                                v-model="new_subject.surname"
                                                class="textFieldForm"                
                                                outlined
                                                label="SURNAME"
                                                color="var(--main-border-color)"
                                                style=" color: var(--main-input-color); font-size: 18px;"
                                              ></v-text-field>
                                            </v-col>      
                                            <v-col cols="12" sm="1"><!--Check Box M/F-->
                                              <p  class="labelForm"> 
                                                M &nbsp;/&nbsp; F
                                              </p>
                                              <v-row>
                                                <v-col cols="12" sm="6">
                                                  <v-checkbox
                                                    v-model="new_subject.sex"
                                                    class="mt-n1"
                                                    color="grey"
                                                    hide-details
                                                    @click="female=!female"
                                                  ></v-checkbox>                    
                                                </v-col> 
                                                <v-col cols="12" sm="6">
                                                  <v-checkbox
                                                    v-model="female"
                                                    class="ml-n3 mt-n1"
                                                    color="grey"
                                                    hide-details
                                                    @click="new_subject.sex=!new_subject.sex"
                                                  ></v-checkbox>                      
                                                </v-col>  
                                              </v-row>  
                                            </v-col>       
                                          </v-row>
                                          <v-row>
                                              <v-col cols="12" class="text-center mt-n5" >
                                                
                                                <v-btn
                                                  color="var(--main-primary-color)"
                                                  dark
                                                  @click="create_new_subject"
                                                >
                                                  Create
                                                </v-btn>

                                                <v-btn
                                                  dark
                                                  class=" ml-1 btn_bg nohover " elevation="0" 
                                                  color="white"                                                     
                                                  @click="Close_newSubject"
                                                >
                                                  Cancel
                                                </v-btn>
                                                <br/> <br/>
                                              </v-col>                                              
                                           </v-row>                    
                                                                     
          
                                </v-col>
                              </v-row>
                            </v-container>  
                          </v-card>

                      <div class="mt-4" v-if="!newSubject" style="background-color:var(--main-bg-color);">
                          <v-card class="mt-1 ml-4 mr-4" elevation="0"  style="background-color: white;"  ><!--First Row Filtri-->
                            <v-container style="background-color: var(--main-bg-form-color);" >
                            
                            <v-row>  <!-- Filter -->
                              <v-col cols="12" sm="12" class="mt-3 ml-4">
                                <p class="titleLabelForm"> 
                                    FILTER AND FIND ACTIVE SUBJECT
                                </p>
                              </v-col> 
                              <v-col cols="12" sm="6" md="3" class="mt-n5 ml-4">
                                  <v-row>
                                      <v-col cols="12" sm="9">
                                          <v-autocomplete
                                            v-model="filter_name_surname"
                                            item-color="var(--main-active-select-list-color)"
                                            class="textFieldForm"
                                            style=" color: var(--main-input-color); font-size: 18px;"   
                                            color="var(--main-border-color)"   
                                            prepend-inner-icon="mdi-filter-variant"                               
                                            :items="valid_subject_filter"
                                            :loading="isLoading_contact"
                                            :search-input.sync="search_contact"
                                            clearable
                                            clear-icon="mdi-close"
                                            item-text="name"
                                            item-value="id_subject"
                                            label="Name and Surname"
                                            outlined
                                          ></v-autocomplete>    
                                      </v-col>                                
                                  </v-row>                                

                              </v-col>
                            
                              <v-col cols="12" sm="6" md="3" class="mt-n5 ml-4">
                                  <v-autocomplete
                                    :items="roles"
                                    item-color="var(--main-active-select-list-color)"
                                    v-model="filter_id_role"
                                    menu-props="auto"
                                    label="Role"
                                    prepend-inner-icon="mdi-filter-variant"
                                    outlined
                                    :loading="isLoading_role"
                                    :search-input.sync="search_role"
                                    clearable
                                    clear-icon="mdi-close"                                    
                                    item-text="des_role"
                                    item-value="id_role"   
                                    color="var(--main-border-color)" 
                                    style=" color: var(--main-input-color); font-size: 18px;"   
                                    class="textFieldForm"                                    
                                  ></v-autocomplete>
                              </v-col> 
                              <!-- Fine Campi Filter -->

                              <!-- pulsanti Filtro-->
                              <v-col cols="12" sm="12" md="12" class="ml-4" >
                                <v-row class="mt-n12 mb-3">   
                                  <!-- Apply Clear-->
                                  <v-col cols="12" sm="12"  align="left">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn  
                                              class="mt-3 btnForm"  
                                              elevation="0" color="var(--main-primary-color)" dark v-bind="attrs" v-on="on" 
                                              @click="getSubject()"
                                            > Apply and Search</v-btn>
                                        </template>
                                        <span>Apply Selected Filter</span>
                                    </v-tooltip>
                                    
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-if="valid_subject.length>0"
                                                class="mt-3 ml-1 btn_bg nohover " elevation="0" 
                                                color="white"   dark v-bind="attrs" v-on="on" @click="clear_filter"
                                            > Clear
                                            </v-btn>
                                            <v-btn v-else
                                                class="mt-3 ml-1 " elevation="0" 
                                                color="var(--main-bg-color)"  dark v-bind="attrs" v-on="on" @click="clear_filter"
                                                disabled
                                            > 
                                            </v-btn>
                                        </template>
                                        <span>Clear All Filter</span>
                                    </v-tooltip> 
                                  </v-col>
                                </v-row>                     
                              </v-col>
                              <!-- Dialog New Subject -->
                              <v-dialog  v-model="dialog_newSubject"  max-width="70%" persistent>
                                <v-card>
                                    <v-toolbar
                                        class="dialogToolbar"
                                        dense
                                        style="color:var(--main-primary_text-color)"                
                                        >
                                        <v-toolbar-title> New Subject </v-toolbar-title>
                                        <v-spacer></v-spacer>
                                            <v-icon                                       
                                              @click="dialog_newSubject = false"
                                              color="var(--main-lbltop-color)"
                                              small >
                                              mdi-close
                                            </v-icon>
                                    </v-toolbar>
                                    <v-card>
                                        <v-container>
                                          <v-row>
                                            <v-col cols="12" sm="2"> <!--Selec ROLE-->
                                              <p style="text-align: left;font-size: 14px;font-weight: 700; margin-left: 4px;;"> 
                                                Role
                                              </p>
                                                <v-select
                                                  :items="roles"
                                                  item-color="var(--main-active-select-list-color)"
                                                  v-model="new_subject.id_role"
                                                  class="mt-n2 ml-1"  
                                                  dense
                                                  height="50"                    
                                                  outlined
                                                  menu-props="auto"
                                                  label="Role"
                                                  hide-details
                                                  item-text="des_role"
                                                  item-value="id_role"                    
                                                  single-line
                                                ></v-select>
                                            </v-col> 

                                            <v-col cols="12" sm="2" md="3">  <!--Text Name-->
                                              <p style="text-align: left;font-size: 14px;font-weight: 700; margin-left: 3px;;"> 
                                                Name
                                              </p>
                                              <v-text-field
                                                v-model="new_subject.name"                        
                                                dense
                                                height="50"
                                                class="mt-n2"                
                                                outlined
                                                label="Name"
                                              ></v-text-field>   
                                            </v-col>

                                            <v-col cols="12" sm="3"><!--Text Name-->
                                              <p style="text-align: left;font-size: 14px;font-weight: 700; margin-left: 3px;;"> 
                                                Middle Name
                                              </p>
                                              <v-text-field
                                                v-model="new_subject.middle_name"
                                                dense
                                                height="50"
                                                class="mt-n2"                
                                                outlined
                                                label="Middle Name"
                                              ></v-text-field>
                                            </v-col>

                                            <v-col cols="12" sm="3"><!--Text Surname-->
                                              <p style="text-align: left;font-size: 14px;font-weight: 700; margin-left: 3px;;"> 
                                                Surname
                                              </p>
                                              <v-text-field
                                                v-model="new_subject.surname"
                                                dense
                                                height="50"
                                                class="mt-n2"                
                                                outlined
                                                label="Surame"
                                              ></v-text-field>
                                            </v-col>      
                                            <v-col cols="12" sm="1"><!--Check Box M/F-->
                                              <p style="text-align: left;font-size: 14px;font-weight: 700; margin-left: 8px;;"> 
                                                M &nbsp;/&nbsp; F
                                              </p>
                                              <v-row>
                                                <v-col cols="12" sm="6">
                                                  <v-checkbox
                                                    v-model="new_subject.sex"
                                                    class="mt-n1"
                                                    hide-details
                                                    @click="female=!female"
                                                  ></v-checkbox>                    
                                                </v-col> 
                                                <v-col cols="12" sm="6">
                                                  <v-checkbox
                                                    v-model="female"
                                                    class="ml-n3 mt-n1"
                                                    hide-details
                                                    @click="new_subject.sex=!new_subject.sex"
                                                  ></v-checkbox>                      
                                                </v-col>  
                                              </v-row>  
                                            </v-col>       
                                          </v-row>
                                          <v-row>
                                              <v-col cols="12" class="text-center">
                                                <v-btn
                                                  rounded
                                                  color="var(--main-primary-color)"
                                                  dark
                                                  @click="create_new_subject"
                                                >
                                                  Create
                                                </v-btn>
                                              </v-col>
                                            </v-row>                    
                                        </v-container>
                                    </v-card>
                                </v-card>
                                
                              </v-dialog>                          
                            </v-row>
                          </v-container>
                          </v-card>
                          <v-row>
                                <v-col cols="12" class="mt-7 ml-7"> 
                              <div v-if="!newSubject && !valid_subject.length>0">
                                <p class="text-left text-info-color">Create new subject or apply filter to view subjects.</p>
                              </div>
                          </v-col>
                          </v-row>

                          <v-card v-if="!newSubject && valid_subject.length>0"  class="ml-4 mr-4" elevation="0"  > <!--Gestione Tabella-->
                              <v-row>
                                <v-col cols="12" style="background-color:var(--main-bg-color);"> 
                                  <template>
                                    <v-data-table
                                    
                                      :headers="headers_active_sub"
                                      :items="valid_subject"
                                      
                                      item-key="id"
                                      sort-by="surname"
                                      class="elevation-0 mt-n3"
                                      color="var(--main-input-color)"
                                      :footer-props="{
                                                'items-per-page-text':'Rows per page',
                                            }"
                                    >

                                      <template v-slot:[`header.surname`]="{}">
                                        <a class="textHeader" style="color:var(--main-dth-color);"> Surname </a>
                                      </template>
                                      <template v-slot:[`header.name`]="{}">
                                        <a class="textHeader" style="color:var(--main-dth-color);"> Name </a>
                                      </template>
                                      <template v-slot:[`header.des_role`]="{}">
                                        <a class="textHeader" style="color:var(--main-dth-color);"> Role </a>
                                      </template>
                                      <template v-slot:[`header.date_birth`]="{}">
                                        <a class="textHeader" style="color:var(--main-dth-color);"> Date of Birth </a>
                                      </template>         
                                      <template v-slot:[`header.email`]="{}">
                                        <a class="textHeader" style="color:var(--main-dth-color);"> Email </a>
                                      </template>                                                           
                                                                                                

                                      <template v-slot:[`item.date_birth`]="{ item }" >
                                            {{ formatDate (item.date_birth) }}

                                      </template>        
                                      <template v-slot:[`item.surname`]="{ item }">
                                      <span @click="open_subject(item)" style="text-decoration: underline; cursor: pointer;"> {{ item.surname}}</span>
                                    </template>                                                                                                                  
                                      <template v-slot:[`item.actions`]="{ item }">
                                          <v-icon color="var(--main-primary-color)"  @click="open_subject(item)"
                                            >mdi-eye
                                          </v-icon>
                                          <v-icon color="var(--main-primary-color)" @click="disable_subject(item)"
                                            >mdi-delete
                                          </v-icon>
                                      </template>
                                      <template v-slot:no-data>
                                          Empty Result.
                                      </template>
                                    </v-data-table>
                                  </template>
                                  <v-overlay :value="overlay">
                                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                                  </v-overlay>          
                                </v-col>
                              </v-row>
                          </v-card>   

                          



                      </div> 
                      </v-tab-item>                       
                       
                       <!--Tab Event Subject -->
                      <v-tab-item
                        :value="`eventsubject`" 
                        transition="false"
                        class="mt-3 no-transition"
                      >
                      <div class="mt-4" style="background-color:var(--main-bg-color); ">
                        <v-card  class="ml-4 mr-4 mb-4" elevation="0" style="background-color: white;"  ><!-- Row Filtri-->
                            <v-container style="background-color: var(--main-bg-form-color);" >
         
                              <v-row>
                                <v-col cols="12" sm="12" class="mt-3 ml-4">
                                <p class="titleLabelForm"> 
                                    FILTER AND FIND SUBJECT IN EVENT 
                                </p>
                              </v-col> 
                              <v-col cols="12" sm="6" md="3" class="mt-n5 ml-4">
                          
                                          <v-autocomplete
                                            v-model="filter_event"
                                            :items="events_subject"
                                            item-text='event'
                                            item-value='id_subject_event'            
                                            label="Event"
                                            prepend-inner-icon="mdi-filter-variant"
                                            outlined
                                            clearable
                                            clear-icon="mdi-close"
                                            color="var(--main-border-color)" 
                                            item-color="var(--main-active-select-list-color)"
                                            style=" color: var(--main-input-color); font-size: 18px;"   
                                            class="textFieldForm"
                                            :loading="isLoading_event"
                                            :search-input.sync="search_event"                                          
                                          ></v-autocomplete>

                                </v-col>

                                <v-col cols="12" sm="6" md="3" class="mt-n5 ml-4">
                                    <v-autocomplete
                                      v-model="filter_name_surname"
                                      item-color="var(--main-active-select-list-color)"
                                      class="textFieldForm"
                                      style=" color: var(--main-input-color); font-size: 18px;"   
                                      color="var(--main-border-color)"   
                                      prepend-inner-icon="mdi-filter-variant"                               
                                      :items="valid_subject_filter"
                                      :loading="isLoading_contact"
                                      :search-input.sync="search_contact"
                                      clearable
                                      clear-icon="mdi-close"
                                      item-text="name"
                                      item-value="id_subject"
                                      label="Name and Surname"
                                      outlined
                                    ></v-autocomplete>  
                                </v-col>

                                <v-col cols="12" sm="6" md="3" class="mt-n5 ml-4">
                                    <v-autocomplete
                                      :items="roles"
                                      item-color="var(--main-active-select-list-color)"
                                      v-model="filter_id_role"
                                      menu-props="auto"
                                      label="Role"
                                      prepend-inner-icon="mdi-filter-variant"
                                      outlined
                                      :loading="isLoading_role"
                                      :search-input.sync="search_role"
                                      clearable
                                      clear-icon="mdi-close"                                    
                                      item-text="des_role"
                                      item-value="id_role"   
                                      color="var(--main-border-color)" 
                                      style=" color: var(--main-input-color); font-size: 18px;"   
                                      class="textFieldForm"                                    
                                    ></v-autocomplete>
                                </v-col> 
                                <!-- Fine Campi Filter -->
                                <!-- pulsanti Filtro-->
                                <v-col cols="12" sm="12" md="12" class="ml-4" >
                                  <v-row class="mt-n12 mb-3">                           

                                    <!-- Apply Clear-->
                                    <v-col cols="12" sm="6"  align="left">
                                      <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }">
                                              <v-btn  
                                                class="mt-3 btnForm "  
                                                elevation="0" color="var(--main-primary-color)" dark v-bind="attrs" v-on="on" 
                                                @click="getEventSubject()"
                                              > Apply and Search</v-btn>
                                          </template>
                                          <span>Apply Selected Filter</span>
                                      </v-tooltip>
                                      
                                      <v-tooltip top >
                                          <template v-slot:activator="{ on, attrs }">
                                              <v-btn v-if="valid_subject.length>0"
                                                  class="mt-3 ml-1 btn_bg nohover" elevation="0" 
                                                  color="white"   dark v-bind="attrs" v-on="on" @click="clear_filter"
                                              > Clear
                                              </v-btn>
                                              <v-btn v-else
                                                  class="mt-3 ml-1 " elevation="0" 
                                                  color="var(--main-bg-color)"  dark v-bind="attrs" v-on="on" @click="clear_filter"
                                                  disabled
                                              > 
                                              </v-btn>
                                          </template>
                                          <span>Clear All Filter</span>
                                      </v-tooltip>                            
                                </v-col>
                                </v-row>
                                </v-col>
                                </v-row>                    
                            </v-container>
                    
                          </v-card>  
                          <v-row>
                                <v-col cols="12" class="mt-2 ml-7"> 
                              <div v-if="!valid_subject.length>0">
                                <p class="text-left text-info-color">Apply filter to view subjects.</p>
                              </div>
                          </v-col>
                          </v-row>
                          <v-card v-if="valid_subject.length>0" class=" ml-4 mr-4" elevation="0"  > <!--Gestione Tabella-->
                             <v-row>
                              <v-col cols="12" style="background-color:var(--main-bg-color);"> 
                                <template>

                                  <v-data-table
                                    :headers="headers_ev_sub"
                                    :items="valid_subject"
                                    item-key="id"
                                    color="var(--main-input-color)"
                                    sort-by="surname"
                                    class="elevation-0 mt-n2"
                                    :footer-props="{
                                              'items-per-page-text':'Rows per page',
                                          }"
                                  >
                                    <template v-slot:[`header.status_flight`]="{}">
                                      <v-icon color="var(--main-dth-color)" size="24">mdi-airplane</v-icon>
                                    </template> 
                                    <template v-slot:[`header.status_hotel`]="{}">
                                      <v-icon color="var(--main-dth-color)" size="24">mdi-seat-individual-suite</v-icon>
                                    </template>
                                    <template v-slot:[`header.status_taxi`]="{}">
                                      <v-icon color="var(--main-dth-color)" size="24">mdi-van-passenger</v-icon>
                                    </template>
                                    <template v-slot:[`header.status_covid`]="{}">
                                      <v-icon color="var(--main-dth-color)" size="24">mdi-vanish</v-icon>
                                    </template>
                                    <template v-slot:[`header.status_medical`]="{}">
                                      <v-icon color="var(--main-dth-color)" size="24">mdi-hospital-box</v-icon>
                                    </template>
                                    <template v-slot:[`header.status_music`]="{}">
                                      <v-icon color="var(--main-dth-color)" size="24">mdi-music</v-icon>
                                    </template>
                                    <template v-slot:[`header.surname`]="{}">
                                      <a class="textHeader" style="color:var(--main-dth-color);"> Surname </a>
                                    </template>
                                    <template v-slot:[`header.name`]="{}">
                                      <a class="textHeader" style="color:var(--main-dth-color);"> Name </a>
                                    </template>
                                    <template v-slot:[`header.des_role`]="{}">
                                      <a class="textHeader" style="color:var(--main-dth-color);"> Role </a>
                                    </template>
                                    <template v-slot:[`header.date_event`]="{}">
                                      <a class="textHeader" style="color:var(--main-dth-color);"> Date Event </a>
                                    </template>                               
                                   
                                    <template v-slot:[`item.status_flight`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              v-model="item.status_flight"
                                              readonly
                                          ></v-checkbox>
                                    </template>
                                    <template v-slot:[`item.status_hotel`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              v-model="item.status_hotel"
                                              readonly
                                          ></v-checkbox>
                                    </template> 
                                    <template v-slot:[`item.status_taxi`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              v-model="item.status_taxi"
                                              readonly
                                          ></v-checkbox>
                                    </template>       
                                    <template v-slot:[`item.status_covid`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              v-model="item.status_covid"
                                              readonly
                                          ></v-checkbox>
                                    </template> 
                                    <template v-slot:[`item.status_medical`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              v-model="item.status_medical"
                                              readonly
                                          ></v-checkbox>
                                    </template> 
                                    <template v-slot:[`item.status_music`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              v-model="item.status_music"
                                              readonly
                                          ></v-checkbox>
                                    </template>   
                                    <template v-slot:[`item.date_event`]="{ item }">
                                          {{ formatDate (item.date_event) }}
                                    </template>                  
                                    <template v-slot:[`item.surname`]="{ item }">
                                      <span @click="open_subject(item)" style="text-decoration: underline; cursor: pointer;"> {{ item.surname}}</span>
                                    </template>                                                                                                      
                                    <template v-slot:[`item.actions`]="{ item }">

                                                  <v-icon color="var(--main-primary-color)" @click="open_subject(item)"
                                                  >mdi-eye
                                                  </v-icon>
                                    </template>
                                    <template v-slot:no-data>
                                        Empty Result
                                    </template>

                                  </v-data-table>
                                </template>
                                <v-overlay :value="overlay">
                                  <v-progress-circular indeterminate size="64"></v-progress-circular>
                                </v-overlay>          
                              </v-col>   
                            </v-row>  
                          </v-card> 
                        </div>              
                      </v-tab-item>

                      <!--Tab Disabled Subject -->
                      <v-tab-item
                        :value="`disabledsubject`"
                        transition="false"
                        class="mt-3 no-transition"
                      >
                      <div class="mt-4" style="background-color:var(--main-bg-color); ">
                          <!-- Filter -->
                          <v-card class="ml-4 mr-4 mb-4" elevation="0" style="background-color: white;"  ><!-- Row Filtri-->
                            <v-container style="background-color: var(--main-bg-form-color);" >
                              <v-row>  <!-- Filter -->

                                  <v-col cols="12" sm="12">
                                      <v-row>
                                        <v-col cols="12" sm="12" class="mt-3 ml-4">
                                          <p class="titleLabelForm"> 
                                              FILTER AND FIND SUBJECT DISABLED
                                          </p>
                                        </v-col> 
                        
                                        <v-col cols="12" sm="6" md="3" class="mt-n5 ml-4">
                                            <v-autocomplete
                                              v-model="filter_name_surname"
                                              item-color="var(--main-active-select-list-color)"
                                              class="textFieldForm"
                                              style=" color: var(--main-input-color); font-size: 18px;"   
                                              color="var(--main-border-color)"   
                                              prepend-inner-icon="mdi-filter-variant"                               
                                              :items="disabled_subjects_filter"
                                              :loading="isLoading_contact"
                                              :search-input.sync="search_contact"
                                              clearable
                                              clear-icon="mdi-close"
                                              item-text="name"
                                              item-value="id_subject"
                                              label="Name and Surname"
                                              outlined
                                            ></v-autocomplete>  
                                          </v-col>      
                                          <v-col cols="12" sm="6" md="3" class="mt-n5 ml-4">
                                    <v-autocomplete
                                      :items="roles"
                                      item-color="var(--main-active-select-list-color)"
                                      v-model="filter_id_role"
                                      menu-props="auto"
                                      label="Role"
                                      prepend-inner-icon="mdi-filter-variant"
                                      outlined
                                      :loading="isLoading_role"
                                      :search-input.sync="search_role"
                                      clearable
                                      clear-icon="mdi-close"                                    
                                      item-text="des_role"
                                      item-value="id_role"   
                                      color="var(--main-border-color)" 
                                      style=" color: var(--main-input-color); font-size: 18px;"   
                                      class="textFieldForm"                                    
                                    ></v-autocomplete>
                                  </v-col>                                                                     
                                      </v-row>                                

                                  </v-col>

                                  
                                  <!-- Fine Campi Filter -->
                                  <v-col cols="12" sm="12" md="12" class="ml-4" >
                                <v-row class="mt-n12 mb-3">                           

                                  <!-- Apply Clear-->
                                  <v-col cols="12" sm="6"  align="left">
           
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn  
                                                  class="mt-3 btnForm"  
                                                  elevation="0" color="var(--main-primary-color)" dark v-bind="attrs" v-on="on" 
                                                  @click="getSubject()"
                                                > Apply and Search</v-btn>
                                            </template>
                                            <span>Apply Selected Filter</span>
                                        </v-tooltip>
                                        
                                        <v-tooltip top >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-if="disabled_subjects.length>0"
                                                    class="mt-3 ml-1 btn_bg nohover " elevation="0" 
                                                    color="white"   dark v-bind="attrs" v-on="on" @click="clear_filter"
                                                > Clear
                                                </v-btn>
                                                <v-btn v-else
                                                    class="mt-3 ml-1 " elevation="0" 
                                                    color="var(--main-bg-color)"  dark v-bind="attrs" v-on="on" @click="clear_filter"
                                                    disabled
                                                > 
                                                </v-btn>
                                            </template>
                                            <span>Clear All Filter</span>
                                        </v-tooltip> 
                                      </v-col>


                                      <v-col cols="12" sm="6" align="right">

                                      </v-col>

                                    </v-row>                     
                                  </v-col>                         
                                  </v-row>
                            </v-container>


                            </v-card>
                            <v-row>
                                <v-col cols="12" class="mt-2 ml-7"> 
                              <div v-if="!disabled_subjects.length>0">
                                <p class="text-left text-info-color">Apply filter to view subjects.</p>
                              </div>
                          </v-col>
                          </v-row>
                            <v-card v-if="disabled_subjects.length>0" class="ml-4 mr-4" elevation="0"  > <!--Gestione Tabella-->
                                <v-row>
                                  <v-col cols="12" style="background-color:var(--main-bg-color);"> 
                                    <template>
                                      <v-data-table
                                      
                                        :headers="headers_active_sub"
                                        :items="disabled_subjects"
                                        
                                        item-key="id"
                                        sort-by="surname"
                                        class="elevation-0 mt-n3"
                                        color="var(--main-input-color)"
                                        :footer-props="{
                                                  'items-per-page-text':'Rows per page',
                                              }"
                                      >

                                        <template v-slot:[`header.surname`]="{}">
                                          <a class="textHeader" style="color:var(--main-dth-color);"> Surname </a>
                                        </template>
                                        <template v-slot:[`header.name`]="{}">
                                          <a class="textHeader" style="color:var(--main-dth-color);"> Name </a>
                                        </template>
                                        <template v-slot:[`header.des_role`]="{}">
                                          <a class="textHeader" style="color:var(--main-dth-color);"> Role </a>
                                        </template>
                                        <template v-slot:[`header.date_birth`]="{}">
                                          <a class="textHeader" style="color:var(--main-dth-color);"> Date of Birth </a>
                                        </template>         
                                        <template v-slot:[`header.email`]="{}">
                                          <a class="textHeader" style="color:var(--main-dth-color);"> Email </a>
                                        </template>          
                                        <template v-slot:[`header.actions`]="{}">
                                          <a class="textHeader" style="color:var(--main-dth-color);"> Restore </a>
                                        </template>                                                                                            
                                                                                                  

                                        <template v-slot:[`item.date_birth`]="{ item }" >
                                              {{ formatDate (item.date_birth) }}
                                        </template> 
                                        <template v-slot:no-data>
                                            Empty Result
                                        </template>                                                                                                                                                               
                                        <template v-slot:[`item.actions`]="{ item }">
                                            <v-icon color="var(--main-primary-color)"  @click="enable_subject(item)"
                                              >mdi-check
                                            </v-icon>
                                        </template>

                                      </v-data-table>
                                    </template>
                                    <v-overlay :value="overlay">
                                      <v-progress-circular indeterminate size="64"></v-progress-circular>
                                    </v-overlay>          
                                  </v-col>
                                </v-row>
                            </v-card>
                            
    
                        </div>
                      </v-tab-item>

                  </v-tabs-items>
          </v-col>
      </v-row>
    </div>
</template>
<style>



</style>
<script>
import subjectTabsDetail from "@/components/Subject/SubjectTabsDetail.vue";

  export default {
    components: {
       subjectTabsDetail,
    },
    data () {
      return {

        snackbar: false,
        snackbar_color: "",
        snackbar_text: "",
        snackbar_icon: "",
        snackbar_icon_color: "", 
        snackbar_text_color:"", 

        dialog_newSubject:false,
        newSubject: false,

        overlay: false,   
        filter_name_surname:null,
        filter_id_role:null,
        filter_id_subject:null,
        filter_event:null,
        events_subject:[],
        
        pagetabs:false,

        modalTab: "data",
        modalTab_subject:"activesubject",
        subjects: [],
        new_subject:{id_subject: '-1', id_role: null, middle_name:'', name: '', surname: '', sex: 1, date_event: ''},

        //obj_subject:null,
        items_subject:null,
        item_subject_sms:null,
        item_subject_mail:null,
        id_subjectSelected:null,

        isLoading_contact: false,
        search_contact: null,    
        isLoading_role: false,
        search_role: null,  
        isLoading_event: false,
        search_event: null,        
                    

        modifica: 0, 

        roles:[],
        all_contact:[],
        events:[],


        headers_ev_sub: [
          { text: 'Surname', value: 'surname' },
          { text: 'Name', value: 'name' },
          { text: 'Role', value: 'des_role' },
          { text: '', value: 'status_flight' },
          { text: '', value: 'status_hotel' },
          { text: '', value: 'status_taxi' },
          { text: '', value: 'status_covid' },
          { text: '', value: 'status_medical' },
          { text: '', value: 'status_music' },
          { text: 'Next Event', value: 'date_event' },
          { text: '', value: 'actions', sortable: false },
        ],
        headers_active_sub: [
          { text: 'Surname', value: 'surname' },
          { text: 'Name', value: 'name' },
          { text: 'Role', value: 'des_role' }, 
          { text: 'Date Of Birth', value: 'date_birth' },
          { text: 'Email', value: 'email' },
          { text: '', value: 'actions', sortable: false, width: '12%' },
        ],

        footerProps: { "items-per-page-options": [15, 30, 50, 100] },        
          

       }
    },
    mounted() {
      this.getRoles()
      this.getAllContact()
     
    }, 
    computed: {
      
      female() {
        return !this.new_subject.sex;
      }, 
      valid_subject() {
          return this.subjects.filter((i) => {
              return (i.validita === null) || (i.validita === 1) || (i.validita === "");
          })
      },

      valid_subject_filter() {
          return this.all_contact.filter((i) => {
              return (i.validita === null) || (i.validita === 1) || (i.validita === "");
          })
      },

      disabled_subjects() {
          return this.subjects.filter((i) => {
              return (i.validita === 0) ;
          })
      },  

      
      disabled_subjects_filter() {
          return this.all_contact.filter((i) => {
              return (i.validita === 0) ;
          })
      },  


    },      
    methods: {

        loadData(){
          this.getRoles()
          this.getAllContact()
          this.getSubjectEvent()
        },

        getRoles(){
            let request = {
                controller: "Bellator",
                method: "POST",
                richiesta: {
                    action: "getrole",
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {   
                  if (res.status == 200){
                    this.roles = [];
                    this.roles =  res.data.role
                  } else {
                    this.snackbar_color = "error";
                    this.snackbar_text = "Error getrole!";
                    this.snackbar = true;
                  }           
            });
        },   

				getAllContact(){
						//restituisce  l'elenco dei contatti ovvero i subject con  idrole = 3 o altri
            let that = this
            that.overlay = true
						that.subjects=[]
						let request = {
								controller: "Bellator",
								method: "POST",
								richiesta: {
										action: "getsubject",
										roleid_in: "(1,2,3,4,5,6,7,8,9)", //subject.id_role IN ..inserire ruoli separati da virgola
										token: localStorage.getItem("user_token"),
								},
						}
						that.$store.dispatch("api", request).then((res) => {   
								if (res.status == 200){
									//that.all_contact = res.data.subject;
                  
                  res.data.subject.forEach(element => {
                  let field = element.surname  + " " +  element.name 
                  that.all_contact.push({  
                      name: field,
                      id_subject: element.id_subject, 
                      id_role: element.id_role, 
                      validita: element.validita  
                    })
                  })
                  //console.log(that.all_contact)
									that.overlay = false 
								} else {
									that.snackbar_color = "error";
									that.snackbar_text = "Event contact not loaded.";
									that.snackbar = true;
									that.overlay = false
								}     
						})
						.catch((ex) => {
								console.log(ex)
								that.overlay = false
						});
				},          
   
        getSubjectEvent(){
          this.overlay = true
          let temp_events_subject= []
          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "getsubjectevent",
                  token: localStorage.getItem("user_token"),
              },
          }
          
          this.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                let that = this
                //that.tbl_subject_event = res.data.subject_event
                res.data.subject_event.forEach(element => {
                  if(element.event_validita >  0){  
                    let _name = element.event_name == null ? '' :  element.event_name  
                    let city = element.city_event == null ? '' :  element.city_event
                  // let _data = element.date_event == null ? '' :  that.formatDate(element.date_event)
                    let field = _name + " - " +  city  // + " - " + _data
                  
                    
                    temp_events_subject.push({  
                        event: field,
                        id_subject_event: element.id_subject_event, 
                        id_event: element.fk_id_event, 
                        id_subject: element.fk_id_subject,
                        role: element.des_role, 
                        id_role: element.fk_id_role,
                        validita: element.validita  
                      });
                    }  
                  });
                

                //element distinct of event_subject
                this.events_subject=[]
                temp_events_subject.forEach(element => {
                      let trovato = false
                      this.events_subject.forEach(internalelement => {
                        if (internalelement.event == element.event) trovato = true
                        });
                        if (!trovato) this.events_subject.push(element)
                });
                
                that.overlay = false 
              } else {
                this.snackbar_color = "error";
                this.snackbar_text = "Events not loaded.";
                this.snackbar = true;
                this.overlay = false
              }     
          })
          .catch((ex) => {
            if (ex.response.status == 404 || ex.response.status == undefined) {
              this.overlay = false
              this.snackbar = true;
              this.snackbar_color = "error";
              this.snackbar_text = ex;
              this.overlay = false
            }
          });
        }, 
        
        //Apply
        getSubject(){
            this.overlay = true
            this.subjects=[]
            let request = {
                controller: "Bellator",
                method: "POST",
                richiesta: {
                    action: "getsubject",
                    id_role:this.filter_id_role,
                    id_subject: this.filter_name_surname,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {   
                if (res.status == 200){
                  this.subjects = res.data.subject;
                  this.overlay = false 
                } else {
                  this.snackbar_color = "error";
                  this.snackbar_text = "Subjects not loaded.";
                  this.snackbar = true;
                  this.overlay = false
                }     
            })
            .catch((ex) => {
              if (ex.response.status == 404 || ex.response.status == undefined) {
                this.overlay = false
                this.snackbar = true;
                this.snackbar_color = "error";
                this.snackbar_text = ex;
                this.overlay = false
              }
            });
        },

        //Apply
        getEventSubject(){

            //recupero l'id_evento in relazione al id_subject_event
            let event_filter_array = this.alasql(
            "SELECT id_event FROM ? where id_subject_event = " + this.filter_event,
              [this.events_subject]
             );    
             let id_event_filter = ""
             if (event_filter_array.length > 0){
                id_event_filter = event_filter_array[0].id_event
            } 
             //console.log(id_event_filter)
         
            this.overlay = true
            this.subjects=[]
            let request = {
                controller: "Bellator",
                method: "POST",
                richiesta: {
                    action: "getsubject",
                    id_role:this.filter_id_role,
                    id_subject: this.filter_name_surname,
                    id_event: id_event_filter,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {   
                if (res.status == 200){
                  this.overlay = false 
                  this.subjects = res.data.subject;

                } else {
                  this.snackbar_color = "error";
                  this.snackbar_text = "Subjects not loaded.";
                  this.snackbar = true;
                  this.overlay = false
                }     
            })
            .catch((ex) => {
              if (ex.response.status == 404 || ex.response.status == undefined) {
                this.overlay = false
                this.snackbar = true;
                this.snackbar_color = "error";
                this.snackbar_text = ex;
                this.overlay = false
              }
            });
        },        


        formatDate (date) {
          if (!date) return null
          let new_date = date.substring(0,10)
          const [year, month, day] = new_date.split('-')
          return `${month}/${day}/${year}`
        },  

        open_subject(item){
          this.modifica = Math.round(Math.random()*100);
          this.items_subject = item
          this.item_subject_sms = item.subject_sms
          this.item_subject_mail = item.subject_mail
          this.id_subjectSelected = item.id_subject
          this.pagetabs=true
        },


        valori_di_ritorno_TabsDetail(value){
           // console.log(value)
            this.getSubject()
            this.pagetabs =  false
        },  

        clear_filter(){
          this.filter_name_surname = null
          this.filter_id_role = null
          this.filter_event = null
          this.subjects=[]
        },

        create_new_subject(){
        
          this.overlay = true
          let request = {
              controller: "Bellator",
              method: "PUT",
              richiesta: {
                  action: "setsubject",
                  subject: [this.new_subject],
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {  
                 if (res.status == 200){
                    this.overlay = false
                    let item = res.data[0];
                    this.new_subject.name = '';
                    this.new_subject.middle_name = '';
                    this.new_subject.surname = '';
                    this.new_subject.sex = true;
                    this.open_subject(item)


                    this.snackbar_color = "success";
                    this.snackbar_text = "Data saved successfully. ";
                    this.snackbar = true;

                  } else {
                    this.snackbar_color = "error";
                    this.snackbar_text = "Error save data. ";
                    this.snackbar = true;
                    this.overlay = false
                  }           
          });

        } ,
        
        disable_subject(item){
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setvaliditasubject",
                    id_subject: item.id_subject,
                    validita: 0 ,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {   
                if (res.status == 200){
                    this.getSubject()
                    this.getAllContact()
                    this.snackbar_color = "success";
                    this.snackbar_text = "Subject disabled!. ";
                    this.snackbar = true;
                 
                }
            });
        },

        enable_subject(item){
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setvaliditasubject",
                    id_subject: item.id_subject,
                    validita: 1 ,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {   
                if (res.status == 200){
                    this.getSubject()
                    this.getAllContact()
                    this.overlay = false  
                    this.snackbar_color = "success";
                    this.snackbar_text = "Subject Enabled!. ";
                    this.snackbar = true;      

                }
            });
        },      
        
        Close_newSubject(){
          this.newSubject = false
        },
        

        
        

    }
  }
</script>
