<template>
    <div class="mt-10 ml-6 mr-6">
   
      <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>
        <!-- TABS di dettaglio -->
        <div v-if="pagetabs">
            <subjectTabsDetail 
              :obj_subject = this.items_subject  
              :val_subject_sms = this.item_subject_sms
              :val_subject_mail = this.item_subject_mail
              :id_subject_selected = this.id_subjectSelected
              :modifica = this.modifica
              tab = "event"
              v-on:closeTabsDetail="valori_di_ritorno_TabsDetail"
            /> 
        </div> 


        <v-card id="create" style="background-color: var(--main-bg-color);" v-if="!pagetabs" elevation="0" >
          <div v-if="!pagetabs" class="child-flex" style="background-color:var(--main-bg-color);">

            <v-speed-dial
                v-if="vedi_dettaglio" 
                v-model="fab"
                :top="top"
                :bottom="bottom"
                :right="right"
                :left="left"
                :direction="direction"
                :open-on-hover="hover"
                :transition="transition"
              >
                <template v-slot:activator>
                  <v-btn
                    v-model="fab"
                    color="blue darken-2"
                    dark
                    fab
                  >
                    <v-icon v-if="fab" @click="toTop">
                        mdi-chevron-up
                    </v-icon>
                    <v-icon v-else>
                      mdi-account-circle
                    </v-icon>
                  </v-btn>
                </template>
                <v-btn
                  fab
                  dark
                  small
                  @click="stampa_headShot"
                  color="blue"
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
                <v-btn
                  fab
                  dark
                  small
                  @click="clear_filter"
                  color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-speed-dial>   

            <v-card v-if="vedi_filtri"  class="mt-1 ml-4 mr-4" elevation="0" style="background-color:var(--main-bg-color);"  ><!--First Row Filtri-->
              <v-row>

                <v-col cols="12" sm="6" md="4">
                    <v-row>
                        <v-col class="mt-5" cols="12" sm="6" md="3" width="100"
                          style="letter-spacing: 1px; font-weight: 600; 
                          font-family: Titillium Web; font-size: 18px"
                        >
                            Filter for
                        </v-col>
                        <v-col cols="12" sm="9">
                          <v-autocomplete
                            v-model="filter_event"
                            :items="valid_events"
                            :item-text="(item) => item.event_name + '-' + item.city_event"  
                            item-value='id_event'            
                            label="Event"
                            prepend-inner-icon="mdi-filter-variant"
                            outlined
                            clearable
                            clear-icon="mdi-close"
                            color="var(--main-border-color)" 
                            item-color="var(--main-active-select-list-color)"
                            style=" color: var(--main-input-color); font-size: 18px;"   
                            class="textFieldForm"
                            :loading="isLoading_event"
                            :search-input.sync="search_event"                                          
                          ></v-autocomplete>
                        </v-col>                                
                    </v-row>                                

                </v-col>

                <!-- Fine Campi Filter -->

                <!-- pulsanti Filtro-->
                <v-col cols="12" sm="6" md="2" class="ml-n2 ">
                      <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                              <v-btn  
                                class="mt-3 btnForm "  
                                elevation="0" color="var(--main-primary-color)" dark v-bind="attrs" v-on="on" 
                                @click="getheadshot()"
                              > Apply</v-btn>
                          </template>
                          <span>Apply Selected Filter</span>
                      </v-tooltip>
                      
<!--                       <v-tooltip top >
                          <template v-slot:activator="{ on, attrs }">
                              <v-btn v-if="valid_events.length>0"
                                  class="mt-3 btn_bg nohover " elevation="0" 
                                  color="var(--main-bg-color)"  dark v-bind="attrs" v-on="on" @click="clear_filter"
                              > Clear
                              </v-btn>
                              <v-btn v-else
                                  class="mt-3 ml-1 " elevation="0" 
                                  color="var(--main-bg-color)"  dark v-bind="attrs" v-on="on" @click="clear_filter"
                                  disabled
                              > 
                              </v-btn>
                          </template>
                          <span>Clear All Filter</span>
                      </v-tooltip>  -->                           
                </v-col>
              </v-row>               
            </v-card>  

            <v-card class="mt-n8 ml-4 " elevation="0"  v-if="vedi_dettaglio" > <!--Gestione Tabella-->
                <v-row>
                <v-col cols="12" style="background-color:var(--main-bg-color);"> 
                  <template>
                      
                    <v-data-table
                      :headers="header_headshot"
                      :items="headshot"
                      color="var(--main-input-color)"
                      class="elevation-0"
                      :footer-props="{
                                'items-per-page-text':'Rows per page',
                                'items-per-page-options': [10, 50, 100, 500], 
                            }"
                    >
                      
                      <template v-slot:[`header.fighter`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Fighters </a>
                      </template>

                      <template v-slot:[`header.time`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Time </a>
                      </template>
                      <template v-slot:[`header.sex`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Sex</a>
                      </template>                   


                      <template v-slot:[`header.des_weight_category`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Category </a>
                      </template>
                      <template v-slot:[`header.round`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Round </a>
                      </template>  

                      <template v-slot:[`header.duration`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Duration </a>
                      </template> 

                      <template v-slot:[`header.refree`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Refree </a>
                      </template>
                      <template v-slot:[`header.Pre_Main`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Pre_Main </a>
                      </template> 

                      <template v-slot:[`header.world_title`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> World title </a>
                      </template>
                      <template v-slot:[`header.tournament`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Tournament</a>
                      </template> 
                      
                      <template v-slot:[`header.cutman`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Cutman </a>
                      </template>
                      <template v-slot:[`header.corner`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Corner </a>
                      </template> 


                      <template v-slot:[`item.check_in`]="{ item }">
                            {{formatHourMin(item.time)}} 
                      </template>   

                      <template v-slot:[`item.fighter`]="{ item }">
                        <v-chip
                          :color="getColor(item.ord)"
                          dark
                        >
                          {{ item.fighter }}
                        </v-chip>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon color="var(--main-primary-color)" @click="open_subject(item)"
                                    >mdi-eye
                                    </v-icon>
                      </template>
                      <template v-slot:no-data>
                          Empty Result
                      </template>

                    </v-data-table>
                  </template>
                  <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                  </v-overlay>   

                </v-col>   
              </v-row>  
            </v-card> 
          
          <v-dialog  v-model="pdfdialog" width="unset">
              <v-card>
                  <v-toolbar
                      elevation="0"
                      class="white--text"
                      dense
                      style="position: sticky; top: 0px; z-index: 1
                        box-shadow: none;
                        outline: none;
                        padding-left: 6px;
                        padding-right: 6px;
                        font-size: 14px;
                        background-color: #283046;
                        color: #f6f6f2;
                        font-weight: 700;
                        background-image: linear-gradient(to left top ,#521f1c, #283046 );
                        "                
                      >
                      <v-toolbar-title> Event Print Viewer </v-toolbar-title>
                      <v-spacer></v-spacer>
                          <v-icon
                            color="white"
                            @click="pdfdialog = false"
                          >mdi-close</v-icon>
                  </v-toolbar>
                  <v-row>
                      <v-col cols="12" sm="6" md="12">
                          <iframe
                              :src="this.selectedPdf"
                              frameborder="0"
                              style="height: 90vh !important; width: 90vw !important"
                          >
                          </iframe>
                      </v-col>
                  </v-row>
              </v-card>
              
          </v-dialog>            
          
          </div>
        </v-card>

    </div>
</template>
<style>
  /* Style for Bottom menu  */
  #create .v-speed-dial {
    position: fixed;
    z-index: 2;
  }

  #create .v-btn--floating {
    position: relative;
  }

</style>
<script>
  
import subjectTabsDetail from "@/components/Subject/SubjectTabsDetail.vue";
import DropFile from "@/components/DropFile.vue";
  export default {
    components: {
       subjectTabsDetail,
       DropFile,
    },
    data () {
      return {

        snackbar: false,
        snackbar_color: "",
        snackbar_text: "",

        //x Bottom menu
        direction: 'top',
        fab: false,
        fling: false,
        hover: true,
        tabs: null,
        top: false,
        right: true,
        bottom: true,
        left: false,
        transition: 'slide-y-reverse-transition',
        //x Bottom to Top
        scTimer: 0,
        scY: 0,

        pagetabs:false,
        vedi_filtri:true,
        vedi_dettaglio:false,   

        overlay: false,  
        tipo_file_pdf:false,
        search:'',
        dialogUpload:false,
        pdfdialog:false,
        photodialog:false,
        selectedPdf:null,
        selectedPhoto:'',


        front_page_save:'',
        subjects: [],
        events_subject:[],
        headshot:[],
        events:[],
        editedItem:{},
        event_selected:"",
        filter_event:null,
        isLoading_event: false,
        search_event: null,  

        items_subject:null,
        item_subject_sms:null,
        item_subject_mail:null,
        id_subjectSelected:null,


        header_headshot: [
          { text: '', value: 'actions', sortable: false },
          { text: 'fighter', value: 'fighter' , sortable: false  },
          { text: 'time', value: 'time' , sortable: false  },
          { text: 'category', value: 'des_weight_category' , sortable: false },
          { text: 'round', value: 'round' , sortable: false },
          { text: 'duration', value: 'duration', sortable: false  },
          { text: 'refree', value: 'refree', sortable: false },
          { text: 'Pre_Main', value: 'Pre_Main', sortable: false  },
          { text: 'world_title', value: 'world_title' , sortable: false },
          { text: 'tournament', value: 'tournament' , sortable: false },
          { text: 'cutman', value: 'cutman' , sortable: false }, 
          { text: 'corner', value: 'corner', sortable: false  },
        ],  

         footerProps: { "items-per-page-options": [15, 30, 50, 100] },    
       }
    },
    props: {
    },     
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
      this.getEvents()
    }, 
    computed: {
      valid_events() {
        return this.events.filter((i) => {
            return (i.validita === null) || (i.validita === 1) || (i.validita === "");
        })
      },

     
    }, 
    watch: {
    },  
    methods: {

        getEvents(){
          var filter_page =  ''
          this.overlay = true
          this.events=[]
          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "getevents",
                  search: filter_page,
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                this.events = res.data.events;
                this.overlay = false 
              } else {
                this.snackbar_color = "error";
                this.snackbar_text = "Events not loaded.";
                this.snackbar = true;
                this.overlay = false
              }     
          })
          .catch((ex) => {
            if (ex.response.status == 404 || ex.response.status == undefined) {
              this.overlay = false
              this.snackbar = true;
              this.snackbar_color = "error";
              this.snackbar_text = ex;
              this.snackbar_icon_color = "var(--main-primary_text-color)";
              this.overlay = false
            }
          });
        },
  
        getheadshot(){
           if (this.filter_event == "" ||  this.filter_event == null) return

          this.overlay = true
          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "getheadshot_rpt",
                  idevent: this.filter_event,
                  token: localStorage.getItem("user_token"),
              },
          }
          
          this.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                this.headshot = res.data.headshotRpt;
                this.vedi_dettaglio = true
                this.vedi_filtri = false
                this.overlay = false 
              } else {
                this.snackbar_color = "error";
                this.snackbar_text = "Data not loaded.";
                this.snackbar = true;
                this.overlay = false
              }     
          })
          .catch((ex) => {
            
              this.snackbar = true;
              this.snackbar_color = "error";
              this.snackbar_text = ex;
              this.overlay = false
            
          });
        },            

         
        open_subject(item){
          this.modifica = Math.round(Math.random()*100);
          this.items_subject = item
          this.item_subject_sms = item.subject_sms
          this.item_subject_mail = item.subject_mail
          this.id_subjectSelected = item.id_subject
          this.pagetabs=true
        },        

        valori_di_ritorno_TabsDetail(value){
            this.getheadshot()
            this.pagetabs =  false
        },  

         

        clear_filter(){
          this.editedItem.front_page_event = this.front_page_save 
          this.filter_event = null
          this.headshot=[]
          this.dialogUpload = false
          this.vedi_dettaglio = false 
          this.vedi_filtri = true            
        },


        formatDate (date) {
          if (!date) return null
          let new_date = date.substring(0,10)
          const [year, month, day] = new_date.split('-')
          return `${month}/${day}/${year}`
        },  
        
        formatHourMin (time) {
          if (!time) return null
          return time.substring(0,5)

        },         

        getColor (ord) {
          if (ord == 2) return 'blue'
          else return 'red'
        },    

        print(){},

    async stampa_headShot(){
        this.overlay= true
        await this.getStampaEvento();
        const byteCharacters = atob(this.file_b64);
        const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        this.selectedPdf = blobUrl;
        this.pdfdialog = true;
        this.overlay = false   
    },

    getStampaEvento() {
        return new Promise((resolve, reject) => {
            this.overlay = true;
            let disegnoReq = {
                controller: "Bellator",
                method: "POST",
                richiesta: {
                action: "getmatchpdf",
                id_event: this.filter_event,                
                token: localStorage.getItem("user_token"),
                },
            };
            this.file_b64 = '';
            this.$store.dispatch("api", disegnoReq).then((res) => {
                this.file_b64 = res.data
                this.overlay = false;
                resolve(res);
                return;
            })
            .catch((e) => {
                this.overlay = false;
                reject(e);
                console.log(e);
            });
        });
    }, 


      //Gestione Go Bottom to TOP----------------------------

      handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
      toTop: function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },      

      //--------------------------------------------        

    }
  }
</script>
