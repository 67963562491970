<template>
    <div>
      <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>
      <!-- TABS di dettaglio -->
      <v-row style="position:fixed; z-index: 1;  width:90%;"> <!--Gestione TAB di dettaglio tab inerni-->

          <v-col cols="12" md="12 " class="mt-n2"  style="background-color: var(--main-bg-color);   "  >
              <v-toolbar dense  elevation="0" style="height: 80px; padding-top: 20px; background-color: var(--main-bg-color); ">

                  <div class="mt-2 hidden-sm-and-down" style="width: 260px;">
                      <v-icon
                        class="ml-n15  mt-n2"
                        large
                        color="var(--main-primary-color)"
                      >
                        mdi-pencil
                      </v-icon>
                      <label 
                            style="
                              padding-left: 6px;
                              width: 220px;
                              font-size: 20px;
                              font-family: Titillium Web;
                              font-style: normal;
                              color: var(--main-dth-color);
                              font-weight: 700; 
                      "> {{this.OBJ_EVENT.name}} 
                      </label>   
                  </div>

                  <div  v-if="$vuetify.breakpoint.sm ? style='width:100px; max-width:300px;' : style='width:55%;' "  >                    
                    <v-tabs
                        v-model="modalTab"
                        slider-size="4"
                        slider-color="red"
                        color="var(--main-primary-color)"
                        background-color="var(--main-bg-color)"
                    >

                      <v-tab :href="`#AdBookDetail`" 
                          class="tab_style"   
                          style="font-size: 1.0rem; text-transform: Initial;"

                      > </v-tab >
                      <!--
                      <v-tab :href="`#boutsheet`" 
                          style="margin-left: 15px; font-size: 1.0rem; text-transform: Initial;" class="tab_style"              
                      >Bout Sheet</v-tab>
                      <v-tab :href="`#trip`" 
                          style="margin-left: 15px; font-size: 1.0rem; text-transform: Initial;" class="tab_style"
                      >Trip</v-tab>
                      <v-tab :href="`#photo`" 
                          style="margin-left: 15px; font-size: 1.0rem; text-transform: Initial;" class="tab_style"
                      >Photo</v-tab>      
                      <v-tab :href="`#graphics`" 
                          style="margin-left: 15px; font-size: 1.0rem;text-transform: Initial;" class="tab_style"        
                      >Graphics</v-tab>     
                      <v-tab :href="`#media`" 
                          style="margin-left: 15px; font-size: 1.0rem;text-transform: Initial;" class="tab_style"        
                      >Media</v-tab>
                      <v-tab :href="`#broadcast`" 
                          style="margin-left: 15px; font-size: 1.0rem;text-transform: Initial;" class="tab_style"        
                      >Broadcast</v-tab>
                      <v-tab :href="`#social`" 
                          style="margin-left: 15px; font-size: 1.0rem;text-transform: Initial;" class="tab_style"        
                      >Social</v-tab>                                                                                   
                     -->
                    </v-tabs> 
                  </div>
              </v-toolbar>
          </v-col>

      </v-row>
      <v-row  >  <!--Gestione TAB dettagli con chiamata a componenti pagine interne-->
          <v-col cols="12" style="margin-top: 70px; "  >
              <v-tabs-items  style=" background-color: var(--main-bg-color);"  v-model="modalTab" class="no-transition" touchless>
                    <v-tab-item
                    :value="`AdBookDetail`"
                    transition="false"
                    class="no-transition"
                  >
                    <AdBookDetail 
                      :ITEM=this.OBJ_EVENT  
                      :TRIGGER = this.TRIGGER
                      v-on:closeDettEvent="valori_di_ritorno_AdBookDetail"
                    /> 
                  </v-tab-item>
                <!--  
                  <v-tab-item
                    :value="`boutsheet`"
                    transition="false"
                    class="no-transition"
                  >
                      Tab Trip
                      <br> <br> <br> <br> <br>
                  </v-tab-item>
                  <v-tab-item
                    :value="`trip`"
                    transition="false"
                    class="no-transition"
                  >
                      Tab Trip
                      <br> <br> <br> <br> <br>
                  </v-tab-item>
                  <v-tab-item
                    :value="`photo`"
                    transition="false"
                    class="no-transition"
                  >
                      Tab Photo
                      <br> <br> <br> <br> <br>
                  </v-tab-item>
                  <v-tab-item
                    :value="`graphics`"
                    transition="false"
                    class="no-transition"
                  >
                      TAB Graphics
                      <br> <br> <br> <br> <br>
                  </v-tab-item>   
                  <v-tab-item
                    :value="`media`"
                    transition="false"
                    class="no-transition"
                  >
                      TAB Media
                      <br> <br> <br> <br> <br>
                  </v-tab-item>  
                  <v-tab-item
                    :value="`broadcast`"
                    transition="false"
                    class="no-transition"
                  >
                      TAB Broadcast
                      <br> <br> <br> <br> <br>
                  </v-tab-item>  
                  <v-tab-item
                    :value="`social`"
                    transition="false"
                    class="no-transition"
                  >
                      TAB Social
                      <br> <br> <br> <br> <br>
                  </v-tab-item>                                                        
                -->
              </v-tabs-items>
           
          </v-col>
      </v-row>
    </div>
</template>
<style>
</style>
<script>
import AdBookDetail from "./components/AdBookDetail";

  export default {
    components: {
       AdBookDetail,
    },
    data () {
      return {

        snackbar: false,
        snackbar_color: "",
        snackbar_text: "",

        overlay: false,   
        
        modalTab: "data",
        modalTab_event:"AdBookDetail",

       }
    },
    props: {
        OBJ_EVENT: Object,
        TRIGGER: Number,
    },     
    mounted() {
    }, 
    computed: {
    }, 
    watch: {
    },  
    methods: {

        valori_di_ritorno_AdBookDetail(){
            this.$emit("closeDettEvent", "");
        },  


    }
  }
</script>
