var Lib = {

    test(){
        console.log("chiamata libreria")
    },

    format_Date (date) {
      
        if (!date) return null
        let new_date = date.substring(0,10)
        const [month,day,year] = new_date.split('/')
        return `${year}-${month}-${day}`
      }, 

      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
   
      parseDate (date) {
        debugger
        if (!date) return null
        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }, 
     
 
}
export default Lib;