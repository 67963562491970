<template>
  <div id="app">    
    <router-view />
    <Network />
  </div>
</template>
<script>
//Il componente Network è utilizzato per le chiamate lato _MODULI\Amministrazione per la gestione amministrativa.
import Network from "@/components/NET/Network.vue";
export default {
  components : { Network },

  methods: {
   
  },
};
</script>

<style>
/* src\assets\styles\default.scss */
</style>


