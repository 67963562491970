<template>
    <div class="mt-10 ml-6 mr-6">
      <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>

        <!-- TABS di dettaglio -->
        <div v-if="pagetabs">
            <subjectTabsDetail 
              :obj_subject = this.items_subject  
              :val_subject_sms = this.item_subject_sms
              :val_subject_mail = this.item_subject_mail
              :id_subject_selected = this.id_subjectSelected
              :modifica = this.modifica
              tab = "data"
              v-on:closeTabsDetail="valori_di_ritorno_TabsDetail"
            /> 
        </div>      
        <v-card id="create" style="background-color: var(--main-bg-color);" v-if="!pagetabs" elevation="0" >
          <div v-if="!pagetabs" style="background-color:var(--main-bg-color);">
            <v-speed-dial
                v-if="vedi_dettaglio" 
                v-model="fab"
                :top="top"
                :bottom="bottom"
                :right="right"
                :left="left"
                :direction="direction"
                :open-on-hover="hover"
                :transition="transition"
              >
                <template v-slot:activator>
                  <v-btn
                    v-model="fab"
                    color="blue darken-2"
                    dark
                    fab
                  >
                    <v-icon v-if="fab" @click="toTop">
                        mdi-chevron-up
                    </v-icon>
                    <v-icon v-else>
                      mdi-account-circle
                    </v-icon>
                  </v-btn>
                </template>
                <v-btn
                  fab
                  dark
                  small
                  @click="clear_filter"
                  color="red"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-speed-dial>             
            <v-card  v-if="vedi_filtri"  class="mt-1 ml-4 mr-4" elevation="0" style="background-color:var(--main-bg-color);"  ><!--First Row Filtri-->
              <v-row>

                <v-col cols="12" sm="6" md="4">
                    <v-row>
                        <v-col class="mt-5" cols="12" sm="6" md="3" width="100"
                          style="letter-spacing: 1px; font-weight: 600; 
                          font-family: Titillium Web; font-size: 18px"
                        >
                            Filter for
                        </v-col>
                        <v-col cols="12" sm="9">
                          <v-autocomplete
                            v-model="filter_event"
                            :items="valid_events"
                            :item-text="(item) => item.event_name + '-' + item.city_event"  
                            item-value='id_event'            
                            label="Event"
                            prepend-inner-icon="mdi-filter-variant"
                            outlined
                            clearable
                            clear-icon="mdi-close"
                            color="var(--main-border-color)" 
                            item-color="var(--main-active-select-list-color)"
                            style=" color: var(--main-input-color); font-size: 18px;"   
                            class="textFieldForm"
                            :loading="isLoading_event"
                            :search-input.sync="search_event"                                          
                          ></v-autocomplete>
                        </v-col>                                
                    </v-row>                                

                </v-col>

                <!-- Fine Campi Filter -->

                <!-- pulsanti Filtro-->
                <v-col cols="12" sm="6" md="2" class="ml-n2 ">
                      <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                              <v-btn  
                                class="mt-3 btnForm "  
                                elevation="0" color="var(--main-primary-color)" dark v-bind="attrs" v-on="on" 
                                @click="getsubjectspassport()"
                              > Apply</v-btn>
                          </template>
                          <span>Apply Selected Filter</span>
                      </v-tooltip>
                      
  <!--                     <v-tooltip top >
                          <template v-slot:activator="{ on, attrs }">
                              <v-btn v-if="valid_events.length>0"
                                  class="mt-3 btn_bg nohover " elevation="0" 
                                  color="var(--main-bg-color)"  dark v-bind="attrs" v-on="on" @click="clear_filter"
                              > Clear
                              </v-btn>
                              <v-btn v-else
                                  class="mt-3 ml-1 " elevation="0" 
                                  color="var(--main-bg-color)"  dark v-bind="attrs" v-on="on" @click="clear_filter"
                                  disabled
                              > 
                              </v-btn>
                          </template>
                          <span>Clear All Filter</span>
                      </v-tooltip>    -->                         
                </v-col>
              </v-row>               
            </v-card>  

            <v-card class="mt-1 ml-4 " elevation="0"  v-if="vedi_dettaglio" >  <!--Gestione Tabella-->
                <v-row>
                <v-col cols="12" style="background-color:var(--main-bg-color);"> 
                  <template>
                      <v-card-title>
                        <v-spacer></v-spacer>
                        <v-text-field
                            style="color: var(--main-input-color); font-size: 18px;"                              
                            class="textFieldForm"    
                            color="var(--main-border-color)"            
                            outline
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-card-title>
                    <v-data-table
                      :headers="headers_sub_ev"
                      :items="events_subject_passport"
                      item-key="id_subject"
                      :search="search"
                      color="var(--main-input-color)"
                      sort-by="surname"
                      class="elevation-0 mt-n2"
                      :footer-props="{
                                'items-per-page-text':'Rows per page',
                                'items-per-page-options': [30, 60, 100, 500], 
                            }"
                    >
                                    
                      <template v-slot:[`header.surname`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Surname</a>
                      </template>
                      <template v-slot:[`header.name`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Name </a>
                      </template>
                      <template v-slot:[`header.passport_num`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Number </a>
                      </template>                    
                      <template v-slot:[`header.date_birth`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Date Birth </a>
                      </template>  
                      <template v-slot:[`header.passport_issue`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Date Issue </a>
                      </template>                                        
                      <template v-slot:[`header.passport_expire`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Expire Date </a>
                      </template>   
                      <template v-slot:[`header.passport_issued`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Issued By </a>
                      </template>                    
                      <template v-slot:[`item.date_birth`]="{ item }">
                            {{ formatDate (item.date_birth) }}
                      </template>  
                      <template v-slot:[`item.passport_issue`]="{ item }">
                            {{ formatDate (item.passport_issue) }}
                      </template>                       
                      <template v-slot:[`item.passport_expire`]="{ item }">
                            {{ formatDate (item.passport_expire) }}
                      </template>  
                      <template v-slot:[`item.passport_issued`]="{ item }">
                             {{getFlag(item.passport_issued)}} 
                      </template>                       


                      <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon color="var(--main-primary-color)" @click="open_subject(item)"
                                    >mdi-eye
                                    </v-icon>
                      </template>
                      <template v-slot:no-data>
                          Empty Result
                      </template>

                    </v-data-table>
                  </template>
                  <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                  </v-overlay>          
                </v-col>   
              </v-row>  
            </v-card> 
          </div> 
        </v-card>

    </div>
</template>
<style>
  /* Style for Bottom menu  */
  #create .v-speed-dial {
    position: fixed;
    z-index: 2;
  }

  #create .v-btn--floating {
    position: relative;
  }

.v-data-table-header th {
      font-size: 22px;
      font-family: Titillium Web, Regular;
      font-weight: 200;
      color: #9EB3BB;
}
</style>
<script>
  
import subjectTabsDetail from "@/components/Subject/SubjectTabsDetail.vue";

  export default {
    components: {
       subjectTabsDetail,
    },
    data () {
      return {

        snackbar: false,
        snackbar_color: "",
        snackbar_text: "",

       //x Bottom menu
        direction: 'top',
        fab: false,
        fling: false,
        hover: true,
        tabs: null,
        top: false,
        right: true,
        bottom: true,
        left: false,
        transition: 'slide-y-reverse-transition',
        //x Bottom to Top
        scTimer: 0,
        scY: 0,        

        pagetabs:false,
        vedi_filtri:true,
        vedi_dettaglio:false,         
        overlay: false,   
        
        search:'',


        subjects: [],
        events_subject:[],
        events_subject_passport:[],
        events:[],
        event_selected:"",
        filter_event:null,
        isLoading_event: false,
        search_event: null,  

        items_subject:null,
        item_subject_sms:null,
        item_subject_mail:null,
        id_subjectSelected:null,

        headers_sub_ev: [
          { text: '', value: 'actions', sortable: false },
          { text: 'Surname', value: 'surname' },
          { text: 'Name', value: 'name' },
          { text: 'Date Birth', value: 'date_birth' },
          { text: 'Number', value: 'passport_num' },
          { text: 'Date Issue', value: 'passport_issue' },
          { text: 'Expire Date', value: 'passport_expire' },
          { text: 'Issued By', value: 'passport_issued' },
        ],  

         footerProps: { "items-per-page-options": [15, 30, 50, 100] },    

       }
    },
    props: {
    },     
    mounted() {
      this.getEvents()
    }, 
    computed: {
      valid_events() {
        return this.events.filter((i) => {
            return (i.validita === null) || (i.validita === 1) || (i.validita === "");
        })
      },

     
    }, 
    watch: {
    },  
    methods: {

        getEvents(){
          var filter_page =  ''
          this.overlay = true
          this.events=[]
          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "getevents",
                  search: filter_page,
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                this.events = res.data.events;
                this.overlay = false 
              } else {
                this.snackbar_color = "error";
                this.snackbar_text = "Events not loaded.";
                this.snackbar = true;
                this.overlay = false
              }     
          })
          .catch((ex) => {
            if (ex.response.status == 404 || ex.response.status == undefined) {
              this.snackbar = true;
              this.snackbar_color = "error";
              this.snackbar_text = ex;
              this.overlay = false
            }
          });
        },

        
        getsubjectspassport(){
          this.overlay = true

          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "getsubjectspassport_rpt",
                  idevent: this.filter_event,
                  token: localStorage.getItem("user_token"),
              },
          }
          
          this.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                this.events_subject_passport = res.data.passportRpt;
                this.vedi_dettaglio = true
                this.vedi_filtri = false                
                this.overlay = false 
              } else {
                this.snackbar_color = "error";
                this.snackbar_text = "Data not loaded.";
                this.snackbar = true;
                this.overlay = false
              }     
          })
          .catch((ex) => {
            
              this.snackbar = true;
              this.snackbar_color = "error";
              this.snackbar_text = ex;
              this.overlay = false
            
          });
        },         

        open_subject(item){
          this.modifica = Math.round(Math.random()*100);
          this.items_subject = item
          this.item_subject_sms = item.subject_sms
          this.item_subject_mail = item.subject_mail
          this.id_subjectSelected = item.id_subject
          this.pagetabs=true
        },        

        valori_di_ritorno_TabsDetail(value){
           // console.log(value)
           this. getsubjectspassport()
            this.pagetabs =  false
        },  
        clear_filter(){
          this.filter_event = null
          this.events_subject_passport=[]
          this.vedi_dettaglio = false    
          this.vedi_filtri = true            
        },

        formatDate (date) {
          if (!date) return null
          let new_date = date.substring(0,10)
          const [year, month, day] = new_date.split('-')
          return `${month}/${day}/${year}`
        },  


        getFlag(id_flag){
            if (id_flag != null){
              let codFlag = this.alasql(
                  "SELECT  FROM ? WHERE id = '" + id_flag + "'",
                  [this.country_flags]
              );
              return codFlag[0].cod.toUpperCase()
            } else return ''
        },

      //Gestione Go Bottom to TOP--------------------

        handleScroll: function () {
          if (this.scTimer) return;
          this.scTimer = setTimeout(() => {
            this.scY = window.scrollY;
            clearTimeout(this.scTimer);
            this.scTimer = 0;
          }, 100);
        },
        toTop: function () {
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        },      

      //--------------------------------------------          

    }
  }
</script>
