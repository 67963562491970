<template>
    <div class="mt-10 ml-1 mr-1">
      <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>
        <!-- pulsanti Filtro-->
          <v-card v-if="vedi_filtri" class="mt-1 ml-4 mr-4" elevation="0" style="background-color:var(--main-bg-color);"  ><!--First Row Filtri-->
            <v-row>
              <v-col cols="12" sm="6" md="4">
                  <v-row>
                      <v-col class="mt-5" cols="12" sm="6" md="3" width="100"
                        style="letter-spacing: 1px; font-weight: 600; 
                         font-family: Titillium Web; font-size: 18px"
                      >
                          Filter for
                      </v-col>
                      <v-col cols="12" sm="9">
                        <v-autocomplete
                          v-model="filter_event"
                          :items="valid_events"
                          :item-text="(item) => item.event_name + '-' + item.city_event"  
                          item-value='id_event'            
                          label="Event"
                          prepend-inner-icon="mdi-filter-variant"
                          outlined
                          clearable
                          clear-icon="mdi-close"
                          color="var(--main-border-color)" 
                          item-color="var(--main-active-select-list-color)"
                          style=" color: var(--main-input-color); font-size: 18px;"   
                          class="textFieldForm"
                          :loading="isLoading_event"
                          :search-input.sync="search_event"                                          
                        ></v-autocomplete>
                      </v-col>                                
                  </v-row>                                

              </v-col>
              
              <v-col cols="12" sm="6" md="2" class="ml-n2 ">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn  
                              class="mt-3 btnForm "  
                              elevation="0" color="var(--main-primary-color)" dark v-bind="attrs" v-on="on" 
                              @click="view_Detail()"
                            > Apply</v-btn>
                        </template>
                        <span>Apply Selected Filter</span>
                    </v-tooltip>
                    
<!--                     <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="valid_events.length>0"
                                class="mt-3 btn_bg nohover " elevation="0" 
                                color="var(--main-bg-color)"  dark v-bind="attrs" v-on="on" @click="clear_filter"
                            > Clear
                            </v-btn>
                            <v-btn v-else
                                class="mt-3 ml-1 " elevation="0" 
                                color="var(--main-bg-color)"  dark v-bind="attrs" v-on="on" @click="clear_filter"
                                disabled
                            > 
                            </v-btn>
                        </template>
                        <span>Clear All Filter</span>
                    </v-tooltip>  -->                           
              </v-col>
            </v-row>               
          </v-card> 
        <!-- Fine Campi Filter -->

      <!-- TABS degli Slot  -->
      <v-row v-if="vedi_dettaglio==true" style="position:fixed; z-index: 1;  width:90%; height: 5%;"> <!--Gestione TAB -->

          <v-col cols="12" md="12 " class="mt-n2"  style="background-color: var(--main-bg-color);   "  >
              <v-toolbar dense  elevation="0" style="height: 80px; padding-top: 20px; background-color: var(--main-bg-color); ">

                  <div  v-if="$vuetify.breakpoint.sm ? style='width:100px; max-width:300px;' : style='width:55%;' "  >                    
                    <v-tabs
                        v-model="modalTab"
                        slider-size="4"
                        slider-color="red"
                        color="var(--main-primary-color)"
                        background-color="var(--main-bg-color)"
                    >

                      <v-tab :href="`#photoshooting`" 
                           style="font-size: 1.0rem; text-transform: Initial;"  class="tab_style"
                      >Photo Shooting</v-tab >

                      <v-tab :href="`#social`" 
                          style="margin-left: 15px; font-size: 1.0rem; text-transform: Initial;" class="tab_style"
                      >Social Interview</v-tab>

                      <v-tab :href="`#zoominterview`" 
                          style="margin-left: 15px; font-size: 1.0rem; text-transform: Initial;" class="tab_style"
                      >Zoom Interview</v-tab>      
                      <v-tab :href="`#commentator`" 
                          style="margin-left: 15px; font-size: 1.0rem;text-transform: Initial;" class="tab_style"        
                      >Commentator Interview</v-tab>     
                      <v-tab :href="`#media`" 
                          style="margin-left: 15px; font-size: 1.0rem;text-transform: Initial;" class="tab_style"        
                      >Media Day</v-tab>    
                      <v-tab :href="`#appointment`" 
                          style="margin-left: 15px; font-size: 1.0rem;text-transform: Initial;" class="tab_style"        
                      >Appointment</v-tab>                                                                                                    
                    
                    </v-tabs> 
                  </div>
              </v-toolbar>
          </v-col>

      </v-row>
      <v-row  v-if="vedi_dettaglio" >  <!--Gestione TAB dettagli con chiamata a componenti pagine interne-->
          <v-col cols="12" style="margin-top: 70px; "  >
              <v-tabs-items  style=" background-color: var(--main-bg-color);"  v-model="modalTab" class="no-transition" touchless>
                    <v-tab-item
                    :value="`photoshooting`"
                    transition="false"
                    class="no-transition"
                  >
                    <PhotoShootingRPT 
                      :ID_EVENT=this.filter_event 
                       v-on:closeDettEvent="close_Detail"
                    /> 
                  </v-tab-item>

                  <v-tab-item
                    :value="`social`"
                    transition="false"
                    class="no-transition"
                  >
                    <SocialInterviewRPT 
                      :ID_EVENT=this.filter_event 
                       v-on:closeDettEvent="close_Detail"
                    />                
                  </v-tab-item>
                  <v-tab-item
                    :value="`zoominterview`"
                    transition="false"
                    class="no-transition"
                  >
                    <ZoomInterviewRPT 
                      :ID_EVENT=this.filter_event 
                       v-on:closeDettEvent="close_Detail"
                    />
                  </v-tab-item>
                  <v-tab-item
                    :value="`commentator`"
                    transition="false"
                    class="no-transition"
                  >
                    <CommentatorInterviewRPT 
                      :ID_EVENT=this.filter_event 
                       v-on:closeDettEvent="close_Detail"
                    />
                  </v-tab-item>   
                  <v-tab-item
                    :value="`media`"
                    transition="false"
                    class="no-transition"
                  >
                    <MediaDayRPT 
                      :ID_EVENT=this.filter_event 
                       v-on:closeDettEvent="close_Detail"
                    />
                  </v-tab-item>  
                  <v-tab-item
                    :value="`appointment`"
                    transition="false"
                    class="no-transition"
                  >
                    <FixedAppointmentRPT 
                      :ID_EVENT=this.filter_event 
                       v-on:closeDettEvent="close_Detail"
                    />
                  </v-tab-item>                    
                                                      

              </v-tabs-items>
           
          </v-col>
      </v-row>

    </div>
</template>
<style>
</style>
<script>
  
  import PhotoShootingRPT from "./Comp_Rpt_App/rpt_photoshooting.vue";
  import FixedAppointmentRPT from "./Comp_Rpt_App/rpt_fixedeventappointment.vue";
  import ZoomInterviewRPT from "./Comp_Rpt_App/rpt_zoominterview.vue";
  import SocialInterviewRPT from "./Comp_Rpt_App/rpt_socialinterview.vue";
  import CommentatorInterviewRPT from "./Comp_Rpt_App/rpt_commentatorinterview.vue";
  import MediaDayRPT from "./Comp_Rpt_App/rpt_mediaday.vue";
  export default {
    components: {
      PhotoShootingRPT,
      FixedAppointmentRPT,
      ZoomInterviewRPT,
      SocialInterviewRPT,
      CommentatorInterviewRPT,
      MediaDayRPT,
    },
    data () {
      return {

        snackbar: false,
        snackbar_color: "",
        snackbar_text: "",

        vedi_filtri:true,
        vedi_dettaglio:false,

        overlay: false,   

        events:[],
        event_selected:"",
        filter_event:null,
        isLoading_event: false,
        search_event: null,
        
        modalTab: "data",
        modalTab_event:"photoshooting",

       }
    },
    props: {
    },     
    mounted() {
      this.getEvents()
    }, 
    computed: {
      valid_events() {
        return this.events.filter((i) => {
            return (i.validita === null) || (i.validita === 1) || (i.validita === "");
        })
      },
    }, 
    watch: {
    },  
    methods: {


        getEvents(){
          var filter_page =  ''
          this.overlay = true
          this.events=[]
          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "getevents",
                  search: filter_page,
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                this.events = res.data.events;
                this.overlay = false 
              } else {
                this.snackbar_color = "error";
                this.snackbar_text = "Events not loaded.";
                this.snackbar = true;
                this.overlay = false
              }     
          })
          .catch((ex) => {
            if (ex.response.status == 404 || ex.response.status == undefined) {
              this.snackbar = true;
              this.snackbar_color = "error";
              this.snackbar_text = ex;
              this.overlay = false
            }
          });
        },


        view_Detail(){
                this.vedi_filtri = false
                this.vedi_dettaglio= true         
        },

        close_Detail(){
            this.vedi_filtri = true
            this.vedi_dettaglio= false          
        }


    }
  }
</script>
