<template>
  <v-app id="inspire">
 
      <v-snackbar :color="colore_snackbar" v-model="snackbar">
        {{ this.messaggio_errore }}
        <v-btn color="white" text @click="snackbar = false">Chiudi</v-btn>
      </v-snackbar>
      <div style="background-color: #E9ECEA; height: 110px; ">
            <img v-if="$vuetify.breakpoint.sm == true || $vuetify.breakpoint.xs == true" 
               class="logo mt-9 "
               width="300" 
              :src="LogoSVG"/>
            <img v-else 
              class="logo mt-9 " 
              width="400"
              :src="LogoSVG"/>
      </div> 
      <div align="center" style="align-items: center; ">
          <p class="Login">Login</p>
          <v-text-field
            class="mt-10 textFieldForm"
            style="width:350px; margin:auto;"
            v-model="email"
            prepend-inner-icon="mdi-account"
            label="Username"
            color="#747474;"
            outlined
            background-color="white"
            :rules="[rules.required]"
            v-on:keyup.enter="login()"
          ></v-text-field> 
          <v-text-field
            v-model="password"
             style="width:350px; margin:auto;"
            class="mt-2 textFieldForm"
            color="#747474;"
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            prepend-inner-icon="mdi-lock"
            :type="show3 ? 'text' : 'password'"
            name="input-10-2"
            label="Password"
            outlined
            background-color="white"
            :rules="[rules.required]"
            @click:append="show3 = !show3"
            v-on:keyup.enter="login()"
          ></v-text-field>      
          <v-btn
            :loading="loading3"
            :disabled="loading3"
            class="mt-1 btnForm"
            style="
              width: 350px;
              height: 35px;
              color: #ffffff;
              font-family: 'Titillium Web', sans-serif;
            "
            color="var(--main-primary-color)"
            elevation="0"
            x-large            
            @click="login()"
            >Login
          </v-btn>
          <v-row justify="center"  class="mt-3" style="padding-right: 90px;">
              <v-checkbox v-model="checked" color="var(--main-primary-color)" 
                  :label="`Remember me on this device.`"
              ></v-checkbox>
          </v-row> 

          <v-divider class="mt-10" style="max-width:40%"/>    
          <v-btn
            class="mt-5 btn nohover" style="text-transform: initial; "
            text
            color="var(--main-primary-color)"
            v-on:click="openDialogRecuperoPassword"
            >Forgot your password?</v-btn
          >
      </div>  

      <v-footer color="white" app  class="ml-1 mr-2" fixed>
        <v-row>
          <v-col>
           <p style="font-family: 'Titillium Web', sans-serif; font-weight: 600; color:#747474; font-size:18px;" >
             ©{{new Date().getFullYear()}} Bellator MMA. All rights reserved.
           </p>
            <v-btn
              class="btn nohover"
              text
              color="var(--main-primary-color)"
              v-on:click="daFare"
              >Privacy Policy</v-btn
            >
            <v-btn
              class="btn nohover"
              text
              color="var(--main-primary-color)"
              v-on:click="daFare"
              >Cookies Policy</v-btn
            > 
            <v-btn
              class="btn nohover"
              text
              color="var(--main-primary-color)"
              v-on:click="daFare"
              >Do Not Sell My Personal Information</v-btn
            >                         
          </v-col> 
        </v-row>
      </v-footer>
     
      <v-dialog v-model="dialog" width="600px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{ titolo_modal }}</span>
          </v-card-title>
          <v-card-text>{{ testo_modal }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="rifiutoDialog()"
              >Rifiuto</v-btn
            >
            <v-btn color="green darken-1" text @click="accettoDialog()"
              >Accetto</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>        

      <v-dialog v-model="dialog_cambia_password" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ titolo_cambio_psw }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-if="data_psw == ''"
                    name="passwordOld"
                    label="Password Precedente"
                    id="passwordOld"
                    v-model="passwordOld"
                    :rules="[rules.required]"
                    :append-icon="value_log ? 'visibility' : 'visibility_off'"
                    @click:append="() => (value_log = !value_log)"
                    :type="value_log ? 'password' : 'text'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    name="password"
                    label="Nuova Password"
                    id="password"
                    :append-icon="value_log ? 'visibility' : 'visibility_off'"
                    @click:append="() => (value_log = !value_log)"
                    :type="value_log ? 'password' : 'text'"
                    v-model="passwordNew"
                    :rules="[rules.required, rules.min, rules.password]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    name="confirmPassword"
                    label="Conferma Password"
                    id="confirmPassword"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="passwordConfirm"
                    :rules="[comparePasswords, rules.required]"
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog_cambia_password = false"
              >Chiudi</v-btn
            >
            <v-btn color="blue darken-1" text @click="salvaNuovaPassword"
              >Salva</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_recupero_password" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Recupero Password</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    name="emailRecupero"
                    label="Email"
                    id="emailRecupero"
                    type="email"
                    v-model="emailRecupero"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog_recupero_password = false"
              >Chiudi</v-btn
            >
            <v-btn color="blue darken-1" text @click="recuperoPassword"
              >Invia</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog del lettore QR code -->
      <v-dialog v-model="qr_reader_visible" persistent>
        <v-toolbar>
          <v-toolbar-title> </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="handleCloseQRDialog" v-bind="attrs" v-on="on">
                <v-icon color="error" large> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <qrcode-stream
          :camera="camera"
          @decode="handleOnDecode"
        ></qrcode-stream>
      </v-dialog>
    

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-app>
  
</template>

<style scoped>
  .Login {
      margin-top:35px;
      margin-left: 5px;
      font-size: 28px;
      font-weight: 900;
      font-family: Titillium Web;  font-weight:800!important;
      color: #9EB3BB;
  }
  
  /*  Gestione pulsante btn senza hover 
  
  */
  .btn{
    text-transform: capitalize;   /* Tutte le prime lettere Maiuscole  - Initial riporta com'è scritto  */
    font-family: "Titillium Web", sans-serif;
    font-weight: 800;
    font-size:18px; 
    letter-spacing: 0px;
    text-decoration: underline;
  }

  .btn:not(.nohover):hover {
    border: 1px solid #0814bf;
    
  } /* Scomparsa  tasto grigio su hover */
  .btn::before {
    display: none;
  }


  .bkground {
    background: url('../assets/bg.png');
    background-size: cover;
    height: 100vh;
  }
  .centerDiv {
    width: 100%; 
    height: 100%; 
    vertical-align: middle; 
    padding-top: 7%
  }
  .v-text-field input {
    padding-bottom: 20px;
  }
</style>

<script>
import LogoSVG from '@/assets/svg/bellmma_tm_wr.svg'
import backgroundUrl from "../assets/logo.png";
import axios from "axios";
import config from "../../public/config";
//import { firebase } from "../plugins/firebase";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  components: {
    SnackbarQV,
  },
  data: () => ({
    LogoSVG,
    checked:true,
    backgroundUrl,
    config: config,
    snackbar: false,
    value: String,
    value_rip: String,
    value_log: String,
    loading3: false,
    loader: null,
    show3: false,
    img_azienda: "../assets/logo.png",
    email: "",
    messaggio_errore: "Email o Password errati!",
    password: "",
    profile:"",
    ripeti_password_reg: "",
    password_red: "",
    n_tessera_reg: "",
    email_reg: "",
    titolo_azienda: "",
    nome_reg: "",
    cognome_reg: "",
    data_psw: "",
    titolo_cambio_psw: "Cambio Password Obbligatorio",
    dialog_cambia_password: false,
    idUtentePasswordNew: -1,
    passwordNew: "",
    passwordConfirm: "",
    passwordOld: "",
    showPassword: false,
    colore_snackbar: "error",
    dialog: false,
    tokenJwt: "",
    testo_modal: "",
    arrayAvvisi: [],
    testo_azienda: "",
    check_privacy: 0,
    check_termini: 0,
    check_avvisi: 0,
    des_titolo_azienda: "Benvenuto in Q-ERP",
    tipo: "",
    titolo: "",
    testo: "",
    titolo_modal: "",
    avvisoAttuale: 0,
    dialog_recupero_password: false,
    emailRecupero: "",
    rules: {
      required: (value) => !!value || "Requested.",
      min: (v) => v.length >= 8 || "Min 8 Characters",
      password: (value) => {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
        return (
          pattern.test(value) ||
          "Min. 8 caratteri con almeno una lettera maiuscola, un numero, e un carattere speciale."
        );
      },
    },
    camera: "off",
    qr_reader_visible: false,


    images: {
        logo: require('@/assets/logo_bellmma.png')
    },

  }),
  name: "Login",
  props: {
    source: String,
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      this.loader = null;
    },
  },
  computed: {
    comparePasswords() {
      return this.passwordNew === this.passwordConfirm
        ? true
        : "La password non coincide";
    },
  },
  mounted() {
    
    localStorage.clear();
    var name_azienda = window.location.href
      .replace("https://", "")
      .replace("http://", "")
      .replace("www", "");
    var sup = name_azienda.split(".");
    //console.log(sup[0]);
    if (sup[0].includes("localhost")) {
      sup[0] = "test";
    }

    this.setConfig(sup[0]);
    
    var url = window.location.href.split("?");
    if (url.length > 1) {
      this.data_psw = url[1].replace("k=", "");
      this.dialog_cambia_password = true;
      this.titolo_cambio_psw = "Reimposta la tua password";
    } else {
      this.titolo_cambio_psw = "Cambio Password Obbligatorio";
    }
    this.getCookie();
  },
  methods: {

    async setConfig(param) {
        await new Promise((resolve, reject) => {
          axios
            .get("../../../config.json")
            .then((response) => {
              this.$store.dispatch("setConfig", response.data);
              
              this.profile = response.data.config.profile
              resolve(response);
              
              return;
            })
            .catch((e) => {
              reject(e);
              console.log(e);
            });
        });
        this.getInfoAzienda(param);
    },

    getInfoAzienda(azienda) {
      var request = {
        controller: "Token",
        method: "POST",
        richiesta: {
          action: "getinfoazienda",
          nome_azienda: azienda,
        },
      };
      //console.log("getinfoazienda request: ", JSON.stringify(request));
      this.$store.dispatch("api", request).then((res) => {
        if (res.data.length > 0) {
          if (res.data[0].img_azienda != null && res.data[0].img_azienda != "")

            //this.img_azienda = res.data[0].img_azienda;
            this.img_azienda = this.images.logo; 
            
          localStorage.setItem("img_azienda", this.img_azienda);
          if (
            res.data[0].ragione_sociale != null &&
            res.data[0].ragione_sociale != ""
          ) {
            this.titolo_azienda = res.data[0].ragione_sociale;
            localStorage.setItem("titolo_azienda", this.titolo_azienda);
          }
          if (
            res.data[0].testo_azienda != null &&
            res.data[0].testo_azienda != ""
          ) {
            this.testo_azienda = res.data[0].testo_azienda;
            localStorage.setItem("testo_azienda", res.data[0].testo_azienda);
          }
          if (res.data[0].titolo != null && res.data[0].titolo != "") {
            this.des_titolo_azienda = res.data[0].titolo;
            localStorage.setItem("des_titolo_azienda", res.data[0].titolo);
          }
          if (
            res.data[0].prefisso_utente != null &&
            res.data[0].prefisso_utente != ""
          ) {
            this.$store.state.prefisso_utente = res.data[0].prefisso_utente;
            // localStorage.setItem(
            //   "prefisso_utente",
            //   res.data[0].prefisso_utente
            // );
            console.log(res.data[0].prefisso_utente);
          }
        }
      });
    },
    accettoDialog() {
      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      // var finale = {
      //   action: "setcheckutente",
      //   id: localStorage.id,
      //   dbname: "medical",
      //   tipo: this.arrayAvvisi[this.avvisoAttuale].tipo,
      //   token: this.tokenJwt,
      // };

      var request = {
        controller: "Token",
        method: "PUT",
        richiesta: {
          action: "setcheckutente",
          id: localStorage.id,
          dbname: "medical",
          tipo: this.arrayAvvisi[this.avvisoAttuale].tipo,
          token: this.tokenJwt,
        },
      };
      this.$store.dispatch("api", request).then((response) => {
        this.azienda = response.data[0];
      });
      // var data = JSON.stringify(finale);
      // axios.put(this.$store.state.config.ip + "api/Token", data, config).catch((e) => {
      //   console.log(e);
      // });

      if (this.avvisoAttuale === this.arrayAvvisi.length - 1) {
        localStorage.setItem("user_token", this.tokenJwt);
        this.$router.replace("home/");
      } else {
        this.avvisoAttuale++;
        this.titolo_modal = this.arrayAvvisi[this.avvisoAttuale].titolo;
        this.testo_modal = this.arrayAvvisi[this.avvisoAttuale].testo;
        this.dialog = true;
      }
    },
    rifiutoDialog() {
      this.avvisoAttuale = 0;
      this.dialog = false;
      localStorage.clear();
      this.loading3 = false;
    },
    openDialogNuovaPassword: function () {
      this.passwordOld = "";
      this.passwordNew = "";
      this.passwordConfirm = "";
      this.idUtentePasswordNew = -1;
      this.loading3 = false;
      this.dialog_cambia_password = true;
    },
    salvaNuovaPassword: function () {
      this.idUtentePasswordNew = localStorage.id;

      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      //var finale;
      if (this.data_psw != "" && this.data_psw != null) {
        if (
          this.idUtentePasswordNew == -1 ||
          this.passwordNew == "" ||
          this.passwordConfirm == "" ||
          this.passwordNew != this.passwordConfirm
        ) {
          this.colore_snackbar = "error";
          this.messaggio_errore =
            "Errore: Compilare correttamente tutti i campi";
          this.snackbar = true;
          return;
        }
        // finale = {
        //   action: "setrecuperopsw",
        //   dbname: "medical",
        //   password: this.passwordNew,
        //   data: this.data_psw,
        // };
        // var data = JSON.stringify(finale);

        var request = {
          controller: "Token",
          method: "POST",
          richiesta: {
            action: "setrecuperopsw",
            dbname: "medical",
            password: this.passwordNew,
            data: this.data_psw,
          },
        };
        this.$store.dispatch("api", request).then(() => {
          this.passwordOld = "";
          this.passwordNew = "";
          this.passwordConfirm = "";
          this.idUtentePasswordNew = -1;
          this.dialog_cambia_password = false;
          this.colore_snackbar = "success";
          this.messaggio_errore = "Password Modificata";
          this.snackbar = true;
          this.password = "";
          this.loading3 = false;
          localStorage.clear();
        });


      } else {
        if (
          this.idUtentePasswordNew == -1 ||
          this.passwordNew == "" ||
          this.passwordOld == "" ||
          this.passwordConfirm == "" ||
          this.passwordNew != this.passwordConfirm
        ) {
          this.colore_snackbar = "error";
          this.messaggio_errore =
            "Errore: Compilare correttamente tutti i campi";
          this.snackbar = true;
          return;
        }
        // finale = {
        //   action: "putcambio",
        //   id_utente: this.idUtentePasswordNew,
        //   dbname: "medical",
        //   old: this.passwordOld,
        //   new: this.passwordNew,
        //   token: this.tokenJwt,
        // };
        // data = JSON.stringify(finale);
        request = {
          controller: "Token",
          method: "PUT",
          richiesta: {
            action: "putcambio",
            id_utente: this.idUtentePasswordNew,
            dbname: "medical",
            old: this.passwordOld,
            new: this.passwordNew,
            token: this.tokenJwt,
          },
        };
        this.$store.dispatch("api", request).then((response) => {
          if (response.status == 200) {
            if (response.data.attivo == 0) {
              this.loading3 = false;
              this.colore_snackbar = "error";
              this.messaggio_errore =
                "Errore:  L'utente non è attivo, contattare un responsabile";
              this.snackbar = true;
              return;
            }
            this.passwordOld = "";
            this.passwordNew = "";
            this.passwordConfirm = "";
            this.idUtentePasswordNew = -1;
            this.dialog_cambia_password = false;
            this.colore_snackbar = "success";
            this.messaggio_errore = "Password Modificata";
            this.snackbar = true;
            this.password = "";
            this.loading3 = false;
            localStorage.clear();
          } else {
            this.colore_snackbar = "orange";
            this.messaggio_errore = "Errore di modifica password";
            this.snackbar = true;
          }
        });
     }
    },

    openDialogRecuperoPassword: function () {
      this.emailRecupero = "";
      this.dialog_recupero_password = true;
    },

    daFare(){},

    recuperoPassword: function () {
      if (this.emailRecupero == "") {
        //compilare tutto
        this.colore_snackbar = "error";
        this.messaggio_errore = "Errore: Compilare correttamente tutti i campi";
        this.snackbar = true;
        return;
      }

      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      // var finale = {
      //   action: "getrecuperopsw",
      //   db_name: "medical",
      //   email: this.emailRecupero,
      // };
      // var data = JSON.stringify(finale);

      var request = {
        controller: "Token",
        method: "POST",
        richiesta: {
          action: "getrecuperopsw",
          db_name: "medical",
          email: this.emailRecupero,
        },
      };
      this.$store.dispatch("api", request).then((response) => {
        this.emailRecupero = "";
        this.dialog_recupero_password = false;

        if (response.status == "204") {
          this.colore_snackbar = "error";
          this.messaggio_errore = "Errore:  Email non trovata";
        } else {
          this.colore_snackbar = "success";
          this.messaggio_errore = "Email Inviata.\nControlla la tua email";
        }

        this.snackbar = true;
      });

      // axios
      //   .post(this.$store.state.config.ip + "api/Token", data, config)
      //   .then((response) => {
      //     console.log(response);
      //     // JSON responses are automatically parsed.
      //     this.emailRecupero = "";
      //     this.dialog_recupero_password = false;

      //     if (response.status == "204") {
      //       this.colore_snackbar = "error";
      //       this.messaggio_errore = "Errore:  Email non trovata";
      //     } else {
      //       this.colore_snackbar = "success";
      //       this.messaggio_errore = "Email Inviata.\nControlla la tua email";
      //     }

      //     this.snackbar = true;
      //   })
      //   .catch((e) => {
      //     this.emailRecupero = "";
      //     this.dialog_recupero_password = false;
      //     this.colore_snackbar = "error";
      //     this.messaggio_errore = "Errore nel recupero della Password";
      //     this.snackbar = true;

      //     if (e.response.status == 401) {
      //       this.$router.replace("login");
      //     }
      //     //controllare password ed utente
      //     console.log(e);
      //   });
    },

    invioLogin: function (e) {
      if (e.keyCode === 13) {
        this.login();
      }
    },

    login: function () {
      this.loader = "loading3";
      if (this.email == "" || this.password == "") {
        //compilare tutto
        this.colore_snackbar = "error";
        this.messaggio_errore = "Compilare correttamente username e password";
        this.snackbar = true;
        this.loading3 = false;
        return;
      }

      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      var request = {
        controller: "Token",
        method: "POST",
        richiesta: {},
      };

      /*if (
        localStorage.prefisso_utente != null &&
        localStorage.prefisso_utente != undefined &&
        localStorage.prefisso_utente != "" &&
        !this.email.includes("/")
      ) {
        request.richiesta = {
          username: this.email,
          password: this.password,
          prefisso_utente: localStorage.prefisso_utente,
        };
      } else 
      //eliminato la gestione con lo / e gestito il profilo dal file di configurazione.
      if (this.email.includes("/")) {
        var username = this.email.split("/");
        request.richiesta = {
          username: username[1],
          password: this.password,
          prefisso_utente: username[0],
        };
      } else {
        request.richiesta = {
          username: this.email,
          password: this.password,
          prefisso_utente: this.profile,
        };
      } */
      request.richiesta = {
          username: this.email,
          password: this.password,
          prefisso_utente: this.profile,
        };
      this.$store
        .dispatch("api", request)
        .then((response) => {
          if (response.data.jwt != "") {
            if (response.data.attivo == 0) {
              this.loading3 = false;
              //controllare password ed utente

              this.colore_snackbar = "error";
              this.messaggio_errore =
                "L'utente non è attivo, contattare il personale!";
              this.snackbar = true;
              return;
            }

            /*if(response.data.versione != "" && response.data.versione != this.$store.state.versione){
            this.loading3 = false;
            //controllare password ed utente
            this.colore_snackbar = "error";
            this.messaggio_errore =
              "Azzerare i dati di navigazione del browser.";
            this.snackbar = true;
            return;
          }*/


            //Gestione ricorda password mediante setCookie
            const self = this;
            
            if (self.checked == true) {
             var md5 = require("md5");
            //Enter account name, password, and save days, 3 parameters
              self.setCookie(self.email, md5(this.password), 7);
            } else self.clearCookie(); //Clear cookies
           

            localStorage.setItem("user_token", response.data.jwt);
            localStorage.setItem(
              "moduli_dx",
              JSON.stringify(response.data.moduloDx)
            );
            localStorage.setItem(
              "moduli_sx",
              JSON.stringify(response.data.moduloSx)
            );
            localStorage.setItem("id", response.data.id);
            localStorage.setItem("nominativo", response.data.nominativo);
            localStorage.setItem("des_profilo", response.data.des_profilo);
            localStorage.setItem("id_profilo", response.data.id_profilo);
            localStorage.setItem("des_azienda", response.data.des_azienda);
            localStorage.setItem("cod_azienda", response.data.cod_azienda);
            localStorage.setItem("id_azienda", response.data.id_azienda);
            localStorage.setItem("cod_profilo", response.data.cod_profilo);
           // localStorage.setItem("chat", response.data.firebase_ok);
            localStorage.setItem("db_name", response.data.db_name);
            localStorage.setItem("email", response.data.email);
            localStorage.setItem(
              "prefisso_user",
              response.data.prefisso_utente
            );
            localStorage.setItem("task_validi", response.data.task_validi);
            localStorage.setItem("task_scaduti", response.data.task_scaduti);

            //  Info accesso ai moduli amministrativi
            let auth_level = Number(response.data.auth_level);
            if (auth_level >= 0) {
              auth_level = new Uint32Array([auth_level])[0];
            }
            localStorage.auth_level = auth_level;
            // this.$store.state.auth_level = auth_level;
            // this.$store.state.show_admin_tools =
            localStorage.show_admin_tools = auth_level == -1 || auth_level > 0;

            if (response.data.check_privacy === "1") {
              this.check_privacy = 1;
            }
            if (response.data.check_termini === "1") {
              this.check_termini = 1;
            }
            if (response.data.check_avvisi === "1") {
              this.check_avvisi = 1;
            }

            if (response.data.check_privacy === "1") {
              this.check_privacy = 1;
            }
            if (response.data.check_termini === "1") {
              this.check_termini = 1;
            }
            if (response.data.check_avvisi === "1") {
              this.check_avvisi = 1;
            }

            this.arrayAvvisi = [];
            if (response.data.cambio_password == 1) {
              this.tokenJwt = response.data.jwt;
              localStorage.removeItem("user_token");
              this.loading3 = false;
              this.dialog_cambia_password = true;
            } else {
              if (
                this.check_privacy != 1 &&
                response.data.privacy != null &&
                response.data.privacy != "" &&
                response.data.privacy != undefined
              ) {
                this.tokenJwt = response.data.jwt;
                localStorage.removeItem("user_token");
                this.titolo_modal = "PRIVACY";
                this.testo_modal = response.data.privacy;
                var obj = {
                  testo: response.data.privacy,
                  titolo: "PRIVACY",
                  tipo: "privacy",
                };
                this.arrayAvvisi.push(obj);
              }
              if (
                this.check_termini != 1 &&
                response.data.termini_uso != null &&
                response.data.termini_uso != "" &&
                response.data.termini_uso != undefined
              ) {
                this.tokenJwt = response.data.jwt;
                localStorage.removeItem("user_token");
                this.titolo_modal = "TERMINI D' USO";
                this.testo_modal = response.data.termini_uso;
                var objx = {
                  testo: response.data.termini_uso,
                  titolo: "TERMINI D' USO",
                  tipo: "termini_uso",
                };
                this.arrayAvvisi.push(objx);
              }
              if (this.arrayAvvisi.length > 0) {
                this.titolo_modal = this.arrayAvvisi[this.avvisoAttuale].titolo;
                this.testo_modal = this.arrayAvvisi[this.avvisoAttuale].testo;
                this.dialog = true;
                return;
              }
              this.$router.replace("admin/");
 
            }
          } else {
            this.loading3 = false;
            //controllare password ed utente
            this.colore_snackbar = "error";
            if (response.data.status == 403) {
              this.messaggio_errore = "Username o password errati!";
            } else {
              this.messaggio_errore = response.data.result;
            }
            this.snackbar = true;
          }
        })
        .catch((e) => {
          console.log("catch Login auth", e);
          this.loading3 = false;
          if (e.toString().includes("403") || e.toString().includes("401")) {
            this.messaggio_errore = "Username o password errati!";
          } else {
            this.messaggio_errore = e;
          }
          this.snackbar = true;
        });

     
    },
    handleOnDecode(arg) {
      this.email = arg.split("(Q)")[1].split(";")[0];
      this.password = arg.split("(H)")[1].split(";")[0];
      this.camera = "off";
      this.qr_reader_visible = false;

      this.login();
    },
    clickQRCode() {
      this.qr_reader_visible = true;
      this.camera = "auto";
    },
    handleCloseQRDialog() {
      this.qr_reader_visible = false;
      this.camera = "off";
    },
    noop() {
      // do nothing ?
    },

    //Gestione Cookies salvataggio user e password
    //Set cookies
    setCookie(c_name, c_pwd, exdays) {
      
        //let exdate = new Date(); // get the time
        //exdate.setTime (exdate.gettime() + 24 * 60 * 60 * 1000 * exdays); // number of days saved
        var datascadenza = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * exdays ); // number of days saved
        //String concatenation cookie
        window.document.cookie ="userName" + "=" + this.email + ";path=/;expires=" + datascadenza;
        window.document.cookie ="userPwd" + "=" + this.password + ";path=/;expires=" + datascadenza;
    },
    //Get cookies
    getCookie() {
      if (document.cookie.length > 0) {
         let arr = document.cookie.split (";"); // the format shown here needs to be cut to output its own
                for (let i = 0; i < arr.length; i++) {
                    let arr2 = arr[i].split ("="); // cut again
                    //Judge the corresponding value of search
                    if (arr2[0].trim() == "userName") {
                        this.email = arr2[1]; // save to the place where the data is saved
                    } else if (arr2[0].trim() == "userPwd") {
                        this.password = arr2[1];
                    }
                }
            }
        },
        //Clear cookies
    clearCookie() {
        this.setcookie ('', '', - 1); // modify both values to be empty, and the number of days is negative 1 day
    },

  },
};
</script>

