<template>
    <div  id="create" >

          <v-snackbar
            content-class="snackbar"
            :color="snackbar_color"
            left 
            class="snackbar-gen"
            v-model="snackbar"
            :timeout="3000"
          >
            <div style="float: right;">
                <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
            </div>
            <div>
              <br> 
            <h3 :style="{ color: snackbar_text_color + '!important' }">
              {{ snackbar_text }}
            </h3>
            </div>
          </v-snackbar>

          <v-speed-dial
              v-model="fab"
              :top="top"
              :bottom="bottom"
              :right="right"
              :left="left"
              :direction="direction"
              :open-on-hover="hover"
              :transition="transition"
            >
              <template v-slot:activator>
                <v-btn
                  v-model="fab"
                  color="blue darken-2"
                  dark
                  fab
                >
                  <v-icon v-if="fab" @click="toTop">
                      mdi-chevron-up
                  </v-icon>
                  <v-icon v-else>
                    mdi-account-circle
                  </v-icon>
                </v-btn>
              </template> 
              <v-btn
                  fab
                  dark
                  small
                  @click="stampa_boutsheet"
                  color="blue"
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              <v-btn
                fab
                dark
                small
                @click="closeEventDett"
                color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
          </v-speed-dial> 

          <v-col cols="12">
            <div class="mt-2 boutsheet-title">
                    <v-row style="align-items: center;">
                <v-col cols="1" style="text-align: left; min-width: 180px;">
                    <v-btn
                    
                    id="btn_new"
                    outlined
                    color="white"
                    class="btn_bs-add"
                    @click="newFight()"
                  >
                <v-icon   
                                                    
                  color="white"
                   >
                  mdi-plus
                </v-icon>
                    <span>Add Fight</span>
                  </v-btn>
                </v-col>
                <v-col cols="" style="text-align: left;">

                <h2 style="color:white; text-align: left; display: inline-block; padding-right: 20px;" > 
                    {{ITEM.event_name}} {{formatDate(ITEM.date_event)}} {{ITEM.city_event}}, {{getCountry(ITEM.country_event)}} 
                </h2> 
                <span>h. {{ITEM.prelim_start_time != null ? ITEM.prelim_start_time.substring(0, 5) : ""   }}  </span>
                </v-col>

                      
              </v-row>
               
            
            </div>
          </v-col> 
    
          <v-col v-if="list_match" cols="12" class="mt-n4 boutsheet-list"  >
           
            <v-list style="background-color: var(--main-bg-color);" >
              <v-list-item-group 
                v-model="selectedItem"
                color="primary"
              >
                <v-list-item v-for="(eventmatch_array, index) in eventmatch_array"
                  :key="index" 
                  :ripple="false"
                  >
                    <v-row class="pt-3" >
                      <v-col cols="12" xs="12" sm="6" md="1"> 
                        <h3 class="titleLabelForm"> M/F</h3>
                        <p class="mt-3 ml-1 text-left">{{eventmatch_array.sex == 1 ? "M" :  "F" }} </p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="6" md="2"> 
                        <h3 class="titleLabelForm" style="">Weight Class</h3> 
                        <p class="mt-3 ml-1 text-left"> {{getWeightClass(eventmatch_array.weight_class)}} </p>
                      </v-col>
                      
                      <v-col cols="12" xs="12" sm="6" md="2"> 
                          <h3 class="titleLabelForm"  style="">Weight</h3> 
                          <p class="mt-3 ml-1 text-left"> {{ getWeight(eventmatch_array.weight_class)}} </p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="6" md="1"> 
                          <h3 class="titleLabelForm"  style="">Round</h3> 
                          <p class="mt-3 ml-1 text-left"> {{eventmatch_array.round}} </p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="6" md="1"> 
                        <h3 class="titleLabelForm"  style="">Dur.</h3> 
                        <p class="mt-3 ml-1 text-left"> {{eventmatch_array.duration != null ? eventmatch_array.duration.substring(0, 5) : ""}}  </p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="6" md="1"> 
                        <h3 class="titleLabelForm"  style="">Referee</h3> 
                        <p class="mt-3 ml-1 text-left"> {{eventmatch_array.surname_refree}}</p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="6" md="1"> 
                        <h3 class="titleLabelForm"  style="">Und\Main </h3>
                        <p class="mt-3 ml-1 text-left"> {{getPreMain(eventmatch_array.pre_main)}}</p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="6" md="1"> 
                        <h3 class="titleLabelForm"  style="">World T.</h3> 
                        <v-switch
                          class="ml-2 mt-2 switch-gen"
                          v-model="eventmatch_array.world_title"
                          color="green"
                          inset
                          readonly
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" xs="12" sm="6" md="1"> 
                        <h3 class="titleLabelForm"  style="">Tourn.</h3> 
                        <v-switch
                          class="ml-2 mt-2 switch-gen"
                          v-model="eventmatch_array.tournament"
                          color="green"
                          inset
                          readonly
                        ></v-switch>
                      </v-col>                      
                      <v-col cols="12" xs="12" sm="6" md="1"> 
                          <v-icon
                            class="mt-8 btn_icon"
                            @click="editItem(index)"
                          >
                            mdi-pencil
                          </v-icon>
                          <v-icon
                            class="mt-8 btn_icon"
                            @click="deleteItem(index)"
                          >
                            mdi-delete
                          </v-icon>                    
                      </v-col>
                      <v-col cols="12" sm="5" class="boutsheetmatchbox home">  
                        <v-row align="center" justify="center">
                          <v-col cols="12" md="2" style=""> 
                            <v-img :src="eventmatch_array.head_photo"  height="50" width="50" style="border:2px solid #0032AD; border-radius: 50%; margin-left: 5px;  padding-left: 5px;" /> 
                          </v-col>
                          <v-col cols="12" md="4" style="text-align: left;" class="ml-n5 bsmb-name"
                          > {{eventmatch_array.athlete_1_name}} <br>{{eventmatch_array.athlete_1_surname}}</v-col>
                          <v-col cols="12" md="2" class="text-center text-uppercase "> 
                            <CountryFlag class="mt-2 d-block ml-auto mr-auto " :country="getFlag(eventmatch_array.flag)" size='big' style="border-radius: 50%;"/> 
                            {{getFlag(eventmatch_array.flag)}}
                          </v-col>
                          <v-col  cols="12" md="4" style="text-align: left; color:#303030" > 
                            {{eventmatch_array.des_weight_category1 }}  <br>City: {{eventmatch_array.fighting_out_of }} <br> Win: {{ getWin(eventmatch_array.win_sub,eventmatch_array.win_ko,eventmatch_array.win_decision ) }} Lost: {{ getLost(eventmatch_array.lost_sub,eventmatch_array.lost_ko,eventmatch_array.lost_decision ) }}
                          </v-col>
                        </v-row> 
                      </v-col>
                      
                      <v-col cols="12" sm="2" class="d-flex" style="align-items:center; justify-content:center;">
                            <h2 class="">VS</h2>                         
                      </v-col>
                      
                      <v-col cols="12" sm="5" class="boutsheetmatchbox away"> 
                        <v-row align="center" justify="center">
                          <v-col cols="12" md="2" style=""> 
                            <v-img :src="eventmatch_array.head_photo1"  height="50" width="50" style="border:2px solid #ED1C24; border-radius: 50%; margin-left: 5px;  padding-left: 5px;" /> 
                          </v-col>
                          <v-col cols="12" md="4" style="text-align: left;" class="ml-n5 bsmb-name"
                          > {{eventmatch_array.athlete_2_name}} <br>{{eventmatch_array.athlete_2_surname}}</v-col>
                          <v-col cols="12" md="2" class="text-center text-uppercase "> 
                            <CountryFlag class="mt-2 d-block ml-auto mr-auto " :country="getFlag(eventmatch_array.flag1)" size='big' style="border-radius: 50%;"/> 
                            {{getFlag(eventmatch_array.flag1)}}
                          </v-col>
                          <v-col cols="12" md="4" style="margin left:30px; text-align: left; color:#303030" > 
                            {{eventmatch_array.des_weight_category2 }}  
                            <br>City: {{eventmatch_array.fighting_out_of1 }} 
                            <br> Win: {{ getWin(eventmatch_array.win_sub1,eventmatch_array.win_ko1,eventmatch_array.win_decision1 ) }} Lost: {{ getLost(eventmatch_array.lost_sub1,eventmatch_array.lost_ko1,eventmatch_array.lost_decision1 )}}
                          </v-col>
                        </v-row>  

                      </v-col>
                    </v-row>         
                </v-list-item>

              </v-list-item-group>
            </v-list>
             
          </v-col>  

          

          <v-col v-if="!list_match" cols="12" class="mt-n4 mt-12" style="padding-top: 100px;" >
            <v-card elevation="0">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="2"> <!--Sex -->
                      <p class="labelForm mt-n4 ml-5"  >{{ editedItem.sex || 'null' }}</p>
                      <v-radio-group
                        v-model="editedItem.sex"
                        mandatory
                        row
                        class="mt-n2 ml-6"
                      >
                        <v-radio
                          value="Male"
                          class="textFieldForm"
                          item-color="var(--main-active-select-list-color)"
                          style=" color: var(--main-input-color); font-size: 18px;"
                          color="var(--main-border-color)"                         
                        ></v-radio>
                        <v-radio
                          value="Female"
                          class="textFieldForm"
                          item-color="var(--main-active-select-list-color)"
                          style=" color: var(--main-input-color); font-size: 18px;"
                          color="var(--main-border-color)"                         
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12" sm="6" md="4"> <!--Weight Category-->
                      <v-select
                        class="textFieldForm ml-1"
                        style=" color: var(--main-input-color); font-size: 16px;"
                        item-color="var(--main-active-select-list-color)"
                        color="var(--main-border-color)" 
                        :items="weight_division"
                        v-model="editedItem.weight_class"
                        outlined
                        menu-props="auto"
                        label="Weight Category"
                        item-text="des"
                        item-value="id"   
                        @change="getpeso"
                      ></v-select>                    
                    </v-col>
                    <v-col cols="12" sm="6" md="2" > <!--Weight -->
                      <v-text-field
                        v-model="editedItem.des_weight_category"
                        label="Weight"
                        class="textFieldForm"
                        item-color="var(--main-active-select-list-color)"
                        style=" color: var(--main-input-color); font-size: 18px;"
                        color="var(--main-border-color)"     
                        outlined 
                        :readonly="NotWritable"                    
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="2"> <!--Round -->
                      <v-text-field
                        v-model="editedItem.round"
                        label="Round"
                        class="textFieldForm"
                        item-color="var(--main-active-select-list-color)"
                        style=" color: var(--main-input-color); font-size: 18px;"
                        color="var(--main-border-color)" 
                        type="Number"    
                        outlined                     
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="2"> <!--Duration -->
                      <v-text-field
                        v-model="editedItem.duration"
                        label="Duration"
                        class="textFieldForm"
                        item-color="var(--main-active-select-list-color)"
                        style=" color: var(--main-input-color); font-size: 18px;"
                        color="var(--main-border-color)"     
                        outlined     
                        type="time"                
                      ></v-text-field>
                    </v-col>                
                    <v-col cols="12" sm="6" md="3" > <!--Refree -->
                      <v-select
                        class="textFieldForm ml-1"
                        style=" color: var(--main-input-color); font-size: 18px;"
                        item-color="var(--main-active-select-list-color)"
                        color="var(--main-border-color)" 
                        :items="valid_refree"
                        v-model="editedItem.fk_id_subject_refree"
                        outlined
                        menu-props="auto"
                        clearable
                        clear-icon="mdi-close"
                        label="Referee"
                        item-text="name"
                        item-value="id_subject"   
                      ></v-select> 
                    </v-col>   
                    <v-col cols="12" sm="6" md="3" > <!--Under Main Card" -->
                      <v-select
                        class="textFieldForm ml-1"
                        style=" color: var(--main-input-color); font-size: 18px;"
                        item-color="var(--main-active-select-list-color)"
                        color="var(--main-border-color)" 
                        :items="pre_main"
                        v-model="editedItem.pre_main"
                        outlined
                        menu-props="auto"
                        label="Under Main Card"
                        item-text="des"
                        item-value="id"   
                      ></v-select> 
                    </v-col>   
                    <v-col cols="12" sm="6" md="2" class="mt-n1">  <!-- World Title -->
                        
                        <v-switch
                          label="World Title"
                          class="ml-12 switch-gen"
                          v-model="editedItem.world_title"
                          color="green"
                          inset
                        ></v-switch>                   
                    </v-col>   
                    <v-col cols="12" sm="6" md="2" class="mt-n1">  <!-- Tournament -->
                  
                        <v-switch
                          label="Tournament"
                          class="ml-12 switch-gen"
                          v-model="editedItem.tournament"
                          color="green"
                          inset
                        ></v-switch>                   
                    </v-col>  
                    <v-col cols="12" sm="6" md="2"> <!--Order -->
                      <v-text-field
                        v-model="editedItem.order"
                        label="Order"
                        class="textFieldForm"
                        item-color="var(--main-active-select-list-color)"
                        style=" color: var(--main-input-color); font-size: 18px;"
                        color="var(--main-border-color)" 
                        type="Number"    
                        outlined                     
                      ></v-text-field>
                    </v-col>                                    
                    <v-col cols="12" sm="5" md="5" class="ml-12 home"> <!--Athlete_1 -->
                      <v-autocomplete
                        v-model="editedItem.fk_id_subject_athlete1" 
                        item-color="var(--main-active-select-list-color)"
                        class="textFieldForm mt-n2"
                        style=" color: var(--main-input-color); font-size: 18px;"   
                        color="var(--main-border-color)"                                  
                        :items="array_athlete"
                        :loading="isLoading_athlete_1"
                        :search-input.sync="search_athlete_1"
                        clearable
                        prepend-icon="mdi-circle"
                        clear-icon="mdi-close"
                        item-text="name"
                        item-value="id_subject"
                        label="Fighters BLUE"
                        outlined
                      ></v-autocomplete>                  
                    </v-col>    
                    <v-col cols="12" sm="1" md="1">     <!--VS -->                      
                      <p class="titleLabelForm ml-8 mt-2"> 
                          VS
                      </p> 
                    </v-col> 
                    <v-col cols="12" sm="5" md="5" class="away"> <!--Athlete_2 -->
                      <v-autocomplete
                        v-model="editedItem.fk_id_subject_athlete2" 
                        item-color="var(--main-active-select-list-color)"
                        icon-color="#ff0000"
                        class="textFieldForm mt-n2"
                        style=" color: var(--main-input-color); font-size: 18px; "   
                        color="var(--main-border-color)"                                  
                        :items="array_athlete"
                        :loading="isLoading_athlete_2"
                        :search-input.sync="search_athlete_2"
                        clearable
                        prepend-icon="mdi-circle"
                        clear-icon="mdi-close"
                        item-text="name"
                        item-value="id_subject"
                        label="Fighters RED"
                        outlined
                      ></v-autocomplete>                  
                    </v-col>
                                                          
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions> <!--Save -->
                <v-col cols="12" sm="12" >
                  <v-card elevation="0" class="mt-n10">
                    <v-row style=" var(--main-bg-color);">
                      <v-col cols="12" class="text-center" >
                        <v-btn
                          class="btnForm"
                          color="var(--main-primary-color)"
                          dark 
                          @click="save"
                          elevation="0"
                        >
                          Save
                        </v-btn>
                        <v-btn
                          class="ml-1 btn_bg nohover"
                          color="trasparent"
                          style="background: transparent;"
                          elevation="0"
                          @click="backToList"
                        >
                          Cancel
                        </v-btn>                        
                        
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-dialog v-model="dialogDelete" max-width="1024px" >
                    <v-card class="dialog-del">                      
                      <div class="alert-top">!</div>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color=" darken-1" class="btn_bg nohover" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color=" " class="btnForm" dark elevation="0" @click="deleteItemConfirm">Yes, proceed</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>      
          
          <v-dialog  v-model="pdfdialog" width="unset">
              <v-card>
                  <v-toolbar
                      elevation="0"
                      class="white--text"
                      dense
                      style="position: sticky; top: 0px; z-index: 1
                        box-shadow: none;
                        outline: none;
                        padding-left: 6px;
                        padding-right: 6px;
                        font-size: 14px;
                        background-color: #283046;
                        color: #f6f6f2;
                        font-weight: 700;
                        background-image: linear-gradient(to left top ,#521f1c, #283046 );
                        "                
                      >
                      <v-toolbar-title> Event Print Viewer </v-toolbar-title>
                      <v-spacer></v-spacer>
                          <v-icon
                            color="white"
                            @click="pdfdialog = false"
                          >mdi-close</v-icon>
                  </v-toolbar>
                  <v-row>
                      <v-col cols="12" sm="6" md="12">
                          <iframe
                              :src="this.selectedPdf"
                              frameborder="0"
                              style="height: 90vh !important; width: 90vw !important"
                          >
                          </iframe>
                      </v-col>
                  </v-row>
              </v-card>
              
          </v-dialog>            

          <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>   
    </div>
  
</template>
<style>
  /* Style for Bottom menu  */
  #create .v-speed-dial {
    position: fixed;
    z-index: 2;
  }

  #create .v-btn--floating {
    position: relative;
  }

</style>
<script>
import CountryFlag from 'vue-country-flag'

export default {
    components: {
       CountryFlag,
    },  
  data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"", 

      dialogDelete: false,

     //x Bottom menu
      direction: 'top',
      fab: false,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
      //x Bottom to Top
      scTimer: 0,
      scY: 0,

      disable_btn_fight: false,
      pdfdialog:false,
      selectedPdf:null,

      list_match: true,
      overlay: false,  
      selectedItem: null,
      venues:[],

      array_athlete:[],
      isLoading_athlete_1: false,
      search_athlete_1: null,      
      isLoading_athlete_2: false,
      search_athlete_2: null,      
      
      referee_array:[],
      weight_category:"",
      dialog: false,

      eventmatch_array:[],

      NotWritable:true,

      editedIndex: -1,
      editedItem: { sex: '', weight_class: '', des_weight_category: '', round: '0', referee:'', pre_main: 'Main', world_title:'1', tournament:'1', time: '', order: '', athlete_1_name: '', athlete_1_surname: '', country_1: '', weight_1: '', Athlete_2_FN: '', athlete_2_surname: '', country_2: '', weight_2: '', },
      defaultItem: { sex: '', weight_class: '', des_weight_category: '', round: '0', referee:'', pre_main: 'Main', world_title:'1', tournament: '1', time: '', order: '', athlete_1_name: '', athlete_1_surname: '', country_1: '', weight_1: '', Athlete_2_FN: '', athlete_2_surname: '', country_2: '', weight_2: '', },

      images: {
        img_atleta1: require('@/assets/avatars/13.png'),
        flg_atleta1: require('@/assets/flags/de.png'),
        flg_atleta2: require('@/assets/flags/en.png')
      }
      

    };
  },
    props: {
      ITEM: Object,
      TRIGGER: Number,
  },  
  watch: {
    TRIGGER: {
        immediate: true,
        handler() {
          this.getSubjectEvent();
          //this.getRefree();
          this.getEventMatch();
          this.getTabelleVenue()
        }
    },
  },
    computed: {
      
      valid_refree() {
          return this.referee_array.filter((i) => {
              return (i.validita === null) || (i.validita === 1) || (i.validita === "");
          })
      },
     

    },
  mounted() {
    
  },
  methods: {

      getEventMatch(){
        
          let that = this
          that.overlay = true
          that.eventmatch_array=[]
          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "geteventmatch",
                  idevent:this.ITEM.id_event, 
                  token: localStorage.getItem("user_token"),
              },
          }
          that.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                res.data.eventmatch.forEach((elem) => {
                  that.eventmatch_array.push ( Object.assign({}, elem))
                });
                that.overlay = false 
              } else {
                that.snackbar_color = "error";
                that.snackbar_text = "Event match not loaded.";
                that.snackbar = true;
                that.overlay = false
              }     
          })
          .catch((ex) => {
              console.log(ex)
              that.overlay = false
          });
      },

      getSubjectEvent(){
        this.overlay = true
        this.events_Subject=[]
        
        let request = {
            controller: "Bellator",
            method: "POST",
            richiesta: {
                action: "getsubjectevent",
                //idsubject:this.ID_SUBJECT,
                idevent:this.ITEM.id_event,
                token: localStorage.getItem("user_token"),
            },
        }
        this.$store.dispatch("api", request).then((res) => {   
            if (res.status == 200){
              //Carico atleti e Refree (commision - 5) associati all'evento
              res.data.subject_event.forEach(element => {
                if (element.fk_id_role == 1) {
                  let field = element.surname  + " " +  element.name 
                  this.array_athlete.push({  
                      name: field,
                      id_subject: element.fk_id_subject, 
                      id_role: element.fk_id_role, 
                      validita: element.validita  
                    });
                }
                if (element.fk_id_role == 5) {
                  let field = element.surname  + " " +  element.name 
                  this.referee_array.push({  
                      name: field,
                      id_subject: element.fk_id_subject, 
                      id_role: element.fk_id_role, 
                      validita: element.validita  
                    });
                }
               });

              this.overlay = false 
            } else {
              this.snackbar_color = "error";
              this.snackbar_text = "Events not loaded.";
              this.snackbar = true;
              this.overlay = false
            }     
        })
        .catch((ex) => {
            this.snackbar = true;
            this.snackbar_color = "error";
            this.snackbar_text = ex;
            this.overlay = false
        });
      },  

      //non utilizzato --> refree = commission associati all'evento gestiti in getSubjectEvent
      getRefree(){
          let that = this
          that.overlay = true
          that.subjects=[]
          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "getsubject",
                  roleid_in: "(5)", //Commision come Refree anche non associati all'evento
                  token: localStorage.getItem("user_token"),
              },
          }
          that.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                res.data.subject.forEach(element => {
                let field = element.surname  + " " +  element.name 
                that.referee_array.push({  
                    name: field,
                    id_subject: element.id_subject, 
                    id_role: element.id_role, 
                    validita: element.validita  
                  })
                })
                //console.log(that.all_contact)
                that.overlay = false 
              } else {
                that.snackbar_color = "error";
                that.snackbar_text = "Event contact not loaded.";
                that.snackbar = true;
                that.overlay = false
              }     
          })
          .catch((ex) => {
              console.log(ex)
              that.overlay = false
          });
      },

      /*
      getTabelleVenue_() {
        let request = {
          controller: "Global",
          method: "POST",
          richiesta: {
            action: "gettabelle",
            token: localStorage.getItem("user_token"),
            tipo: "VENUE",
            filter: "validita = 1",
          },
        };
        this.$store.dispatch("api", request).then((res) => {
          this.venues = res.data;
        });
      },      
      */
      getTabelleVenue() {
        let request = {
          controller: "Bellator",
          method: "POST",
          richiesta: {
            action: "getaddressbook",
            token: localStorage.getItem("user_token"),
            id_type: '3', //Venue
          },
        };
        this.$store.dispatch("api", request).then((res) => {
          this.venues = res.data.address_book;
        });
      },      

      
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      newFight(){

        const element = document.getElementById('btn_new');
        element.setAttribute('disabled', '');        

        this.editedItem = {
          id_match: -1,
          fk_id_event: this.ITEM.id_event,
          sex: null,
          weight_division:null,
          round: 3,
          pre_main: null,
          duration: "05:00",
          world_title:0,
          tournament:0,
          time: null,
          fk_id_subject_athlete1: null,
          fk_id_subject_athlete2: null,
          fk_id_subject_refree: null,    
        }
        
        //console.log("New",this.editedItem)

        this.list_match=false
      },
      editItem (index) {
        //Disabilito il pulsante tramite il suo identificativo
        const element = document.getElementById('btn_new');
        element.setAttribute('disabled', '');

        this.editedIndex = index
        this.editedItem = Object.assign({}, this.eventmatch_array[index])

        this.list_match = false
      },

      backToList(){
        //Riabilito il pulsante tramite il suo identificativo
        const element = document.getElementById('btn_new');
        element.removeAttribute('disabled');
        this.list_match=true
      },


      save(){
          this.overlay = true
          if (this.editedItem.sex == "Male") this.editedItem.sex = 1; else this.editedItem.sex = 0
          let request = {
              controller: "Bellator",
              method: "PUT",
              richiesta: {
                  action: "seteventmatch",
                  eventmatch: [this.editedItem],
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {  
                    const element = document.getElementById('btn_new');
                    element.removeAttribute('disabled');
                 if (res.status == 200){
                    this.overlay = false
                    this.getEventMatch()
                    this.list_match= true

                  } else {
                    this.snackbar_color = "error";
                    this.snackbar_text = "Error save data. ";
                    this.snackbar_icon = "mdi-window-close";
                    this.snackbar = true;
                    this.overlay = false
                  }           
          });
         
      },  

    //  Delete  ----------------------------

      deleteItem (index) {

          this.editedIndex = index
          this.editedItem = Object.assign({}, this.eventmatch_array[index])
          this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.eventmatch_array.splice(this.editedIndex, 1)
        this.delete(this.editedItem)
        this.closeDelete()
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = {}
          this.editedIndex = -1
        })
      },

      delete(item){
        this.overlay = true
        let request = {
            controller: "Bellator",
            method: "PUT",
            richiesta: {
                action: "deleventmatch",
                id_match: item.id_match,
                token: localStorage.getItem("user_token"),
            },
        }
        this.$store.dispatch("api", request).then((res) => {   
            if (res.status == 200){
                this.overlay = false   
                this.snackbar_color = "success";
                this.snackbar_text = "Item deleted! ";
                this.snackbar_icon = "mdi-window-close";
                this.snackbar = true;                    
            }
        });
      },	

      //Get Value ----------------------------

      getWeightClass(_id){
        if(_id != null){
          return this.alasql(
            "SELECT FROM ? where id = " + _id,
              [this.weight_division]
             )[0].des;
        }
      },

      getWeight(_id){ //non usata
          //id_weight_division / id_weight_division1 des_weight_category1 / des_weight_category2
          if(_id != null){  
          let category_array = this.alasql(
            "SELECT FROM ? where id = " + _id,
              [this.weight_division]
             );
             return  category_array[0].lbs + "Lbs / " + category_array[0].kg + "kg"
          }else  return "- Lbs / - kg"
      },


      
      getpeso(){
          let category_array = this.alasql(
            "SELECT FROM ? where id = " + this.editedItem.weight_class,
              [this.weight_division]
             );
          if (this.editedItem.weight_class != 9) {  //Catch Weight
            this.editedItem.des_weight_category =  category_array[0].lbs + "Lbs / " + category_array[0].kg + "kg"  
            this.NotWritable = true
          } else{
            this.editedItem.des_weight_category = ""
            this.NotWritable = false
          }
      },      

      getPreMain(_id){
        if(_id != null){  
          return this.alasql(
            "SELECT FROM ? where id = " + _id,
              [this.pre_main]
             )[0].des;
        }   
      },  

      getFlag(id_flag){
          if (id_flag != null){
            let codFlag = this.alasql(
                "SELECT  FROM ? WHERE id = '" + id_flag + "'",
                [this.country_flags]
            );
            return codFlag[0].cod
          } else return ''
      },

      getCountry(id_country){
          if (id_country != null){
            let codCountry = this.alasql(
                "SELECT  FROM ? WHERE id = '" + id_country + "'",
                [this.country_flags]
            );
            return codCountry[0].name
          } 
      }, 

      getVenue(id_venue){

          if (id_venue != null){
            let listVenue = this.alasql(
                "SELECT  FROM ? WHERE id_address_book = " + id_venue ,
                [this.venues]
            );
            if (listVenue != null && listVenue.lenght > 0){
              return listVenue[0].city
            }
          } 
      },

      getWin(win_sub,win_ko,win_decision){
           return Number(win_sub) + Number(win_ko) + Number(win_decision) 
      },
      getLost(lost_sub,lost_ko,lost_decision){
           return Number(lost_sub) + Number(lost_ko) + Number(lost_decision) 
      },

      closeEventDett(){
         this.$emit("closeDettEvent", "");
      },

      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },   
      
      
      async stampa_boutsheet(){
        this.overlay= true
        await this.getStampaEvento();
        const byteCharacters = atob(this.file_b64);
        const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        debugger
        this.selectedPdf = blobUrl;
        this.pdfdialog = true;
        this.overlay = false   
    },

    getStampaEvento() {
        return new Promise((resolve, reject) => {
            this.overlay = true;
            let disegnoReq = {
                controller: "Bellator",
                method: "POST",
                richiesta: {
                action: "getboutpdf",
                id_event: this.ITEM.id_event,                
                token: localStorage.getItem("user_token"),
                },
            };
            this.file_b64 = '';
            this.$store.dispatch("api", disegnoReq).then((res) => {
                this.file_b64 = res.data
                this.overlay = false;
                resolve(res);
                return;
            })
            .catch((e) => {
                this.overlay = false;
                reject(e);
                console.log(e);
            });
        });
    }, 



      //Gestione Go Bottom to TOP----------------------------

      handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
      toTop: function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },      

      //--------------------------------------------      
  },
};
</script>