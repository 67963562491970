<template>
      <v-card class="mt-5 ml-4 mr-4" elevation="0">
            <v-snackbar
              content-class="snackbar"
              :color="snackbar_color"
              left
              class="snackbar-gen"
              v-model="snackbar"
              :timeout="3000"
            >
              <div style="float: right;">
                  <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
              </div>
              <div>
              <h3 :style="{ color: snackbar_text_color + '!important' }">
                {{ snackbar_text }}
              </h3>
              </div>
            </v-snackbar>
            <v-row> 
            <v-col cols="12" sm="12" md="12">
                <p class="titleLabelForm"> 
                    COMMENTATOR INTERVIEW
                </p>
            </v-col> 
  
            <v-col cols="12" sm="6" md="3" class="mt-n5"> <!--DATE-->
                <v-select
                  v-model="selected_date"
                  class="textFieldForm ml-1"
                  item-color="var(--main-active-select-list-color)"
                  style=" color: var(--main-input-color); font-size: 18px;"
                  color="var(--main-border-color)" 
                  :items="distict_date"
                  prepend-inner-icon="mdi-calendar"
                  outlined
                  menu-props="auto"
                  label="Data"
                  item-text="data"
                  item-value="id_commentator_interview"   
                  @change="place_of_date"
                ></v-select>
            </v-col>    
            <v-col cols="12" sm="6" md="3" class="mt-n5 "> <!--distinct_place-->
                <v-select
                  v-model= "id_selected_place"
                  class="textFieldForm ml-1"
                  item-color="var(--main-active-select-list-color)"
                  style=" color: var(--main-input-color); font-size: 18px;"
                  color="var(--main-border-color)" 
                  :items="distinct_place"
                  outlined
                  menu-props="auto"
                  label="Place"
                  item-text="place"
                  item-value="id_commentator_interview"  
                  @blur="selected_record"
                ></v-select>
            </v-col>  
                                  
            <v-col cols="12" sm="6" md="2" class="mt-n3"> <!--Button Search Available Slot-->
                <v-btn
                  color="var(--main-primary-color)"
                  style="color:var(--main-primary_text-color); text-transform: initial;  font-family: 'Titillium Web', sans-serif !important; font-size: 18px !important; "
                  class="btnForm"
                  @click="getAvailableSlot"
                  elevation="0"
                >
                  Search Available Slot
                </v-btn> 
            </v-col>   
            <v-col cols="12" sm="6" md="4" class="mt-n2"/>

            <v-col cols="12" sm="6" md="7" class="mt-n5 " style="text-align: left;"> <!--Badge ore-->
                <div >
                    <v-badge v-for="item  in commentator_interview_slot_array" :key="item.idx" v-bind:capacity="item.capacity"
                    color="#778A91"

                      overlap
                      :content="item.capacity"
                    >
                      <v-btn
                        :id="item.idx"
                        large
                        class="ml-4 mb-4 btn-slots"
                        style="background: #E9ECEA;"
                        depressed
                        @click="changestyle(item)"
                      >
                        {{item.slot}}
                      </v-btn>
                    </v-badge>
                </div>     
            </v-col>

            <v-col cols="12" sm="6" class="mt-n3 text-center"> <!-- Button Add Slot -->
                <v-btn 
                  v-if="btn_add_visible"
                  class="btn_plus"
                  color="var(--main-primary-color)"
                  style="color:var(--main-primary_text-color);"
                  @click="add_SubjectEventCommentatorInterview"
                  elevation="0"
                >
                  <v-icon dark
                  >
                    mdi-plus
                  </v-icon>
                  <span class="btn_plus-text">Add Activity Slot</span> 
                </v-btn> 
            </v-col>             
          
          
              <!-- Table -->
              <v-col cols="12" sm="9"  class="" >
                <template>
                  <v-data-table
                    v-if="commentator_interview_reserved_array_length>0"
                    :headers="headers"
                    :items="commentator_interview_reserved_array"
                    hide-default-footer
                    :items-per-page="12000"                    
                  >
                      <template v-slot:[`header.data`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Date </a>
                      </template>   
                      <template v-slot:[`header.place`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Place </a>
                      </template>                                                         
                      <template v-slot:[`header.slot`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Time </a>
                      </template>


                    <template v-slot:[`item.data`]="{ item }">
                          {{formatDate(item.data)}} 
                    </template>  
                                         
                    <template v-slot:[`item.actions`]="{ item }">  
                      <v-icon
                        color="var(--main-primary-color)"
                        @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </template>
              </v-col>

              <v-dialog v-model="dialogDelete" max-width="1024px" >
                    <v-card class="dialog-del">                      
                      <div class="alert-top">!</div>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color=" darken-1" class="btn_bg nohover" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color=" " class="btnForm" dark elevation="0" @click="deleteItemConfirm">Yes, proceed</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

              <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
              </v-overlay> 
            </v-row>
      </v-card>   
</template>

<script>


export default {   
	data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",   
      
      overlay: false,  

      selected_date:"",
      id_selected_place:"",
      id_commentator_interview_selected:"",
      place_selected:"",
      id_sub_ev_commentator_interview_selected: null,

      date_place_array:[],
      distinct_place:[],
      commentator_interview_array:[],
      commentator_interview_slot_array:[],
      commentator_interview_reserved_array:[],
      commentator_interview_reserved_array_length:0,
      btn_add_visible: false,

      edited_sub_ev_commentator_interview: {},
      editedIndex_sub_ev_commentator_interview: -1,		

      dialogDelete: false,
      headers: [
        { text: 'Date', value: 'data' },
        { text: 'Place', value: 'place' },
        { text: 'Time', value: 'slot' },
        { text: '', value: 'actions', sortable: false },
      ],
     
    };		
	},
  props: {
         ID_SUBJECT: Number,
         ID_EVENT: Number,
  },
  computed: {

      distict_date(){
          return this.alasql(
          "SELECT distinct data  FROM ? ",
            [this.commentator_interview_array]
        ); 
      },

    },

  watch: {
        ID_EVENT: {
          immediate: true,
          handler() {
            this.getCommentatorInterview()
            
          },

      },
  },  	
  mounted() {

  },	
    
 
	methods:{
      
        changestyle(item)
        {
          
          if (document.getElementById(item.idx).style.background =="rgb(119, 138, 145)"){ //elemento prenontato torna normale sprenotato "rgb(210, 224, 234)"  "rgb(147, 184, 210)" 
            document.getElementById(item.idx).style.background='#e9ecea'
            document.getElementById(item.idx).style.color='#000'
            this.id_sub_ev_commentator_interview_selected = null
          }else{
            document.getElementById(item.idx).style.background ='#778A91'
              document.getElementById(item.idx).style.color='#fff'
              //Se non è prenotato lo prenoto e gli altri tornano originali
              this.id_sub_ev_commentator_interview_selected = item.idx

              let array_idx  = this.alasql(
                "SELECT idx  FROM  ? where  idx != "  + item.idx, [this.commentator_interview_slot_array]
              );
              array_idx.forEach (element => {
                document.getElementById(element.idx).style.background='#e9ecea'
                document.getElementById(element.idx).style.color='#000'              
              });
          }
        },

				getCommentatorInterview(){
					let that = this
					that.overlay = true
					that.commentator_interview_array=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getcommentatorinterview",
									idevent: that.ID_EVENT,
									token: localStorage.getItem("user_token"),
							},
					}
					that.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
								that.commentator_interview_array = res.data.commentatorinterview;
								that.overlay = false 
                that.getCommentatorInterviewReserved()
							} else {
								that.snackbar_color = "error";
								that.snackbar_test = "Table Data not loaded.";
								that.snackbar = true;
								that.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							that.overlay = false
					});
				},  
        
        getCommentatorInterviewReserved(){
					let that = this
					that.overlay = true
					that.commentator_interview_reserved_array=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getsubjecteventcommentatorinterview",
                  id_subject: that.ID_SUBJECT,
                  id_event:that.ID_EVENT,
									token: localStorage.getItem("user_token"),
							},
					}
					that.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
								that.commentator_interview_reserved_array = res.data.SubjectEventCommentatorInterviewSlot
                that.commentator_interview_reserved_array_length = that.commentator_interview_reserved_array.length
								that.overlay = false 
							} else {
								that.snackbar_color = "error";
								that.snackbar_test = "Table Data not loaded.";
								that.snackbar = true;
								that.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							that.overlay = false
					});
				},         

        getAvailableSlot(){
					let that = this
					that.overlay = true
					that.commentator_interview_slot_array=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getsubjecteventcommentatorinterview",
									IdEvSubCommentatorInterview: that.id_commentator_interview_selected,
                  place: that.place_selected,
                  data:that.selected_date,
                  tipo: "Slot",
									token: localStorage.getItem("user_token"),
							},
					}
					that.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
								that.commentator_interview_slot_array = res.data.SubjectEventCommentatorInterviewSlot
                if (that.commentator_interview_slot_array.length > 0 )  that.btn_add_visible = true; else  that.btn_add_visible = false;
								this.overlay = false 
							} else {
								that.snackbar_color = "error";
								that.snackbar_test = "Table Data not loaded.";
								that.snackbar = true;
								that.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							that.overlay = false
					});
				}, 
      
        place_of_date(){
          
          this.date_place_array = this.alasql(
            "SELECT place, data, id_commentator_interview  FROM  ?  where data = '" + this.selected_date +"'",
              [this.commentator_interview_array]
          );       
          this.distinct_place=[]
          this.date_place_array.forEach(element => {
                let trovato = false
                this.distinct_place.forEach(internalelement => {
                  if (internalelement.place == element.place) trovato = true
                  });
                  if (!trovato) this.distinct_place.push(element)
          });
          //console.log("Distinct_Place:", this.distinct_place)

        },

        selected_record(){
          this.id_commentator_interview_selected = this.id_selected_place 
          this.place_selected  =  this.alasql(
                                    "SELECT  place  FROM ?  where id_commentator_interview = " + this.id_selected_place,
                                      [this.distinct_place]
                                  )[0].place; 
        },

				add_SubjectEventCommentatorInterview(){
					let that = this
					that.overlay = true
					that.commentator_interview_slot_array=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getsubjecteventcommentatorinterview",
									IdEvSubCommentatorInterview: that.id_sub_ev_commentator_interview_selected,
									token: localStorage.getItem("user_token"),
							},
					}
					that.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
               
								let record_to_update = res.data.SubjectEventCommentatorInterviewSlot
                
                record_to_update[0].fk_id_subject = that.ID_SUBJECT
                //console.log(record_to_update)
                this.overlay = false
                this.save_SubjectEventCommentatorInterview(record_to_update)
							} else {
								that.snackbar_color = "error";
								that.snackbar_test = "Error while update record.";
								that.snackbar = true;
								that.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							that.overlay = false
					});
				},

        save_SubjectEventCommentatorInterview(record_to_save){
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setsubevcommentatorinterview",
                    subevcommentatorinterview: record_to_save,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {     
                  if (res.status == 200){
                      this.overlay = false
                      this.getAvailableSlot()
                      this.getCommentatorInterviewReserved()
                      this.snackbar_color = "success";
                      this.snackbar_text = "Entry saved successfully. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                    } else {
                      this.snackbar_color = "error";
                      this.snackbar_text = "Data Saving Error. ";
                      this.snackbar = true;
                      this.overlay = false
                    }           
            });
        },

				deleteItem (item) {
						this.editedIndex_sub_ev_commentator_interview = this.commentator_interview_reserved_array.indexOf(item)
						this.edited_sub_ev_commentator_interview = Object.assign({}, item)
						this.dialogDelete = true
				},

				deleteItemConfirm () {
          this.edited_sub_ev_commentator_interview.fk_id_subject = null
          let _array = [this.edited_sub_ev_commentator_interview]
          this.save_SubjectEventCommentatorInterview(_array)
          this.commentator_interview_reserved_array.splice(this.editedIndex_sub_ev_commentator_interview, 1)
          this.dialogDelete = false
				},
   
        formatDate (date) {
          if (!date) return null
          const [year, month, day] = date.split('-')
          return `${month}/${day}/${year}`
        },      

	}
}

</script>
