<template>
      <v-card class="mt-5 ml-4 mr-4" elevation="0">
            <v-snackbar
              content-class="snackbar"
              :color="snackbar_color"
              right
              v-model="snackbar"
              :timeout="3000"
            >
              <div style="float: right;">
                  <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
              </div>
              <div>
              <h3 :style="{ color: snackbar_text_color + '!important' }">
                {{ snackbar_text }}
              </h3>
              </div>
            </v-snackbar>
            <v-row> 
              <v-col cols="12" sm="12">
                  <p class="titleLabelForm"> 
                      MANAGER
                  </p>
              </v-col> 
              <v-col cols="12" sm="3" class="mt-n3 ">  <!--Selec Manager-->
                    <v-autocomplete
                      v-model="manager_selected"
                      item-color="var(--main-active-select-list-color)"
                      class="textFieldForm mt-n2"
                      style=" color: var(--main-input-color); font-size: 18px;"   
                      color="var(--main-border-color)"                                  
                      :items="manager"
                      :loading="isLoading_manager"
                      :search-input.sync="search_manager"
                      clearable
                      :item-text="(item) => item.name + ' ' + item.surname"
                      item-value="id_subject"
                      label="Manager"
                      outlined
                    ></v-autocomplete>                

              </v-col>  
              <v-col cols="12" sm="1" class="mt-n3">
                  <v-btn
				  	class="btn_plus"
                    color="var(--main-primary-color)"
                    style="color:var(--main-primary_text-color);"
                    @click="add_manager"
                    elevation="0"
                  >
                    <v-icon dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn> 
              </v-col>           
          
              <v-col cols="12" sm="8" class="mt-n5"/>
              <!-- Table Manager -->
              <v-col cols="12" sm="8" class="mt-n5" v-if="manager_subject.length>0">
                <template>
                  <v-data-table
                    :headers="headers"
                    :items="manager_subject"
                    hide-default-footer
                    :items-per-page="12000"                    
                  >
										<template v-slot:[`header.name`]="{}">
											<a class="textHeader" style="color:var(--main-dth-color);"> Name </a>
										</template>                                      
										<template v-slot:[`header.surname`]="{}">
											<a class="textHeader" style="color:var(--main-dth-color);"> Surname </a>
										</template>

										<template v-slot:[`header.middle_name`]="{}">
											<a class="textHeader" style="color:var(--main-dth-color);"> Middle Name </a>
										</template>
										<template v-slot:[`header.email`]="{}">
											<a class="textHeader" style="color:var(--main-dth-color);"> Email </a>
										</template> 

                    <template v-slot:[`item.actions`]="{ item }">  
                      <v-icon
                        color="var(--main-primary-color)"
                        @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
				  <v-dialog v-model="dialogDelete" max-width="1024px" >
                    <v-card class="dialog-del">                      
                      <div class="alert-top">!</div>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color=" darken-1" class="btn_bg nohover" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color=" " class="btnForm" dark elevation="0" @click="deleteItemConfirm">Yes, proceed</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>                 
                </template>
              </v-col>

            </v-row>
      </v-card>   
</template>
<script>

export default {
	data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",     

      manager:[],
      manager_subject:[],
      editedManager: {},
      editedIndexManager: -1,
      manager_selected:[],

      isLoading_manager: false,
      search_manager: null, 			

      overlay: false,  

      dialogDelete: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Surname', value: 'surname' },
        { text: 'Middle Name', value: 'middle_name' },
        { text: 'Email', value: 'email' },
        { text: '', value: 'actions', sortable: false },
      ],
     

    };		
	},
  props: {
      ID_SUBJECT: Number,
  },	
  mounted() {
    this.getManager_select()
    this.getManager_subject()
  },	
	methods:{

				getManager_select(){
						//restituisce  l'elenco dei manager ovvero i subject con  idrole = 2
						this.overlay = true
						this.subjects=[]
						let request = {
								controller: "Bellator",
								method: "POST",
								richiesta: {
										action: "getsubject",
										id_role: "8",
										token: localStorage.getItem("user_token"),
								},
						}
						this.$store.dispatch("api", request).then((res) => {   
								if (res.status == 200){
									this.manager = res.data.subject;
									this.overlay = false 
								} else {
									this.snackbar_color = "error";
									this.snackbar_text = "Manager not loaded.";
									this.snackbar = true;
									this.overlay = false
								}     
						})
						.catch((ex) => {
								console.log(ex)
								this.overlay = false
						});
				},  
				
				getManager_subject(){
					let that = this
					this.overlay = true
					this.subjects=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getmanager",
									idsubject: this.ID_SUBJECT,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
								that.manager_subject = res.data.manager;
							// console.log(that.manager_subject.length)
								this.overlay = false 
							} else {
								this.snackbar_color = "error";
								this.snackbar_test = "Manager not loaded.";
								this.snackbar = true;
								this.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							this.overlay = false
					});
				},

				deleteItem (item) {
						this.editedIndexManager = this.manager_subject.indexOf(item)
						this.editedManager = Object.assign({}, item)
						this.dialogDelete = true
				},

				deleteItemConfirm () {
					this.manager_subject.splice(this.editedIndexManager, 1)
					this.delete_manager(this.editedManager)
					this.closeDelete()
				},

				closeDelete () {
					this.dialogDelete = false
					this.$nextTick(() => {
						this.editedManager = {}
						this.editedIndexManager = -1
					})
				},      

				add_manager(){
					//console.log(this.manager_selected) // id_subect del manager
					//controllo id_subject non sia nell'array manager_subject = id_subject_manager
					// non è presente faccio la insert  con id_subject attuale e l'id del subject del manager
						let count = this.alasql(
								"SELECT count (*) as presente FROM ? WHERE id_subject_manager = " + this.manager_selected ,
								[this.manager_subject]
						);
					if (count[0].presente > 0){
									this.snackbar_color = "error";
									this.snackbar_text = "Manager already exists.";
									this.snackbar = true;
									this.overlay = false           
					}else{
								let new_manager_subject = [{id_manager:-1, id_subject: this.ID_SUBJECT , id_subject_manager: this.manager_selected, }]
								this.overlay = true
								let request = {
										controller: "Bellator",
										method: "PUT",
										richiesta: {
												action: "setmanager",
												manager: new_manager_subject,
												token: localStorage.getItem("user_token"),
										},
								}
								this.$store.dispatch("api", request).then((res) => {     
											if (res.status == 200){
													this.overlay = false
													this.snackbar_color = "success";
													this.snackbar_text = "Manager saved successfully. ";
													this.snackbar_icon = "mdi-window-close";
													this.snackbar = true;
													this.getManager_subject();
												} else {
													this.snackbar_color = "error";
													this.snackbar_text = "Error save Manager. ";
													this.snackbar = true;
													this.overlay = false
												}           
								});           

					}
				},

				delete_manager(item){
					this.overlay = true
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action: "delmanagersubject",
									id_manager: item.id_manager,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
									this.getSubject()
									this.overlay = false   
									this.snackbar_color = "success";
									this.snackbar_text = "Item deleted!";
									this.snackbar = true;                    
							}
					});
				},				
				

	}
}
</script>
