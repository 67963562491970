<template>
      <v-card class="mt-5 ml-4 mr-4" elevation="0">
            <v-snackbar
              content-class="snackbar"
              :color="snackbar_color"
              left
              class="snackbar-gen"
              v-model="snackbar"
              :timeout="3000"
            >
              <div style="float: right;">
                  <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
              </div>
              <div>
              <h3 :style="{ color: snackbar_text_color + '!important' }">
                {{ snackbar_text }}
              </h3>
              </div>
            </v-snackbar>
            <v-row> 

              <v-col cols="12" sm="6" md="12" class="text-left">
                  <div class="titleLabelForm d-inline-block"> 
                      CHECKIN
                  </div> 
                <v-switch
                  class="mt-n1 ml-8 switch-gen d-inline-block"
                  label="Mail"
                  v-model="checkin_mail"
                  color="green"
                  inset
                  @change="setcheckinmail"
                ></v-switch>
                <v-switch
                  class="mt-n1 ml-8 switch-gen d-inline-block"
                  label="SMS"
                  v-model="checkin_sms"
                  color="green"
                  inset
                  @change="setcheckinsms"
                ></v-switch>          
              </v-col> 


              <v-col cols="12" sm="6" md="6" class="mt-n5 "> <!--place-->
                  <v-text-field
                    v-model="checkin_place"
                    label="Place"
                    outlined
                    color="var(--main-border-color)" 
                    style=" color: var(--main-input-color); font-size: 18px;"   
                    class="textFieldForm">
                  ></v-text-field>
              </v-col>  
              <v-col cols="12" sm="6" md="6" class="mt-n5 "/>
              <v-col cols="12" sm="6" md="2" class="mt-n5"> <!--DATE-->
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="checkin_date"
                      label="Date"
                      style="color: var(--main-input-color); font-size: 18px;"                              
                      class="textFieldForm"    
                      color="var(--main-border-color)"               
                      outlined
                      persistent-hint
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu = false"
                    locale="en"
                  ></v-date-picker>
                </v-menu> 
              </v-col>    
              <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--time-->
                <v-text-field
                  v-model="checkin_time_from"
                  label="Local Time From"
                  value="00:00"
                  style="color: var(--main-input-color); font-size: 18px;"                              
                  class="textFieldForm"    
                  color="var(--main-border-color)"            
                  outlined                                                
                  type="time"
                ></v-text-field> 
              </v-col>  
               <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--time-->
                <v-text-field
                  v-model="checkin_time_to"
                  label="Local Time To"
                  value="00:00"
                  style="color: var(--main-input-color); font-size: 18px;"                              
                  class="textFieldForm"    
                  color="var(--main-border-color)"            
                  outlined                                                
                  type="time"
                ></v-text-field> 
              </v-col>  
              <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--time-->
                <v-text-field
                  v-model="checkin_time_slot"
                  label="Time Slot"
                  value="00:20"
                  style="color: var(--main-input-color); font-size: 18px;"                              
                  class="textFieldForm"    
                  color="var(--main-border-color)"            
                  outlined                                                
                  type="time"
                ></v-text-field> 
              </v-col>  
              <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--time-->
                <v-text-field
                  v-model="checkin_max_capacity"
                  label="Max Capacity Slot"
                  style="color: var(--main-input-color); font-size: 18px;"                              
                  class="textFieldForm"    
                  color="var(--main-border-color)"            
                  outlined                                                
                  type="number"
                ></v-text-field> 
              </v-col>  

              <v-col cols="12" sm="6" md="1" class="mt-n2"> <!--Button Add-->
                  <v-btn
                    class="btn_plus"
                    color="var(--main-primary-color)"
                    style="color:var(--main-primary_text-color);"
                    @click="add_item"
                    elevation="0"
                  >
                    <v-icon dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn> 
              </v-col>   
      
          
              <!-- Table -->
              <v-col cols="12" sm="9"  class="mt-n5" v-if="checkin_array.length>0">
                <template>
                  <v-data-table
                    :headers="headers"
                    :items="checkin_array"
                    hide-default-footer
                    :items-per-page="12000"                    
                  >
                      <template v-slot:[`header.data`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Date </a>
                      </template>                   
                      <template v-slot:[`header.place`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Place </a>
                      </template>                                      
                      <template v-slot:[`header.time_from`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Time From </a>
                      </template>
                      <template v-slot:[`header.time_to`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Time To </a>
                      </template>
                      <template v-slot:[`header.time_slot`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Time Slot </a>
                      </template>                                            
                      <template v-slot:[`header.max_capacity`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Max Capacity </a>
                      </template>

                    <template v-slot:[`item.data`]="{ item }">
                          {{formatDate(item.data)}} 
                    </template>   
                    <template v-slot:[`item.time_from`]="{ item }">
                          {{  item.time_from != null ? item.time_from.substring(0, 5) : "" }} 
                    </template>       
                    
                    <template v-slot:[`item.time_to`]="{ item }">
                          {{ item.time_to != null ? item.time_to.substring(0, 5) : "" }} 
                    </template>
                    
                    <template v-slot:[`item.time_slot`]="{ item }">
                          {{ item.time_slot != null ? item.time_slot.substring(0, 5) : "" }} 
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">  
                      <v-icon
                        color="var(--main-primary-color)"
                        @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>

                  </v-data-table>

                  <v-dialog v-model="dialogDelete" max-width="1024px" >
                    <v-card class="dialog-del">                      
                      <div class="alert-top">!</div>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color=" darken-1" class="btn_bg nohover" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color=" " class="btnForm" dark elevation="0" @click="deleteItemConfirm">Yes, proceed</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>    
                  <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                  </v-overlay>                                   
                </template>
              </v-col>

            </v-row>
      </v-card>   
</template>

<script>

export default {   
	data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",     

      checkin_array:[],
      edited_checkin: {},
      editedIndex_checkin: -1,

      checkin_sms:null,
      checkin_mail:null,      

      checkin_place:null,
      checkin_time_from:'00:00',
      checkin_time_to:'00:00',
      checkin_time_slot:'00:00',
      checkin_max_capacity:1,

     // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date: null,
      menu:false,

      overlay: false,  

      dialogDelete: false,
      headers: [
        { text: 'Date', value: 'data' },
        { text: 'Place', value: 'place' },
        { text: 'Time From', value: 'time_from' },
        { text: 'Time To', value: 'time_to' },
        { text: 'Time Slot', value: 'time_slot' },
        { text: 'Max Capacity', value: 'max_capacity' },                        
        { text: '', value: 'actions', sortable: false },
      ],
     
    };		
	},
  props: {
      ID_EVENT: Number,
  },
  computed: {
      checkin_date () {
        return this.formatDate(this.date)
      },
    },

  watch: {
        ID_EVENT: {
          immediate: true,
          handler() {
            this.getCheckIn()
          },

      },
  },  	
  mounted() {
  },	
    

	methods:{

				getCheckIn(){
					let that = this
					this.overlay = true
					this.checkin_array=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getcheckin",
									idevent: this.ID_EVENT,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
								that.checkin_array = res.data.checkin;
                that.checkin_sms = that.checkin_array[0].checkin_sms;
                that.checkin_mail = that.checkin_array[0].checkin_mail;                
								this.overlay = false 
							} else {
								this.snackbar_color = "error";
								this.snackbar_test = "Table Data not loaded.";
								this.snackbar = true;
								this.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							this.overlay = false
					});
				},

				deleteItem (item) {
						this.editedIndex_checkin = this.checkin_array.indexOf(item)
						this.edited_checkin = Object.assign({}, item)
						this.dialogDelete = true
				},

				deleteItemConfirm () {
					this.checkin_array.splice(this.editedIndex_checkin, 1)
					this.delete(this.edited_checkin)
					this.closeDelete()
				},

				closeDelete () {
					this.dialogDelete = false
					this.$nextTick(() => {
						this.edited_checkin = {}
						this.editedIndex_checkin = -1
					})
				},      

				add_item(){
            if (this.date == null){
              this.snackbar_color = "error";
              this.snackbar_text = "You have to Insert a date. ";
              this.snackbar_icon = "mdi-window-close";
              this.snackbar = true;
              return
            }
            if (this.checkin_place == null){
              this.snackbar_color = "error";
              this.snackbar_text = "You have to Insert a place. ";
              this.snackbar_icon = "mdi-window-close";
              this.snackbar = true;
              return
            }             
            let new_checkin = [{id_checkin:-1, fk_id_event: this.ID_EVENT , data: this.date,  place: this.checkin_place, time_from: this.checkin_time_from, time_to: this.checkin_time_to, time_slot: this.checkin_time_slot, max_capacity: this.checkin_max_capacity, checkin_mail: 1, checkin_sms : 1 }]
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setcheckin",
                    checkin: new_checkin,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {     
                  if (res.status == 200){
                      this.date = null
                      this.checkin_place = null
                      this.checkin_time_from = '00:00' 
                      this.checkin_time_to = '00:00'
                      this.checkin_time_slot = '00:00'
                      this.checkin_max_capacity = 1                    
                      this.overlay = false
                      this.snackbar_color = "success";
                      this.snackbar_text = "Entry saved successfully. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.getCheckIn();
                    } else {
                      this.snackbar_color = "error";
                      this.snackbar_text = "Data Saving Error. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.overlay = false
                    }           
            });           
				},

				delete(item){
					this.overlay = true
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action: "delcheckin",
									id_checkin: item.id_checkin,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
									this.overlay = false   
									this.snackbar_color = "success";
									this.snackbar_text = "Item deleted! ";
                  this.snackbar_icon = "mdi-window-close";
									this.snackbar = true;                    
							}
					});
				},				

				setcheckinsms(){
					this.overlay = true
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action: "setcheckinsms",
									value: this.checkin_sms,
                  id_event: this.ID_EVENT,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
									this.overlay = false                      
							}
					});
				},   
        
				setcheckinmail(){
					this.overlay = true
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action: "setcheckinmail",
									value: this.checkin_mail,
                  id_event: this.ID_EVENT,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
									this.overlay = false                      
							}
					});
				},        
				        
				
        parseDate (date) {
          if (!date) return null
          const [month, day, year] = date.split('/')
          return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        }, 
        format_Date (date) {
          if (!date) return null
          let new_date = date.substring(0,10)
          const [month,day,year] = new_date.split('/')
          return `${year}-${month}-${day}`
        }, 

        formatDate (date) {
          if (!date) return null
          const [year, month, day] = date.split('-')
          return `${month}/${day}/${year}`
        },      

	}
}

</script>
