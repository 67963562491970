<template>
  <app-layout>
    <v-main>
      <v-container class=" px-2 " fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </app-layout>
</template>

<script>
import HomeLayout from "./layouts/HomeLayout";

export default {
  components: {
    "app-layout": HomeLayout,
  },
  data: () => ({
      nome_azienda: "Modulo Contabilità",
      des_profilo: localStorage.des_profilo,
      nome_utente: localStorage.nominativo,
    }),
};
</script>