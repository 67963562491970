<template>
  <v-app id="inspire" style="background-color:var(--main-bg-color); ">
    
      <v-app-bar  app  clipped  elevation="0" class="mr-1 mb-2" style="height: 100px; background-color:var(--main-app-color); " >
        <v-toolbar-title class="titolo_SX ml-10" >{{ $route.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          dense
          class="searchtop textFieldForm mt-6 mr-7"
          flat
          hide-details
          label="Search"
          prepend-inner-icon="mdi-magnify"
          solo-inverted
          style="max-width:350px;"
        ></v-text-field>

        <v-badge
          color="red"
          content="!"
          overlap
          class="mt-7"
          transition="slide-x-transition"
        >
            <v-icon
              color="black lighten-1"
              style="font-size: 30px;"
            >
              mdi-bell-outline
            </v-icon>
        </v-badge>

        <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="var(--main-app-color)"  elevation="0"  v-bind="attrs" v-on="on" class="ml-4 mr-1 pr-1 pl-1 mt-5">
               <v-img src="https://cdn.vuetifyjs.com/images/john.jpg"  max-height="35" max-width="35" style=" border-radius: 50%; width:50px; height:50px;" /> 
            </v-btn>
          </template>

              <!-- CARD UTENTE -->
              <v-card>
                <v-card-title>{{nome_utente}}</v-card-title>
                <v-card-subtitle class="text-left">{{des_profilo}}</v-card-subtitle>
                <v-divider />
                <v-card-actions>
                  <!-- IMPOSTAZIONI -->
                  <v-btn
                    icon
                    x-small
                    title="Impostazioni Account"
                    @click="dialogGestioneAccount = true"
                  >
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                  <!-- AMMINISTRAZIONE -->
                  <v-btn
                    icon
                    x-small
                    title="Amministrazione"
                    v-if="show_admin_tools == true"
                    @click="$router.push('/amministrazione/dashboard')"
                  >
                    <v-icon>mdi-shield-account</v-icon>
                  </v-btn>
                  <v-spacer />
                  <v-btn color="error" text @click="logout">LOGOUT</v-btn>
                </v-card-actions>

              </v-card>
        </v-menu>
        <!-- AMMINISTRAZIONE Dialog Gestione Utenti-->
        <v-dialog
          v-model="dialogGestioneAccount" persistent width="600px">
          <v-card>
              <v-toolbar dense  color="white" elevation="3">
              <span>Gestione Utente</span>
              <v-spacer></v-spacer>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn  small v-bind="attrs" v-on="on" @click="dialogGestioneAccount=false">
                            <v-icon color="red"
                            >mdi-close-circle-outline
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Chiudi.</span>
                </v-tooltip>            
              </v-toolbar>
              <GestioneAccount/> 
          </v-card>
        </v-dialog>        
      </v-app-bar>
    
    <!-- Barra di navigazione laterale DX   
      <v-navigation-drawer right app :mini-variant="mini2" class="mt-2 mr-1 rounded">
        <v-list dense class rounded>
          <v-list-item link>
            <v-list-item-icon class="ml-n2">
              <v-icon>mdi-heart</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>aaa</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list dense class rounded style="position: absolute;bottom: 0;">
          <v-spacer></v-spacer>
          <v-list-item link>
            <v-list-item-icon class="ml-n2">
              <v-icon>mdi-heart</v-icon>
            </v-list-item-icon>

            <v-list-item-content></v-list-item-content>
          </v-list-item>
          <v-list-item link s>
            <v-list-item-icon class="ml-n2">
              <v-icon>mdi-heart</v-icon>
            </v-list-item-icon>

            <v-list-item-content></v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    -->
   
    <!-- Barra di navigazione laterale SX -->
    <v-navigation-drawer
      v-model="drawer"
      color="var(--main-primary-color)"
      dark
      app 
      permanent
      :mini-variant="mini"
      class="ml-1 rounded b-menu-left"
      style="height:99%"
    >
    <v-card height="100" elevation="0"  id="grad">
      <v-list-item class="px-2 mb-2" rounded>
        <v-list-item-avatar v-if="mini" style="cursor:pointer" class="mt-6"  @click.stop="mini = !mini">
          <v-icon >mdi-menu</v-icon>
        </v-list-item-avatar>

        <v-list-item-avatar class="mt-6"  style="cursor:pointer" v-if="!mini" @click.stop="mini = !mini">
          <v-icon>mdi-menu-open</v-icon>
        </v-list-item-avatar>

        <v-list-item-title class="ml-n12">
          <img
          :src="LogoInternoSVG"
          style="margin-top: 20px;
            width: 50px;
            height: 50px;"
        />
        </v-list-item-title>


      </v-list-item>
    </v-card>
    <v-divider ></v-divider>

      <!-- Menu con sottomenu -->
      <v-list >
        <v-list-group
          
          v-for="item in my_menu"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          :append-icon="item.icondx" 
          
          no-action
          class="mymenulist"
         
          
        >
          <template v-slot:activator>
            <v-list-item-content >
              <v-list-item-title v-text="item.title"  ></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item class="mymenulist"
            v-for="child in item.my_menu"
            :key="child.title"
            link :to="child.path"
            @click="setmenuattivo(item.title)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="child.title" ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      
      <v-list>
        <v-list-item class="mymenulist mt-n5" link to="/admin/addressbook">
          <v-list-item-icon >
            <v-icon  class="material-icons" color="var(--main-icons-color);"> fmd_good</v-icon>
          </v-list-item-icon>
 
          <v-list-item-content>
            <v-list-item-title  style="font-size:16px;" @click="setmenuattivo('Address Book')">Address Book</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider> 
    
      <!-- Menu singolo Non Usato -->
      <v-list class="mymenulist" dense v-for="item in items" :key="item.title"  >
        <v-list-item  class="mymenulist" link :to="item.path">
          <v-list-item-icon class="ml-n2">
            <v-icon  color="var(--main-icons-color);">{{ item.icon }}</v-icon>
          </v-list-item-icon>
 
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>


        

    </v-navigation-drawer>

    <slot></slot>

    <!--
      <v-footer app dark color="#283046" class="ml-1 mr-2" fixed>
        <span>Footer</span>
        <v-spacer></v-spacer>
        <span>&copy; 2022</span>
      </v-footer>
    -->   
  </v-app>
</template>

<style scoped>

    #grad {
      height: 100px;
      background-color: var(--grad_toolbar_bg_da); /* For browsers that do not support gradients */
      background-image: linear-gradient(var(--grad_toolbar_bg_da), var(--grad_toolbar_bg_a));
    }
    .titolo_SX {
        margin-top:25px;
        margin-left: 5px;
        font-size: 28px;
        font-family: Titillium Web, serif;
        font-style: normal;
        color: var(--main-lbltop-color);
        font-weight: 700;        
        line-height: initial;        
    }

</style>

<script>
import LogoInternoSVG from '@/assets/svg/b-brand.svg'
import GestioneAccount from "@/components/AMMINISTRAZIONE/GestioneAccount.vue";
export default {
  components: {
    GestioneAccount,
  },  
  props: {
    source: String
  },
  data: () => ({
        LogoInternoSVG,
        items: [],
        items_: [
          { text: "Logistica", icon: "mdi-van-utility", path: "/home/logistica" },
          { text: "Produzione", icon: "mdi-fan", path: "/home/produzione" },
          { text: "Audience", icon: "mdi-account", path: "/home/dashboard" },
          { text: "Conversions", icon: "mdi-flag", path: "/home/dashboard" },
          { text: "Mio-Link", icon: "mdi-thumb-up", path: "/home/mycontent" }
        ],
        my_menu: [
            {
              //action: 'mdi-ticket',
              action: 'menu-events',
              icondx: 'mdi-chevron-down',
              my_menu: [{ title: 'Manage',  path: "/admin/events" }],
              title: 'Events',
            },
            {
              action: 'mdi-account',
              icondx: 'mdi-chevron-down',
              //active: true,
              my_menu: [
                { title: 'Manage',  path: "/admin/subject" },
              ],
              title: 'Subject',
            },
            {
              action: 'mdi-hospital-box',
              icondx: 'mdi-chevron-down',
              my_menu: [{ title: 'List Item' }],
              title: 'Medicals',
            },
            {
              action: 'mdi-airplane',
              icondx: 'mdi-chevron-down',
              my_menu: [{ title: 'List Item' }],
              title: 'Travel',
            },
            {
              action: 'mdi-seat-individual-suite',
              icondx: 'mdi-chevron-down',
              my_menu: [{ title: 'List Item' }],
              title: 'Accomodations',
            },

            {
              action: 'mdi-format-list-bulleted-square',
              icondx: 'mdi-chevron-down',
              my_menu: [
                { title: 'Info Pack',  path: "/admin/report/infopack" },
                { title: 'Check In',  path: "/admin/report/checkin" },
                { title: 'Appointment',  path: "/admin/report/appointment" },
                { title: 'Medical',  path: "/admin/report/medical" },
                { title: 'HeadShot',  path: "/admin/report/headshot" },
                { title: 'Weight Check',  path: "/admin/report/weightcheck" },
                { title: 'Deduction',  path: "/admin/report/deduction" },
                { title: 'BoutSheet',  path: "/admin/report/boutsheet" },
                { title: 'Passports',  path: "/admin/report/passports" },
                { title: 'Licences',  path: "/admin/report/licences" },
                
                
              ],
              title: 'Reports',
            },
          ],

        dialogGestioneAccount:false,
        show_admin_tools: localStorage.show_admin_tools == "true",
        des_profilo:localStorage.des_profilo,
        nome_utente:localStorage.nominativo,
        menu:false,
        drawer: false,
        drawerRight: null,
        right: false,
        left: false,
        mini: false,
        size_width: '',

        menu_attivo: '',
        
        images: {
            logo: require('@/assets/Bellator-1.png')
        },
        
        icons: {
            belt: require('@/assets/svg/belt.svg')
        }   
    

  }),

  computed: { 
      
    },

  watch: {
    size_width: {
        immediate: true,
        handler() {
                this.onResize()
            }
        },   
  },
  mounted(){
      this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })
  },
      

  methods:{

      onResize () {
          this.size_width = this.$vuetify.breakpoint.width
          this.size_height = this.$vuetify.breakpoint.height
          //console.log(this.size_height)
          //if (this.size_width < 900) this.mini = true;
      },

      logout() {
        localStorage.removeItem("user_token");
        localStorage.removeItem("id");
        localStorage.removeItem("nominativo");
        localStorage.removeItem("des_profilo");
        localStorage.removeItem("id_profilo");
        localStorage.removeItem("des_azienda");
        localStorage.removeItem("id_azienda");
        this.$router.replace("/login");        
      },

      setmenuattivo(value){
        this.menu_attivo= value
      }

  }
};
</script>