<template>

      <v-card class="mt-5 ml-4 mr-4" elevation="0">
            <v-snackbar
              content-class="snackbar"
              :color="snackbar_color"
              left
              class="snackbar-gen"
              v-model="snackbar"
              :timeout="3000"
            >
              <div style="float: right;">
                  <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
              </div>
              <div>
              <h3 :style="{ color: snackbar_text_color + '!important' }">
                {{ snackbar_text }}
              </h3>
              </div>
            </v-snackbar>
            <v-row> 
              <v-col cols="12" sm="12">
                  <p class="titleLabelForm"> 
                      Bellator contact for this Event
                  </p>
              </v-col> 
              <v-col cols="12" sm="3" class="mt-n3 ">  <!--Selec Contact-->
                    <v-autocomplete
                      v-model="contact_selected"
                      item-color="var(--main-active-select-list-color)"
                      class="textFieldForm mt-n2"
                      style=" color: var(--main-input-color); font-size: 18px;"   
                      color="var(--main-border-color)"                                  
                      :items="valid_contact"
                      :loading="isLoading_contact"
                      :search-input.sync="search_contact"
                      clearable
                      clear-icon="mdi-close"
                      item-text="name"
                      item-value="id_subject"
                      label="Contact"
                      outlined
                    ></v-autocomplete>                

              </v-col>  
              <v-col cols="12" sm="1" class="mt-n3">
                  <v-btn
                    class="btn_plus"
                    color="var(--main-primary-color)"
                    style="color:var(--main-primary_text-color);"
                    @click="add_event_contact"
                    :disabled="contact_selected <= 0"
                    elevation="0"
                  >
                    <v-icon dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn> 
              </v-col>           
          
              <v-col cols="12" sm="8" class="mt-n5"/>
              <p class="ml-3 mt-n8 text-gen-nocontent" v-if="contact_event.length<=0">
                Select contacts for this event
              </p>

              <!-- Table -->
              <v-col cols="12" sm="10" class="mt-n5" v-if="contact_event.length>0">
                <template>
                  <v-data-table
                    :headers="headers"
                    :items="contact_event"
                    hide-default-footer
                    :items-per-page="12000"                    
                  >
                      <template v-slot:[`header.name`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Name </a>
                      </template>                                      
                      <template v-slot:[`header.surname`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Surname </a>
                      </template>

                      <template v-slot:[`header.phone_number`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Phone Number </a>
                      </template>
                      <template v-slot:[`header.email`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Email </a>
                      </template> 
                      <template v-slot:[`header.id_type_contact`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Contact Type </a>
                      </template> 

                    <template v-slot:[`item.phone_number`]="{ item }" >
                          {{ get_prefix(item.prefix_phone) }} {{ item.phone_number }}
                    </template> 
                    <template v-slot:[`item.id_type_contact`]="{ item }">
                            <v-select
                              class="textFieldForm ml-1"
                              style=" color: var(--main-input-color); font-size: 18px;"
                              item-color="var(--main-active-select-list-color)"
                              color="var(--main-border-color)" 
                              :items="ev_contact_event_type"
                              v-model="item.id_type_contact"
                              clearable
                              clear-icon="mdi-close"
                              dense
                              hide-details=""
                              menu-props="auto"
                              label=""
                              item-text="des"
                              item-value="id"   
                            ></v-select>                            
                    </template>                     
                    <template v-slot:[`item.actions`]="{ item }">  
                      <v-icon
                        color="var(--main-primary-color)"
                        @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                      <v-icon
                        color="var(--main-primary-color)"
                        @click="update_event_contact(item)"
                      >
                        mdi-content-save
                      </v-icon>
                    </template>                    

                  </v-data-table>
                  <v-dialog v-model="dialogDelete" max-width="1024px" >
                    <v-card class="dialog-del">                      
                      <div class="alert-top">!</div>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color=" darken-1" class="btn_bg nohover" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color=" " class="btnForm" dark elevation="0" @click="deleteItemConfirm">Yes, proceed</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>     
                  <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                  </v-overlay>                                
                </template>
              </v-col>

            </v-row>
      </v-card>   
</template>
<script>

export default {
	data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",     

      all_contact:[],
      contact_event:[],
      editedContact: {},
      editedIndexContact: -1,
      contact_selected:[],

      isLoading_contact: false,
      search_contact: null, 			

      overlay: false,  

      dialogDelete: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Surname', value: 'surname' },
        { text: 'Phone', value: 'phone_number' },
        { text: 'Email', value: 'email' },
        { text: 'Contact Type', value: 'id_type_contact' , width: '25%' },
        { text: '', value: 'actions', sortable: false, width: '12%' },
      ],
     

    };		
	},
  props: {
      ID_EVENT: Number,
  },
  
    computed: {
    
    valid_contact() {
        return this.all_contact.filter((i) => {
            return (i.validita === null) || (i.validita === 1) || (i.validita === "");
        })
    },
  },
  mounted() {
  //  this.getAllContactEvent()
    
  },	
    watch: {
      ID_EVENT: {
          immediate: true,
          handler() {
              this.getAllContactEvent()
          }
      },
  }, 
	methods:{

				getAllContactEvent(){
						//restituisce  l'elenco dei contatti ovvero i subject con  idrole = 3 o altri
						this.overlay = true
						this.subjects=[]
						let request = {
								controller: "Bellator",
								method: "POST",
								richiesta: {
										action: "getsubject",
										roleid_in: "(3)", //subject.id_role IN ..inserire ruoli separati da virgola (non associati ad evento)
										token: localStorage.getItem("user_token"),
								},
						}
						this.$store.dispatch("api", request).then((res) => {   
								if (res.status == 200){
									//this.all_contact = res.data.subject;
                  res.data.subject.forEach(element => {
                  let field = element.surname  + " " +  element.name 
                  this.all_contact.push({  
                      name: field,
                      id_subject: element.id_subject, 
                      id_role: element.id_role, 
                      validita: element.validita  
                    })
                  })
									this.overlay = false 
                  this.getContact_for_Event()
								} else {
									this.snackbar_color = "error";
									this.snackbar_text = "Event contact not loaded.";
									this.snackbar = true;
									this.overlay = false
								}     
						})
						.catch((ex) => {
								console.log(ex)
								this.overlay = false
						});
				},  
				
				getContact_for_Event(){
					let that = this
					this.overlay = true
					this.contact_event=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getcontactevent",
									idevent: this.ID_EVENT,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
                
								that.contact_event = res.data.contactEvent;
								this.overlay = false 
							} else {
								this.snackbar_color = "error";
								this.snackbar_test = "Contact for event not loaded.";
								this.snackbar = true;
								this.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							this.overlay = false
					});
				},

				deleteItem (item) {
						this.editedIndexContact = this.contact_event.indexOf(item)
						this.editedContact = Object.assign({}, item)
						this.dialogDelete = true
				},

				deleteItemConfirm () {
					this.contact_event.splice(this.editedIndexContact, 1)
					this.delete_event_contact(this.editedContact)
					this.closeDelete()
				},

				closeDelete () {
					this.dialogDelete = false
					this.$nextTick(() => {
						this.editedContact = {}
						this.editedIndexContact = -1
					})
				},      

				add_event_contact(){
						let count = this.alasql(
								"SELECT count (*) as presente FROM ? WHERE fk_id_subject = " + this.contact_selected ,
								[this.contact_event]
						);
					if (count[0].presente > 0){
									this.snackbar_color = "error";
									this.snackbar_text = "Event contact already exist.";
									this.snackbar = true;
									this.overlay = false           
					}else{
								let new_contact_event = [{id_contact:-1, fk_id_event: this.ID_EVENT , fk_id_subject: this.contact_selected, }]
								this.overlay = true
								let request = {
										controller: "Bellator",
										method: "PUT",
										richiesta: {
												action: "setcontactevent",
												contact: new_contact_event,
												token: localStorage.getItem("user_token"),
										},
								}
								this.$store.dispatch("api", request).then((res) => {     
											if (res.status == 200){
													this.overlay = false
													this.snackbar_color = "success";
													this.snackbar_text = "Contact saved successfully. ";
													this.snackbar_icon = "mdi-window-close";
													this.snackbar = true;
													this.getContact_for_Event();
												} else {
													this.snackbar_color = "error";
													this.snackbar_text = "Error save contact. ";
													this.snackbar = true;
													this.overlay = false
												}           
								});           

					}
				},

				update_event_contact(item){
					this.overlay = true
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action: "setcontactevent",
									contact: [item],
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
									this.getContact_for_Event()
									this.overlay = false   
									this.snackbar_color = "success";
									this.snackbar_text = "Contact saved successfully ";
									this.snackbar = true;                    
							}
					});
				},        

				delete_event_contact(item){
					this.overlay = true
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action: "delcontactevent",
									id_contact: item.id_contact,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
									this.getContact_for_Event()
									this.overlay = false   
									this.snackbar_color = "success";
									this.snackbar_text = "Item deleted! ";
									this.snackbar = true;                    
							}
					});
				},			
        
        
        get_prefix(id_prefix){
          if (id_prefix != null){
            let prefix_array = this.alasql(
                "SELECT  FROM ? WHERE id = '" + id_prefix + "'",
                [this.prefix]
            );
            return prefix_array[0].Prefix
          }else return ""
        },        
          

	}
}
</script>
