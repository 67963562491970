<template>
    <div class="mt-4 ml-5 mr-5" >

       <div v-if="pagetabs">
            <subjectTabsDetail 
              :obj_subject = this.items_subject  
              :val_subject_sms = this.item_subject_sms
              :val_subject_mail = this.item_subject_mail
              :id_subject_selected = this.id_subjectSelected
              :modifica = this.modifica
              tab = "event"
              v-on:closeTabsDetail="valori_di_ritorno_TabsDetail"
            /> 
        </div>   


      <v-card  v-if="!pagetabs" id="create" style="background-color: var(--main-bg-color);" elevation="0" >
          <v-row class="child-flex">

              <v-snackbar
                  content-class="snackbar"
                  :color="snackbar_color"
                  left
                  class="snackbar-gen"

                  v-model="snackbar"
                  :timeout="3000"
                >
                  <div style="float: right;">
                      <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
                  </div>
                  <div>
                  <h3 :style="{ color: snackbar_text_color + '!important' }">
                    {{ snackbar_text }}
                  </h3>
                  </div>
              </v-snackbar>

              <v-speed-dial
                  v-model="fab"
                  :top="top"
                  :bottom="bottom"
                  :right="right"
                  :left="left"
                  :direction="direction"
                  :open-on-hover="hover"
                  :transition="transition"
                >
                  <template v-slot:activator>
                    <v-btn
                      v-model="fab"
                      color="blue darken-2"
                      dark
                      fab
                    >
                      <v-icon v-if="fab" @click="toTop">
                         mdi-chevron-up
                      </v-icon>
                      <v-icon v-else>
                        mdi-account-circle
                      </v-icon>
                    </v-btn>
                  </template>
<!--                   
                  <v-btn
                    fab
                    dark
                    small
                    color="green"
                  >
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
 -->
                  <v-btn
                    fab
                    dark
                    small
                    @click="close()"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-speed-dial>     

              <!--Event-->
              <v-row  style="background-color:#ffffff;" >
                <v-col cols="12" sm="12" >
                  <v-card class="mt-3  ml-4 mr-4" elevation="0">
                      <v-row> 
                        <v-col cols="12" sm="12" class="ml-4">
                            <p class="titleLabelForm"> 
                                REPORT APPOINTMENT
                            </p>
                        </v-col>                                 
                        <v-col cols="12" class="ml-3 mt-n6" >                                           
                          <v-row >

                            <v-col cols="12" sm="12"  class="mt-n1" >   
                                <div>
                                  <v-sheet
                                    tile
                                    height="54"
                                    class="d-flex"
                                  >
                                    <v-btn
                                      icon
                                      class="ma-2"
                                      @click="$refs.calendar.prev()"
                                    >
                                      <v-icon>mdi-chevron-left</v-icon>
                                    </v-btn>
                                    <v-select
                                      v-model="type"
                                      :items="types"
                                      class="textFieldForm ma-2"
                                      item-color="var(--main-active-select-list-color)"
                                      style=" color: var(--main-input-color); font-size: 18px;"
                                      color="var(--main-border-color)" 
                                      dense
                                      outlined
                                      hide-details
                                      label="type"
                                    ></v-select>

                                    <v-select
                                      v-model="weekday"
                                      :items="weekdays"
                                      dense
                                      outlined
                                      hide-details
                                      label="weekdays"
                                      class="textFieldForm ma-2"
                                      item-color="var(--main-active-select-list-color)"
                                      style=" color: var(--main-input-color); font-size: 18px;"
                                      color="var(--main-border-color)" 
                                    ></v-select>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      icon
                                      class="ma-2"
                                      @click="$refs.calendar.next()"
                                    >
                                      <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                  </v-sheet>
                                  <v-sheet height="600">
                                    <v-calendar
                                      ref="calendar"
                                      v-model="value"
                                      interval-height="110"
                                      :weekdays="weekday"
                                      :type="type"
                                      :events="events"
                                      :event-overlap-mode="mode"
                                      :event-overlap-threshold="30"
                                      :event-color="getEventColor"
                                      @click:event="showEvent"
                                      :start=this.start_date
                                    ></v-calendar>
                                    <v-menu
                                      v-model="selectedOpen"
                                      :close-on-content-click="false"
                                      :activator="selectedElement"
                                      offset-x
                                    >
                                      <v-card
                                        color="grey lighten-4"
                                        min-width="350px"
                                        flat
                                      >
                                        <v-toolbar
                                          :color="selectedEvent.color"
                                          dark
                                        >
                                          
                                          <v-icon>mdi-pencil</v-icon>
                                          <v-spacer/>
                                          <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                                          <v-spacer></v-spacer>

                                          <v-btn icon>
                                            <v-icon
                                              @click="getSubject(selectedEvent.name)"
                                            >mdi-eye</v-icon>
                                          </v-btn>
                                        </v-toolbar>
                                          <v-card-text>Start on {{ format_field(selectedEvent.start) }}</v-card-text>
                                          <v-card-text class="mt-n8"> End on {{ format_field(selectedEvent.end) }}</v-card-text>
                                          
                                        <v-card-actions class="mt-n4">
                                          <v-btn
                                            text
                                            color="secondary"
                                            @click="selectedOpen = false"
                                          >
                                            Cancel
                                          </v-btn>
                                        </v-card-actions>
                                      </v-card>
                                    </v-menu>                                    
                                  </v-sheet>
                                </div>

                            </v-col>                                                                                                                               

                          </v-row>                 
                        </v-col>              
                    </v-row>
                  </v-card>  
                </v-col>
              </v-row>   

                                

          </v-row>
           <br> 
      </v-card> 

    </div>
</template>
<style>
  /* Style for Bottom menu  */
  #create .v-speed-dial {
    position: fixed;
    z-index: 2;
  }

  #create .v-btn--floating {
    position: relative;
  }

</style>

<script>



  import subjectTabsDetail from "@/components/Subject/SubjectTabsDetail.vue";

  export default {
    components: {
       subjectTabsDetail,
    },
  data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",     
     
     //x Bottom menu
      direction: 'top',
      fab: false,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
      //x Bottom to Top
      scTimer: 0,
      scY: 0,

      
      start_date:  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      selectedOpen: false,
      selectedEvent: {},
      selectedElement: null,

      type: 'month',
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
      ],
      value: '',
      events: [],

      events_id:[],
      subjects:[],
      pagetabs:false,
      items_subject:null,
      item_subject_sms:null,
      item_subject_mail:null,
      id_subjectSelected:null,      

      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    };

    
  },
  props: {
      ID_EVENT: Number,
  },  
  computed: {                           
        
  },
  watch: {

  },  
  mounted() {

        this.getzoominterviewslot_rpt()
        this.getzoominterviewslotfree_rpt()

    //Gestione Go Bottom to TOP-----------------
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {

      format_field(campo){
        if(campo != null){
        console.log(campo.toString().substr(0,25))
        var a = campo.toString().substr(0,25)
        } else a= campo
        return a
      },

      get_subject(subject){
        console.log(subject)
        const subject_event = this.events_id.find(i => i.name === subject)
        console.log(subject_event.id)
      },

       
      getSubject(subject){
          debugger
            const subject_event = this.events_id.find(i => i.name === subject)
            console.log(subject_event.id)
            this.subjects=[]
            let request = {
                controller: "Bellator",
                method: "POST",
                richiesta: {
                    action: "getsubject",
                    id_subject: subject_event.id,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {   
                if (res.status == 200){
                  this.subjects = res.data.subject;
                  let item = this.subjects[0]
                  this.modifica = Math.round(Math.random()*100);
                  this.selectedOpen = false
                  this.items_subject = item
                  this.item_subject_sms = item.subject_sms
                  this.item_subject_mail = item.subject_mail
                  this.id_subjectSelected = item.id_subject
                  this.pagetabs=true

                } else {
                  this.snackbar_color = "error";
                  this.snackbar_text = "Subjects not loaded.";
                  this.snackbar = true;
                }     
            })
            .catch((ex) => {
              if (ex.response.status == 404 || ex.response.status == undefined) {
                this.snackbar = true;
                this.snackbar_color = "error";
                this.snackbar_text = ex;
              }
            });
      },

      valori_di_ritorno_TabsDetail(value){
          this.pagetabs =  false
      }, 

      getzoominterviewslot_rpt(){
        let that = this
        this.overlay = true
        this.slot_event=[]
        
        let request = {
            controller: "Bellator",
            method: "POST",
            richiesta: {
                action: "getzoominterviewslot_rpt",
                idevent: this.ID_EVENT,
                token: localStorage.getItem("user_token"),
            },
        }
        this.$store.dispatch("api", request).then((res) => {   
            
            if (res.status == 200){
              
              if (res.data.zoominterviewSlotRpt.length > 0){
                const [ aaaa, mmm, ggg ] = res.data.zoominterviewSlotRpt[0].data.split("-");
                this.start_date  = new Date(aaaa, mmm-1, ggg);
              }
              
              res.data.zoominterviewSlotRpt.forEach((elem) => {

                    const [ aa, mm, gg ] = elem.data.split("-");

                    const [ hs, ms, ss ] = elem.time_from.split(":");
                    const [ he, me, se ] = elem.time_to.split(":");
                    
                    var first = new Date(aa, mm-1, gg, hs, ms, ss);
                    var second = new Date(aa, mm-1, gg, he, me, se);

                    that.events.push({
                      name: elem.surname + " " + elem.name,
                      start: first,
                      end: second,
                      color: "grey",
                      timed: true,
                    })

                    that.events_id.push({
                      id: elem.fk_id_subject,
                      name: elem.surname + " " + elem.name,
                    })                    
                    
                });

              this.overlay = false 
            } else {
              //this.snackbar_color = "error";
              //this.snackbar_test = "Table Data not loaded.";
              //this.snackbar = true;
              this.overlay = false
            }     
        })
        .catch((ex) => {
            console.log(ex)
            this.overlay = false
        });
      },    


      getzoominterviewslotfree_rpt(){
        let that = this
        this.overlay = true
        this.slot_event=[]
        
        let request = {
            controller: "Bellator",
            method: "POST",
            richiesta: {
                action: "getzoominterviewslotfree_rpt",
                idevent: this.ID_EVENT,
                token: localStorage.getItem("user_token"),
            },
        }
        this.$store.dispatch("api", request).then((res) => {   
            if (res.status == 200){

              if (res.data.zoominterviewSlotFreeRpt.length > 0){
                const [ aaaa, mmm, ggg ] = res.data.zoominterviewSlotFreeRpt[0].data.split("-");
                this.start_date  = new Date(aaaa, mmm-1, ggg);
              }

              res.data.zoominterviewSlotFreeRpt.forEach((elem) => {
                    const [ aa, mm, gg ] = elem.data.split("-");

                    const [ hs, ms, ss ] = elem.time_from.split(":");
                    const [ he, me, se ] = elem.time_to.split(":");
                    
                    var first = new Date(aa, mm-1, gg, hs, ms, ss);
                    var second = new Date(aa, mm-1, gg, he, me, se);

                    that.events.push({
                      name: "Free",
                      start: first,
                      end: second,
                      color: "green",
                      timed: true,
                    })
                    
                });

              this.overlay = false 
            } else {
             // this.snackbar_color = "error";
             // this.snackbar_test = "Table Data not loaded.";
             // this.snackbar = true;

              this.overlay = false
            }     
        })
        .catch((ex) => {
            console.log(ex)
            this.overlay = false
        });
      },       


      getEvents ({ start, end }) {
        const events = []

        const min = new Date(`${start.date}T00:00:00`)
        const max = new Date(`${end.date}T23:59:59`)
        const days = (max.getTime() - min.getTime()) / 86400000
        const eventCount = this.rnd(days, days + 20)

        for (let i = 0; i < eventCount; i++) {
          const allDay = this.rnd(0, 3) === 0
          const firstTimestamp = this.rnd(min.getTime(), max.getTime())
          const first = new Date(firstTimestamp - (firstTimestamp % 900000))
          const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
          const second = new Date(first.getTime() + secondTimestamp)

          events.push({
            name: this.names[this.rnd(0, this.names.length - 1)],
            start: first,
            end: second,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            timed: !allDay,
          })
        }
        this.events = events
        
      },

      getEventColor (event) {
        return event.color
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },

      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => this.selectedOpen = true, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },      

      no_op(){},

      close(){
        this.$emit("closeDettEvent", "");
      },

      //Gestione Go Bottom to TOP----------------------------

      handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
      toTop: function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },      

      //--------------------------------------------

     
  },
};
</script>

