import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from "../store";

import Amministrazione from "@/views/_MODULI/AMMINISTRAZIONE/Amministrazione.vue";

import Login from '@/views/Login.vue' ;
import Admin from '@/views/Home.vue' ;

import Events from '@/views/Events/Events.vue' ;
import Subject from '@/views/Subject/Subject.vue' ;

import AddressBook from '@/views/AddressBook/AddressBook.vue' ;

import Mail from '@/views/Pages/TabellaEmail.vue' ;


import RptAppointment from '@/views/Report/Slot/AppointmentRPT.vue' ;
import RptMedical from '@/views/Report/Medical/MedicalRPT.vue' ;
import RptPassport from '@/views/Report/Passport/PassportRPT.vue' ;
import RptLicence from '@/views/Report/Licence/LicenceRPT.vue' ;
import RptWeightCheck from '@/views/Report/Weightcheck/WeightCheckRPT.vue' ;
import RptCheckIn from '@/views/Report/Checkin/CheckInRPT.vue' ;
import RptInfoPack from '@/views/Report/InfoPack/InfoPackRPT.vue' ;
import RptHeadShot from '@/views/Report/Headshot/HeadShotRPT.vue' ;
import RptDeduction from '@/views/Report/Deduction/DeductionRPT.vue' ;

import RptBoutsheet from '@/views/Report/Boutsheet/BoutsheetRPT.vue' ;
Vue.use(VueRouter)


const routes = [

  {
    path: "*",
    redirect: "/login",
  },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },

  //#region Amministrazione
  {
    path: "/amministrazione/dashboard",
    name: "Amministrazione",
    component: Amministrazione,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
 
  //#endregion  

  {
    path: "/admin",
    component: Admin,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        redirect: "/admin/events",
      },
      {
        path: "/admin/events",
        name: "Events",
        component: Events,
        meta: {
          requiresAuth: true,
        },
      },
             
      {
        path: "/admin/subject",
        name: "Subject",
        component: Subject,
        meta: {
          requiresAuth: true,
        },
      },    

      {
        path: "/admin/addressbook",
        name: "AddressBook",
        component: AddressBook,
        meta: {
          requiresAuth: true,
        },
      },   

      {
        path: "/admin/report/infopack",
        name: "Report - InfoPack",
        component: RptInfoPack,
        meta: {
          requiresAuth: true,
        },
      },
      
      {
        path: "/admin/report/appointment",
        name: "Report - Appointment",
        component: RptAppointment,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/report/medical",
        name: "Report - Medical",
        component: RptMedical,
        meta: {
          requiresAuth: true,
        },
      },      
      {
        path: "/admin/report/passports",
        name: "Report - Passport",
        component: RptPassport,
        meta: {
          requiresAuth: true,
        },
      },      
      {
        path: "/admin/report/licences",
        name: "Report - Licence",
        component: RptLicence,
        meta: {
          requiresAuth: true,
        },
      },    
      {
        path: "/admin/report/deduction",
        name: "Report - Deduction",
        component: RptDeduction,
        meta: {
          requiresAuth: true,
        },
      },      
      {
        path: "/admin/report/boutsheet",
        name: "Report - Boutsheet",
        component: RptBoutsheet,
        meta: {
          requiresAuth: true,
        },
      },  
      {
        path: "/admin/report/weightcheck",
        name: "Report - WeightCheck",
        component: RptWeightCheck,
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/admin/report/checkin",
        name: "Report - CheckIn",
        component: RptCheckIn,
        meta: {
          requiresAuth: true,
        },
      },      

      {
        path: "/admin/report/headshot",
        name: "Report - HeadShot",
        component: RptHeadShot,
        meta: {
          requiresAuth: true,
        },
      },      

      {
        path: "/admin/test",
        name: "Mail",
        component: Mail,
        meta: {
          requiresAuth: true,
        },
      },
      

    ],
  },
  

]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  //controllo che l'utente abbia l'auth per entrare
  let jwt_get = localStorage.user_token;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !jwt_get && to.path !== "/login") next("login");
  else if (!requiresAuth && jwt_get) next("admin");
  else {
    if (
      store.state.pagina_attuale == "controllo" &&
      store.state.check_paginaAttuale == 1
    ) {
      store.state.conferma_cambio_rotta = true;
      store.state.rotta_to = to;
      store.state.rotta_from = from;
    } else {
      next();
    }
  }
});

export default router
