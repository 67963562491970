<template>
      <!-- TABS di dettaglio -->
      <div>
        <v-row style="position:fixed; z-index: 1;  width:90%;"> <!--Gestione TAB di dettagli TESTA TAB-->

            <v-col cols="12" md="12 " class="mt-n2"  style="background-color: var(--main-bg-color);   "  >
                <v-toolbar dense  elevation="0" style="height: 80px; padding-top: 20px; background-color: var(--main-bg-color); align-items: center; ">
               
                    <div class="hidden-sm-and-down text-left" style="width: 260px;">
                     <label 
                              style="
                                display: inline-block;
                                margin-bottom: 4px;
                                padding-left: 6px;
                                width: 220px;
                                font-size: 20px;
                                font-family: Robo+Slab, serif;
                                font-style: normal;
                                color: var(--main-dth-color);
                                font-weight: 700; 
                        "> {{selected_subject}}
                        </label>   
                    </div>
                    
                    <div  v-if="$vuetify.breakpoint.sm ? style='width:100px; max-width:300px;' : style='width:55%;' "  >                    
                      <v-tabs
                          v-model="modalTab"
                          slider-size="4"
                          slider-color="red"
                          color="var(--main-primary-color)"
                          background-color="var(--main-bg-color)"
                      >

                        <v-tab :href="`#data`" 
                            class="tab_style"   
                            style="font-size: 1.1rem;text-transform: Initial;"

                        >Data</v-tab >
                        <v-tab :href="`#event`" 
                            style="margin-left: 15px; font-size: 1.0rem; text-transform: Initial; " class="tab_style"              
                        >Event</v-tab>
                        <v-tab :href="`#trip`" 
                            style="margin-left: 15px; font-size: 1.0rem; text-transform: Initial; " class="tab_style"
                        >Trip</v-tab>
                        <v-tab v-if="this.obj_subject.id_role == 1" 
                            :href="`#medical`" 
                            style="margin-left: 15px; font-size: 1.0rem; text-transform: Initial; " class="tab_style"
                        >Medical</v-tab>      
                        <v-tab v-if="this.obj_subject.id_role == 1"
                            :href="`#contract`" 
                            style="margin-left: 15px; font-size: 1.0rem; text-transform: Initial; " class="tab_style"        
                        >Contract</v-tab>                      
                      
                      </v-tabs> 
                    </div>

                  <v-switch
                    class="ml-8 mt-5 switch-gen"
                    label="Mail"
                    v-model="val_subject_mail"
                    color="green"
                    inset
                    @change="setsubjectmail"
                  ></v-switch>    
                  <v-switch
                    class="ml-8 mt-5 switch-gen"
                    label="SMS"
                    v-model="val_subject_sms"
                    color="green"
                    inset
                    @change="setsubjectsms"
                  ></v-switch>                     
                </v-toolbar>
                
            </v-col>

        </v-row>
        <v-row >  <!--Gestione TAB dettagli con chiamata a componenti-->
            <v-col cols="12" style="margin-top: 70px; "  >
                <v-tabs-items  style=" background-color: var(--main-bg-color);"  v-model="modalTab" class="no-transition" touchless>
                      <v-tab-item
                      :value="`data`"
                      transition="false"
                      class="no-transition"
                    >
                      <dataComponent 
                        :ITEM=this.obj_subject  
                        :TRIGGER = this.modifica
                        v-on:saveData="valori_di_ritorno_dataComponent"
                        v-on:closeDettSubject="valori_di_ritorno_dataComponent"
                      /> 
                    </v-tab-item>
                    <v-tab-item
                      :value="`event`"
                      transition="false"
                      class="no-transition"
                    >
                        <SubjectEvents
                        :ITEM= this.obj_subject  
                        :TRIGGER = this.modifica
                        v-on:closeDettSubject="valori_di_ritorno_dataComponent"
                        />
                    </v-tab-item>
                    <v-tab-item
                      :value="`trip`"
                      transition="false"
                      class="no-transition"
                    >
                      Tab Trip
                    </v-tab-item>
                    <v-tab-item
                      v-if="this.obj_subject.id_role == 1"
                      :value="`medical`"
                      transition="false"
                      class="no-transition"
                    >
                      <!--
                        Tab Medical
                        
                        -->
                        <Medical
                        :ITEM= this.obj_subject  
                        :TRIGGER = this.modifica
                        v-on:closeSubjectMedical="valori_di_ritorno_dataComponent"
                        />
                    </v-tab-item>
                    <v-tab-item
                      v-if="this.obj_subject.id_role == 1"
                      :value="`contract`"
                      transition="false"
                      class="no-transition"
                    >
                        Contract
                        <br> <br> <br> <br> <br>
                    </v-tab-item>   

                </v-tabs-items>
            
            </v-col>
        </v-row>
      </div>
</template>
<style>


</style>
<script>
import dataComponent from "@/components/Subject/Data";
import SubjectEvents from "@/components/Subject/SubjectEvents";
import Medical from "@/components/Subject/Medical";


  export default {
    components: {
       dataComponent,
       SubjectEvents,
       Medical
    },
    data () {
      return {

        overlay: false,   
        modalTab: "",

       }
    },
    mounted() {
    }, 
    props: {
      obj_subject: Object,
      val_subject_sms: Number,
      val_subject_mail: Number,
      id_subject_selected: Number,
      modifica: Number,
      tab: String
    },   

    watch: {
      tab: {
          immediate: true,
          handler() {
            this.modalTab = this.tab;
          }
      },
    },
          
    computed: {
      
      selected_subject() {
        const sbj = ''
        return  sbj.concat(this.obj_subject.name, ' ', this.obj_subject.surname )
      }, 

    },      
    methods: {
  
        valori_di_ritorno_dataComponent(value){
            //console.log(value)
            this.modalTab = this.tab
            this.$emit("closeTabsDetail", "");
        },  


				setsubjectsms(){
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action: "setsubjectsms",
									value: this.val_subject_sms,
                  id_subject: this.id_subject_selected,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
									this.overlay = false                      
							}
					});
				},   
         
				setsubjectmail(){
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action: "setsubjectmail",
									value: this.val_subject_mail,
                  id_subject: this.id_subject_selected,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
									this.overlay = false                      
							}
					});
				},         

    }
  }
</script>
