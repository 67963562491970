<template>
    <div :style="'z-index:'+ui.max_z_index">
        <v-dialog v-model="ui.show" persistent width="700" >
            <v-card>
                <v-app-bar dense color="primary" dark>
                    <v-toolbar-title>Avviso di rete</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="ui.show=false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>
                <v-container>
                    <v-row align="center">
                        <v-col cols="1" align="left">
                            <v-icon x-large>{{codes[ui.code].icon}}</v-icon>
                        </v-col>
                        <v-col cols="10">
                            <v-card-title class="ma-0 py-0">{{codes[ui.code].title}}</v-card-title> 
                            <v-card-text align="left" class="ma-0 py-0">{{(ui.code==2?ui.server_msg.message:codes[ui.code].body)}}</v-card-text>
                        </v-col>                    
                    </v-row>
                    <div v-if="ui.code==2" class="network_detail">
                        <v-divider />
                        <h3>Dettagli</h3>
                        <p><strong>URL:</strong> {{ui.server_msg.detail.URL}}</p>
                        <p><strong>Richiesta:</strong> {{ui.server_msg.detail.action}}</p>
                    </div>              
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="logout">Logout</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="ui.show_loading" persistent width="350">
            <v-card>
                <v-container>
                    <v-progress-circular v-if="ui.flavor==null" indeterminate color="primary" size="200" style="margin:50px"></v-progress-circular>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

//  La classe NETWORK gestisce le chiamate HTTP e le relative proprietà
import Network from "./Network.js";
export default {
    name : "NETWORK",
    data(){
        return{
            ui : {
                max_z_index : 2147483647,
                show : false,
                show_loading: false,
                loading_queue : 0,
                last_options : {},
                code : 0,
                flavor : null,
                server_msg : {
                    message : null,
                    detail : {
                        URL : null,
                        action : null
                    }
                },
            },
            //  Fonte codici : Wikipedia
            codes : {
                //  Errori generici                
                0 : {title: 'Errore di rete',body:'Non è stato possibile contattare il server.',icon:'mdi-network-off'},
                1 : {title: 'Richiesta annullata',body:'La richiesta è stata annullata prima del completamento.',icon:'mdi-network-off'},
                2 : {title: 'Errore', body : null, icon: 'mdi-sort-variant-remove'},
                //  204
                204 : {title: '204: No content',body:'Il server ha processato la richiesta, ma non ha restituito contenuti nella risposta.',icon:'mdi-code-json'},
                //  Principali codici di errore client
                400 : {title: 'Errore 400: Richiesta mal formulata',body:'La richiesta non può essere soddisfatta a causa di errori di sintassi.',icon:'mdi-close-box'},
                401 : {title: 'Errore 401: Autenticazione non valida',body:'Autenticazione scaduta o non valida.',icon:'mdi-account-cancel'},
                403 : {title: 'Errore 403: Accesso vietato',body:'Non è consentito accedere alla risorsa.',icon:'mdi-cancel'},
                404 : {title: 'Errore 404: Risorsa non trovata',body:'Il server non è riuscito a trovare la risorsa richiesta.',icon:'mdi-help-network'},
                405 : {title: 'Errore 405: Metodo non consentito',body:'La richiesta è stata eseguita con un metodo non consentito.',icon:'mdi-close-octagon'},
                //  Principali codici di errore server
                500 : {title: 'Errore 500: Errore interno del server',body:'Errore durante l\'elaborazione della richiesta.',icon:'mdi-server-off'},
                501 : {title: 'Errore 501: Metodo non supportato',body:'Il server non è in grado di soddisfare il metodo della richiesta.',icon:'mdi-server-off'},
                502 : {title: 'Errore 502: Errore gateway',body:'Risposta invalida dal server di upstream.',icon:'mdi-server-network-off'},
                503 : {title: 'Errore 503: Non disponibile',body:'Il server è momentaneamente non disponibile.',icon:'mdi-server-off'},
                504 : {title: 'Errore 504: Timeout gateway',body:'Timeout durante l\'attesa di risposta dal server di upstream.',icon:'mdi-clock-alert'},
                505 : {title: 'Errore 505: Versione HTTP non supportata',body:'La versione HTTP richiesta non è supportata dal server.',icon:'mdi-server-off'},
                509 : {title: 'Errore 509: Limite di larghezza di banda superato',body:'',icon:'mdi-speedometer'},
            },
            NETWORK : null,
        }
    },
    mounted(){
        const that = this;
        Object.assign(this.ui, {
            show_loader : function(){
                const path = window.location.pathname;
                const flavors = [{
                    path : '/home/graph',
                    flavor : 'graph'
                }];
                that.ui.flavor = null;
                for(let i=0; i < flavors.length; i++){
                    const flavor = flavors[i];
                    if(path.indexOf(flavor.path) >= 0){
                        that.ui.flavor = flavor.flavor;
                        break;
                    }                
                }
                that.ui.show_loading = true;
            },
            hide_loader : function(){
                that.ui.show_loading = false;
            }
        })
        this.NETWORK = new Network(this.ui);
        this.$store.state.Network = this.NETWORK;       
        window.NETWORK = this.NETWORK;
    },
    methods : {
        logout(){
            localStorage.clear();
            sessionStorage.clear();
            this.$router.push('/login');
        }
    }
}
</script>
<style>
.network_loading{
    position: relative;
    display: block;
    width:100%;
    height:100%;
    object-fit: contain;
    object-position: center;
}
.network_detail{
    position: relative;
    text-align: left;
    margin-left:5px;
}
.network_detail > h3{
    margin: 15px 0 5px;
    font-size:15px;
}
.network_detail > p{
    padding:0!important;
    margin:0!important;
}
</style>