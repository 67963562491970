import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store';

import vuetify from '@/plugins/vuetify'

import CountryFlag from 'vue-country-flag'
//Vue.component('country-flag', CountryFlag)
Vue.use(CountryFlag);

import alasqlJs from '../node_modules/alasql/dist/alasql.min.js';
const alasql = {
  install() {
    Vue.prototype.alasql = alasqlJs;
  },
};
Vue.use(alasql);

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
// don't forget to import styles
import "tiptap-vuetify/dist/main.css";

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'md'
})

//default css app
import './assets/styles/default.css'

import axios from "axios";

Vue.config.productionTip = false

  //Oggetti globali Statici
  Vue.prototype.country_flags = [
    { id:'1', name: 'Afghanistan', cod: 'af'},
    { id:'2', name: 'Aland Islands', cod: 'ax'},
    { id:'3', name: 'Albania', cod: 'al'},
    { id:'4', name: 'Algeria', cod: 'dz'},
    { id:'5', name: 'American Samoa', cod: 'as'},
    { id:'6', name: 'Andorra', cod: 'ad'},
    { id:'7', name: 'Angola', cod: 'ao'},
    { id:'8', name: 'Anguilla', cod: 'ai'},
    { id:'9', name: 'Antarctica', cod: 'aq'},
    { id:'10', name: 'Antigua and Barbuda', cod: 'ag'},
    { id:'11', name: 'Argentina', cod: 'ar'},
    { id:'12', name: 'Armenia', cod: 'am'},
    { id:'13', name: 'Aruba', cod: 'aw'},
    { id:'14', name: 'Australia', cod: 'au'},
    { id:'15', name: 'Austria', cod: 'at'},
    { id:'16', name: 'Azerbaijan', cod: 'az'},
    { id:'17', name: 'Bahamas', cod: 'bs'},
    { id:'18', name: 'Bahrain', cod: 'bh'},
    { id:'19', name: 'Bangladesh', cod: 'bd'},
    { id:'20', name: 'Barbados', cod: 'bb'},
    { id:'21', name: 'Belarus', cod: 'by'},
    { id:'22', name: 'Belgium', cod: 'be'},
    { id:'23', name: 'Belize', cod: 'bz'},
    { id:'24', name: 'Benin', cod: 'bj'},
    { id:'25', name: 'Bermuda', cod: 'bm'},
    { id:'26', name: 'Bhutan', cod: 'bt'},
    { id:'27', name: 'Bolivia (Plurinational State of)', cod: 'bo'},
    { id:'28', name: 'Bonaire, Sint Eustatius and Saba', cod: 'bq'},
    { id:'29', name: 'Bosnia and Herzegovina', cod: 'ba'},
    { id:'30', name: 'Botswana', cod: 'bw'},
    { id:'31', name: 'Bouvet Island', cod: 'bv'},
    { id:'32', name: 'Brazil', cod: 'br'},
    { id:'33', name: 'Virgin Islands (British)', cod: 'vg'},
    { id:'34', name: 'British Indian Ocean Territory', cod: 'io'},
    { id:'35', name: 'Brunei Darussalam', cod: 'bn'},
    { id:'36', name: 'Bulgaria', cod: 'bg'},
    { id:'37', name: 'Burkina Faso', cod: 'bf'},
    { id:'38', name: 'Burundi', cod: 'bi'},
    { id:'39', name: 'Cambodia', cod: 'kh'},
    { id:'40', name: 'Cameroon', cod: 'cm'},
    { id:'41', name: 'Canada', cod: 'ca'},
    { id:'42', name: 'Cabo Verde', cod: 'cv'},
    { id:'43', name: 'Cayman Islands', cod: 'ky'},
    { id:'44', name: 'Central African Republic', cod: 'cf'},
    { id:'45', name: 'Chad', cod: 'td'},
    { id:'46', name: 'Chile', cod: 'cl'},
    { id:'47', name: 'China', cod: 'cn'},
    { id:'48', name: 'Hong Kong', cod: 'hk'},
    { id:'49', name: 'Macao', cod: 'mo'},
    { id:'50', name: 'Christmas Island', cod: 'cx'},
    { id:'51', name: 'Cocos (Keeling) Islands', cod: 'cc'},
    { id:'52', name: 'Colombia', cod: 'co'},
    { id:'53', name: 'Comoros', cod: 'km'},
    { id:'54', name: 'Congo', cod: 'cg'},
    { id:'55', name: 'Congo, Democratic Republic of the', cod: 'cd'},
    { id:'56', name: 'Cook Islands', cod: 'ck'},
    { id:'57', name: 'Costa Rica', cod: 'cr'},
    { id:'58', name: 'Côte d\'Ivoire', cod: 'ci'},
    { id:'59', name: 'Croatia', cod: 'hr'},
    { id:'60', name: 'Cuba', cod: 'cu'},
    { id:'61', name: 'Curaçao', cod: 'cw'},
    { id:'62', name: 'Cyprus', cod: 'cy'},
    { id:'63', name: 'Czechia', cod: 'cz'},
    { id:'64', name: 'Denmark', cod: 'dk'},
    { id:'65', name: 'Djibouti', cod: 'dj'},
    { id:'66', name: 'Dominica', cod: 'dm'},
    { id:'67', name: 'Dominican Republic', cod: 'do'},
    { id:'68', name: 'Ecuador', cod: 'ec'},
    { id:'69', name: 'Egypt', cod: 'eg'},
    { id:'70', name: 'El Salvador', cod: 'sv'},
    { id:'71', name: 'Equatorial Guinea', cod: 'gq'},
    { id:'72', name: 'Eritrea', cod: 'er'},
    { id:'73', name: 'Estonia', cod: 'ee'},
    { id:'74', name: 'Ethiopia', cod: 'et'},
    { id:'75', name: 'Falkland Islands (Malvinas)', cod: 'fk'},
    { id:'76', name: 'Faroe Islands', cod: 'fo'},
    { id:'77', name: 'Fiji', cod: 'fj'},
    { id:'78', name: 'Finland', cod: 'fi'},
    { id:'79', name: 'France', cod: 'fr'},
    { id:'80', name: 'French Guiana', cod: 'gf'},
    { id:'81', name: 'French Polynesia', cod: 'pf'},
    { id:'82', name: 'French Southern Territories', cod: 'tf'},
    { id:'83', name: 'Gabon', cod: 'ga'},
    { id:'84', name: 'Gambia', cod: 'gm'},
    { id:'85', name: 'Georgia', cod: 'ge'},
    { id:'86', name: 'Germany', cod: 'de'},
    { id:'87', name: 'Ghana', cod: 'gh'},
    { id:'88', name: 'Gibraltar', cod: 'gi'},
    { id:'89', name: 'Greece', cod: 'gr'},
    { id:'90', name: 'Greenland', cod: 'gl'},
    { id:'91', name: 'Grenada', cod: 'gd'},
    { id:'92', name: 'Guadeloupe', cod: 'gp'},
    { id:'93', name: 'Guam', cod: 'gu'},
    { id:'94', name: 'Guatemala', cod: 'gt'},
    { id:'95', name: 'Guernsey', cod: 'gg'},
    { id:'96', name: 'Guinea', cod: 'gn'},
    { id:'97', name: 'Guinea-Bissau', cod: 'gw'},
    { id:'98', name: 'Guyana', cod: 'gy'},
    { id:'99', name: 'Haiti', cod: 'ht'},
    { id:'100', name: 'Heard Island and McDonald Islands', cod: 'hm'},
    { id:'101', name: 'Holy See', cod: 'va'},
    { id:'102', name: 'Honduras', cod: 'hn'},
    { id:'103', name: 'Hungary', cod: 'hu'},
    { id:'104', name: 'Iceland', cod: 'is'},
    { id:'105', name: 'India', cod: 'in'},
    { id:'106', name: 'Indonesia', cod: 'id'},
    { id:'107', name: 'Iran (Islamic Republic of)', cod: 'ir'},
    { id:'108', name: 'Iraq', cod: 'iq'},
    { id:'109', name: 'Ireland', cod: 'ie'},
    { id:'110', name: 'Isle of Man', cod: 'im'},
    { id:'111', name: 'Israel', cod: 'il'},
    { id:'112', name: 'Italy', cod: 'it'},
    { id:'113', name: 'Jamaica', cod: 'jm'},
    { id:'114', name: 'Japan', cod: 'jp'},
    { id:'115', name: 'Jersey', cod: 'je'},
    { id:'116', name: 'Jordan', cod: 'jo'},
    { id:'117', name: 'Kazakhstan', cod: 'kz'},
    { id:'118', name: 'Kenya', cod: 'ke'},
    { id:'119', name: 'Kiribati', cod: 'ki'},
    { id:'120', name: 'Korea (Democratic People\'s Republic of)', cod: 'kp'},
    { id:'121', name: 'Korea, Republic of', cod: 'kr'},
    { id:'122', name: 'Kuwait', cod: 'kw'},
    { id:'123', name: 'Kyrgyzstan', cod: 'kg'},
    { id:'124', name: 'Lao People\'s Democratic Republic', cod: 'la'},
    { id:'125', name: 'Latvia', cod: 'lv'},
    { id:'126', name: 'Lebanon', cod: 'lb'},
    { id:'127', name: 'Lesotho', cod: 'ls'},
    { id:'128', name: 'Liberia', cod: 'lr'},
    { id:'129', name: 'Libya', cod: 'ly'},
    { id:'130', name: 'Liechtenstein', cod: 'li'},
    { id:'131', name: 'Lithuania', cod: 'lt'},
    { id:'132', name: 'Luxembourg', cod: 'lu'},
    { id:'133', name: 'North Macedonia', cod: 'mk'},
    { id:'134', name: 'Madagascar', cod: 'mg'},
    { id:'135', name: 'Malawi', cod: 'mw'},
    { id:'136', name: 'Malaysia', cod: 'my'},
    { id:'137', name: 'Maldives', cod: 'mv'},
    { id:'138', name: 'Mali', cod: 'ml'},
    { id:'139', name: 'Malta', cod: 'mt'},
    { id:'140', name: 'Marshall Islands', cod: 'mh'},
    { id:'141', name: 'Martinique', cod: 'mq'},
    { id:'142', name: 'Mauritania', cod: 'mr'},
    { id:'143', name: 'Mauritius', cod: 'mu'},
    { id:'144', name: 'Mayotte', cod: 'yt'},
    { id:'145', name: 'Mexico', cod: 'mx'},
    { id:'146', name: 'Micronesia (Federated States of)', cod: 'fm'},
    { id:'147', name: 'Moldova, Republic of', cod: 'md'},
    { id:'148', name: 'Monaco', cod: 'mc'},
    { id:'149', name: 'Mongolia', cod: 'mn'},
    { id:'150', name: 'Montenegro', cod: 'me'},
    { id:'151', name: 'Montserrat', cod: 'ms'},
    { id:'152', name: 'Morocco', cod: 'ma'},
    { id:'153', name: 'Mozambique', cod: 'mz'},
    { id:'154', name: 'Myanmar', cod: 'mm'},
    { id:'155', name: 'Namibia', cod: 'na'},
    { id:'156', name: 'Nauru', cod: 'nr'},
    { id:'157', name: 'Nepal', cod: 'np'},
    { id:'158', name: 'Netherlands', cod: 'nl'},
    { id:'159', name: 'New Caledonia', cod: 'nc'},
    { id:'160', name: 'New Zealand', cod: 'nz'},
    { id:'161', name: 'Nicaragua', cod: 'ni'},
    { id:'162', name: 'Niger', cod: 'ne'},
    { id:'163', name: 'Nigeria', cod: 'ng'},
    { id:'164', name: 'Niue', cod: 'nu'},
    { id:'165', name: 'Norfolk Island', cod: 'nf'},
    { id:'166', name: 'Northern Mariana Islands', cod: 'mp'},
    { id:'167', name: 'Norway', cod: 'no'},
    { id:'168', name: 'Oman', cod: 'om'},
    { id:'169', name: 'Pakistan', cod: 'pk'},
    { id:'170', name: 'Palau', cod: 'pw'},
    { id:'171', name: 'Palestine, State of', cod: 'ps'},
    { id:'172', name: 'Panama', cod: 'pa'},
    { id:'173', name: 'Papua New Guinea', cod: 'pg'},
    { id:'174', name: 'Paraguay', cod: 'py'},
    { id:'175', name: 'Peru', cod: 'pe'},
    { id:'176', name: 'Philippines', cod: 'ph'},
    { id:'177', name: 'Pitcairn', cod: 'pn'},
    { id:'178', name: 'Poland', cod: 'pl'},
    { id:'179', name: 'Portugal', cod: 'pt'},
    { id:'180', name: 'Puerto Rico', cod: 'pr'},
    { id:'181', name: 'Qatar', cod: 'qa'},
    { id:'182', name: 'Réunion', cod: 're'},
    { id:'183', name: 'Romania', cod: 'ro'},
    { id:'184', name: 'Russian Federation', cod: 'ru'},
    { id:'185', name: 'Rwanda', cod: 'rw'},
    { id:'186', name: 'Saint Barthélemy', cod: 'bl'},
    { id:'187', name: 'Saint Helena, Ascension and Tristan da Cunha', cod: 'sh'},
    { id:'188', name: 'Saint Kitts and Nevis', cod: 'kn'},
    { id:'189', name: 'Saint Lucia', cod: 'lc'},
    { id:'190', name: 'Saint Martin (French part)', cod: 'mf'},
    { id:'191', name: 'Saint Pierre and Miquelon', cod: 'pm'},
    { id:'192', name: 'Saint Vincent and the Grenadines', cod: 'vc'},
    { id:'193', name: 'Samoa', cod: 'ws'},
    { id:'194', name: 'San Marino', cod: 'sm'},
    { id:'195', name: 'Sao Tome and Principe', cod: 'st'},
    { id:'196', name: 'Saudi Arabia', cod: 'sa'},
    { id:'197', name: 'Senegal', cod: 'sn'},
    { id:'198', name: 'Serbia', cod: 'rs'},
    { id:'199', name: 'Seychelles', cod: 'sc'},
    { id:'200', name: 'Sierra Leone', cod: 'sl'},
    { id:'201', name: 'Singapore', cod: 'sg'},
    { id:'202', name: 'Sint Maarten (Dutch part)', cod: 'sx'},
    { id:'203', name: 'Slovakia', cod: 'sk'},
    { id:'204', name: 'Slovenia', cod: 'si'},
    { id:'205', name: 'Solomon Islands', cod: 'sb'},
    { id:'206', name: 'Somalia', cod: 'so'},
    { id:'207', name: 'South Africa', cod: 'za'},
    { id:'208', name: 'South Georgia and the South Sandwich Islands', cod: 'gs'},
    { id:'209', name: 'South Sudan', cod: 'ss'},
    { id:'210', name: 'Soviet Union', cod: 'su'},
    { id:'211', name: 'Spain', cod: 'es'},
    { id:'212', name: 'Sri Lanka', cod: 'lk'},
    { id:'213', name: 'Sudan', cod: 'sd'},
    { id:'214', name: 'Suriname', cod: 'sr'},
    { id:'215', name: 'Svalbard and Jan Mayen', cod: 'sj'},
    { id:'216', name: 'Eswatini', cod: 'sz'},
    { id:'217', name: 'Sweden', cod: 'se'},
    { id:'218', name: 'Switzerland', cod: 'ch'},
    { id:'219', name: 'Syrian Arab Republic', cod: 'sy'},
    { id:'220', name: 'Taiwan, Province of China', cod: 'tw'},
    { id:'221', name: 'Tajikistan', cod: 'tj'},
    { id:'222', name: 'Tanzania, United Republic of', cod: 'tz'},
    { id:'223', name: 'Thailand', cod: 'th'},
    { id:'224', name: 'Timor-Leste', cod: 'tl'},
    { id:'225', name: 'Togo', cod: 'tg'},
    { id:'226', name: 'Tokelau', cod: 'tk'},
    { id:'227', name: 'Tonga', cod: 'to'},
    { id:'228', name: 'Trinidad and Tobago', cod: 'tt'},
    { id:'229', name: 'Tunisia', cod: 'tn'},
    { id:'230', name: 'Turkey', cod: 'tr'},
    { id:'231', name: 'Turkmenistan', cod: 'tm'},
    { id:'232', name: 'Turks and Caicos Islands', cod: 'tc'},
    { id:'233', name: 'Tuvalu', cod: 'tv'},
    { id:'234', name: 'Uganda', cod: 'ug'},
    { id:'235', name: 'Ukraine', cod: 'ua'},
    { id:'236', name: 'United Arab Emirates', cod: 'ae'},
    { id:'237', name: 'United Kingdom of Great Britain and Northern Ireland', cod: 'gb'},
    { id:'238', name: 'United States of America', cod: 'us'},
    { id:'239', name: 'United States Minor Outlying Islands', cod: 'um'},
    { id:'240', name: 'Uruguay', cod: 'uy'},
    { id:'241', name: 'Uzbekistan', cod: 'uz'},
    { id:'242', name: 'Vanuatu', cod: 'vu'},
    { id:'243', name: 'Venezuela (Bolivarian Republic of)', cod: 've'},
    { id:'244', name: 'Viet Nam', cod: 'vn'},
    { id:'245', name: 'Virgin Islands (U.S.)', cod: 'vi'},
    { id:'246', name: 'Wallis and Futuna', cod: 'wf'},
    { id:'247', name: 'Western Sahara', cod: 'eh'},
    { id:'248', name: 'Yemen', cod: 'ye'},
    { id:'249', name: 'Zambia', cod: 'zm'},
    { id:'250', name: 'Zimbabwe', cod: 'zw'},
  ],

  Vue.prototype.currency = [
    { id: 1, Country: 'Afghanistan', Currency: 'Afghan afghani', Cod: 'AFN'},
    { id: 2, Country: 'Akrotiri and Dhekelia (UK)', Currency: 'European euro', Cod: 'EUR'},
    { id: 3, Country: 'Aland Islands (Finland)', Currency: 'European euro', Cod: 'EUR'},
    { id: 4, Country: 'Albania', Currency: 'Albanian lek', Cod: 'ALL'},
    { id: 5, Country: 'Algeria', Currency: 'Algerian dinar', Cod: 'DZD'},
    { id: 6, Country: 'American Samoa (USA)', Currency: 'United States dollar', Cod: 'USD'},
    { id: 7, Country: 'Andorra', Currency: 'European euro', Cod: 'EUR'},
    { id: 8, Country: 'Angola', Currency: 'Angolan kwanza', Cod: 'AOA'},
    { id: 9, Country: 'Anguilla (UK)', Currency: 'East Caribbean dollar', Cod: 'XCD'},
    { id: 10, Country: 'Antigua and Barbuda', Currency: 'East Caribbean dollar', Cod: 'XCD'},
    { id: 11, Country: 'Argentina', Currency: 'Argentine peso', Cod: 'ARS'},
    { id: 12, Country: 'Armenia', Currency: 'Armenian dram', Cod: 'AMD'},
    { id: 13, Country: 'Aruba (Netherlands)', Currency: 'Aruban florin', Cod: 'AWG'},
    { id: 14, Country: 'Ascension Island (UK)', Currency: 'Saint Helena pound', Cod: 'SHP'},
    { id: 15, Country: 'Australia', Currency: 'Australian dollar', Cod: 'AUD'},
    { id: 16, Country: 'Austria', Currency: 'European euro', Cod: 'EUR'},
    { id: 17, Country: 'Azerbaijan', Currency: 'Azerbaijan manat', Cod: 'AZN'},
    { id: 18, Country: 'Bahamas', Currency: 'Bahamian dollar', Cod: 'BSD'},
    { id: 19, Country: 'Bahrain', Currency: 'Bahraini dinar', Cod: 'BHD'},
    { id: 20, Country: 'Bangladesh', Currency: 'Bangladeshi taka', Cod: 'BDT'},
    { id: 21, Country: 'Barbados', Currency: 'Barbadian dollar', Cod: 'BBD'},
    { id: 22, Country: 'Belarus', Currency: 'Belarusian ruble', Cod: 'BYN'},
    { id: 23, Country: 'Belgium', Currency: 'European euro', Cod: 'EUR'},
    { id: 24, Country: 'Belize', Currency: 'Belize dollar', Cod: 'BZD'},
    { id: 25, Country: 'Benin', Currency: 'West African CFA franc', Cod: 'XOF'},
    { id: 26, Country: 'Bermuda (UK)', Currency: 'Bermudian dollar', Cod: 'BMD'},
    { id: 27, Country: 'Bhutan', Currency: 'Bhutanese ngultrum', Cod: 'BTN'},
    { id: 28, Country: 'Bolivia', Currency: 'Bolivian boliviano', Cod: 'BOB'},
    { id: 29, Country: 'Bonaire (Netherlands)', Currency: 'United States dollar', Cod: 'USD'},
    { id: 30, Country: 'Bosnia and Herzegovina', Currency: 'Bosnia and Herzegovina convertible mark', Cod: 'BAM'},
    { id: 31, Country: 'Botswana', Currency: 'Botswana pula', Cod: 'BWP'},
    { id: 32, Country: 'Brazil', Currency: 'Brazilian real', Cod: 'BRL'},
    { id: 33, Country: 'British Indian Ocean Territory (UK)', Currency: 'United States dollar', Cod: 'USD'},
    { id: 34, Country: 'British Virgin Islands (UK)', Currency: 'United States dollar', Cod: 'USD'},
    { id: 35, Country: 'Brunei', Currency: 'Brunei dollar', Cod: 'BND'},
    { id: 36, Country: 'Bulgaria', Currency: 'Bulgarian lev', Cod: 'BGN'},
    { id: 37, Country: 'Burkina Faso', Currency: 'West African CFA franc', Cod: 'XOF'},
    { id: 38, Country: 'Burundi', Currency: 'Burundi franc', Cod: 'BIF'},
    { id: 39, Country: 'Cabo Verde', Currency: 'Cabo Verdean escudo', Cod: 'CVE'},
    { id: 40, Country: 'Cambodia', Currency: 'Cambodian riel', Cod: 'KHR'},
    { id: 41, Country: 'Cameroon', Currency: 'Central African CFA franc', Cod: 'XAF'},
    { id: 42, Country: 'Canada', Currency: 'Canadian dollar', Cod: 'CAD'},
    { id: 43, Country: 'Caribbean Netherlands (Netherlands)', Currency: 'United States dollar', Cod: 'USD'},
    { id: 44, Country: 'Cayman Islands (UK)', Currency: 'Cayman Islands dollar', Cod: 'KYD'},
    { id: 45, Country: 'Central African Republic', Currency: 'Central African CFA franc', Cod: 'XAF'},
    { id: 46, Country: 'Chad', Currency: 'Central African CFA franc', Cod: 'XAF'},
    { id: 47, Country: 'Chatham Islands (New Zealand)', Currency: 'New Zealand dollar', Cod: 'NZD'},
    { id: 48, Country: 'Chile', Currency: 'Chilean peso', Cod: 'CLP'},
    { id: 49, Country: 'China', Currency: 'Chinese Yuan Renminbi', Cod: 'CNY'},
    { id: 50, Country: 'Christmas Island (Australia)', Currency: 'Australian dollar', Cod: 'AUD'},
    { id: 51, Country: 'Cocos (Keeling) Islands (Australia)', Currency: 'Australian dollar', Cod: 'AUD'},
    { id: 52, Country: 'Colombia', Currency: 'Colombian peso', Cod: 'COP'},
    { id: 53, Country: 'Comoros', Currency: 'Comorian franc', Cod: 'KMF'},
    { id: 54, Country: 'Congo, Democratic Republic of the', Currency: 'Congolese franc', Cod: 'CDF'},
    { id: 55, Country: 'Congo, Republic of the', Currency: 'Central African CFA franc', Cod: 'XAF'},
    { id: 56, Country: 'Cook Islands (New Zealand)', Currency: 'Cook Islands dollar', Cod: 'none'},
    { id: 57, Country: 'Costa Rica', Currency: 'Costa Rican colon', Cod: 'CRC'},
    { id: 58, Country: 'Cote d\'Ivoire', Currency: 'West African CFA franc', Cod: 'XOF'},
    { id: 59, Country: 'Croatia', Currency: 'Croatian kuna', Cod: 'HRK'},
    { id: 60, Country: 'Cuba', Currency: 'Cuban peso', Cod: 'CUP'},
    { id: 61, Country: 'Curacao (Netherlands)', Currency: 'Netherlands Antillean guilder', Cod: 'ANG'},
    { id: 62, Country: 'Cyprus', Currency: 'European euro', Cod: 'EUR'},
    { id: 63, Country: 'Czechia', Currency: 'Czech koruna', Cod: 'CZK'},
    { id: 64, Country: 'Denmark', Currency: 'Danish krone', Cod: 'DKK'},
    { id: 65, Country: 'Djibouti', Currency: 'Djiboutian franc', Cod: 'DJF'},
    { id: 66, Country: 'Dominica', Currency: 'East Caribbean dollar', Cod: 'XCD'},
    { id: 67, Country: 'Dominican Republic', Currency: 'Dominican peso', Cod: 'DOP'},
    { id: 68, Country: 'Ecuador', Currency: 'United States dollar', Cod: 'USD'},
    { id: 69, Country: 'Egypt', Currency: 'Egyptian pound', Cod: 'EGP'},
    { id: 70, Country: 'El Salvador', Currency: 'United States dollar', Cod: 'USD'},
    { id: 71, Country: 'Equatorial Guinea', Currency: 'Central African CFA franc', Cod: 'XAF'},
    { id: 72, Country: 'Eritrea', Currency: 'Eritrean nakfa', Cod: 'ERN'},
    { id: 73, Country: 'Estonia', Currency: 'European euro', Cod: 'EUR'},
    { id: 74, Country: 'Eswatini', Currency: 'Swazi lilangeni', Cod: 'SZL'},
    { id: 75, Country: 'Ethiopia', Currency: 'Ethiopian birr', Cod: 'ETB'},
    { id: 76, Country: 'Falkland Islands (UK)', Currency: 'Falkland Islands pound', Cod: 'FKP'},
    { id: 77, Country: 'Faroe Islands (Denmark)', Currency: 'Faroese krona', Cod: 'none'},
    { id: 78, Country: 'Fiji', Currency: 'Fijian dollar', Cod: 'FJD'},
    { id: 79, Country: 'Finland', Currency: 'European euro', Cod: 'EUR'},
    { id: 80, Country: 'France', Currency: 'European euro', Cod: 'EUR'},
    { id: 81, Country: 'French Guiana (France)', Currency: 'European euro', Cod: 'EUR'},
    { id: 82, Country: 'French Polynesia (France)', Currency: 'CFP franc', Cod: 'XPF'},
    { id: 83, Country: 'Gabon', Currency: 'Central African CFA franc', Cod: 'XAF'},
    { id: 84, Country: 'Gambia', Currency: 'Gambian dalasi', Cod: 'GMD'},
    { id: 85, Country: 'Georgia', Currency: 'Georgian lari', Cod: 'GEL'},
    { id: 86, Country: 'Germany', Currency: 'European euro', Cod: 'EUR'},
    { id: 87, Country: 'Ghana', Currency: 'Ghanaian cedi', Cod: 'GHS'},
    { id: 88, Country: 'Gibraltar (UK)', Currency: 'Gibraltar pound', Cod: 'GIP'},
    { id: 89, Country: 'Greece', Currency: 'European euro', Cod: 'EUR'},
    { id: 90, Country: 'Greenland (Denmark)', Currency: 'Danish krone', Cod: 'DKK'},
    { id: 91, Country: 'Grenada', Currency: 'East Caribbean dollar', Cod: 'XCD'},
    { id: 92, Country: 'Guadeloupe (France)', Currency: 'European euro', Cod: 'EUR'},
    { id: 93, Country: 'Guam (USA)', Currency: 'United States dollar', Cod: 'USD'},
    { id: 94, Country: 'Guatemala', Currency: 'Guatemalan quetzal', Cod: 'GTQ'},
    { id: 95, Country: 'Guernsey (UK)', Currency: 'Guernsey Pound', Cod: 'GGP'},
    { id: 96, Country: 'Guinea', Currency: 'Guinean franc', Cod: 'GNF'},
    { id: 97, Country: 'Guinea-Bissau', Currency: 'West African CFA franc', Cod: 'XOF'},
    { id: 98, Country: 'Guyana', Currency: 'Guyanese dollar', Cod: 'GYD'},
    { id: 99, Country: 'Haiti', Currency: 'Haitian gourde', Cod: 'HTG'},
    { id: 100, Country: 'Honduras', Currency: 'Honduran lempira', Cod: 'HNL'},
    { id: 101, Country: 'Hong Kong (China)', Currency: 'Hong Kong dollar', Cod: 'HKD'},
    { id: 102, Country: 'Hungary', Currency: 'Hungarian forint', Cod: 'HUF'},
    { id: 103, Country: 'Iceland', Currency: 'Icelandic krona', Cod: 'ISK'},
    { id: 104, Country: 'India', Currency: 'Indian rupee', Cod: 'INR'},
    { id: 105, Country: 'Indonesia', Currency: 'Indonesian rupiah', Cod: 'IDR'},
    { id: 106, Country: 'International Monetary Fund (IMF)', Currency: 'SDR (Special Drawing Right)', Cod: 'XDR'},
    { id: 107, Country: 'Iran', Currency: 'Iranian rial', Cod: 'IRR'},
    { id: 108, Country: 'Iraq', Currency: 'Iraqi dinar', Cod: 'IQD'},
    { id: 109, Country: 'Ireland', Currency: 'European euro', Cod: 'EUR'},
    { id: 110, Country: 'Isle of Man (UK)', Currency: 'Manx pound', Cod: 'IMP'},
    { id: 111, Country: 'Israel', Currency: 'Israeli new shekel', Cod: 'ILS'},
    { id: 112, Country: 'Italy', Currency: 'European euro', Cod: 'EUR'},
    { id: 113, Country: 'Jamaica', Currency: 'Jamaican dollar', Cod: 'JMD'},
    { id: 114, Country: 'Japan', Currency: 'Japanese yen', Cod: 'JPY'},
    { id: 115, Country: 'Jersey (UK)', Currency: 'Jersey pound', Cod: 'JEP'},
    { id: 116, Country: 'Jordan', Currency: 'Jordanian dinar', Cod: 'JOD'},
    { id: 117, Country: 'Kazakhstan', Currency: 'Kazakhstani tenge', Cod: 'KZT'},
    { id: 118, Country: 'Kenya', Currency: 'Kenyan shilling', Cod: 'KES'},
    { id: 119, Country: 'Kiribati', Currency: 'Australian dollar', Cod: 'AUD'},
    { id: 120, Country: 'Kosovo', Currency: 'European euro', Cod: 'EUR'},
    { id: 121, Country: 'Kuwait', Currency: 'Kuwaiti dinar', Cod: 'KWD'},
    { id: 122, Country: 'Kyrgyzstan', Currency: 'Kyrgyzstani som', Cod: 'KGS'},
    { id: 123, Country: 'Laos', Currency: 'Lao kip', Cod: 'LAK'},
    { id: 124, Country: 'Latvia', Currency: 'European euro', Cod: 'EUR'},
    { id: 125, Country: 'Lebanon', Currency: 'Lebanese pound', Cod: 'LBP'},
    { id: 126, Country: 'Lesotho', Currency: 'Lesotho loti', Cod: 'LSL'},
    { id: 127, Country: 'Liberia', Currency: 'Liberian dollar', Cod: 'LRD'},
    { id: 128, Country: 'Libya', Currency: 'Libyan dinar', Cod: 'LYD'},
    { id: 129, Country: 'Liechtenstein', Currency: 'Swiss franc', Cod: 'CHF'},
    { id: 130, Country: 'Lithuania', Currency: 'European euro', Cod: 'EUR'},
    { id: 131, Country: 'Luxembourg', Currency: 'European euro', Cod: 'EUR'},
    { id: 132, Country: 'Macau (China)', Currency: 'Macanese pataca', Cod: 'MOP'},
    { id: 133, Country: 'Madagascar', Currency: 'Malagasy ariary', Cod: 'MGA'},
    { id: 134, Country: 'Malawi', Currency: 'Malawian kwacha', Cod: 'MWK'},
    { id: 135, Country: 'Malaysia', Currency: 'Malaysian ringgit', Cod: 'MYR'},
    { id: 136, Country: 'Maldives', Currency: 'Maldivian rufiyaa', Cod: 'MVR'},
    { id: 137, Country: 'Mali', Currency: 'West African CFA franc', Cod: 'XOF'},
    { id: 138, Country: 'Malta', Currency: 'European euro', Cod: 'EUR'},
    { id: 139, Country: 'Marshall Islands', Currency: 'United States dollar', Cod: 'USD'},
    { id: 140, Country: 'Martinique (France)', Currency: 'European euro', Cod: 'EUR'},
    { id: 141, Country: 'Mauritania', Currency: 'Mauritanian ouguiya', Cod: 'MRU'},
    { id: 142, Country: 'Mauritius', Currency: 'Mauritian rupee', Cod: 'MUR'},
    { id: 143, Country: 'Mayotte (France)', Currency: 'European euro', Cod: 'EUR'},
    { id: 144, Country: 'Mexico', Currency: 'Mexican peso', Cod: 'MXN'},
    { id: 145, Country: 'Micronesia', Currency: 'United States dollar', Cod: 'USD'},
    { id: 146, Country: 'Moldova', Currency: 'Moldovan leu', Cod: 'MDL'},
    { id: 147, Country: 'Monaco', Currency: 'European euro', Cod: 'EUR'},
    { id: 148, Country: 'Mongolia', Currency: 'Mongolian tugrik', Cod: 'MNT'},
    { id: 149, Country: 'Montenegro', Currency: 'European euro', Cod: 'EUR'},
    { id: 150, Country: 'Montserrat (UK)', Currency: 'East Caribbean dollar', Cod: 'XCD'},
    { id: 151, Country: 'Morocco', Currency: 'Moroccan dirham', Cod: 'MAD'},
    { id: 152, Country: 'Mozambique', Currency: 'Mozambican metical', Cod: 'MZN'},
    { id: 153, Country: 'Myanmar', Currency: 'Myanmar kyat', Cod: 'MMK'},
    { id: 154, Country: 'Namibia', Currency: 'Namibian dollar', Cod: 'NAD'},
    { id: 155, Country: 'Nauru', Currency: 'Australian dollar', Cod: 'AUD'},
    { id: 156, Country: 'Nepal', Currency: 'Nepalese rupee', Cod: 'NPR'},
    { id: 157, Country: 'Netherlands', Currency: 'European euro', Cod: 'EUR'},
    { id: 158, Country: 'New Caledonia (France)', Currency: 'CFP franc', Cod: 'XPF'},
    { id: 159, Country: 'New Zealand', Currency: 'New Zealand dollar', Cod: 'NZD'},
    { id: 160, Country: 'Nicaragua', Currency: 'Nicaraguan cordoba', Cod: 'NIO'},
    { id: 161, Country: 'Niger', Currency: 'West African CFA franc', Cod: 'XOF'},
    { id: 162, Country: 'Nigeria', Currency: 'Nigerian naira', Cod: 'NGN'},
    { id: 163, Country: 'Niue (New Zealand)', Currency: 'New Zealand dollar', Cod: 'NZD'},
    { id: 164, Country: 'Norfolk Island (Australia)', Currency: 'Australian dollar', Cod: 'AUD'},
    { id: 165, Country: 'Northern Mariana Islands (USA)', Currency: 'United States dollar', Cod: 'USD'},
    { id: 166, Country: 'North Korea', Currency: 'North Korean won', Cod: 'KPW'},
    { id: 167, Country: 'North Macedonia', Currency: 'Macedonian denar', Cod: 'MKD'},
    { id: 168, Country: 'Norway', Currency: 'Norwegian krone', Cod: 'NOK'},
    { id: 169, Country: 'Oman', Currency: 'Omani rial', Cod: 'OMR'},
    { id: 170, Country: 'Pakistan', Currency: 'Pakistani rupee', Cod: 'PKR'},
    { id: 171, Country: 'Palau', Currency: 'United States dollar', Cod: 'USD'},
    { id: 172, Country: 'Palestine', Currency: 'Israeli new shekel', Cod: 'ILS'},
    { id: 173, Country: 'Panama', Currency: 'United States dollar', Cod: 'USD'},
    { id: 174, Country: 'Papua New Guinea', Currency: 'Papua New Guinean kina', Cod: 'PGK'},
    { id: 175, Country: 'Paraguay', Currency: 'Paraguayan guarani', Cod: 'PYG'},
    { id: 176, Country: 'Peru', Currency: 'Peruvian sol', Cod: 'PEN'},
    { id: 177, Country: 'Philippines', Currency: 'Philippine peso', Cod: 'PHP'},
    { id: 178, Country: 'Pitcairn Islands (UK)', Currency: 'New Zealand dollar', Cod: 'NZD'},
    { id: 179, Country: 'Poland', Currency: 'Polish zloty', Cod: 'PLN'},
    { id: 180, Country: 'Portugal', Currency: 'European euro', Cod: 'EUR'},
    { id: 181, Country: 'Puerto Rico (USA)', Currency: 'United States dollar', Cod: 'USD'},
    { id: 182, Country: 'Qatar', Currency: 'Qatari riyal', Cod: 'QAR'},
    { id: 183, Country: 'Reunion (France)', Currency: 'European euro', Cod: 'EUR'},
    { id: 184, Country: 'Romania', Currency: 'Romanian leu', Cod: 'RON'},
    { id: 185, Country: 'Russia', Currency: 'Russian ruble', Cod: 'RUB'},
    { id: 186, Country: 'Rwanda', Currency: 'Rwandan franc', Cod: 'RWF'},
    { id: 187, Country: 'Saba (Netherlands)', Currency: 'United States dollar', Cod: 'USD'},
    { id: 188, Country: 'Saint Barthelemy (France)', Currency: 'European euro', Cod: 'EUR'},
    { id: 189, Country: 'Saint Helena (UK)', Currency: 'Saint Helena pound', Cod: 'SHP'},
    { id: 190, Country: 'Saint Kitts and Nevis', Currency: 'East Caribbean dollar', Cod: 'XCD'},
    { id: 191, Country: 'Saint Lucia', Currency: 'East Caribbean dollar', Cod: 'XCD'},
    { id: 192, Country: 'Saint Martin (France)', Currency: 'European euro', Cod: 'EUR'},
    { id: 193, Country: 'Saint Pierre and Miquelon (France)', Currency: 'European euro', Cod: 'EUR'},
    { id: 194, Country: 'Saint Vincent and the Grenadines', Currency: 'East Caribbean dollar', Cod: 'XCD'},
    { id: 195, Country: 'Samoa', Currency: 'Samoan tala', Cod: 'WST'},
    { id: 196, Country: 'San Marino', Currency: 'European euro', Cod: 'EUR'},
    { id: 197, Country: 'Sao Tome and Principe', Currency: 'Sao Tome and Principe dobra', Cod: 'STN'},
    { id: 198, Country: 'Saudi Arabia', Currency: 'Saudi Arabian riyal', Cod: 'SAR'},
    { id: 199, Country: 'Senegal', Currency: 'West African CFA franc', Cod: 'XOF'},
    { id: 200, Country: 'Serbia', Currency: 'Serbian dinar', Cod: 'RSD'},
    { id: 201, Country: 'Seychelles', Currency: 'Seychellois rupee', Cod: 'SCR'},
    { id: 202, Country: 'Sierra Leone', Currency: 'Sierra Leonean leone', Cod: 'SLL'},
    { id: 203, Country: 'Singapore', Currency: 'Singapore dollar', Cod: 'SGD'},
    { id: 204, Country: 'Sint Eustatius (Netherlands)', Currency: 'United States dollar', Cod: 'USD'},
    { id: 205, Country: 'Sint Maarten (Netherlands)', Currency: 'Netherlands Antillean guilder', Cod: 'ANG'},
    { id: 206, Country: 'Slovakia', Currency: 'European euro', Cod: 'EUR'},
    { id: 207, Country: 'Slovenia', Currency: 'European euro', Cod: 'EUR'},
    { id: 208, Country: 'Solomon Islands', Currency: 'Solomon Islands dollar', Cod: 'SBD'},
    { id: 209, Country: 'Somalia', Currency: 'Somali shilling', Cod: 'SOS'},
    { id: 210, Country: 'South Africa', Currency: 'South African rand', Cod: 'ZAR'},
    { id: 211, Country: 'South Georgia Island (UK)', Currency: 'Pound sterling', Cod: 'GBP'},
    { id: 212, Country: 'South Korea', Currency: 'South Korean won', Cod: 'KRW'},
    { id: 213, Country: 'South Sudan', Currency: 'South Sudanese pound', Cod: 'SSP'},
    { id: 214, Country: 'Spain', Currency: 'European euro', Cod: 'EUR'},
    { id: 215, Country: 'Sri Lanka', Currency: 'Sri Lankan rupee', Cod: 'LKR'},
    { id: 216, Country: 'Sudan', Currency: 'Sudanese pound', Cod: 'SDG'},
    { id: 217, Country: 'Suriname', Currency: 'Surinamese dollar', Cod: 'SRD'},
    { id: 218, Country: 'Svalbard and Jan Mayen (Norway)', Currency: 'Norwegian krone', Cod: 'NOK'},
    { id: 219, Country: 'Sweden', Currency: 'Swedish krona', Cod: 'SEK'},
    { id: 220, Country: 'Switzerland', Currency: 'Swiss franc', Cod: 'CHF'},
    { id: 221, Country: 'Syria', Currency: 'Syrian pound', Cod: 'SYP'},
    { id: 222, Country: 'Taiwan', Currency: 'New Taiwan dollar', Cod: 'TWD'},
    { id: 223, Country: 'Tajikistan', Currency: 'Tajikistani somoni', Cod: 'TJS'},
    { id: 224, Country: 'Tanzania', Currency: 'Tanzanian shilling', Cod: 'TZS'},
    { id: 225, Country: 'Thailand', Currency: 'Thai baht', Cod: 'THB'},
    { id: 226, Country: 'Timor-Leste', Currency: 'United States dollar', Cod: 'USD'},
    { id: 227, Country: 'Togo', Currency: 'West African CFA franc', Cod: 'XOF'},
    { id: 228, Country: 'Tokelau (New Zealand)', Currency: 'New Zealand dollar', Cod: 'NZD'},
    { id: 229, Country: 'Tonga', Currency: 'Tongan pa’anga', Cod: 'TOP'},
    { id: 230, Country: 'Trinidad and Tobago', Currency: 'Trinidad and Tobago dollar', Cod: 'TTD'},
    { id: 231, Country: 'Tristan da Cunha (UK)', Currency: 'Pound sterling', Cod: 'GBP'},
    { id: 232, Country: 'Tunisia', Currency: 'Tunisian dinar', Cod: 'TND'},
    { id: 233, Country: 'Turkey', Currency: 'Turkish lira', Cod: 'TRY'},
    { id: 234, Country: 'Turkmenistan', Currency: 'Turkmen manat', Cod: 'TMT'},
    { id: 235, Country: 'Turks and Caicos Islands (UK)', Currency: 'United States dollar', Cod: 'USD'},
    { id: 236, Country: 'Tuvalu', Currency: 'Australian dollar', Cod: 'AUD'},
    { id: 237, Country: 'Uganda', Currency: 'Ugandan shilling', Cod: 'UGX'},
    { id: 238, Country: 'Ukraine', Currency: 'Ukrainian hryvnia', Cod: 'UAH'},
    { id: 239, Country: 'United Arab Emirates', Currency: 'UAE dirham', Cod: 'AED'},
    { id: 240, Country: 'United Kingdom', Currency: 'Pound sterling', Cod: 'GBP'},
    { id: 241, Country: 'United States of America', Currency: 'United States dollar', Cod: 'USD'},
    { id: 242, Country: 'Uruguay', Currency: 'Uruguayan peso', Cod: 'UYU'},
    { id: 243, Country: 'US Virgin Islands (USA)', Currency: 'United States dollar', Cod: 'USD'},
    { id: 244, Country: 'Uzbekistan', Currency: 'Uzbekistani som', Cod: 'UZS'},
    { id: 245, Country: 'Vanuatu', Currency: 'Vanuatu vatu', Cod: 'VUV'},
    { id: 246, Country: 'Vatican City (Holy See)', Currency: 'European euro', Cod: 'EUR'},
    { id: 247, Country: 'Venezuela', Currency: 'Venezuelan bolivar', Cod: 'VES'},
    { id: 248, Country: 'Vietnam', Currency: 'Vietnamese dong', Cod: 'VND'},
    { id: 249, Country: 'Wake Island (USA)', Currency: 'United States dollar', Cod: 'USD'},
    { id: 250, Country: 'Wallis and Futuna (France)', Currency: 'CFP franc', Cod: 'XPF'},
    { id: 251, Country: 'Yemen', Currency: 'Yemeni rial', Cod: 'YER'},
    { id: 252, Country: 'Zambia', Currency: 'Zambian kwacha', Cod: 'ZMW'},
    { id: 253, Country: 'Zimbabwe', Currency: 'United States dollar', Cod: 'USD'},
  ],

  Vue.prototype.prefix = [
    { id: '1', Country: 'Afghanistan', Prefix: '+93'},
    { id: '2', Country: 'Albania', Prefix: '+355'},
    { id: '3', Country: 'Algeria', Prefix: '+213'},
    { id: '4', Country: 'American Samoa', Prefix: '+684'},
    { id: '5', Country: 'Andorra', Prefix: '+376'},
    { id: '6', Country: 'Angola', Prefix: '+244'},
    { id: '7', Country: 'Anguilla', Prefix: '+1-264'},
    { id: '8', Country: 'Antarctica', Prefix: '+672'},
    { id: '9', Country: 'Antigua', Prefix: '+1-268'},
    { id: '10', Country: 'Argentina', Prefix: '+54'},
    { id: '11', Country: 'Armenia', Prefix: '+374'},
    { id: '12', Country: 'Aruba', Prefix: '+297'},
    { id: '13', Country: 'Ascension', Prefix: '+247'},
    { id: '14', Country: 'Australia', Prefix: '+61'},
    { id: '15', Country: 'Australian External Territories', Prefix: '+672'},
    { id: '16', Country: 'Austria', Prefix: '+43'},
    { id: '17', Country: 'Azerbaijan', Prefix: '+994'},
    { id: '18', Country: 'Bahamas', Prefix: '+1-242'},
    { id: '19', Country: 'Bahrain', Prefix: '+973'},
    { id: '20', Country: 'Bangladesh', Prefix: '+880'},
    { id: '21', Country: 'Barbados', Prefix: '+-245'},
    { id: '22', Country: 'Belarus', Prefix: '+375'},
    { id: '23', Country: 'Belgium', Prefix: '+32'},
    { id: '24', Country: 'Belize', Prefix: '+501'},
    { id: '25', Country: 'Benin', Prefix: '+229'},
    { id: '26', Country: 'Bermuda', Prefix: '+1-441'},
    { id: '27', Country: 'Bhutan', Prefix: '+975'},
    { id: '28', Country: 'Bolivia', Prefix: '+591'},
    { id: '29', Country: 'Bosnia & Herzegovina', Prefix: '+387'},
    { id: '30', Country: 'Botswana', Prefix: '+267'},
    { id: '31', Country: 'Brazil', Prefix: '+55'},
    { id: '32', Country: 'British Virgin Islands', Prefix: '+1-284'},
    { id: '33', Country: 'Brunei Darussalam', Prefix: '+673'},
    { id: '34', Country: 'Bulgaria', Prefix: '+359'},
    { id: '35', Country: 'Burkina Faso', Prefix: '+226'},
    { id: '36', Country: 'Burundi', Prefix: '+257'},
    { id: '37', Country: 'Cambodia', Prefix: '+855'},
    { id: '38', Country: 'Cameroon', Prefix: '+237'},
    { id: '39', Country: 'Canada', Prefix: '+1'},
    { id: '40', Country: 'Cape Verde Islands', Prefix: '+238'},
    { id: '41', Country: 'Cayman Islands', Prefix: '+1-345'},
    { id: '42', Country: 'Central African Republic', Prefix: '+236'},
    { id: '43', Country: 'Chad', Prefix: '+235'},
    { id: '44', Country: 'Chatham Islands (New Zealand)', Prefix: '+64'},
    { id: '45', Country: 'Chile', Prefix: '+56'},
    { id: '46', Country: 'China (PRC)', Prefix: '+86'},
    { id: '47', Country: 'Christmas Island', Prefix: '+61'},
    { id: '48', Country: 'Colombia', Prefix: '+57'},
    { id: '49', Country: 'Comoros', Prefix: '+269'},
    { id: '50', Country: 'Congo', Prefix: '+242'},
    { id: '51', Country: 'Congo(Democratic Republic of)', Prefix: '+243'},
    { id: '52', Country: 'Cook Islands', Prefix: '+682'},
    { id: '53', Country: 'Costa Rica', Prefix: '+506'},
    { id: '54', Country: 'Cote d\'Ivoire (Ivory Coast)', Prefix: '+225'},
    { id: '55', Country: 'Croatia', Prefix: '+385'},
    { id: '56', Country: 'Cuba', Prefix: '+53'},
    { id: '57', Country: 'Cyprus', Prefix: '+357'},
    { id: '58', Country: 'Czech Republic', Prefix: '+420'},
    { id: '59', Country: 'Denmark', Prefix: '+45'},
    { id: '60', Country: 'Diego Garcia', Prefix: '+246'},
    { id: '61', Country: 'Djibouti', Prefix: '+253'},
    { id: '62', Country: 'Dominica', Prefix: '+1-767'},
    { id: '63', Country: 'Dominican Republic', Prefix: '+1-809'},
    { id: '64', Country: 'East Timor', Prefix: '+670'},
    { id: '65', Country: 'Easter Island', Prefix: '+56'},
    { id: '66', Country: 'Ecuador', Prefix: '+593'},
    { id: '67', Country: 'Egypt', Prefix: '+20'},
    { id: '68', Country: 'El Salvador', Prefix: '+503'},
    { id: '69', Country: 'Equatorial Guinea', Prefix: '+240'},
    { id: '70', Country: 'Eritrea', Prefix: '+291'},
    { id: '71', Country: 'Estonia', Prefix: '+372'},
    { id: '72', Country: 'Ethiopia', Prefix: '+251'},
    { id: '73', Country: 'Falkland Islands', Prefix: '+500'},
    { id: '74', Country: 'Faroe Islands', Prefix: '+298'},
    { id: '75', Country: 'Fiji Islands', Prefix: '+679'},
    { id: '76', Country: 'Finland', Prefix: '+358'},
    { id: '77', Country: 'France', Prefix: '+33'},
    { id: '78', Country: 'French Antilles', Prefix: '+596'},
    { id: '79', Country: 'French Guiana', Prefix: '+594'},
    { id: '80', Country: 'French Polynesia', Prefix: '+689'},
    { id: '81', Country: 'Gabonese Republic', Prefix: '+241'},
    { id: '82', Country: 'Gambia', Prefix: '+220'},
    { id: '83', Country: 'Georgia', Prefix: '+995'},
    { id: '84', Country: 'Germany', Prefix: '+49'},
    { id: '85', Country: 'Ghana', Prefix: '+233'},
    { id: '86', Country: 'Gibraltar', Prefix: '+350'},
    { id: '87', Country: 'Greece', Prefix: '+30'},
    { id: '88', Country: 'Greenland', Prefix: '+299'},
    { id: '89', Country: 'Grenada', Prefix: '+1-473'},
    { id: '90', Country: 'Guadeloupe', Prefix: '+590'},
    { id: '91', Country: 'Guam', Prefix: '+1-671'},
    { id: '92', Country: 'Guatemala', Prefix: '+502'},
    { id: '93', Country: 'Guinea Bissau', Prefix: '+245'},
    { id: '94', Country: 'Guinea', Prefix: '+224'},
    { id: '95', Country: 'Guyana', Prefix: '+592'},
    { id: '96', Country: 'Haiti', Prefix: '+509'},
    { id: '97', Country: 'Honduras', Prefix: '+504'},
    { id: '98', Country: 'Hong Kong', Prefix: '+852'},
    { id: '99', Country: 'Hungary', Prefix: '+36'},
    { id: '100', Country: 'Iceland', Prefix: '+354'},
    { id: '101', Country: 'India', Prefix: '+91'},
    { id: '102', Country: 'Indonesia', Prefix: '+62'},
    { id: '103', Country: 'Iran', Prefix: '+98'},
    { id: '104', Country: 'Iraq', Prefix: '+964'},
    { id: '105', Country: 'Ireland', Prefix: '+353'},
    { id: '106', Country: 'Israel', Prefix: '+972'},
    { id: '107', Country: 'Italy', Prefix: '+39'},
    { id: '108', Country: 'Jamaica', Prefix: '+1-876'},
    { id: '109', Country: 'Japan', Prefix: '+81'},
    { id: '110', Country: 'Jordan', Prefix: '+962'},
    { id: '111', Country: 'Kazakhstan', Prefix: '+7'},
    { id: '112', Country: 'Kenya', Prefix: '+254'},
    { id: '113', Country: 'Kiribati', Prefix: '+686'},
    { id: '114', Country: 'Korea (North)', Prefix: '+850'},
    { id: '115', Country: 'Korea (South)', Prefix: '+82'},
    { id: '116', Country: 'Kuwait', Prefix: '+965'},
    { id: '117', Country: 'Kyrgyz Republic', Prefix: '+996'},
    { id: '118', Country: 'Laos', Prefix: '+856'},
    { id: '119', Country: 'Latvia', Prefix: '+371'},
    { id: '120', Country: 'Lebanon', Prefix: '+961'},
    { id: '121', Country: 'Lesotho', Prefix: '+266'},
    { id: '122', Country: 'Liberia', Prefix: '+231'},
    { id: '123', Country: 'Libya', Prefix: '+218'},
    { id: '124', Country: 'Liechtenstein', Prefix: '+423'},
    { id: '125', Country: 'Lithuania', Prefix: '+370'},
    { id: '126', Country: 'Luxembourg', Prefix: '+352'},
    { id: '127', Country: 'Macao', Prefix: '+853'},
    { id: '128', Country: 'Macedonia', Prefix: '+389'},
    { id: '129', Country: 'Madagascar', Prefix: '+261'},
    { id: '130', Country: 'Malawi', Prefix: '+265'},
    { id: '131', Country: 'Malaysia', Prefix: '+60'},
    { id: '132', Country: 'Maldives', Prefix: '+960'},
    { id: '133', Country: 'Mali Republic', Prefix: '+223'},
    { id: '134', Country: 'Malta', Prefix: '+356'},
    { id: '135', Country: 'Marshall Islands', Prefix: '+692'},
    { id: '136', Country: 'Martinique', Prefix: '+596'},
    { id: '137', Country: 'Mauritania', Prefix: '+222'},
    { id: '138', Country: 'Mauritius', Prefix: '+230'},
    { id: '139', Country: 'Mayotte Island', Prefix: '+269'},
    { id: '140', Country: 'Mexico', Prefix: '+52'},
    { id: '141', Country: 'Micronesia', Prefix: '+691'},
    { id: '142', Country: 'Midway Island', Prefix: '+1-808'},
    { id: '143', Country: 'Moldova', Prefix: '+373'},
    { id: '144', Country: 'Monaco', Prefix: '+377'},
    { id: '145', Country: 'Mongolia', Prefix: '+976'},
    { id: '146', Country: 'Montserrat', Prefix: '+1-664'},
    { id: '147', Country: 'Morocco', Prefix: '+212'},
    { id: '148', Country: 'Mozambique', Prefix: '+258'},
    { id: '149', Country: 'Myanmar', Prefix: '+95'},
    { id: '150', Country: 'Namibia', Prefix: '+264'},
    { id: '151', Country: 'Nauru', Prefix: '+674'},
    { id: '152', Country: 'Nepal', Prefix: '+977'},
    { id: '153', Country: 'Netherlands', Prefix: '+31'},
    { id: '154', Country: 'Netherlands Antilles', Prefix: '+599'},
    { id: '155', Country: 'Nevis', Prefix: '+1-869'},
    { id: '156', Country: 'New Caledonia', Prefix: '+687'},
    { id: '157', Country: 'New Zealand', Prefix: '+64'},
    { id: '158', Country: 'Nicaragua', Prefix: '+505'},
    { id: '159', Country: 'Niger', Prefix: '+227'},
    { id: '160', Country: 'Nigeria', Prefix: '+234'},
    { id: '161', Country: 'Niue', Prefix: '+683'},
    { id: '162', Country: 'Norfolk Island', Prefix: '+672'},
    { id: '163', Country: 'Northern Marine Islands', Prefix: '+1-670'},
    { id: '164', Country: 'Norway', Prefix: '+47'},
    { id: '165', Country: 'Oman', Prefix: '+968'},
    { id: '166', Country: 'Pakistan', Prefix: '+92'},
    { id: '167', Country: 'Palau', Prefix: '+680'},
    { id: '168', Country: 'Panama', Prefix: '+507'},
    { id: '169', Country: 'Papua New Guinea', Prefix: '+675'},
    { id: '170', Country: 'Paraguay', Prefix: '+595'},
    { id: '171', Country: 'Peru', Prefix: '+51'},
    { id: '172', Country: 'Philippines', Prefix: '+63'},
    { id: '173', Country: 'Poland', Prefix: '+48'},
    { id: '174', Country: 'Portugal', Prefix: '+351'},
    { id: '175', Country: 'Puerto Rico', Prefix: '+1-787'},
    { id: '176', Country: 'Qatar', Prefix: '+974'},
    { id: '177', Country: 'Reunion Island', Prefix: '+262'},
    { id: '178', Country: 'Romania', Prefix: '+40'},
    { id: '179', Country: 'Russia', Prefix: '+7'},
    { id: '180', Country: 'Rwanda', Prefix: '+250'},
    { id: '181', Country: 'St. Helena', Prefix: '+290'},
    { id: '182', Country: 'St. Kitts/Nevis', Prefix: '+1-869'},
    { id: '183', Country: 'St Lucia', Prefix: '+1-758'},
    { id: '184', Country: 'St. Pierre & Miquelon', Prefix: '+508'},
    { id: '185', Country: 'St. Vincent & Grenadines', Prefix: '+1-784'},
    { id: '186', Country: 'San Marino', Prefix: '+378'},
    { id: '187', Country: 'Sao Tomo and Principe', Prefix: '+239'},
    { id: '188', Country: 'Saudi Arabia', Prefix: '+966'},
    { id: '189', Country: 'Senegal', Prefix: '+221'},
    { id: '190', Country: 'Serbia & Montenegro', Prefix: '+381'},
    { id: '191', Country: 'Seychelles Republic', Prefix: '+248'},
    { id: '192', Country: 'Sierra Leone', Prefix: '+232'},
    { id: '193', Country: 'Singapore', Prefix: '+65'},
    { id: '194', Country: 'Slovak Republic', Prefix: '+421'},
    { id: '195', Country: 'Slovenia', Prefix: '+386'},
    { id: '196', Country: 'Solomon Islands', Prefix: '+677'},
    { id: '197', Country: 'Somalia', Prefix: '+252'},
    { id: '198', Country: 'South Africa', Prefix: '+27'},
    { id: '199', Country: 'Spain', Prefix: '+34'},
    { id: '200', Country: 'Sri Lanka', Prefix: '+94'},
    { id: '201', Country: 'Sudan', Prefix: '+249'},
    { id: '202', Country: 'Suriname', Prefix: '+597'},
    { id: '203', Country: 'Swaziland', Prefix: '+268'},
    { id: '204', Country: 'Sweden', Prefix: '+46'},
    { id: '205', Country: 'Switzerland', Prefix: '+41'},
    { id: '206', Country: 'Syria', Prefix: '+963'},
    { id: '207', Country: 'Taiwan', Prefix: '+886'},
    { id: '208', Country: 'Tajikistan', Prefix: '+992'},
    { id: '209', Country: 'Tanzania', Prefix: '+255'},
    { id: '210', Country: 'Thailand', Prefix: '+66'},
    { id: '211', Country: 'Togolese Republic', Prefix: '+228'},
    { id: '212', Country: 'Tokelau', Prefix: '+690'},
    { id: '213', Country: 'Tonga Islands', Prefix: '+676'},
    { id: '214', Country: 'Trinidad & Tobago', Prefix: '+-867'},
    { id: '215', Country: 'Tunisia', Prefix: '+216'},
    { id: '216', Country: 'Turkey', Prefix: '+90'},
    { id: '217', Country: 'Turkmenistan', Prefix: '+993'},
    { id: '218', Country: 'Turks & Caicos Islands', Prefix: '+1-649'},
    { id: '219', Country: 'Tuvalu', Prefix: '+688'},
    { id: '220', Country: 'Uganda', Prefix: '+256'},
    { id: '221', Country: 'Ukraine', Prefix: '+380'},
    { id: '222', Country: 'United Arab Emirates', Prefix: '+971'},
    { id: '223', Country: 'United Kingdom', Prefix: '+44'},
    { id: '224', Country: 'USA', Prefix: '+1'},
    { id: '225', Country: 'US Virgin Islands', Prefix: '+1-340'},
    { id: '226', Country: 'Uruguay', Prefix: '+598'},
    { id: '227', Country: 'Uzbekistan', Prefix: '+998'},
    { id: '228', Country: 'Vanuatu', Prefix: '+678'},
    { id: '229', Country: 'Vietnam', Prefix: '+84'},
    { id: '230', Country: 'Venezuela', Prefix: '+58'},
    { id: '231', Country: 'Yemen', Prefix: '+998'},
    { id: '232', Country: 'Zambia', Prefix: '+260'},
    { id: '233', Country: 'Zimbabwe', Prefix: '+263'},
  ]
 
  Vue.prototype.size = [
    { id: 1, des: 'S', },
    { id: 2, des: 'M', },
    { id: 3, des: 'L', },
    { id: 4, des: 'XL', },
    { id: 5, des: 'XXL', },
  ],

  Vue.prototype.type_vaccination = [
    { id: 1, des: 'Pfizer', },
    { id: 2, des: 'Moderna', },
    { id: 3, des: 'Jassen', },
    { id: 4, des: 'AstraZeneca', },
    { id: 5, des: 'Curevac', },
    { id: 6, des: 'Sputink', },
    { id: 7, des: 'Sinovac', },
  ],

  Vue.prototype.fight_stance = [
    { id: 1, des: 'Orthodox', },
    { id: 2, des: 'Southpaw', },
  ],
  
  Vue.prototype.meal_day = [
    { id: 1, des: 'Meal', },
    { id: 2, des: 'Day', },
  ],

  Vue.prototype.diem_ticket = [
    { id: 1, des: 'Diem', },
    { id: 2, des: 'Ticket', },
  ],

  Vue.prototype.credential = [
    { id: 1, des: 'Decision Maker', },
    { id: 2, des: 'All Area', },
    { id: 3, des: 'Athlete', },
    { id: 4, des: 'Coach', },
    { id: 5, des: 'Commission', },
    { id: 6, des: 'Staff', },
    { id: 7, des: 'Guest', },
    { id: 8, des: 'Production', },
  ],

  Vue.prototype.covid_state = [
    { id: 1, des: 'Negative', },
    { id: 2, des: 'Poitive', },
    { id: 3, des: 'Not Arrived', },
  ],


  //creare anche tabella per visualizzazione report
  Vue.prototype.ev_contact_event_type = [
    { id: 1, des: 'Flight / Car service', },
    { id: 2, des: 'Hotel / Tickets / Per Diem', },
    { id: 3, des: 'Medicals', },
    { id: 4, des: 'Covid Test', },
  ],

  Vue.prototype.pre_main = [
    { id: 1, des: 'PRELIM', },
    { id: 2, des: 'MAIN CARD', },
  ],

  Vue.prototype.weight_division = [
    { id: 1, des: 'Flyweight', lbs: '125', kg: '56', type: 'Female', },
    { id: 2, des: 'Bantamweight', lbs: '135', kg: '61', type: 'Male',},
    { id: 3, des: 'Featherweight', lbs: '145', kg: '65', type: 'All', },
    { id: 4, des: 'Lightweight', lbs: '155', kg: '70', type: 'Male', },
    { id: 5, des: 'Welterweight', lbs: '170', kg: '77', type: 'Male', },
    { id: 6, des: 'Middleweight', lbs: '185', kg: '84', type: 'Male',},
    { id: 7, des: 'Light Heavyweight', lbs: '205', kg: '93', type: 'Male', },
    { id: 8, des: 'Heavyweight', lbs: '265', kg: '120', type: 'Male',},
    { id: 9, des: 'Catch Weight', lbs: '-', kg: '-', type: 'All',},
  ],

  Vue.prototype.exam_type = [
    { id: 1, des: 'EKG'},
    { id: 2, des: 'EGG'},
    { id: 3, des: 'MRI'},
    { id: 4, des: 'PHYSICAL'},
    { id: 5, des: 'BLOOD TEST'},
    { id: 6, des: 'EYE EXAM'},
    { id: 99, des: 'OTHER'},
  ],

  Vue.prototype.address_book_type = [
    { id: '1', des: 'HOTEL'},
    { id: '2', des: 'AIRPORT'},
    { id: '3', des: 'VENUE'},
    { id: '4', des: 'FEDERATION'},
    { id: '99', des: 'OTHER'},
  ],
  

  Vue.prototype.utc = [
    { id:'1', country: 'Afghanistan', city: 'Kabul', UTC: '+04:30', UTC_dec: '4,5' },
    { id:'2', country: 'Aland Islands', city: 'Mariehamn', UTC: '+03:00', UTC_dec: '3' },
    { id:'3', country: 'Albania', city: 'Tirane', UTC: '+02:00', UTC_dec: '2' },
    { id:'4', country: 'Algeria', city: 'Algiers', UTC: '+01:00', UTC_dec: '1' },
    { id:'5', country: 'American Samoa', city: 'Pago_Pago', UTC: '-11:00', UTC_dec: '-11' },
    { id:'6', country: 'Andorra', city: 'Andorra', UTC: '+02:00', UTC_dec: '2' },
    { id:'7', country: 'Angola', city: 'Luanda', UTC: '+01:00', UTC_dec: '1' },
    { id:'8', country: 'Anguilla', city: 'Anguilla', UTC: '-04:00', UTC_dec: '-4' },
    { id:'9', country: 'Antarctica', city: 'Troll', UTC: '+02:00', UTC_dec: '2' },
    { id:'10', country: 'Antarctica', city: 'Palmer', UTC: '-03:00', UTC_dec: '-3' },
    { id:'11', country: 'Antarctica', city: 'Rothera', UTC: '-03:00', UTC_dec: '-3' },
    { id:'12', country: 'Antarctica', city: 'Syowa', UTC: '+03:00', UTC_dec: '3' },
    { id:'13', country: 'Antarctica', city: 'Mawson', UTC: '+05:00', UTC_dec: '5' },
    { id:'14', country: 'Antarctica', city: 'Vostok', UTC: '+06:00', UTC_dec: '6' },
    { id:'15', country: 'Antarctica', city: 'Davis', UTC: '+07:00', UTC_dec: '7' },
    { id:'16', country: 'Antarctica', city: 'Casey', UTC: '+08:00', UTC_dec: '8' },
    { id:'17', country: 'Antarctica', city: 'DumontDUrville', UTC: '+10:00', UTC_dec: '10' },
    { id:'18', country: 'Antarctica', city: 'McMurdo', UTC: '+12:00', UTC_dec: '12' },
    { id:'19', country: 'Antigua And Barbuda', city: 'Antigua', UTC: '-04:00', UTC_dec: '-4' },
    { id:'20', country: 'Argentina', city: 'Buenos_Aires', UTC: '-03:00', UTC_dec: '-3' },
    { id:'21', country: 'Argentina', city: 'Catamarca', UTC: '-03:00', UTC_dec: '-3' },
    { id:'22', country: 'Argentina', city: 'Cordoba', UTC: '-03:00', UTC_dec: '-3' },
    { id:'23', country: 'Argentina', city: 'Jujuy', UTC: '-03:00', UTC_dec: '-3' },
    { id:'24', country: 'Argentina', city: 'La_Rioja', UTC: '-03:00', UTC_dec: '-3' },
    { id:'25', country: 'Argentina', city: 'Mendoza', UTC: '-03:00', UTC_dec: '-3' },
    { id:'26', country: 'Argentina', city: 'Rio_Gallegos', UTC: '-03:00', UTC_dec: '-3' },
    { id:'27', country: 'Argentina', city: 'Salta', UTC: '-03:00', UTC_dec: '-3' },
    { id:'28', country: 'Argentina', city: 'San_Juan', UTC: '-03:00', UTC_dec: '-3' },
    { id:'29', country: 'Argentina', city: 'San_Luis', UTC: '-03:00', UTC_dec: '-3' },
    { id:'30', country: 'Argentina', city: 'Tucuman', UTC: '-03:00', UTC_dec: '-3' },
    { id:'31', country: 'Argentina', city: 'Ushuaia', UTC: '-03:00', UTC_dec: '-3' },
    { id:'32', country: 'Armenia', city: 'Yerevan', UTC: '+04:00', UTC_dec: '4' },
    { id:'33', country: 'Aruba', city: 'Aruba', UTC: '-04:00', UTC_dec: '-4' },
    { id:'34', country: 'Australia', city: 'Perth', UTC: '+08:00', UTC_dec: '8' },
    { id:'35', country: 'Australia', city: 'Eucla', UTC: '+08:45', UTC_dec: '8,75' },
    { id:'36', country: 'Australia', city: 'Adelaide', UTC: '+09:30', UTC_dec: '9,5' },
    { id:'37', country: 'Australia', city: 'Broken_Hill', UTC: '+09:30', UTC_dec: '9,5' },
    { id:'38', country: 'Australia', city: 'Darwin', UTC: '+09:30', UTC_dec: '9,5' },
    { id:'39', country: 'Australia', city: 'Brisbane', UTC: '+10:00', UTC_dec: '10' },
    { id:'40', country: 'Australia', city: 'Currie', UTC: '+10:00', UTC_dec: '10' },
    { id:'41', country: 'Australia', city: 'Hobart', UTC: '+10:00', UTC_dec: '10' },
    { id:'42', country: 'Australia', city: 'Lindeman', UTC: '+10:00', UTC_dec: '10' },
    { id:'43', country: 'Australia', city: 'Melbourne', UTC: '+10:00', UTC_dec: '10' },
    { id:'44', country: 'Australia', city: 'Sydney', UTC: '+10:00', UTC_dec: '10' },
    { id:'45', country: 'Australia', city: 'Lord_Howe', UTC: '+10:30', UTC_dec: '10,5' },
    { id:'46', country: 'Australia', city: 'Macquarie', UTC: '+11:00', UTC_dec: '11' },
    { id:'47', country: 'Austria', city: 'Vienna', UTC: '+02:00', UTC_dec: '2' },
    { id:'48', country: 'Azerbaijan', city: 'Baku', UTC: '+04:00', UTC_dec: '4' },
    { id:'49', country: 'Bahamas', city: 'Nassau', UTC: '-04:00', UTC_dec: '-4' },
    { id:'50', country: 'Bahrain', city: 'Bahrain', UTC: '+03:00', UTC_dec: '3' },
    { id:'51', country: 'Bangladesh', city: 'Dhaka', UTC: '+06:00', UTC_dec: '6' },
    { id:'52', country: 'Barbados', city: 'Barbados', UTC: '-04:00', UTC_dec: '-4' },
    { id:'53', country: 'Belarus', city: 'Minsk', UTC: '+03:00', UTC_dec: '3' },
    { id:'54', country: 'Belgium', city: 'Brussels', UTC: '+02:00', UTC_dec: '2' },
    { id:'55', country: 'Belize', city: 'Belize', UTC: '-06:00', UTC_dec: '-6' },
    { id:'56', country: 'Benin', city: 'Porto-Novo', UTC: '+01:00', UTC_dec: '1' },
    { id:'57', country: 'Bermuda', city: 'Bermuda', UTC: '-03:00', UTC_dec: '-3' },
    { id:'58', country: 'Bhutan', city: 'Thimphu', UTC: '+06:00', UTC_dec: '6' },
    { id:'59', country: 'Bolivia', city: 'La_Paz', UTC: '-04:00', UTC_dec: '-4' },
    { id:'60', country: 'Bonaire, Saint Eustatius And Saba', city: 'Kralendijk', UTC: '-04:00', UTC_dec: '-4' },
    { id:'61', country: 'Bosnia And Herzegovina', city: 'Sarajevo', UTC: '+02:00', UTC_dec: '2' },
    { id:'62', country: 'Botswana', city: 'Gaborone', UTC: '+02:00', UTC_dec: '2' },
    { id:'63', country: 'Brazil', city: 'Noronha', UTC: '-02:00', UTC_dec: '-2' },
    { id:'64', country: 'Brazil', city: 'Araguaina', UTC: '-03:00', UTC_dec: '-3' },
    { id:'65', country: 'Brazil', city: 'Bahia', UTC: '-03:00', UTC_dec: '-3' },
    { id:'66', country: 'Brazil', city: 'Belem', UTC: '-03:00', UTC_dec: '-3' },
    { id:'67', country: 'Brazil', city: 'Fortaleza', UTC: '-03:00', UTC_dec: '-3' },
    { id:'68', country: 'Brazil', city: 'Maceio', UTC: '-03:00', UTC_dec: '-3' },
    { id:'69', country: 'Brazil', city: 'Recife', UTC: '-03:00', UTC_dec: '-3' },
    { id:'70', country: 'Brazil', city: 'Santarem', UTC: '-03:00', UTC_dec: '-3' },
    { id:'71', country: 'Brazil', city: 'Sao_Paulo', UTC: '-03:00', UTC_dec: '-3' },
    { id:'72', country: 'Brazil', city: 'Boa_Vista', UTC: '-04:00', UTC_dec: '-4' },
    { id:'73', country: 'Brazil', city: 'Campo_Grande', UTC: '-04:00', UTC_dec: '-4' },
    { id:'74', country: 'Brazil', city: 'Cuiaba', UTC: '-04:00', UTC_dec: '-4' },
    { id:'75', country: 'Brazil', city: 'Manaus', UTC: '-04:00', UTC_dec: '-4' },
    { id:'76', country: 'Brazil', city: 'Porto_Velho', UTC: '-04:00', UTC_dec: '-4' },
    { id:'77', country: 'Brazil', city: 'Eirunepe', UTC: '-05:00', UTC_dec: '-5' },
    { id:'78', country: 'Brazil', city: 'Rio_Branco', UTC: '-05:00', UTC_dec: '-5' },
    { id:'79', country: 'British Indian Ocean Territory', city: 'Chagos', UTC: '+06:00', UTC_dec: '6' },
    { id:'80', country: 'British Virgin Islands', city: 'Tortola', UTC: '-04:00', UTC_dec: '-4' },
    { id:'81', country: 'Brunei', city: 'Brunei', UTC: '+08:00', UTC_dec: '8' },
    { id:'82', country: 'Bulgaria', city: 'Sofia', UTC: '+03:00', UTC_dec: '3' },
    { id:'83', country: 'Burkina Faso', city: 'Ouagadougou', UTC: '0:0', UTC_dec: '0' },
    { id:'84', country: 'Burundi', city: 'Bujumbura', UTC: '+02:00', UTC_dec: '2' },
    { id:'85', country: 'Cambodia', city: 'Phnom_Penh', UTC: '+07:00', UTC_dec: '7' },
    { id:'86', country: 'Cameroon', city: 'Douala', UTC: '+01:00', UTC_dec: '1' },
    { id:'87', country: 'Canada', city: 'St_Johns', UTC: '-02:30', UTC_dec: '-2,5' },
    { id:'88', country: 'Canada', city: 'Glace_Bay', UTC: '-03:00', UTC_dec: '-3' },
    { id:'89', country: 'Canada', city: 'Goose_Bay', UTC: '-03:00', UTC_dec: '-3' },
    { id:'90', country: 'Canada', city: 'Halifax', UTC: '-03:00', UTC_dec: '-3' },
    { id:'91', country: 'Canada', city: 'Moncton', UTC: '-03:00', UTC_dec: '-3' },
    { id:'92', country: 'Canada', city: 'Blanc-Sablon', UTC: '-04:00', UTC_dec: '-4' },
    { id:'93', country: 'Canada', city: 'Iqaluit', UTC: '-04:00', UTC_dec: '-4' },
    { id:'94', country: 'Canada', city: 'Nipigon', UTC: '-04:00', UTC_dec: '-4' },
    { id:'95', country: 'Canada', city: 'Pangnirtung', UTC: '-04:00', UTC_dec: '-4' },
    { id:'96', country: 'Canada', city: 'Thunder_Bay', UTC: '-04:00', UTC_dec: '-4' },
    { id:'97', country: 'Canada', city: 'Toronto', UTC: '-04:00', UTC_dec: '-4' },
    { id:'98', country: 'Canada', city: 'Atikokan', UTC: '-05:00', UTC_dec: '-5' },
    { id:'99', country: 'Canada', city: 'Rainy_River', UTC: '-05:00', UTC_dec: '-5' },
    { id:'100', country: 'Canada', city: 'Rankin_Inlet', UTC: '-05:00', UTC_dec: '-5' },
    { id:'101', country: 'Canada', city: 'Resolute', UTC: '-05:00', UTC_dec: '-5' },
    { id:'102', country: 'Canada', city: 'Winnipeg', UTC: '-05:00', UTC_dec: '-5' },
    { id:'103', country: 'Canada', city: 'Cambridge_Bay', UTC: '-06:00', UTC_dec: '-6' },
    { id:'104', country: 'Canada', city: 'Edmonton', UTC: '-06:00', UTC_dec: '-6' },
    { id:'105', country: 'Canada', city: 'Inuvik', UTC: '-06:00', UTC_dec: '-6' },
    { id:'106', country: 'Canada', city: 'Regina', UTC: '-06:00', UTC_dec: '-6' },
    { id:'107', country: 'Canada', city: 'Swift_Current', UTC: '-06:00', UTC_dec: '-6' },
    { id:'108', country: 'Canada', city: 'Yellowknife', UTC: '-06:00', UTC_dec: '-6' },
    { id:'109', country: 'Canada', city: 'Creston', UTC: '-07:00', UTC_dec: '-7' },
    { id:'110', country: 'Canada', city: 'Dawson', UTC: '-07:00', UTC_dec: '-7' },
    { id:'111', country: 'Canada', city: 'Dawson_Creek', UTC: '-07:00', UTC_dec: '-7' },
    { id:'112', country: 'Canada', city: 'Fort_Nelson', UTC: '-07:00', UTC_dec: '-7' },
    { id:'113', country: 'Canada', city: 'Vancouver', UTC: '-07:00', UTC_dec: '-7' },
    { id:'114', country: 'Canada', city: 'Whitehorse', UTC: '-07:00', UTC_dec: '-7' },
    { id:'115', country: 'Cape Verde', city: 'Cape_Verde', UTC: '-01:00', UTC_dec: '-1' },
    { id:'116', country: 'Cayman Islands', city: 'Cayman', UTC: '-05:00', UTC_dec: '-5' },
    { id:'117', country: 'Central African Republic', city: 'Bangui', UTC: '+01:00', UTC_dec: '1' },
    { id:'118', country: 'Chad', city: 'Ndjamena', UTC: '+01:00', UTC_dec: '1' },
    { id:'119', country: 'Chile', city: 'Punta_Arenas', UTC: '-03:00', UTC_dec: '-3' },
    { id:'120', country: 'Chile', city: 'Santiago', UTC: '-03:00', UTC_dec: '-3' },
    { id:'121', country: 'Chile', city: 'Easter', UTC: '-05:00', UTC_dec: '-5' },
    { id:'122', country: 'China', city: 'Urumqi', UTC: '+06:00', UTC_dec: '6' },
    { id:'123', country: 'China', city: 'Shanghai', UTC: '+08:00', UTC_dec: '8' },
    { id:'124', country: 'Christmas Island', city: 'Christmas', UTC: '+07:00', UTC_dec: '7' },
    { id:'125', country: 'Cocos Islands', city: 'Cocos', UTC: '+06:30', UTC_dec: '6,5' },
    { id:'126', country: 'Colombia', city: 'Bogota', UTC: '-05:00', UTC_dec: '-5' },
    { id:'127', country: 'Comoros', city: 'Comoro', UTC: '+03:00', UTC_dec: '3' },
    { id:'128', country: 'Cook Islands', city: 'Rarotonga', UTC: '-10:00', UTC_dec: '-10' },
    { id:'129', country: 'Costa Rica', city: 'Costa_Rica', UTC: '-06:00', UTC_dec: '-6' },
    { id:'130', country: 'Croatia', city: 'Zagreb', UTC: '+02:00', UTC_dec: '2' },
    { id:'131', country: 'Cuba', city: 'Havana', UTC: '-04:00', UTC_dec: '-4' },
    { id:'132', country: 'Curacao', city: 'Curacao', UTC: '-04:00', UTC_dec: '-4' },
    { id:'133', country: 'Cyprus', city: 'Famagusta', UTC: '+03:00', UTC_dec: '3' },
    { id:'134', country: 'Cyprus', city: 'Nicosia', UTC: '+03:00', UTC_dec: '3' },
    { id:'135', country: 'Czech Republic', city: 'Prague', UTC: '+02:00', UTC_dec: '2' },
    { id:'136', country: 'Democratic Republic Of The Congo', city: 'Kinshasa', UTC: '+01:00', UTC_dec: '1' },
    { id:'137', country: 'Democratic Republic Of The Congo', city: 'Lubumbashi', UTC: '+02:00', UTC_dec: '2' },
    { id:'138', country: 'Denmark', city: 'Copenhagen', UTC: '+02:00', UTC_dec: '2' },
    { id:'139', country: 'Djibouti', city: 'Djibouti', UTC: '+03:00', UTC_dec: '3' },
    { id:'140', country: 'Dominica', city: 'Dominica', UTC: '-04:00', UTC_dec: '-4' },
    { id:'141', country: 'Dominican Republic', city: 'Santo_Domingo', UTC: '-04:00', UTC_dec: '-4' },
    { id:'142', country: 'East Timor', city: 'Dili', UTC: '+09:00', UTC_dec: '9' },
    { id:'143', country: 'Ecuador', city: 'Guayaquil', UTC: '-05:00', UTC_dec: '-5' },
    { id:'144', country: 'Ecuador', city: 'Galapagos', UTC: '-06:00', UTC_dec: '-6' },
    { id:'145', country: 'Egypt', city: 'Cairo', UTC: '+02:00', UTC_dec: '2' },
    { id:'146', country: 'El Salvador', city: 'El_Salvador', UTC: '-06:00', UTC_dec: '-6' },
    { id:'147', country: 'Equatorial Guinea', city: 'Malabo', UTC: '+01:00', UTC_dec: '1' },
    { id:'148', country: 'Eritrea', city: 'Asmara', UTC: '+03:00', UTC_dec: '3' },
    { id:'149', country: 'Estonia', city: 'Tallinn', UTC: '+03:00', UTC_dec: '3' },
    { id:'150', country: 'Ethiopia', city: 'Addis_Ababa', UTC: '+03:00', UTC_dec: '3' },
    { id:'151', country: 'Falkland Islands', city: 'Stanley', UTC: '-03:00', UTC_dec: '-3' },
    { id:'152', country: 'Faroe Islands', city: 'Faroe', UTC: '+01:00', UTC_dec: '1' },
    { id:'153', country: 'Fiji', city: 'Fiji', UTC: '+12:00', UTC_dec: '12' },
    { id:'154', country: 'Finland', city: 'Helsinki', UTC: '+03:00', UTC_dec: '3' },
    { id:'155', country: 'France', city: 'Paris', UTC: '+02:00', UTC_dec: '2' },
    { id:'156', country: 'French Guiana', city: 'Cayenne', UTC: '-03:00', UTC_dec: '-3' },
    { id:'157', country: 'French Polynesia', city: 'Gambier', UTC: '-09:00', UTC_dec: '-9' },
    { id:'158', country: 'French Polynesia', city: 'Marquesas', UTC: '-09:30', UTC_dec: '-9,5' },
    { id:'159', country: 'French Polynesia', city: 'Tahiti', UTC: '-10:00', UTC_dec: '-10' },
    { id:'160', country: 'French Southern Territories', city: 'Kerguelen', UTC: '+05:00', UTC_dec: '5' },
    { id:'161', country: 'Gabon', city: 'Libreville', UTC: '+01:00', UTC_dec: '1' },
    { id:'162', country: 'Gambia', city: 'Banjul', UTC: '0:0', UTC_dec: '0' },
    { id:'163', country: 'Georgia', city: 'Tbilisi', UTC: '+04:00', UTC_dec: '4' },
    { id:'164', country: 'Germany', city: 'Berlin', UTC: '+02:00', UTC_dec: '2' },
    { id:'165', country: 'Germany', city: 'Busingen', UTC: '+02:00', UTC_dec: '2' },
    { id:'166', country: 'Ghana', city: 'Accra', UTC: '0:0', UTC_dec: '0' },
    { id:'167', country: 'Gibraltar', city: 'Gibraltar', UTC: '+02:00', UTC_dec: '2' },
    { id:'168', country: 'Greece', city: 'Athens', UTC: '+03:00', UTC_dec: '3' },
    { id:'169', country: 'Greenland', city: 'Godthab', UTC: '-02:00', UTC_dec: '-2' },
    { id:'170', country: 'Greenland', city: 'Thule', UTC: '-03:00', UTC_dec: '-3' },
    { id:'171', country: 'Greenland', city: 'Danmarkshavn', UTC: '0:0', UTC_dec: '0' },
    { id:'172', country: 'Greenland', city: 'Scoresbysund', UTC: '0:0', UTC_dec: '0' },
    { id:'173', country: 'Grenada', city: 'Grenada', UTC: '-04:00', UTC_dec: '-4' },
    { id:'174', country: 'Guadeloupe', city: 'Guadeloupe', UTC: '-04:00', UTC_dec: '-4' },
    { id:'175', country: 'Guam', city: 'Guam', UTC: '+10:00', UTC_dec: '10' },
    { id:'176', country: 'Guatemala', city: 'Guatemala', UTC: '-06:00', UTC_dec: '-6' },
    { id:'177', country: 'Guernsey', city: 'Guernsey', UTC: '+01:00', UTC_dec: '1' },
    { id:'178', country: 'Guinea', city: 'Conakry', UTC: '0:0', UTC_dec: '0' },
    { id:'179', country: 'Guinea-Bissau', city: 'Bissau', UTC: '0:0', UTC_dec: '0' },
    { id:'180', country: 'Guyana', city: 'Guyana', UTC: '-04:00', UTC_dec: '-4' },
    { id:'181', country: 'Haiti', city: 'Port-au-Prince', UTC: '-04:00', UTC_dec: '-4' },
    { id:'182', country: 'Honduras', city: 'Tegucigalpa', UTC: '-06:00', UTC_dec: '-6' },
    { id:'183', country: 'Hong Kong', city: 'Hong_Kong', UTC: '+08:00', UTC_dec: '8' },
    { id:'184', country: 'Hungary', city: 'Budapest', UTC: '+02:00', UTC_dec: '2' },
    { id:'185', country: 'Iceland', city: 'Reykjavik', UTC: '0:0', UTC_dec: '0' },
    { id:'186', country: 'India', city: 'Kolkata', UTC: '+05:30', UTC_dec: '5,5' },
    { id:'187', country: 'Indonesia', city: 'Jakarta', UTC: '+07:00', UTC_dec: '7' },
    { id:'188', country: 'Indonesia', city: 'Pontianak', UTC: '+07:00', UTC_dec: '7' },
    { id:'189', country: 'Indonesia', city: 'Makassar', UTC: '+08:00', UTC_dec: '8' },
    { id:'190', country: 'Indonesia', city: 'Jayapura', UTC: '+09:00', UTC_dec: '9' },
    { id:'191', country: 'Iran', city: 'Tehran', UTC: '+04:30', UTC_dec: '4,5' },
    { id:'192', country: 'Iraq', city: 'Baghdad', UTC: '+03:00', UTC_dec: '3' },
    { id:'193', country: 'Ireland', city: 'Dublin', UTC: '+01:00', UTC_dec: '1' },
    { id:'194', country: 'Isle Of Man', city: 'Isle_of_Man', UTC: '+01:00', UTC_dec: '1' },
    { id:'195', country: 'Israel', city: 'Jerusalem', UTC: '+03:00', UTC_dec: '3' },
    { id:'196', country: 'Italy', city: 'Rome', UTC: '+02:00', UTC_dec: '2' },
    { id:'197', country: 'Ivory Coast', city: 'Abidjan', UTC: '0:0', UTC_dec: '0' },
    { id:'198', country: 'Jamaica', city: 'Jamaica', UTC: '-05:00', UTC_dec: '-5' },
    { id:'199', country: 'Japan', city: 'Tokyo', UTC: '+09:00', UTC_dec: '9' },
    { id:'200', country: 'Jersey', city: 'Jersey', UTC: '+01:00', UTC_dec: '1' },
    { id:'201', country: 'Jordan', city: 'Amman', UTC: '+03:00', UTC_dec: '3' },
    { id:'202', country: 'Kazakhstan', city: 'Aqtau', UTC: '+05:00', UTC_dec: '5' },
    { id:'203', country: 'Kazakhstan', city: 'Aqtobe', UTC: '+05:00', UTC_dec: '5' },
    { id:'204', country: 'Kazakhstan', city: 'Atyrau', UTC: '+05:00', UTC_dec: '5' },
    { id:'205', country: 'Kazakhstan', city: 'Oral', UTC: '+05:00', UTC_dec: '5' },
    { id:'206', country: 'Kazakhstan', city: 'Almaty', UTC: '+06:00', UTC_dec: '6' },
    { id:'207', country: 'Kazakhstan', city: 'Qyzylorda', UTC: '+06:00', UTC_dec: '6' },
    { id:'208', country: 'Kenya', city: 'Nairobi', UTC: '+03:00', UTC_dec: '3' },
    { id:'209', country: 'Kiribati', city: 'Tarawa', UTC: '+12:00', UTC_dec: '12' },
    { id:'210', country: 'Kiribati', city: 'Enderbury', UTC: '+13:00', UTC_dec: '13' },
    { id:'211', country: 'Kiribati', city: 'Kiritimati', UTC: '+14:00', UTC_dec: '14' },
    { id:'212', country: 'Kuwait', city: 'Kuwait', UTC: '+03:00', UTC_dec: '3' },
    { id:'213', country: 'Kyrgyzstan', city: 'Bishkek', UTC: '+06:00', UTC_dec: '6' },
    { id:'214', country: 'Laos', city: 'Vientiane', UTC: '+07:00', UTC_dec: '7' },
    { id:'215', country: 'Latvia', city: 'Riga', UTC: '+03:00', UTC_dec: '3' },
    { id:'216', country: 'Lebanon', city: 'Beirut', UTC: '+03:00', UTC_dec: '3' },
    { id:'217', country: 'Lesotho', city: 'Maseru', UTC: '+02:00', UTC_dec: '2' },
    { id:'218', country: 'Liberia', city: 'Monrovia', UTC: '0:0', UTC_dec: '0' },
    { id:'219', country: 'Libya', city: 'Tripoli', UTC: '+02:00', UTC_dec: '2' },
    { id:'220', country: 'Liechtenstein', city: 'Vaduz', UTC: '+02:00', UTC_dec: '2' },
    { id:'221', country: 'Lithuania', city: 'Vilnius', UTC: '+03:00', UTC_dec: '3' },
    { id:'222', country: 'Luxembourg', city: 'Luxembourg', UTC: '+02:00', UTC_dec: '2' },
    { id:'223', country: 'Macao', city: 'Macau', UTC: '+08:00', UTC_dec: '8' },
    { id:'224', country: 'Macedonia', city: 'Skopje', UTC: '+02:00', UTC_dec: '2' },
    { id:'225', country: 'Madagascar', city: 'Antananarivo', UTC: '+03:00', UTC_dec: '3' },
    { id:'226', country: 'Malawi', city: 'Blantyre', UTC: '+02:00', UTC_dec: '2' },
    { id:'227', country: 'Malaysia', city: 'Kuala_Lumpur', UTC: '+08:00', UTC_dec: '8' },
    { id:'228', country: 'Malaysia', city: 'Kuching', UTC: '+08:00', UTC_dec: '8' },
    { id:'229', country: 'Maldives', city: 'Maldives', UTC: '+05:00', UTC_dec: '5' },
    { id:'230', country: 'Mali', city: 'Bamako', UTC: '0:0', UTC_dec: '0' },
    { id:'231', country: 'Malta', city: 'Malta', UTC: '+02:00', UTC_dec: '2' },
    { id:'232', country: 'Marshall Islands', city: 'Kwajalein', UTC: '+12:00', UTC_dec: '12' },
    { id:'233', country: 'Marshall Islands', city: 'Majuro', UTC: '+12:00', UTC_dec: '12' },
    { id:'234', country: 'Martinique', city: 'Martinique', UTC: '-04:00', UTC_dec: '-4' },
    { id:'235', country: 'Mauritania', city: 'Nouakchott', UTC: '0:0', UTC_dec: '0' },
    { id:'236', country: 'Mauritius', city: 'Mauritius', UTC: '+04:00', UTC_dec: '4' },
    { id:'237', country: 'Mayotte', city: 'Mayotte', UTC: '+03:00', UTC_dec: '3' },
    { id:'238', country: 'Mexico', city: 'Bahia_Banderas', UTC: '-05:00', UTC_dec: '-5' },
    { id:'239', country: 'Mexico', city: 'Cancun', UTC: '-05:00', UTC_dec: '-5' },
    { id:'240', country: 'Mexico', city: 'Matamoros', UTC: '-05:00', UTC_dec: '-5' },
    { id:'241', country: 'Mexico', city: 'Merida', UTC: '-05:00', UTC_dec: '-5' },
    { id:'242', country: 'Mexico', city: 'Mexico_City', UTC: '-05:00', UTC_dec: '-5' },
    { id:'243', country: 'Mexico', city: 'Monterrey', UTC: '-05:00', UTC_dec: '-5' },
    { id:'244', country: 'Mexico', city: 'Chihuahua', UTC: '-06:00', UTC_dec: '-6' },
    { id:'245', country: 'Mexico', city: 'Mazatlan', UTC: '-06:00', UTC_dec: '-6' },
    { id:'246', country: 'Mexico', city: 'Ojinaga', UTC: '-06:00', UTC_dec: '-6' },
    { id:'247', country: 'Mexico', city: 'Hermosillo', UTC: '-07:00', UTC_dec: '-7' },
    { id:'248', country: 'Mexico', city: 'Tijuana', UTC: '-07:00', UTC_dec: '-7' },
    { id:'249', country: 'Micronesia', city: 'Chuuk', UTC: '+10:00', UTC_dec: '10' },
    { id:'250', country: 'Micronesia', city: 'Kosrae', UTC: '+11:00', UTC_dec: '11' },
    { id:'251', country: 'Micronesia', city: 'Pohnpei', UTC: '+11:00', UTC_dec: '11' },
    { id:'252', country: 'Moldova', city: 'Chisinau', UTC: '+03:00', UTC_dec: '3' },
    { id:'253', country: 'Monaco', city: 'Monaco', UTC: '+02:00', UTC_dec: '2' },
    { id:'254', country: 'Mongolia', city: 'Hovd', UTC: '+07:00', UTC_dec: '7' },
    { id:'255', country: 'Mongolia', city: 'Choibalsan', UTC: '+08:00', UTC_dec: '8' },
    { id:'256', country: 'Mongolia', city: 'Ulaanbaatar', UTC: '+08:00', UTC_dec: '8' },
    { id:'257', country: 'Montenegro', city: 'Podgorica', UTC: '+02:00', UTC_dec: '2' },
    { id:'258', country: 'Montserrat', city: 'Montserrat', UTC: '-04:00', UTC_dec: '-4' },
    { id:'259', country: 'Morocco', city: 'Casablanca', UTC: '+01:00', UTC_dec: '1' },
    { id:'260', country: 'Mozambique', city: 'Maputo', UTC: '+02:00', UTC_dec: '2' },
    { id:'261', country: 'Myanmar', city: 'Yangon', UTC: '+06:30', UTC_dec: '6,5' },
    { id:'262', country: 'Namibia', city: 'Windhoek', UTC: '+02:00', UTC_dec: '2' },
    { id:'263', country: 'Nauru', city: 'Nauru', UTC: '+12:00', UTC_dec: '12' },
    { id:'264', country: 'Nepal', city: 'Kathmandu', UTC: '+05:45', UTC_dec: '5,75' },
    { id:'265', country: 'Netherlands', city: 'Amsterdam', UTC: '+02:00', UTC_dec: '2' },
    { id:'266', country: 'New Caledonia', city: 'Noumea', UTC: '+11:00', UTC_dec: '11' },
    { id:'267', country: 'New Zealand', city: 'Auckland', UTC: '+12:00', UTC_dec: '12' },
    { id:'268', country: 'New Zealand', city: 'Chatham', UTC: '+12:45', UTC_dec: '12,75' },
    { id:'269', country: 'Nicaragua', city: 'Managua', UTC: '-06:00', UTC_dec: '-6' },
    { id:'270', country: 'Niger', city: 'Niamey', UTC: '+01:00', UTC_dec: '1' },
    { id:'271', country: 'Nigeria', city: 'Lagos', UTC: '+01:00', UTC_dec: '1' },
    { id:'272', country: 'Niue', city: 'Niue', UTC: '-11:00', UTC_dec: '-11' },
    { id:'273', country: 'Norfolk Island', city: 'Norfolk', UTC: '+11:00', UTC_dec: '11' },
    { id:'274', country: 'North Korea', city: 'Pyongyang', UTC: '+08:30', UTC_dec: '8,5' },
    { id:'275', country: 'Northern Mariana Islands', city: 'Saipan', UTC: '+10:00', UTC_dec: '10' },
    { id:'276', country: 'Norway', city: 'Oslo', UTC: '+02:00', UTC_dec: '2' },
    { id:'277', country: 'Oman', city: 'Muscat', UTC: '+04:00', UTC_dec: '4' },
    { id:'278', country: 'Pakistan', city: 'Karachi', UTC: '+05:00', UTC_dec: '5' },
    { id:'279', country: 'Palau', city: 'Palau', UTC: '+09:00', UTC_dec: '9' },
    { id:'280', country: 'Palestinian Territory', city: 'Gaza', UTC: '+03:00', UTC_dec: '3' },
    { id:'281', country: 'Palestinian Territory', city: 'Hebron', UTC: '+03:00', UTC_dec: '3' },
    { id:'282', country: 'Panama', city: 'Panama', UTC: '-05:00', UTC_dec: '-5' },
    { id:'283', country: 'Papua New Guinea', city: 'Port_Moresby', UTC: '+10:00', UTC_dec: '10' },
    { id:'284', country: 'Papua New Guinea', city: 'Bougainville', UTC: '+11:00', UTC_dec: '11' },
    { id:'285', country: 'Paraguay', city: 'Asuncion', UTC: '-04:00', UTC_dec: '-4' },
    { id:'286', country: 'Peru', city: 'Lima', UTC: '-05:00', UTC_dec: '-5' },
    { id:'287', country: 'Philippines', city: 'Manila', UTC: '+08:00', UTC_dec: '8' },
    { id:'288', country: 'Pitcairn', city: 'Pitcairn', UTC: '-08:00', UTC_dec: '-8' },
    { id:'289', country: 'Poland', city: 'Warsaw', UTC: '+02:00', UTC_dec: '2' },
    { id:'290', country: 'Portugal', city: 'Madeira', UTC: '+01:00', UTC_dec: '1' },
    { id:'291', country: 'Portugal', city: 'Lisbon', UTC: '+01:00', UTC_dec: '1' },
    { id:'292', country: 'Portugal', city: 'Azores', UTC: '0:0', UTC_dec: '0' },
    { id:'293', country: 'Puerto Rico', city: 'Puerto_Rico', UTC: '-04:00', UTC_dec: '-4' },
    { id:'294', country: 'Qatar', city: 'Qatar', UTC: '+03:00', UTC_dec: '3' },
    { id:'295', country: 'Republic Of The Congo', city: 'Brazzaville', UTC: '+01:00', UTC_dec: '1' },
    { id:'296', country: 'Reunion', city: 'Reunion', UTC: '+04:00', UTC_dec: '4' },
    { id:'297', country: 'Romania', city: 'Bucharest', UTC: '+03:00', UTC_dec: '3' },
    { id:'298', country: 'Russia', city: 'Kaliningrad', UTC: '+02:00', UTC_dec: '2' },
    { id:'299', country: 'Russia', city: 'Kirov', UTC: '+03:00', UTC_dec: '3' },
    { id:'300', country: 'Russia', city: 'Moscow', UTC: '+03:00', UTC_dec: '3' },
    { id:'301', country: 'Russia', city: 'Simferopol', UTC: '+03:00', UTC_dec: '3' },
    { id:'302', country: 'Russia', city: 'Volgograd', UTC: '+03:00', UTC_dec: '3' },
    { id:'303', country: 'Russia', city: 'Astrakhan', UTC: '+04:00', UTC_dec: '4' },
    { id:'304', country: 'Russia', city: 'Samara', UTC: '+04:00', UTC_dec: '4' },
    { id:'305', country: 'Russia', city: 'Saratov', UTC: '+04:00', UTC_dec: '4' },
    { id:'306', country: 'Russia', city: 'Ulyanovsk', UTC: '+04:00', UTC_dec: '4' },
    { id:'307', country: 'Russia', city: 'Yekaterinburg', UTC: '+05:00', UTC_dec: '5' },
    { id:'308', country: 'Russia', city: 'Omsk', UTC: '+06:00', UTC_dec: '6' },
    { id:'309', country: 'Russia', city: 'Barnaul', UTC: '+07:00', UTC_dec: '7' },
    { id:'310', country: 'Russia', city: 'Krasnoyarsk', UTC: '+07:00', UTC_dec: '7' },
    { id:'311', country: 'Russia', city: 'Novokuznetsk', UTC: '+07:00', UTC_dec: '7' },
    { id:'312', country: 'Russia', city: 'Novosibirsk', UTC: '+07:00', UTC_dec: '7' },
    { id:'313', country: 'Russia', city: 'Tomsk', UTC: '+07:00', UTC_dec: '7' },
    { id:'314', country: 'Russia', city: 'Irkutsk', UTC: '+08:00', UTC_dec: '8' },
    { id:'315', country: 'Russia', city: 'Chita', UTC: '+09:00', UTC_dec: '9' },
    { id:'316', country: 'Russia', city: 'Khandyga', UTC: '+09:00', UTC_dec: '9' },
    { id:'317', country: 'Russia', city: 'Yakutsk', UTC: '+09:00', UTC_dec: '9' },
    { id:'318', country: 'Russia', city: 'Ust-Nera', UTC: '+10:00', UTC_dec: '10' },
    { id:'319', country: 'Russia', city: 'Vladivostok', UTC: '+10:00', UTC_dec: '10' },
    { id:'320', country: 'Russia', city: 'Magadan', UTC: '+11:00', UTC_dec: '11' },
    { id:'321', country: 'Russia', city: 'Sakhalin', UTC: '+11:00', UTC_dec: '11' },
    { id:'322', country: 'Russia', city: 'Srednekolymsk', UTC: '+11:00', UTC_dec: '11' },
    { id:'323', country: 'Russia', city: 'Anadyr', UTC: '+12:00', UTC_dec: '12' },
    { id:'324', country: 'Russia', city: 'Kamchatka', UTC: '+12:00', UTC_dec: '12' },
    { id:'325', country: 'Rwanda', city: 'Kigali', UTC: '+02:00', UTC_dec: '2' },
    { id:'326', country: 'Saint Barthelemy', city: 'St_Barthelemy', UTC: '-04:00', UTC_dec: '-4' },
    { id:'327', country: 'Saint Helena', city: 'St_Helena', UTC: '0:0', UTC_dec: '0' },
    { id:'328', country: 'Saint Kitts And Nevis', city: 'St_Kitts', UTC: '-04:00', UTC_dec: '-4' },
    { id:'329', country: 'Saint Lucia', city: 'St_Lucia', UTC: '-04:00', UTC_dec: '-4' },
    { id:'330', country: 'Saint Martin', city: 'Marigot', UTC: '-04:00', UTC_dec: '-4' },
    { id:'331', country: 'Saint Pierre And Miquelon', city: 'Miquelon', UTC: '-02:00', UTC_dec: '-2' },
    { id:'332', country: 'Saint Vincent And The Grenadines', city: 'St_Vincent', UTC: '-04:00', UTC_dec: '-4' },
    { id:'333', country: 'Samoa', city: 'Apia', UTC: '+13:00', UTC_dec: '13' },
    { id:'334', country: 'San Marino', city: 'San_Marino', UTC: '+02:00', UTC_dec: '2' },
    { id:'335', country: 'Sao Tome And Principe', city: 'Sao_Tome', UTC: '+01:00', UTC_dec: '1' },
    { id:'336', country: 'Saudi Arabia', city: 'Riyadh', UTC: '+03:00', UTC_dec: '3' },
    { id:'337', country: 'Senegal', city: 'Dakar', UTC: '0:0', UTC_dec: '0' },
    { id:'338', country: 'Serbia', city: 'Belgrade', UTC: '+02:00', UTC_dec: '2' },
    { id:'339', country: 'Seychelles', city: 'Mahe', UTC: '+04:00', UTC_dec: '4' },
    { id:'340', country: 'Sierra Leone', city: 'Freetown', UTC: '0:0', UTC_dec: '0' },
    { id:'341', country: 'Singapore', city: 'Singapore', UTC: '+08:00', UTC_dec: '8' },
    { id:'342', country: 'Sint Maarten', city: 'Lower_Princes', UTC: '-04:00', UTC_dec: '-4' },
    { id:'343', country: 'Slovakia', city: 'Bratislava', UTC: '+02:00', UTC_dec: '2' },
    { id:'344', country: 'Slovenia', city: 'Ljubljana', UTC: '+02:00', UTC_dec: '2' },
    { id:'345', country: 'Solomon Islands', city: 'Guadalcanal', UTC: '+11:00', UTC_dec: '11' },
    { id:'346', country: 'Somalia', city: 'Mogadishu', UTC: '+03:00', UTC_dec: '3' },
    { id:'347', country: 'South Africa', city: 'Johannesburg', UTC: '+02:00', UTC_dec: '2' },
    { id:'348', country: 'South Georgia And The South Sandwich Islands', city: 'South_Georgia', UTC: '-02:00', UTC_dec: '-2' },
    { id:'349', country: 'South Korea', city: 'Seoul', UTC: '+09:00', UTC_dec: '9' },
    { id:'350', country: 'South Sudan', city: 'Juba', UTC: '+03:00', UTC_dec: '3' },
    { id:'351', country: 'Spain', city: 'Canary', UTC: '+01:00', UTC_dec: '1' },
    { id:'352', country: 'Spain', city: 'Ceuta', UTC: '+02:00', UTC_dec: '2' },
    { id:'353', country: 'Spain', city: 'Madrid', UTC: '+02:00', UTC_dec: '2' },
    { id:'354', country: 'Sri Lanka', city: 'Colombo', UTC: '+05:30', UTC_dec: '5,5' },
    { id:'355', country: 'Sudan', city: 'Khartoum', UTC: '+02:00', UTC_dec: '2' },
    { id:'356', country: 'Suriname', city: 'Paramaribo', UTC: '-03:00', UTC_dec: '-3' },
    { id:'357', country: 'Svalbard And Jan Mayen', city: 'Longyearbyen', UTC: '+02:00', UTC_dec: '2' },
    { id:'358', country: 'Swaziland', city: 'Mbabane', UTC: '+02:00', UTC_dec: '2' },
    { id:'359', country: 'Sweden', city: 'Stockholm', UTC: '+02:00', UTC_dec: '2' },
    { id:'360', country: 'Switzerland', city: 'Zurich', UTC: '+02:00', UTC_dec: '2' },
    { id:'361', country: 'Syria', city: 'Damascus', UTC: '+03:00', UTC_dec: '3' },
    { id:'362', country: 'Taiwan', city: 'Taipei', UTC: '+08:00', UTC_dec: '8' },
    { id:'363', country: 'Tajikistan', city: 'Dushanbe', UTC: '+05:00', UTC_dec: '5' },
    { id:'364', country: 'Tanzania', city: 'Dar_es_Salaam', UTC: '+03:00', UTC_dec: '3' },
    { id:'365', country: 'Thailand', city: 'Bangkok', UTC: '+07:00', UTC_dec: '7' },
    { id:'366', country: 'Togo', city: 'Lome', UTC: '0:0', UTC_dec: '0' },
    { id:'367', country: 'Tokelau', city: 'Fakaofo', UTC: '+13:00', UTC_dec: '13' },
    { id:'368', country: 'Tonga', city: 'Tongatapu', UTC: '+13:00', UTC_dec: '13' },
    { id:'369', country: 'Trinidad And Tobago', city: 'Port_of_Spain', UTC: '-04:00', UTC_dec: '-4' },
    { id:'370', country: 'Tunisia', city: 'Tunis', UTC: '+01:00', UTC_dec: '1' },
    { id:'371', country: 'Turkey', city: 'Istanbul', UTC: '+03:00', UTC_dec: '3' },
    { id:'372', country: 'Turkmenistan', city: 'Ashgabat', UTC: '+05:00', UTC_dec: '5' },
    { id:'373', country: 'Turks And Caicos Islands', city: 'Grand_Turk', UTC: '-04:00', UTC_dec: '-4' },
    { id:'374', country: 'Tuvalu', city: 'Funafuti', UTC: '+12:00', UTC_dec: '12' },
    { id:'375', country: 'U.S. Virgin Islands', city: 'St_Thomas', UTC: '-04:00', UTC_dec: '-4' },
    { id:'376', country: 'Uganda', city: 'Kampala', UTC: '+03:00', UTC_dec: '3' },
    { id:'377', country: 'Ukraine', city: 'Kiev', UTC: '+03:00', UTC_dec: '3' },
    { id:'378', country: 'Ukraine', city: 'Uzhgorod', UTC: '+03:00', UTC_dec: '3' },
    { id:'379', country: 'Ukraine', city: 'Zaporozhye', UTC: '+03:00', UTC_dec: '3' },
    { id:'380', country: 'United Arab Emirates', city: 'Dubai', UTC: '+04:00', UTC_dec: '4' },
    { id:'381', country: 'United Kingdom of Great Britain and Northern Ireland', city: 'London', UTC: '+01:00', UTC_dec: '1' },
    { id:'382', country: 'United States of America', city: 'Detroit', UTC: '-04:00', UTC_dec: '-4' },
    { id:'383', country: 'United States of America', city: 'Indianapolis', UTC: '-04:00', UTC_dec: '-4' },
    { id:'384', country: 'United States of America', city: 'Marengo', UTC: '-04:00', UTC_dec: '-4' },
    { id:'385', country: 'United States of America', city: 'Petersburg', UTC: '-04:00', UTC_dec: '-4' },
    { id:'386', country: 'United States of America', city: 'Vevay', UTC: '-04:00', UTC_dec: '-4' },
    { id:'387', country: 'United States of America', city: 'Vincennes', UTC: '-04:00', UTC_dec: '-4' },
    { id:'388', country: 'United States of America', city: 'Winamac', UTC: '-04:00', UTC_dec: '-4' },
    { id:'389', country: 'United States of America', city: 'Louisville', UTC: '-04:00', UTC_dec: '-4' },
    { id:'390', country: 'United States of America', city: 'Monticello', UTC: '-04:00', UTC_dec: '-4' },
    { id:'391', country: 'United States of America', city: 'New_York', UTC: '-04:00', UTC_dec: '-4' },
    { id:'392', country: 'United States of America', city: 'Chicago', UTC: '-05:00', UTC_dec: '-5' },
    { id:'393', country: 'United States of America', city: 'Knox', UTC: '-05:00', UTC_dec: '-5' },
    { id:'394', country: 'United States of America', city: 'Tell_City', UTC: '-05:00', UTC_dec: '-5' },
    { id:'395', country: 'United States of America', city: 'Menominee', UTC: '-05:00', UTC_dec: '-5' },
    { id:'396', country: 'United States of America', city: 'Beulah', UTC: '-05:00', UTC_dec: '-5' },
    { id:'397', country: 'United States of America', city: 'Center', UTC: '-05:00', UTC_dec: '-5' },
    { id:'398', country: 'United States of America', city: 'New_Salem', UTC: '-05:00', UTC_dec: '-5' },
    { id:'399', country: 'United States of America', city: 'Boise', UTC: '-06:00', UTC_dec: '-6' },
    { id:'400', country: 'United States of America', city: 'Denver', UTC: '-06:00', UTC_dec: '-6' },
    { id:'401', country: 'United States of America', city: 'Los_Angeles', UTC: '-07:00', UTC_dec: '-7' },
    { id:'402', country: 'United States of America', city: 'Phoenix', UTC: '-07:00', UTC_dec: '-7' },
    { id:'403', country: 'United States of America', city: 'Anchorage', UTC: '-08:00', UTC_dec: '-8' },
    { id:'404', country: 'United States of America', city: 'Juneau', UTC: '-08:00', UTC_dec: '-8' },
    { id:'405', country: 'United States of America', city: 'Metlakatla', UTC: '-08:00', UTC_dec: '-8' },
    { id:'406', country: 'United States of America', city: 'Nome', UTC: '-08:00', UTC_dec: '-8' },
    { id:'407', country: 'United States of America', city: 'Sitka', UTC: '-08:00', UTC_dec: '-8' },
    { id:'408', country: 'United States of America', city: 'Yakutat', UTC: '-08:00', UTC_dec: '-8' },
    { id:'409', country: 'United States of America', city: 'Adak', UTC: '-09:00', UTC_dec: '-9' },
    { id:'410', country: 'United States of America', city: 'Honolulu', UTC: '-10:00', UTC_dec: '-10' },
    { id:'411', country: 'United States Minor Outlying Islands', city: 'Midway', UTC: '-11:00', UTC_dec: '-11' },
    { id:'412', country: 'United States Minor Outlying Islands', city: 'Wake', UTC: '+12:00', UTC_dec: '12' },
    { id:'413', country: 'Uruguay', city: 'Montevideo', UTC: '-03:00', UTC_dec: '-3' },
    { id:'414', country: 'Uzbekistan', city: 'Samarkand', UTC: '+05:00', UTC_dec: '5' },
    { id:'415', country: 'Uzbekistan', city: 'Tashkent', UTC: '+05:00', UTC_dec: '5' },
    { id:'416', country: 'Vanuatu', city: 'Efate', UTC: '+11:00', UTC_dec: '11' },
    { id:'417', country: 'Vatican', city: 'Vatican', UTC: '+02:00', UTC_dec: '2' },
    { id:'418', country: 'Venezuela', city: 'Caracas', UTC: '-04:00', UTC_dec: '-4' },
    { id:'419', country: 'Vietnam', city: 'Ho_Chi_Minh', UTC: '+07:00', UTC_dec: '7' },
    { id:'420', country: 'Wallis And Futuna', city: 'Wallis', UTC: '+12:00', UTC_dec: '12' },
    { id:'421', country: 'Western Sahara', city: 'El_Aaiun', UTC: '+01:00', UTC_dec: '1' },
    { id:'422', country: 'Yemen', city: 'Aden', UTC: '+03:00', UTC_dec: '3' },
    { id:'423', country: 'Zambia', city: 'Lusaka', UTC: '+02:00', UTC_dec: '2' },
    { id:'424', country: 'Zimbabwe', city: 'Harare', UTC: '+02:00', UTC_dec: '2' }
  ]



new Vue({
  router,
  store,
  vuetify,
  
  async beforeCreate() {
    await new Promise((resolve, reject) => {
      axios
        .get("../../../config.json")
        .then((response) => {
          this.$store.dispatch("setConfig", response.data);
          resolve(response);
          return;
        })
        .catch((e) => {
          reject(e);
          console.log(e);
        });
    });
  },


  
  

  render: h => h(App)
}).$mount('#app')
