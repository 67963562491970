<template>
    <div class="mt-10 ml-6 mr-6" v-if="pagetabs" >
      <EventDetail 
        :OBJ_EVENT=this.obj_event  
        :TRIGGER = this.modifica
        v-on:closeDettEvent="chiudi_dettaglio"
      /> 
    </div>
    <div class="mt-10 ml-6 mr-6" v-else >
      <v-row  style="position:fixed; z-index: 1; width:85%; "> <!--Create New Event--> 
            <v-col cols="12" md="12 " class="mt-n2 event-toolbar"  style=" background-color: var(--main-bg-color);"  >
              <v-toolbar dense  elevation="0" style="height: 80px; padding-top: 20px;  background-color: var(--main-bg-color);">   
                 <div  v-if="$vuetify.breakpoint.sm ? style='width:100px; max-width:300px;' : style='width:55%;' "  >    
                  <v-tabs
                      v-model="modalTab_event"
                      grow
                      slider-size="3"
                      slider-color="red"
                      color="white"
                  >
                      <v-tab :href="`#neweventfilter`" 
                              class="btn-gen-red"
                              style= ""
                              @click="newEvent=true"
                      >Create New Event</v-tab >
                  </v-tabs>
                </div>
              </v-toolbar>
            </v-col>
      </v-row>                  
      <v-row  >  <!--Gestione Filtri e Tabella dentro unico Tab -->
          <v-col cols="12" class=" mt-10"  >
                  <v-tabs-items v-model="modalTab_event" class="no-transition "  style="background-color:var(--main-bg-color);" touchless>

                      <!-- Tab Event Main -->
                      <v-tab-item
                        :value="`neweventfilter`"
                        transition="false"
                        class="mt-3 no-transition"
                      >
                      <div class="mt-4" style="background-color:var(--main-bg-color); ">
                          <!-- Filter -->
                          <v-card v-if="!newEvent" class="ml-4 mr-4 mb-4" elevation="0" style="background-color: white;"  ><!-- Row Filtri-->
                            <v-container style="background-color: var(--main-bg-form-color);" >
                            <v-row >  
                              <v-col cols="12" sm="12" class="mt-3 ml-4">
                                <p class="titleLabelForm"> 
                                    FILTER AND FIND EVENT
                                </p>
                              </v-col> 
                              <v-col cols="12" sm="6" md="3" class="mt-n5 ml-4"> <!-- Event Name-->
                                  <v-autocomplete
                                    v-model="filter_event_name" 
                                    item-color="var(--main-active-select-list-color)"
                                    class="textFieldForm"
                                    style=" color: var(--main-input-color); font-size: 18px;"   
                                    color="var(--main-border-color)"   
                                    prepend-inner-icon="mdi-filter-variant"                               
                                    :items="valid_events_filter"
                                    :loading="isLoading_event"
                                    :search-input.sync="search_event"
                                    clearable
                                    clear-icon="mdi-close"
                                    :item-text="(item) => item.event_name + ' ' + item.city_event"
                                    item-value="id_event"
                                    label="Event Name"
                                    outlined
                                  ></v-autocomplete>                                
                              </v-col>
                              <v-col cols="12" sm="6" md="3" class="mt-n5 ml-4"> <!-- Data From -->
                                <v-menu
                                  v-model="menu_dateFrom_filter"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field 
                                      v-model="filter_formattedDateFrom"
                                      label="Date From"
                                      style="color: var(--main-input-color); font-size: 18px; "                              
                                      class="textFieldForm"    
                                      color="var(--main-border-color)"               
                                      outlined
                                      persistent-hint
                                      prepend-inner-icon="mdi-filter-variant"
                                      v-bind="attrs"
                                      v-on="on"
                                      readonly
                                    >
                                      <template slot="label">
                                        Date From
                                          <v-icon 
                                              v-if="filter_formattedDateFrom!= null"
                                              class="mb-2"
                                              @click ="filter_dateFrom = null; menu_dateFrom_filter = true" 
                                          >mdi-close</v-icon>
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="filter_dateFrom"
                                    no-title
                                    @input="menu_dateFrom_filter = false"
                                    locale="en"
                                  ></v-date-picker>
                                </v-menu> 
                              </v-col> 
                              <v-col cols="12" sm="6" md="3" class="mt-n5 ml-4"> <!-- Data To -->
                                <v-menu
                                    v-model="menu_dateTo_filter"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field 
                                        v-model="filter_formattedDateTo"
                                        label="Date To"
                                        style="color: var(--main-input-color); font-size: 18px;"                           
                                        class="textFieldForm"    
                                        color="var(--main-border-color)"               
                                        outlined
                                        persistent-hint
                                        prepend-inner-icon="mdi-filter-variant"
                                        v-bind="attrs"
                                        v-on="on"
                                        readonly
                                    >
                                        <template slot="label">
                                        Date To
                                            <v-icon 
                                                v-if="filter_formattedDateTo!= null"
                                                class="mb-2"
                                                @click ="filter_dateTo = null; menu_dateTo_filter = true" 
                                            >mdi-close</v-icon>
                                        </template>
                                    </v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="filter_dateTo"
                                    no-title
                                    @input="menu_dateTo_filter = false"
                                    locale="en"
                                    ></v-date-picker>
                                </v-menu>
                              </v-col> 
                              <v-col cols="12" sm="6" md="3" class="mt-n5 ml-4">
                              </v-col>
                              <!-- Country State City 
                                <v-col cols="12" sm="6" md="3" class="mt-n5">  
                                          <v-autocomplete
                                            class="textFieldForm"
                                            v-model="filter_country_event"
                                            :items="country_flags"
                                            :loading="isLoading_country"
                                            :search-input.sync="search_country"
                                            prepend-inner-icon="mdi-filter-variant"
                                            item-color="var(--main-active-select-list-color)"
                                            style=" color: var(--main-input-color); font-size: 18px;"
                                            color="var(--main-border-color)"
                                            clearable
                                            label="Country"
                                            hide-details
                                            item-text="name"
                                            item-value="id"
                                            outlined
                                          ></v-autocomplete>
                                </v-col> 
                                <v-col cols="12" sm="6" md="3" class="mt-n5"> 
                                    <v-text-field
                                      v-model="filter_state"
                                      label="State"
                                      prepend-inner-icon="mdi-filter-variant"
                                      outlined
                                      color="var(--main-border-color)" 
                                      style=" color: var(--main-input-color); font-size: 18px;"   
                                      class="textFieldForm"                                    
                                      v-on:keydown.enter.prevent="getEvents()">
                                    ></v-text-field>
                                </v-col>       
                                <v-col cols="12" sm="6" md="3" class="mt-n5"> 
                                    <v-text-field
                                      v-model="filter_city"
                                      label="City"
                                      prepend-inner-icon="mdi-filter-variant"
                                      outlined
                                      color="var(--main-border-color)" 
                                      style=" color: var(--main-input-color); font-size: 18px;"   
                                      class="textFieldForm"                                    
                                      v-on:keydown.enter.prevent="getEvents()">
                                    ></v-text-field>
                                </v-col>      
                              -->                                                
                              
                              <!-- pulsanti Filtro-->
                              <v-col cols="12" sm="12" md="12" class="ml-4" >
                                <v-row class="mt-n12 mb-3">                           

                                  <!-- Apply Clear-->
                                  <v-col cols="12" sm="6"  align="left">
                                    <v-checkbox
                                            class="btnForm pl-0 mb-8"  
                                            color="black"
                                            v-model="stato"
                                            :label= this.btn_enable_disable
                                            @change="enable_disable_view_event()"
                                          ></v-checkbox>
                                  </v-col>
                                </v-row>
                                <v-row class="mt-n12 mb-3">                           

                                  <!-- Apply Clear-->
                                  <v-col cols="12" sm="6"  align="left">
                     
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn  
                                              class="mt-3 btnForm"  
                                              elevation="0" color="var(--main-primary-color)" dark v-bind="attrs" v-on="on" 
                                              @click="getEvents()"
                                            > Apply and Search</v-btn>
                                        </template>
                                        <span>Apply Selected Filter</span>
                                    </v-tooltip>
                                    
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-if="valid_events.length>0"
                                                class="mt-3 ml-1 btn_bg nohover " elevation="0" 
                                                color="white"   v-bind="attrs" v-on="on" @click="clear_filter"
                                            > Clear
                                            </v-btn>
                                            <v-btn v-else
                                                class="mt-3 ml-1 " elevation="0" 
                                                color="white" dark v-bind="attrs" v-on="on" @click="clear_filter"
                                                disabled
                                            > 
                                            </v-btn>
                                        </template>
                                        <span>Clear All Filter</span>
                                    </v-tooltip> 
                                  </v-col>
                                 <v-col cols="12" sm="3"  align="right"/>
                                 <v-col cols="12" sm="3"  align="right">
                                <v-spacer> </v-spacer>
                                        
                                  </v-col>



                                </v-row>                     
                              </v-col>

                              <!-- Fine Campi Filter -->
                            </v-row>
                          </v-container>
                          </v-card>
                          <v-row>
                              <v-col cols="12" class="mt-2 ml-7"> 
                              <div v-if="!newEvent && !valid_events.length>0">
                                <p class="text-left text-info-color">Create new events or apply filter to view events.</p>
                              </div>
                            </v-col>
                          </v-row>

                          <v-card v-if="newEvent" class="ml-4 mr-4 mb-12" elevation="0"> <!--Gestione New Event non in V-Dialog ma in line-->
                            <v-container style="background-color: var(--main-bg-form-color);" >
                              <v-row >
                                <v-col cols="12" sm="12" class="mt-3 ml-4">
                                    <p class="titleLabelForm"> 
                                        CREATE NEW EVENT
                                    </p>
                                </v-col>                                 
                                <v-col cols="12" class="ml-3 mt-n7" >                                           
                                    <v-row >

                                      <v-col cols="12" sm="6" md="6" class="mt-n1" >

                                          <v-row class="mt-1">

                                              <!-- Event Name--> 
                                              <v-col cols="12" sm="4" md="4" class="ml-3" > <!-- Event Name--> 
                                                <v-text-field
                                                  v-model="new_event.event_name"
                                                  style="color: var(--main-input-color); font-size: 18px;"                              
                                                  class="textFieldForm"    
                                                  color="var(--main-border-color)"            
                                                  outlined
                                                  label="Event Name"
                                                ></v-text-field>
                                              </v-col> 
                                              <v-col class="mt-3 ml-n3" cols="12" sm="1" md="1" 
                                                style="letter-spacing: 1px; font-weight: 600; 
                                                font-family: Titillium Web; font-size: 22px;
                                                align-content: right;"
                                              >
                                                  -
                                              </v-col>                                                                           

                                              <v-col cols="12" sm="7" class="ml-n3">
                                                  <v-text-field
                                                    v-model="new_event.city_event"
                                                    label="City"
                                                    prepend-inner-icon="mdi-filter-variant"
                                                    outlined
                                                    color="var(--main-border-color)" 
                                                    style=" color: var(--main-input-color); font-size: 18px;"   
                                                    class="textFieldForm "                                    
                                                    v-on:keydown.enter.prevent="getEvents()">
                                                  ></v-text-field>
                                              </v-col>                                
                                          </v-row>                                

                                      </v-col>
                                      <v-col cols="12" sm="6" md="2" class="mt-3 ml-n3"> <!--DATE-->
                                        <v-menu
                                            v-model="menueventdata"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field 
                                                v-model="formattedEventData"
                                                label="Date"
                                                style="color: var(--main-input-color); font-size: 18px;"                           
                                                class="textFieldForm"    
                                                color="var(--main-border-color)"               
                                                outlined
                                                persistent-hint
                                                prepend-inner-icon="mdi-calendar"
                                                v-bind="attrs"
                                                v-on="on"
                                                readonly
                                            >
                                                <template slot="label">
                                                Date
                                                    <v-icon 
                                                        v-if="formattedEventData!= null"
                                                        class="mb-2"
                                                        @click ="new_event.date_event = null; menueventdata = true" 
                                                    >mdi-close</v-icon>
                                                </template>
                                            </v-text-field>
                                            </template>
                                            <v-date-picker
                                            v-model="new_event.date_event"
                                            no-title
                                            @input="menueventdata = false"
                                            locale="en"
                                            ></v-date-picker>
                                        </v-menu>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="2" class="mt-3 ml-n3"> <!--Country-->
                                        <v-autocomplete
                                          v-model="new_event.country_event"
                                          class="textFieldForm"
                                          :items="country_flags"
                                          :loading="isLoading_country"
                                          :search-input.sync="search_country"
                                          item-color="var(--main-active-select-list-color)"
                                          style=" color: var(--main-input-color); font-size: 18px;"
                                          color="var(--main-border-color)"
                                          clearable
                                          label="Country"
                                          hide-details
                                          item-text="name"
                                          item-value="id"
                                          outlined
                                        ></v-autocomplete>     
                                      </v-col>  
                                      <v-col cols="12" sm="6" md="2" class="mt-3 ml-n3"> <!--STATE-->
                                        <v-text-field
                                          v-model="new_event.state_event"
                                          style=" color: var(--main-input-color); font-size: 18px;"  
                                          class= "textFieldForm"
                                          color="var(--main-border-color)" 
                                          label="State" 
                                          outlined
                                        ></v-text-field>    
                                      </v-col>    

                                      <v-col cols="12" sm="6" md="6" class="mt-n5" >

                                          <v-row class="mt-1">

                                              <v-col cols="12" sm="6" md="6" class="ml-2 mr-2" >
                                                <v-autocomplete
                                                  v-model="new_event.fk_id_venue"
                                                  class="textFieldForm"
                                                  :items="venues"
                                                  item-color="var(--main-active-select-list-color)"
                                                  style=" color: var(--main-input-color); font-size: 18px;"
                                                  color="var(--main-border-color)"
                                                  clearable
                                                  clear-icon="mdi-close"
                                                  item-text="name"
                                                  item-value="id_address_book"
                                                  outlined
                                                  label="Venue Name"
                                                  @change="onChangeVenue"
                                                >
                                                <!--
                                                  <template #label>
                                                    <span class="">Venue Name</span>
                                                    <a @click.stop style="margin-left:5px; pointer-events: all">
                                                      <Modules
                                                        v-on:dialogModules="getTabelleVenue"
                                                        tipo="VENUE"
                                                        nome="Venue"
                                                        visualizzaCodice=true
                                                        setaction="settabelle"
                                                        getaction="gettabelle"
                                                        controller="Global"
                                                        filter="validita = 1"
                                                        :dati="[{ des: '', additional_desc: '' }]"
                                                      />
                                                    </a>
                                                  </template>  
                                                -->                                                    
                                                </v-autocomplete> 
                                              </v-col> 
                                              
                                              <v-col cols="12" sm="6" md="6" class="ml-n5 "  > <!--CITY--> 
                                                <v-text-field
                                                  v-model="new_event.venue_city"
                                                  style="width:97%; color: var(--main-input-color); font-size: 18px;"                              
                                                  class="textFieldForm"    
                                                  color="var(--main-border-color)"            
                                                  outlined
                                                  label="City"
                                                  readonly
                                                ></v-text-field>
                                              </v-col> 
                                                                    
                               
                                          </v-row>                                

                                      </v-col>
                                      <v-col cols="12" sm="6" md="2" class="mt-n1 ml-n3"> <!--Opening Door-->
                                        <v-text-field
                                          v-model="new_event.open_door_time"
                                          label="Opening Door"
                                          value="00:00"
                                          style="color: var(--main-input-color); font-size: 18px;"                              
                                          class="textFieldForm"    
                                          color="var(--main-border-color)"            
                                          outlined                                                
                                          type="time"
                                        ></v-text-field> 
                                      </v-col>
                                      <v-col cols="12" sm="6" md="2" class="mt-n1 ml-n3"> <!--Prelim Start-->
                                        <v-text-field
                                          v-model="new_event.prelim_start_time"
                                          label="Prelim Start"
                                          value="00:00"
                                          style="color: var(--main-input-color); font-size: 18px;"                              
                                          class="textFieldForm"    
                                          color="var(--main-border-color)"            
                                          outlined                                                
                                          type="time"
                                        ></v-text-field>    
                                      </v-col>  
                                      <v-col cols="12" sm="6" md="2" class="mt-n1 ml-n3"> <!--Main Card Start -->                                            
                                        <v-text-field
                                          v-model="new_event.main_card_time"
                                          label="Main Card Start"
                                          value="00:00"
                                          style="color: var(--main-input-color); font-size: 18px;"                              
                                          class="textFieldForm"    
                                          color="var(--main-border-color)"            
                                          outlined                                                
                                          type="time"
                                        ></v-text-field> 
                                      </v-col>                                                                                                                                 


                                    </v-row>


                                    <v-row>
                                        <v-col cols="12" class="text-center mt-n5" >
                                          
                                          <v-btn
                                            class="btnForm"
                                            dark
                                            @click="create_new_event"
                                          >
                                            Create
                                          </v-btn>

                                          <v-btn
                                            dark
                                            class=" ml-1 btn_bg nohover " elevation="0" 
                                            color="white"                                                     
                                            @click="Close_newEvent"
                                          >
                                            Cancel
                                          </v-btn>
                                          <br/> <br/>
                                        </v-col>                                              
                                    </v-row>                    
                                                                     
          
                                </v-col>
                              </v-row>
                            </v-container>  
                          </v-card>

                          <v-card v-if="!newEvent && valid_events.length>0" class=" ml-4 mr-4 mt-4" elevation="0"  > <!--Gestione Tabella-->
                             <v-row v-if="enable_event">
                              <v-col cols="12" style="background-color:var(--main-bg-color);"> 
                                <template>

                                  <v-data-table
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :headers="headers_event"
                                    :items="valid_events"
                                    item-key="id"
                                    key="date_event"
                                    color="var(--main-input-color)"
                                    class="elevation-0 mt-n2"
                                    :footer-props="{
                                              'items-per-page-text':'Rows per page',
                                          }"
                                  >

                                    <template v-slot:[`header.city_event`]="{}">
                                      <a class="textHeader" style="color:var(--main-dth-color);"> Event </a>
                                    </template>
                                    <template v-slot:[`header.date_event`]="{}">
                                      <a class="textHeader" style="color:var(--main-dth-color);"> Date </a>
                                    </template>  

                                    <template v-slot:[`header.status_info`]="{}" >
                                      <div class="tooltip">
                                        <v-icon color="var(--main-dth-color)" size="24">mdi-information-outline</v-icon>
                                       <span class="tooltiptext">Info</span>
                                      </div>
                                    </template> 
                                    <template v-slot:[`header.status_boot_sheet`]="{}" >
                                      <div class="tooltip">
                                        <v-icon color="var(--main-dth-color)" size="24">mdi-post-outline</v-icon>
                                       <span class="tooltiptext">Bout Sheet</span>
                                      </div>
                                    </template> 
                                    <template v-slot:[`header.status_trip`]="{}" >
                                      <div class="tooltip">
                                        <v-icon color="var(--main-dth-color)" size="24">mdi-airplane</v-icon>
                                       <span class="tooltiptext">Trip</span>
                                      </div>
                                    </template>
                                    <template v-slot:[`header.status_photo`]="{}" >
                                      <div class="tooltip">
                                        <v-icon color="var(--main-dth-color)" size="24">mdi-image</v-icon>
                                       <span class="tooltiptext">Photo</span>
                                      </div>
                                    </template>                                      
                                    <template v-slot:[`header.status_graphics`]="{}" >
                                      <div class="tooltip">
                                        <v-icon color="var(--main-dth-color)" size="24">mdi-file-chart-outline</v-icon>
                                       <span class="tooltiptext">Graphics</span>
                                      </div>
                                    </template>    
                                    <template v-slot:[`header.status_media`]="{}" >
                                      <div class="tooltip">
                                        <v-icon color="var(--main-dth-color)" size="24">mdi-music</v-icon>
                                       <span class="tooltiptext">Media</span>
                                      </div>
                                    </template>   
                                    <template v-slot:[`header.status_broadcast`]="{}" >
                                      <div class="tooltip">
                                        <v-icon color="var(--main-dth-color)" size="24">mdi-graphql</v-icon>
                                       <span class="tooltiptext">Broadcast</span>
                                      </div>
                                    </template>
                                    <template v-slot:[`header.status_social`]="{}" >
                                      <div class="tooltip">
                                        <v-icon color="var(--main-dth-color)" size="24">mdi-account-group</v-icon>
                                       <span class="tooltiptext">Social</span>
                                      </div>
                                    
                                    </template>                                                                                                                                       


                                    <template v-slot:[`item.city_event`]="{ item }">
                                      <span @click="open_event(item)" style="text-decoration: underline; cursor: pointer;"> {{ item.event_name}} {{separatore}} {{item.city_event }}</span>
                                    </template>  
                                    <template v-slot:[`item.status_info`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              readonly
                                              v-model="item.status_info"
                                          ></v-checkbox>
                                    </template>
                                    <template v-slot:[`item.status_boot_sheet`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              readonly
                                              v-model="item.status_boot_sheet"
                                          ></v-checkbox>
                                    </template> 
                                    <template v-slot:[`item.status_trip`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              readonly
                                              v-model="item.status_trip"
                                          ></v-checkbox>
                                    </template>       
                                    <template v-slot:[`item.status_photo`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              readonly
                                              v-model="item.status_photo"
                                          ></v-checkbox>
                                    </template> 
                                    <template v-slot:[`item.status_graphics`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              readonly
                                              v-model="item.status_graphics"
                                          ></v-checkbox>
                                    </template> 
                                    <template v-slot:[`item.status_media`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              readonly
                                              v-model="item.status_media"
                                          ></v-checkbox>
                                    </template>  
                                    <template v-slot:[`item.status_broadcast`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              readonly
                                              v-model="item.status_media"
                                          ></v-checkbox>
                                    </template>  
                                    <template v-slot:[`item.status_social`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              v-model="item.status_media"
                                          ></v-checkbox>
                                    </template>   

                                    <template v-slot:[`item.date_event`]="{ item }">
                                            {{formatDate(item.date_event)}} 
                                    </template>   
                                    <template v-slot:no-data>
                                        Empty Result
                                    </template>     

                                      <template v-slot:[`item.actions`]="{ item }">
                                          <v-icon color="var(--main-primary-color)"  @click="open_event(item)"
                                            >mdi-eye
                                          </v-icon>
                                          <v-icon color="var(--main-primary-color)" @click="disable_event(item)"
                                            >mdi-delete
                                          </v-icon>
                                      </template>
                                  </v-data-table>                                  


                                </template>
                                <v-overlay :value="overlay">
                                  <v-progress-circular indeterminate size="64"></v-progress-circular>
                                </v-overlay>          
                              </v-col>   
                             </v-row>  
                            <v-row v-if="!enable_event">
                              <v-col cols="12" style="background-color:var(--main-bg-color);"> 
                                <template>

                                  <v-data-table
                                    :headers="headers_event"
                                    :items="disabled_events"
                                    item-key="id"
                                    color="var(--main-input-color)"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    class="elevation-0 mt-n2"
                                    :footer-props="{
                                              'items-per-page-text':'Rows per page',
                                          }"
                                  >

                                    <template v-slot:[`header.city_event`]="{}">
                                      <a class="textHeader" style="color:var(--main-dth-color);"> Event </a>
                                    </template>
                                    <template v-slot:[`header.date_event`]="{}">
                                      <a class="textHeader" style="color:var(--main-dth-color);"> Date </a>
                                    </template>                                  
                                    <template v-slot:[`header.status_info`]="{}" >
                                      <div class="tooltip">
                                        <v-icon color="var(--main-dth-color)" size="24">mdi-information-outline</v-icon>
                                       <span class="tooltiptext">Info</span>
                                      </div>
                                    </template> 
                                    <template v-slot:[`header.status_boot_sheet`]="{}" >
                                      <div class="tooltip">
                                        <v-icon color="var(--main-dth-color)" size="24">mdi-post-outline</v-icon>
                                       <span class="tooltiptext">Bout Sheet</span>
                                      </div>
                                    </template> 
                                    <template v-slot:[`header.status_trip`]="{}" >
                                      <div class="tooltip">
                                        <v-icon color="var(--main-dth-color)" size="24">mdi-airplane</v-icon>
                                       <span class="tooltiptext">Trip</span>
                                      </div>
                                    </template>
                                    <template v-slot:[`header.status_photo`]="{}" >
                                      <div class="tooltip">
                                        <v-icon color="var(--main-dth-color)" size="24">mdi-image</v-icon>
                                       <span class="tooltiptext">Photo</span>
                                      </div>
                                    </template>                                      
                                    <template v-slot:[`header.status_graphics`]="{}" >
                                      <div class="tooltip">
                                        <v-icon color="var(--main-dth-color)" size="24">mdi-file-chart-outline</v-icon>
                                       <span class="tooltiptext">Graphics</span>
                                      </div>
                                    </template>    
                                    <template v-slot:[`header.status_media`]="{}" >
                                      <div class="tooltip">
                                        <v-icon color="var(--main-dth-color)" size="24">mdi-music</v-icon>
                                       <span class="tooltiptext">Media</span>
                                      </div>
                                    </template>   
                                    <template v-slot:[`header.status_broadcast`]="{}" >
                                      <div class="tooltip">
                                        <v-icon color="var(--main-dth-color)" size="24">mdi-graphql</v-icon>
                                       <span class="tooltiptext">Broadcast</span>
                                      </div>
                                    </template>
                                    <template v-slot:[`header.status_social`]="{}" >
                                      <div class="tooltip">
                                        <v-icon color="var(--main-dth-color)" size="24">mdi-account-group</v-icon>
                                       <span class="tooltiptext">Social</span>
                                      </div>
                                    </template>      
                                    <template v-slot:[`header.actions`]="{}">
                                      <a class="textHeader" style="color:var(--main-dth-color);"> Restore </a>
                                    </template>                                                                                                                                                                        


                                    <template v-slot:[`item.city_event`]="{ item }">
                                          {{ item.event_name}} {{separatore}} {{item.city_event }}
                                    </template>  
                                    <template v-slot:[`item.status_info`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              readonly
                                              v-model="item.status_info"
                                          ></v-checkbox>
                                    </template>
                                    <template v-slot:[`item.status_boot_sheet`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              readonly
                                              v-model="item.status_boot_sheet"
                                          ></v-checkbox>
                                    </template> 
                                    <template v-slot:[`item.status_trip`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              readonly
                                              v-model="item.status_trip"
                                          ></v-checkbox>
                                    </template>       
                                    <template v-slot:[`item.status_photo`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              readonly
                                              v-model="item.status_photo"
                                          ></v-checkbox>
                                    </template> 
                                    <template v-slot:[`item.status_graphics`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              readonly
                                              v-model="item.status_graphics"
                                          ></v-checkbox>
                                    </template> 
                                    <template v-slot:[`item.status_media`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              readonly
                                              v-model="item.status_media"
                                          ></v-checkbox>
                                    </template>  
                                    <template v-slot:[`item.status_broadcast`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              readonly
                                              v-model="item.status_media"
                                          ></v-checkbox>
                                    </template>  
                                    <template v-slot:[`item.status_social`]="{ item }">
                                          <v-checkbox
                                              class="mb-2"
                                              dense
                                              hide-details
                                              color="green"
                                              v-model="item.status_media"
                                          ></v-checkbox>
                                    </template>   

                                    <template v-slot:[`item.date_event`]="{ item }">
                                            {{formatDate(item.date_event)}} 
                                    </template>   
                                    <template v-slot:no-data>
                                        Empty Result
                                    </template>     

                                      <template v-slot:[`item.actions`]="{ item }">
                                          <v-icon color="var(--main-primary-color)" @click="event_enable(item)"
                                            >mdi-check
                                          </v-icon>
                                      </template>
                                  </v-data-table>                                  


                                </template>
                                <v-overlay :value="overlay">
                                  <v-progress-circular indeterminate size="64"></v-progress-circular>
                                </v-overlay>          
                              </v-col>   
                             </v-row>  
                          </v-card>   


                      </div> 
                      </v-tab-item>                       

                  </v-tabs-items>
          </v-col>
      </v-row>
    </div>
</template>

<script>
import EventDetail from "./EventDetail";
import MyLib from "@/components/Lib/Lib.js";
//import Modules from "@/components/TOOLS/Modules.vue";

  export default {
    components: {
       EventDetail,
  //     Modules,
    },
    data () {
      return {

        snackbar: false,
        snackbar_color: "",
        snackbar_text: "",

        dialog_new_Event:false,
        newEvent: false,
        stato:true,
        enable_event:true,
        btn_enable_disable: "Check for disable events",

        overlay: false,   
        separatore:'-',
        
        pagetabs:false,

        modalTab: "data",
        modalTab_event:"Info",

        filter_event_name:"",
        isLoading_event: false,
        search_event: null, 


        filter_country_event:"",
        filter_state:"",
        filter_city:"",
        
        filter_dateFrom: "", //rif datapicker (data non formattata)
        menu_dateFrom_filter: false, //apertura chiusura datapicker 

        filter_dateTo: "", //rif datapicker (data non formattata)
        menu_dateTo_filter: false, //apertura chiusura datapicker 
        
        //Menu Date 
        dateEventData: "", //rif datapicker (data non formattata)
        menueventdata: false, //apertura chiusura datapicker
        
        new_event:{id_event: '-1', event_name: null, city_event:'', date_event: '', country_event: '', state_event: 1, venue_name:null, venue_city:null, utc_decimale:null, open_door_time:null, prelim_start_time:null , main_card_time:null},
        reset_new_event:{id_event: '-1', event_name: null, city_event:'', date_event: '', country_event: '', state_event: 1, venue_name:null, venue_city:null, utc_decimale:null, open_door_time:null, prelim_start_time:null , main_card_time:null},

        obj_event:null,
        events:[],
        events_filter:[],

        venues:[],

        //Country Autocomplete
        isLoading_country: false,
        search_country: null, 



        modifica: 0, 
        sortDesc: true,
        sortBy: 'date_event',
        headers_event: [
          { text: 'Event', value: 'city_event' },
          { text: 'Date', value: 'date_event' },
          { text: '', value: 'status_info' },
          { text: '', value: 'status_boot_sheet' },
          { text: '', value: 'status_trip' },
          { text: '', value: 'status_photo' },
          { text: '', value: 'status_graphics' },
          { text: '', value: 'status_media' },
          { text: '', value: 'status_broadcast' },
          { text: '', value: 'status_social' },                    
          { text: '', value: 'actions', sortable: false },
        ],
        footerProps: { "items-per-page-options": [15, 30, 50, 100] },  

       }
    },
    mounted() {
          this.getVenue(); 
          //For create new event
          this.getEvents_filter();
    }, 
    computed: {

      valid_events() {
        return this.events.filter((i) => {
            return (i.validita === null) || (i.validita === 1) || (i.validita === "");
        })
      },
      valid_events_filter() {
        return this.events_filter.filter((i) => {
            return (i.validita === null) || (i.validita === 1) || (i.validita === "");
        })
      },      
      disabled_events() {
        return this.events.filter((i) => {
            return (i.validita === 0) 
        })
      },      


      filter_formattedDateFrom() {
        return MyLib.formatDate(this.filter_dateFrom)
      },   
      filter_formattedDateTo() {
              return MyLib.formatDate(this.filter_dateTo)
      },
      formattedEventData() {
              return MyLib.formatDate(this.new_event.date_event)
      }      
    },
    
    watch: {

    },  

    methods: {


        getEvents_filter(){
          var filter_page =  ''
        //  this.overlay = true
          this.events=[]
          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "getevents",
                  search: filter_page,
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                this.events_filter = res.data.events;
          //      this.overlay = false 
              } else {
                this.colore_snack = "error";
                this.messaggio_snackbar = "Events not loaded.";
                this.snackbar = true;
          //      this.overlay = false
              }     
          })
          .catch((ex) => {
              console.log(ex)
          });
        },


        getEvents(){
          var filter_page =  ''
       //   this.overlay = true
          this.events=[]
          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "getevents",
                  idevent: this.filter_event_name,
                  date_event_from: this.filter_dateFrom,
                  date_event_to: this.filter_dateTo,
                  search: filter_page,
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                this.events = res.data.events;
            //   this.overlay = false 
              } else {
                this.colore_snack = "error";
                this.messaggio_snackbar = "Events not loaded.";
                this.snackbar = true;
            //    this.overlay = false
              }     
          })
          .catch((ex) => {
            console.log(ex)
          });
        },


        disable_event(item){
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setvaliditaevent",
                    id_event: item.id_event,
                    validita: 0 ,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {   
                if (res.status == 200){
                    this.getEvents()
                    this.snackbar_color = "success";
                    this.snackbar_text = "Event disabled!. ";
                    this.snackbar = true;
                 
                }
            });
        },

        event_enable(item){
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setvaliditaevent",
                    id_event: item.id_event,
                    validita: 1 ,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {   
                if (res.status == 200){
                    this.getEvents()
                    this.snackbar_color = "success";
                    this.snackbar_text = "Event disabled!. ";
                    this.snackbar = true;
                 
                }
            });
        },        

        enable_disable_view_event(){
          if(!this.stato) {
              this.btn_enable_disable = "Check for enabled events",
              this.enable_event = false
          } 
          else{ 
              this.btn_enable_disable = "Check for disabled events",
              this.enable_event= true;
          }

        },
        clear_filter(){
          this.filter_event_name = null
          this.filter_id_role = null
          this.events=[]
        },

        close_tab(){
          this.getEvents()
          this.pagetabs = false
        },

        open_event(item){
          this.modifica = Math.round(Math.random()*100);
          this.obj_event = item
          this.pagetabs=true
        },
      
        chiudi_dettaglio(){
          this.getEvents()
          this.pagetabs =  false
        },

        create_new_event(){
          this.overlay = true
          let request = {
              controller: "Bellator",
              method: "PUT",
              richiesta: {
                  action: "setevent",
                  event: [this.new_event],
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {  
                 if (res.status == 200){
                    this.overlay = false
                    let item = res.data[0];
                    item.date_event = item.date_event.substring(0,10)
                    this.dialog_new_Event= false;
                    this.open_event(item)
                    this.newEvent =false
                    this.new_event = this.reset_new_event
                    this.snackbar_color = "success";
                    this.snackbar_text = "data saved successfully.";
                    this.snackbar = true;
                  } else {
                    this.snackbar_color = "error";
                    this.snackbar_text = "Error save data. ";
                    this.snackbar = true;
                    this.overlay = false
                  }           
          });

        },
              
        Close_newEvent(){
          this.newEvent = false
        },


        /*
        getTabelleVenue() {
          let request = {
            controller: "Global",
            method: "POST",
            richiesta: {
              action: "gettabelle",
              token: localStorage.getItem("user_token"),
              tipo: "VENUE",
              filter: "validita = 1",
            },
          };
          this.$store.dispatch("api", request).then((res) => {
            this.venues = res.data;
          });
        },
        onChangeVenue_(){
          let row_venue = this.alasql(
                "SELECT * FROM ? WHERE id_tabella = " + this.new_event.fk_id_venue ,
                    [this.venues]
                );
            this.new_event.venue_city = row_venue[0].additional_desc
        },    
        */
        getVenue() {
          let request = {
            controller: "Bellator",
            method: "POST",
            richiesta: {
              action: "getaddressbook",
              token: localStorage.getItem("user_token"),
              id_type: '3', //Venue
            },
          };
          this.$store.dispatch("api", request).then((res) => {
            this.venues = res.data.address_book;

          this.onChangeVenue()
            
          });
        },

        onChangeVenue(){
          let row_venue = this.alasql(
                "SELECT * FROM ? WHERE id_address_book = " + this.new_event.fk_id_venue ,
                    [this.venues]
                );
            if (row_venue != null && row_venue.length > 0){
              this.new_event.venue_city = row_venue[0].city  
              this.new_event.utc = row_venue[0].utc_decimale
            }
        },       

        formatDate (date) {
          if (!date) return null
          const [year, month, day] = date.split('-')
          return `${month}/${day}/${year}`
        },         
           

    }
  }
</script>
