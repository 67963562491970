<template>
    <div class=" ml-6 mr-6" v-if="Subject_Event_Detail" >
      <SubjectEventDetail 
        :ID_SUBJECT= this.ITEM.id_subject
        :ID_EVENT= this.id_Selected_Event
        :ID_ROLE = this.id_Selected_Role
        :EMAIL = this.email_selected
        v-on:closeDett="closeSubjectEventDetail"
      /> 
    </div>
   <div class="mt-n8 ml-6 mr-6" v-else >

      <v-card id="create" style="background-color: var(--main-bg-color);" elevation="0" >
          <v-row class="child-flex">

              <v-snackbar
                content-class="snackbar"
                :color="snackbar_color"
                left
                class="snackbar-gen"
                v-model="snackbar"
                :timeout="3000"
              >
                <div style="float: right;">
                    <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
                </div>
                <div>
                  <h3 :style="{ color: snackbar_text_color + '!important' }">
                    {{ snackbar_text }}
                  </h3>
                </div>
              </v-snackbar>

              <v-speed-dial
                  v-model="fab"
                  :top="top"
                  :bottom="bottom"
                  :right="right"
                  :left="left"
                  :direction="direction"
                  :open-on-hover="hover"
                  :transition="transition"
                >
                  <template v-slot:activator>
                    <v-btn
                      v-model="fab"
                      color="blue darken-2"
                      dark
                      fab
                    >
                      <v-icon v-if="fab" @click="toTop">
                         mdi-chevron-up
                      </v-icon>
                      <v-icon v-else>
                        mdi-account-circle
                      </v-icon>
                    </v-btn>
                  </template>


                  <v-btn
                    fab
                    dark
                    small
                    @click="closeSubjectEvent"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-speed-dial> 
              <!--Subject Event Dettaglio / Delete-->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" v-if="events_Subject.length>0">
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <v-card class="mt-2 ml-4 mr-4" elevation="0">
  
                            <v-col cols="12" sm="12" md="12">
                                <p class="titleLabelForm"> 
                                    EVENT MATCHED
                                </p>
                            </v-col>    
                             <v-col cols="12" sm="6" md="8" class="mt-n10" >                        
                              <v-data-table
                                :headers="headers_events_subject"
                                :items="valid_events_Subject"
                                hide-default-footer
                                :items-per-page="12000"                    
                              >
                                  <template v-slot:[`header.event`]="{}">
                                    <a class="textHeader" style="color:var(--main-dth-color);"> Event </a>
                                  </template>                   
                                  <template v-slot:[`header.role`]="{}">
                                    <a class="textHeader" style="color:var(--main-dth-color);"> Role </a>
                                  </template>                                         

                                <template v-slot:[`item.actions`]="{ item }">  
                                  <v-icon
                                    color="var(--main-primary-color)"
                                    @click="open_Subject_Event_Detail(item)"
                                  >
                                    mdi-pencil
                                  </v-icon>
                                  <v-icon
                                    color="var(--main-primary-color)"
                                    @click="deleteItem(item)"
                                  >
                                    mdi-delete
                                  </v-icon>                                  
                                </template>

                              </v-data-table>

                         
                                <v-dialog v-model="dialogDelete" max-width="1024px" >
                    <v-card class="dialog-del">                      
                      <div class="alert-top">!</div>
                      <v-card-title class="text-h5">Are you sure you want to delete this item? All data relating to the subject and event will be deleted.</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color=" darken-1" class="btn_bg nohover" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color=" " class="btnForm" dark elevation="0" @click="deleteItemConfirm">Yes, proceed</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>                                                              
                             </v-col>
                    </v-card>
                  </v-col>
                </v-row>     
              </v-col>              
                                           
              <!--Subject Event Sezione Associazione Soggetto Evento-->
              <v-col cols="12" sm="12" class="mt-8 ml-n2" >
                <v-row   style="background-color: #ffffff; " >
                  <v-col cols="12" sm="12" >
                    <v-card class="mt-5 ml-4 mr-4" elevation="0">
                          <v-row> 
  
                            <v-col cols="12" sm="12" md="12">
                                <p class="titleLabelForm"> 
                                    SEARCH EVENT</p>
                                <p class="pl-1 mb-6 textHeader text-left text-uppercase" style="font-size:16px; letter-spacing: 2.4px;">
                                  Find an Event to match or view
                                </p>
                            </v-col>      
                                                
                            <!-- Table -->
                            <v-col cols="12" sm="8" md="8"  class="mt-n10" >
                              <template>
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Start typing ..."
                                    color="var(--main-border-color)" 
                                    style=" color: var(--main-input-color); font-size: 18px;"   
                                    class="pl-1 textFieldForm" 
                                    outlined                                 
                                    single-line
                                    hide-details
                                  ></v-text-field>

                                <v-data-table
                                  :headers="headers_event"
                                  :items="valid_events"
                                  item-key="id"
                                  sort-by="date_event"
                                  class="elevation-0 data-table-internal"
                                  :search="search"                                 
                                  hide-default-footer
                                  :items-per-page="1000" 
                                >
                                  <template v-slot:[`header.event`]="{}">
                                    <a class="textHeader" style="color:var(--main-dth-color);"> Event </a>
                                  </template>
                                  <template v-slot:[`header.id_role`]="{}">
                                    <a class="textHeader" style="color:var(--main-dth-color);"> Role </a>
                                  </template>  
                                  
                                  <template v-slot:[`item.id_role`]="{ item }"> <!-- Select Role -->
                                    <v-select
                                      class="textFieldForm"
                                      item-color="var(--main-active-select-list-color)"
                                      style=" color: var(--main-input-color); font-size: 18px; margin: 0; padding: 0;"
                                      color="var(--main-border-color)" 
                                      :items="roles"
                                      v-model="item.id_role"
                                      
                                      dense
                                      clear-icon="mdi-close"
                                      clearable
                                      hide-details=""
                                      menu-props="auto"
                                      label=""
                                      item-text="des_role"
                                      item-value="id_role"   
                                    ></v-select>                                 
                                  </template>     
                                  
                                  <template v-slot:[`item.actions`]="{ item }">  
                                        <v-btn  
                                          class="btnForm"  
                                          elevation="0" color="var(--main-primary-color)" dark 
                                          @click="add_item(item)"
                                        > Apply</v-btn>
                                  </template>
                                </v-data-table>

   
                                                
                              </template>
                            </v-col>
                            <v-overlay :value="overlay">
                              <v-progress-circular indeterminate size="64"></v-progress-circular>
                            </v-overlay> 
                          </v-row>
                    </v-card>            
                  </v-col>
                </v-row>     
              </v-col>               

          </v-row>
           <br> 
      </v-card> 

    </div>
</template>
<style>
  /* Style for Bottom menu  */
  #create .v-speed-dial {
    position: fixed;
    z-index: 2;
  }

  #create .v-btn--floating {
    position: relative;
  }

</style>


<script>

import SubjectEventDetail from "./SubjectEventDetail.vue";

export default {
    components: {
        SubjectEventDetail,
    }, 
  data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",     
      
      overlay: false, 

     //x Bottom menu
      direction: 'top',
      fab: false,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
      //x Bottom to Top
      scTimer: 0,
      scY: 0,
      separatore: " - ",
      search: '',

      id_Selected_Event:null,
      id_Selected_Role:null,    
      email_selected:null,

      edited_events_Subject: {},
      editedIndex_events_Subject: -1,

      Subject_Event_Detail:false,

      editedIndex: -1,
      editedItem: { id_events_Subject:0, id_type: 0, quantity: 0 },
      defaultItem: { id_events_Subject:0, id_type: 0, quantity: 0 },
 

     // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date: null,
      menu:false,

      roles:[],
      events:[],
      events_Subject:[],
      tbl_subject_event:[],

      dialogDelete: false,
      headers_events_subject: [
          { text: 'Event', value: 'event', width:'50%' },
          { text: 'Role', value: 'role', width:'35%' },
          { text: '', value: 'actions', sortable: false, width:'15%' },
      ],

      headers_event: [
        { text: 'Event', value: 'event', width:'50%' },
        { text: 'Role', value: 'id_role', width:'35%' },
        { text: '', value: 'actions', sortable: false, width:'15%' },
      ],

    };
  },
  props: {
      ITEM: Object,
      TRIGGER: Number,
  },  
  computed: {

      valid_events() {
        return this.events.filter((i) => {
            return (i.validita === null) || (i.validita === 1) || (i.validita === "");
        })
      },

      valid_events_Subject() {
        return this.events_Subject.filter((i) => {
            return (i.id_subject === this.ITEM.id_subject);
        })
      },


    },
  watch: {
      TRIGGER: {
        immediate: true,
        handler() {
            this.getAllEvents()
            this.getRoles()
            this.getSubjectEvent();
        }
    },
   
    
  
  },  

  
  mounted() {
  
    
    //Gestione Go Bottom to TOP-----------------
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
        getSubjectEvent(){
          console.log (this.ITEM.id_role)
          this.overlay = true
          this.events_Subject=[]
          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "getsubjectevent",
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                let that = this
                that.tbl_subject_event = res.data.subject_event
               // let today = new Date()
                res.data.subject_event.forEach(element => {
                 // if (element.date_event != null){
                  //  let data_evento = new Date(element.date_event)
                  //  if (data_evento <= today ){
                      let _name = element.event_name == null ? '' :  element.event_name  
                      let city = element.city_event == null ? '' :  element.city_event
                      let _data = element.date_event == null ? '' :  that.formatDate(element.date_event)
                      let field = _name + " - " +  city  + " - " + _data
                      that.events_Subject.push({  
                          event: field,
                          id_subject_event: element.id_subject_event, 
                          id_event: element.fk_id_event, 
                          id_subject: element.fk_id_subject,
                          role: element.des_role, 
                          id_role: element.fk_id_role,
                          email: element.email,
                          validita: element.validita  
                        });
                   // }
                  //}
                });
                that.overlay = false 
              } else {
                this.colore_snack = "error";
                this.messaggio_snackbar = "Events not loaded.";
                this.snackbar = true;
                this.overlay = false
              }     
          })
          .catch((ex) => {
            if (ex.response.status == 404 || ex.response.status == undefined) {
              this.overlay = false
              this.snackbar = true;
              this.snackbar_color = "error";
              this.snackbar_text = ex;
              this.overlay = false
            }
          });
        },  

        getAllEvents(){
          this.overlay = true
          
          this.events=[]
          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "getevents",
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                let that = this
                let today = new Date()
                res.data.events.forEach(element => {
                //Prendo solo gli eventi futuri
                if (element.date_event != null){
                  let data_evento = new Date(element.date_event)
                  if (data_evento >= today ){
                    let _name = element.event_name == null ? '' :  element.event_name  
                    let city = element.city_event == null ? '' :  element.city_event
                    let _data = element.date_event == null ? '' :  that.formatDate(element.date_event) 
                    let field = _name + " - " +  city  + " - " + _data
                    that.events.push({  
                        event: field,
                        id_event: element.id_event, 
                        validita: element.validita, 
                        id_role: this.ITEM.id_role
                      });
                  }
                }
               });
                that.overlay = false 
              } else {
                this.snackbar_color = "error";
                this.messaggio_snackbar = "Events not loaded.";
                this.snackbar = true;
                this.overlay = false
              }     
          })
          .catch((ex) => {
            if (ex.response.status == 404 || ex.response.status == undefined) {
              this.overlay = false
              this.snackbar = true;
              this.snackbar_color = "error";
              this.snackbar_text = ex;
              this.overlay = false
            }
          });
        },    

        getRoles(){
          
            let request = {
                controller: "Bellator",
                method: "POST",
                richiesta: {
                    action: "getrole",
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {   
                  if (res.status == 200){
                    let that = this
                    that.roles = [];
                    that.roles =  res.data.role
                  } else {
                    this.snackbar_color = "error";
                    this.snackbar_text = "Error getrole!";
                    this.snackbar = true;
                  }           
            });
        },   

				add_item(record){
            if ( record.id_role == null){
              this.snackbar_color = "error";
              this.snackbar_text = "Role is a required field.";
              this.snackbar_icon = "mdi-window-close";
              this.snackbar = true;
              return
            }

           let presente = this.alasql(
            "SELECT  count(*) as contatore FROM ? where fk_id_subject = " + this.ITEM.id_subject  + " and fk_id_event = " + record.id_event ,
            [this.tbl_subject_event]
            );
            if (presente[0].contatore > 0)
            {
              this.snackbar_color = "error";
              this.snackbar_text = "Subject already selected for this event.";
              this.snackbar_icon = "mdi-window-close";
              this.snackbar = true;
              return
            }

            let new_subject_event = [{id_subject_event:-1, fk_id_subject: this.ITEM.id_subject,  fk_id_event: record.id_event , fk_id_role : record.id_role, subject_event_mail: 1 , subject_event_sms: 1 }]
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setsubjectevent",
                    subject_event: new_subject_event,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {    
              this.overlay = false 
                  if (res.status == 200){
                      this.snackbar_color = "success";
                      this.snackbar_text = "Entry saved successfully. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.getSubjectEvent();
                      this.getAllEvents();
                      this.overlay = false                      
                    } else {
                      this.snackbar_color = "error";
                      this.snackbar_text = "Data Saving Error. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.overlay = false
                    }           
            });           
				},


        open_Subject_Event_Detail(item){
          this.id_Selected_Event = item.id_event
          this.id_Selected_Role = item.id_role
          this.email_selected = item.email
          this.Subject_Event_Detail = true
        },

				deleteItem (item) {
						this.editedIndex_events_Subject = this.events_Subject.indexOf(item)
						this.edited_events_Subject = Object.assign({}, item)
						this.dialogDelete = true
				},

				deleteItemConfirm () {
					this.events_Subject.splice(this.editedIndex_events_Subject, 1)
					this.delete(this.edited_events_Subject)
					this.closeDelete()
				},

				closeDelete () {
					this.dialogDelete = false
					this.$nextTick(() => {
						this.edited_events_Subject = {}
						this.editedIndex_events_Subject = -1
					})
				},      

				delete(item){
					this.overlay = true
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action: "delsubjectevent",
                  id_subject: item.id_subject,
                  id_event: item.id_event,
									id_subject_event: item.id_subject_event,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
									this.overlay = false   
									this.snackbar_color = "success";
									this.snackbar_text = "Item deleted! ";
                  this.snackbar_icon = "mdi-window-close";
									this.snackbar = true;   
                  this.getSubjectEvent();               
							}
					});
				},				
				
        closeSubjectEventDetail(){
          //this.$emit("closeDettSubject", "");
          this.Subject_Event_Detail=false
        },

        closeSubjectEvent(){
          this.$emit("closeDettSubject", "");
        },

        formatDate (date) {
          if (!date) return null
          const [year, month, day] = date.split('-')
          return `${month}/${day}/${year}`
        },     

      //Gestione Go Bottom to TOP----------------------------

        handleScroll: function () {
          if (this.scTimer) return;
          this.scTimer = setTimeout(() => {
            this.scY = window.scrollY;
            clearTimeout(this.scTimer);
            this.scTimer = 0;
          }, 100);
        },
        toTop: function () {
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        },      

      //--------------------------------------------

     
  },
};
</script>