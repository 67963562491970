<template>
      <v-card class=" ml-8 mr-4" elevation="0">
            <v-snackbar
              content-class="snackbar"
              :color="snackbar_color"
              left
              class="snackbar-gen"
              v-model="snackbar"
              :timeout="3000"
            >
              <div style="float: right;">
                  <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
              </div>
              <div>
              <h3 :style="{ color: snackbar_text_color + '!important' }">
                {{ snackbar_text }}
              </h3>
              </div>
            </v-snackbar>
            <v-row > 
              <v-col cols="12" sm="12" md="12">
                  <p class="labelForm"> 
                      WEIGHT RESUME
                  </p>
              </v-col> 
              <v-col cols="12" sm="6" md="4" class="mt-n5"> <!--DATE-->
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="subject_ev_weight_date"
                      label="Date"
                      style="color: var(--main-input-color); font-size: 18px;"                              
                      class="textFieldForm"    
                      color="var(--main-border-color)"               
                      outlined
                      persistent-hint
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu = false"
                    locale="en"
                  ></v-date-picker>
                </v-menu> 
              </v-col>    
              <v-col cols="12" sm="6" md="3" class="mt-n5 "> <!--time-->
                <v-text-field
                  v-model="subject_ev_weight_time"
                  label="Time"
                  value="00:00"
                  style="color: var(--main-input-color); font-size: 18px;"                              
                  class="textFieldForm"    
                  color="var(--main-border-color)"            
                  outlined                                                
                  type="time"
                ></v-text-field> 
              </v-col>  
  
              <v-col cols="12" sm="6" md="4" class="mt-n5 "> <!--Weight-->
                <v-text-field
                  v-model="subject_ev_weight_weight"
                  label="Weight"
                  value="00:00"
                  style="color: var(--main-input-color); font-size: 18px;"                              
                  class="textFieldForm"    
                  color="var(--main-border-color)"            
                  outlined                                                
                ></v-text-field> 
              </v-col>    

              <v-col cols="12" sm="6" md="1" class="mt-n2 ml-n2"> <!--Button Add-->
                  <v-btn
                    class="btn_plus"
                    color="var(--main-primary-color)"
                    style="color:var(--main-primary_text-color);"
                    @click="add_item"
                    elevation="0"
                  >
                    <v-icon dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn> 
              </v-col>   
      

          
              <!-- Table -->
              <v-col cols="12" sm="9"  class="mt-n5" v-if="subject_ev_weight_array_length>0">
                <template>
                  <v-data-table
                    :headers="headers"
                    :items="subject_ev_weight_array"
                    hide-default-footer
                    :items-per-page="12000"                    
                  >
                      <template v-slot:[`header.data`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Date </a>
                      </template>                                                       
                      <template v-slot:[`header.time`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Time </a>
                      </template>
                                        
                      <template v-slot:[`header.weight`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Weight </a>
                      </template>

                    <template v-slot:[`item.data`]="{ item }">
                          {{formatDate(item.date)}} 
                    </template>   

                    <template v-slot:[`item.time`]="{ item }">
                          {{  item.time != null ? item.time.substring(0, 5) : "" }} 
                    </template>       
                    <template v-slot:[`item.actions`]="{ item }">  
                      <v-icon
                        color="var(--main-primary-color)"
                        @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>

                  </v-data-table>

                  <v-dialog v-model="dialogDelete" max-width="1024px" >
                    <v-card class="dialog-del">                      
                      <div class="alert-top">!</div>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color=" darken-1" class="btn_bg nohover" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color=" " class="btnForm" dark elevation="0" @click="deleteItemConfirm">Yes, proceed</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>  
                                  
                </template>
              </v-col>
              <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
              </v-overlay> 
            </v-row>
      </v-card>   
      
</template>

<script>

export default {   
	data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",     

      subject_ev_weight_array:[],
      edited_subject_ev_weight: {},
      editedIndex_subject_ev_weight: -1,

      subject_ev_weight_time:null,
      subject_ev_weight_weight:null,
      subject_ev_weight_array_length:0,

      date: null,
      menu:false,

      overlay: false,  

      dialogDelete: false,
      headers: [
        { text: 'Date', value: 'data' },
        { text: 'Time', value: 'time' },
        { text: 'Weight', value: 'weight' },
        { text: '', value: 'actions', sortable: false },
      ],
     
    };		
	},
  props: {
      ID_SUBJECT: Number,
      ID_EVENT: Number,
  },
  computed: {
      subject_ev_weight_date () {
        return this.formatDate(this.date)
      },
    },

  watch: {
        ID_EVENT: {
          immediate: true,
          handler() {
          this.getSubjectEvWeight()
          },

      },
  },  	
  mounted() {
  },	
    
 
	methods:{

				getSubjectEvWeight(){
					let that = this
					that.overlay = true
					that.subject_ev_weight_array=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getsubjectevweight",
									id_subject: this.ID_SUBJECT,
                  id_event: this.ID_EVENT,
									token: localStorage.getItem("user_token"),
							},
					}
					that.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
								that.subject_ev_weight_array = res.data.subjectevweight;
                that.subject_ev_weight_array_length = that.subject_ev_weight_array.length
								that.overlay = false 
							} else {
								that.snackbar_color = "error";
								that.snackbar_test = "Table Data not loaded.";
								that.snackbar = true;
								that.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							this.overlay = false
					});
				},

				deleteItem (item) {
						this.editedIndex_subject_ev_weight = this.subject_ev_weight_array.indexOf(item)
						this.edited_subject_ev_weight = Object.assign({}, item)
						this.dialogDelete = true
				},

				deleteItemConfirm () {
					this.subject_ev_weight_array.splice(this.editedIndex_subject_ev_weight, 1)
					this.delete(this.edited_subject_ev_weight)
					this.closeDelete()
				},

				closeDelete () {
					this.dialogDelete = false
					this.$nextTick(() => {
						this.edited_subject_ev_weight = {}
						this.editedIndex_subject_ev_weight = -1
					})
				},      

				add_item(){

            if (this.date == null){
              this.snackbar_color = "error";
              this.snackbar_text = "You have to Insert a date. ";
              this.snackbar_icon = "mdi-window-close";
              this.snackbar = true;
              return
            }          
            let new_subjectevweight = [{id_subject_ev_weight:-1,  fk_id_subject: this.ID_SUBJECT, fk_id_event: this.ID_EVENT, date: this.date, time: this.subject_ev_weight_time, weight: this.subject_ev_weight_weight,  id_weight_category: this.weight_category, }]
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setsubjectevweight",
                    subjectevweight: new_subjectevweight,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {     
                  if (res.status == 200){
                      this.date = null
                      this.subject_ev_weight_time = null 
                      this.subject_ev_weight_weight = null
                      this.date = null
                      this.overlay = false
                      this.snackbar_color = "success";
                      this.snackbar_text = "Entry saved successfully. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.getSubjectEvWeight();
                    } else {
                      this.snackbar_color = "error";
                      this.snackbar_text = "Data Saving Error. ";
                      this.snackbar = true;
                      this.overlay = false
                    }           
            });           
				},

				delete(item){
					this.overlay = true
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action: "delsubjectevweight",
									id_subject_ev_weight: item.id_subject_ev_weight,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
									this.overlay = false   
									this.snackbar_color = "success";
									this.snackbar_text = "Item deleted!";
                  this.snackbar_icon = "mdi-window-close";
									this.snackbar = true;                    
							}
					});
				},				
				
        formatDate (date) {
          if (!date) return null
          const [year, month, day] = date.split('-')
          return `${month}/${day}/${year}`
        },      

	}
}

</script>
