<template>
      <v-card class="mt-5 ml-4 mr-4" elevation="0">
            <v-snackbar
              content-class="snackbar"
              :color="snackbar_color"
              left
              class="snackbar-gen"
              v-model="snackbar"
              :timeout="3000"
            >
              <div style="float: right;">
                  <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
              </div>
              <div>
              <h3 :style="{ color: snackbar_text_color + '!important' }">
                {{ snackbar_text }}
              </h3>
              </div>
            </v-snackbar>
            <v-row> 
              <v-col cols="12" sm="12" md="12">
                  <p class="titleLabelForm"> 
                      FREE TICKETS
                  </p>
              </v-col> 
              <v-col cols="12" sm="6" md="4" class="mt-n5 ">
                <v-autocomplete
                  v-model="subject_ev_ticket_free_id_type"
                  class="textFieldForm"
                  :items="tickettype"
                  item-color="var(--main-active-select-list-color)"
                  style=" color: var(--main-input-color); font-size: 18px;"
                  color="var(--main-border-color)"
                  clearable
                  clear-icon="mdi-close"
                  item-text="nome"
                  item-value="id_ticket_type"
                  outlined
                  label="Tickets Types"
                >
                </v-autocomplete>
              </v-col> 
              <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--Quantity-->
                  <v-text-field
                    v-model="subject_ev_ticket_free_quantita"
                    label="Quantity"
                    outlined
                    color="var(--main-border-color)" 
                    style=" color: var(--main-input-color); font-size: 18px;"   
                    class="textFieldForm"
                    type="number"
                  ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="6" md="1" class="mt-n2"> <!--Button Add-->
                  <v-btn
                    class="btn_plus"
                    color="var(--main-primary-color)"
                    style="color:var(--main-primary_text-color);"
                    @click="add_item"
                    elevation="0"
                  >
                    <v-icon dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn> 
              </v-col>       

              <!-- Table -->
              <v-col cols="12" sm="9"  class="mt-n5" >
                <template>
                  <v-data-table
                   v-if="subject_ev_ticket_free_array_length>0"
                    :headers="headers"
                    :items="subject_ev_ticket_free_array"
                    hide-default-footer
                    :items-per-page="12000"                    
                  >
                      <template v-slot:[`header.nome`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Ticket Types </a>
                      </template>                                                       
                      <template v-slot:[`header.quantita`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Number </a>
                      </template>
                      <template v-slot:[`header.totale`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Total </a>
                      </template>                                        
                      <template v-slot:[`header.disponibilita`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Availability </a>
                      </template>
                      
         

                    <template v-slot:[`item.actions`]="{ item }">  
                      <v-icon
                        color="var(--main-primary-color)"
                        @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>

                  </v-data-table>
                                  
                </template>
              </v-col>


              <v-dialog v-model="dialogDelete" max-width="1024px" >
                    <v-card class="dialog-del">                      
                      <div class="alert-top">!</div>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color=" darken-1" class="btn_bg nohover" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color=" " class="btnForm" dark elevation="0" @click="deleteItemConfirm">Yes, proceed</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

              <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
              </v-overlay> 

              
            </v-row>
      </v-card>   
</template>
<script>

export default {  
      components: {
    } ,
	data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",   

      tickettype:[],  
      ticket_array:[],
      ticket_type:null,

      subject_ev_ticket_free_array:[],
      subject_ev_ticket_free_array_length:0,
      edited_subject_ev_ticket_free: {},
      editedIndex_subject_ev_ticket_free: -1,

      subject_ev_ticket_free_id_type:null,
      subject_ev_ticket_free_quantita:null,

      overlay: false,  

      dialogDelete: false,
      headers: [
        { text: 'Ticket Types', value: 'nome' },
        { text: 'Number', value: 'quantita' },
        { text: '', value: 'actions', sortable: false },
        { text: 'Total', value: 'totale' },        
        { text: 'Availability', value: 'disponibilita' },
      ],
           

    };		
	},
  props: {
      ID_EVENT: Number,
      ID_SUBJECT:Number,
  },
  computed: {
    },

  watch: {
  },  	

  mounted() {
    this.getticket_type();
    
  },	
    

  methods:{

        getticket_type(){  
					this.overlay = true
					this.tickettype=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getticket_type",
									idevent: this.ID_EVENT,
                  tipo:'crud',
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
								this.tickettype = res.data.ticket_type;
								this.overlay = false 
                this.getSubjectEvTicketFree()
							} else {
								this.snackbar_color = "error";
								this.snackbar_test = "Table Data not loaded.";
								this.snackbar = true;
								this.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							this.overlay = false
					});
				},

				getSubjectEvTicketFree(){
					let that = this
					that.overlay = true
					that.subject_ev_ticket_free_array=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getsubjectevticketfree",
									id_subject: this.ID_SUBJECT,
                  id_event: this.ID_EVENT,
									token: localStorage.getItem("user_token"),
							},
					}
					that.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
								that.subject_ev_ticket_free_array = res.data.subjectevticketfree;
                that.subject_ev_ticket_free_array_length = that.subject_ev_ticket_free_array.length
								that.overlay = false 
							} else {
								that.snackbar_color = "error";
								that.snackbar_test = "Table Data not loaded.";
								that.snackbar = true;
								that.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							this.overlay = false
					});
				},      
        
        
        async add_item(){
          
          await this.getticket();

					let that = this
					that.overlay = true
					that.subject_ev_ticket_free_array=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getsubjectevticketfree",
									id_subject: this.ID_SUBJECT,
                  id_event: this.ID_EVENT,
									token: localStorage.getItem("user_token"),
							},
					}
					that.$store.dispatch("api", request).then((res) => {  
              var disponibilita = null
              var id_ticket_selected = null 
							if (res.status == 200){
                
								that.subject_ev_ticket_free_array = res.data.subjectevticketfree;
                let ticket_selected_obj  = this.alasql(
                    "SELECT *  FROM  ? where  nome = '" + that.subject_ev_ticket_free_id_type +"'"  , 
                    [that.subject_ev_ticket_free_array]
                  );


                  if (ticket_selected_obj.length == 0)  //non è ancora in tabella dei ticket free già selezionati
                  {
                    let ticket_type_obj  = this.alasql(
                    "SELECT *  FROM  ? where  id_type = " + that.subject_ev_ticket_free_id_type  , 
                    [that.ticket_array]
                     );
                    if (ticket_type_obj.length > 0){
                      disponibilita = ticket_type_obj[0].quantity
                      id_ticket_selected = ticket_type_obj[0].id_ticket
                    } 
                  }else{
                    disponibilita = ticket_selected_obj[0].disponibilita
                    id_ticket_selected = ticket_selected_obj[0].fk_id_ticket
                  }

                if( parseInt(this.subject_ev_ticket_free_quantita) <= parseInt(disponibilita)) 
                  this.add_item_confirm(id_ticket_selected)
                else {
                  that.snackbar_color = "error";
                  that.snackbar_text = "Quantity exceeds the total number of tickets.";
                  that.snackbar = true;
                  that.overlay = false
                }
								that.overlay = false 
							} else {
								that.snackbar_color = "error";
								that.snackbar_test = "Table Data not loaded.";
								that.snackbar = true;
								that.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							this.overlay = false
					});
				},


        getticket(){

          return new Promise((resolve, reject) => {
                let that = this
                this.overlay = true
                this.ticket_array=[]
                let request = {
                    controller: "Bellator",
                    method: "POST",
                    richiesta: {
                        action: "getticket",
                        idevent: this.ID_EVENT,
                        token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => {   
                    if (res.status == 200){
                      that.ticket_array = res.data.ticket;
                      resolve(res);
                      this.overlay = false 
                    }     
                })
                .catch((ex) => {
                    console.log(ex)
                    reject(ex);
                    this.overlay = false
                });
          });
				},


				add_item_confirm(id_ticket){

            let new_subjectevticket_free = [{id_subject_ev_ticket_free:-1,  fk_id_subject: this.ID_SUBJECT, fk_id_event: this.ID_EVENT,  fk_id_ticket: id_ticket,  quantita: this.subject_ev_ticket_free_quantita}]
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setsubjectevticketfree",
                    subjectevticketfree: new_subjectevticket_free,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {     
                  if (res.status == 200){
                      this.date = null
                      this.subject_ev_ticket_free_ticket_free = null
                      this.date = null
                      this.overlay = false
                      this.snackbar_color = "success";
                      this.snackbar_text = "Entry saved successfully. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.getSubjectEvTicketFree();
                    } else {
                      this.snackbar_color = "error";
                      this.snackbar_text = "Data Saving Error. ";
                      this.snackbar = true;
                      this.overlay = false
                    }           
            });           
				},

				delete(item){
					this.overlay = true
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action: "delsubjectevticketfree",
									id_subject_ev_ticket_free: item.id_subject_ev_ticket_free,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
									this.overlay = false 
                  this.getSubjectEvTicketFree()  
									this.snackbar_color = "success";
									this.snackbar_text = "Item deleted!";
                  this.snackbar_icon = "mdi-window-close";
									this.snackbar = true;                    
							}
					});
				},

				deleteItem (item) {
						this.editedIndex_subject_ev_ticket_free = this.subject_ev_ticket_free_array.indexOf(item)
						this.edited_subject_ev_ticket_free = Object.assign({}, item)
						this.dialogDelete = true
				},

				deleteItemConfirm () {
					this.subject_ev_ticket_free_array.splice(this.editedIndex_subject_ev_ticket_free, 1)
					this.delete(this.edited_subject_ev_ticket_free)
					this.closeDelete()
				},

				closeDelete () {
					this.dialogDelete = false
					this.$nextTick(() => {
						this.edited_subject_ev_ticket_free = {}
						this.editedIndex_subject_ev_ticket_free = -1
					})
				},          
	}
}

</script>
