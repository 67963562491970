<template>
      <v-card class="mt-5 ml-4 mr-4" elevation="0">
            <v-snackbar
              content-class="snackbar"
              :color="snackbar_color"
              right
              v-model="snackbar"
              :timeout="3000"
            >
              <div style="float: right;">
                  <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
              </div>
              <div>
              <h3 :style="{ color: snackbar_text_color + '!important' }">
                {{ snackbar_text }}
              </h3>
              </div>
            </v-snackbar>
            <v-row > 
              <v-col cols="12" sm="12" md="12">
                  <p class="titleLabelForm"> 
                      DEDUCTION
                  </p>
              </v-col> 
              <v-col cols="12" sm="6" md="3" class="mt-n5"> <!--DATE-->
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="subject_ev_deduction_date"
                      label="Date"
                      style="color: var(--main-input-color); font-size: 18px;"                              
                      class="textFieldForm"    
                      color="var(--main-border-color)"               
                      outlined
                      persistent-hint
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu = false"
                    locale="en"
                  ></v-date-picker>
                </v-menu> 
              </v-col>    
              <v-col cols="12" sm="6" md="4" class="mt-n5">  <!--CURRENCY-->
                  <v-autocomplete
                    item-color="var(--main-active-select-list-color)"
                    v-model="subject_ev_deduction_id_currency"
                    class="textFieldForm"
                    :items="country_currency"
                    :loading="isLoading_currency"
                    :search-input.sync="search_currency"
                    style=" font-size: 18px;"
                    color="var(--main-border-color)"
                    clearable
                    label="Currency"
                    hide-details
                    item-text="des"
                    item-value="id"
                    outlined
                  ></v-autocomplete>                
              </v-col> 
  
              <v-col cols="12" sm="6" md="2" class="mt-n5 "> <!--Amount-->
                <v-text-field
                  v-model="subject_ev_deduction_amount"
                  label="Amount"
                  style="color: var(--main-input-color); font-size: 18px;"                              
                  class="textFieldForm"    
                  color="var(--main-border-color)"            
                   type="number"
                  outlined                                                
                ></v-text-field> 
              </v-col>    
              <v-col cols="12" sm="6" md="3" class="mt-n3 ">  <!--Selec Contact-->
                    <v-autocomplete
                      v-model="subject_ev_deduction_id_subject_insert"
                      item-color="var(--main-active-select-list-color)"
                      class="textFieldForm mt-n2"
                      style=" color: var(--main-input-color); font-size: 18px;"   
                      color="var(--main-border-color)"                                  
                      :items="valid_contact"
                      :loading="isLoading_contact"
                      :search-input.sync="search_contact"
                      clearable
                      :item-text="(item) => item.name + ' ' + item.surname"
                      item-value="id_subject"
                      label="Insert By"
                      outlined
                    ></v-autocomplete>                

              </v-col>     
              <v-col cols="12" sm="6" md="9" class="mt-n5 "> <!--Amount-->
                <v-text-field
                  v-model="subject_ev_deduction_description"
                  label="Description"
                  style="color: var(--main-input-color); font-size: 18px;"                              
                  class="textFieldForm"    
                  color="var(--main-border-color)"            
                  outlined     
                                                             
                ></v-text-field> 
              </v-col>                         

              <v-col cols="12" sm="6" md="1" class="mt-n2"> <!--Button Add-->
                  <v-btn
                    class="btn_plus"
                    color="var(--main-primary-color)"
                    style="color:var(--main-primary_text-color);"
                    @click="add_item"
                    elevation="0"
                  >
                    <v-icon dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn> 
              </v-col>   
      

          
              <!-- Table-->
              <v-col cols="12" sm="9"  class="mt-n5"  v-if="subject_ev_deduction_array_length>0">
                <template>
                  <v-data-table
                    :headers="headers"
                    :items="subject_ev_deduction_array"
                    hide-default-footer
                    :items-per-page="12000"                    
                  >
                      <template v-slot:[`header.date`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Date </a>
                      </template>                                                       
                      <template v-slot:[`header.des_currency`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Currency </a>
                      </template>
                      <template v-slot:[`header.amount`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Amount </a>
                      </template>
                      <template v-slot:[`header.id_subject_insert`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Insert By </a>
                      </template>                      
                      <template v-slot:[`header.description`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Description </a>
                      </template>                      

                    <template v-slot:[`item.date`]="{ item }">
                          {{formatDate(item.date)}} 
                    </template>   
                    <template v-slot:[`item.id_subject_insert`]="{ item }">
                          {{getNamebyId(item.id_subject_insert)}} 
                    </template>                     
       
                    <template v-slot:[`item.actions`]="{ item }">  
                      <v-icon
                        color="var(--main-primary-color)"
                        @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>

                  </v-data-table>

                  <v-dialog v-model="dialogDelete" max-width="1024px" >
                    <v-card class="dialog-del">                      
                      <div class="alert-top">!</div>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color=" darken-1" class="btn_bg nohover" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color=" " class="btnForm" dark elevation="0" @click="deleteItemConfirm">Yes, proceed</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>  
                                  
                </template>
              </v-col>
              <v-col cols="12" sm="1"/>
              <v-col cols="12" sm="2" v-if="subject_ev_deduction_array_length>0" >
                <v-text-field
                  v-model="total_amount"
                  label="Total Deduction "
                  style="color: var(--main-input-color); font-size: 18px;"                              
                  class="textHeader"    
                  color="var(--main-border-color)"            
                  readonly
                  outlined                                                
                ></v-text-field> 
              </v-col>
              <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
              </v-overlay> 
            </v-row>
      </v-card>   
      
</template>

<script>

export default {   
	data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",     

      subject_ev_deduction_array:[],
      subject_ev_deduction_array_length:0,
      edited_subject_ev_deduction: {},
      editedIndex_subject_ev_deduction: -1,

      all_contact:[],
      valid_contact:[],
      
      isLoading_contact: false,
      search_contact: null,
      isLoading_currency: false,
      search_currency: null, 

      subject_ev_deduction_id_currency:null,
      subject_ev_deduction_amount:null,
      subject_ev_deduction_id_subject_insert:null,
      subject_ev_deduction_description:null,
      total_amount:0,

      date: null,
      menu:false,

      overlay: false,  

      dialogDelete: false,
      headers: [
        { text: 'Date', value: 'date' },
        { text: 'Currency', value: 'des_currency' },
        { text: 'Amount', value: 'amount' },
        { text: 'Insert By', value: 'id_subject_insert' },
        { text: 'Description', value: 'description' },
        { text: '', value: 'actions', sortable: false },
      ],
     
    };		
	},
  props: {
      ID_SUBJECT: Number,
      ID_EVENT: Number,
  },
  computed: {
      subject_ev_deduction_date () {
        return this.formatDate(this.date)
      },
      country_currency(){
         return this.alasql(
            "SELECT id, Country +  ' - ' + Cod AS  des FROM ? ",
            [this.currency]
        );
      }          
    },

  watch: {
        ID_EVENT: {
          immediate: true,
          handler() {
          this.getSubjectEvDeduction()
          },

      },
  },  	
  mounted() {
    
  },	
    
 
	methods:{

				getSubjectEvDeduction(){
					let that = this
					that.overlay = true
					that.subject_ev_deduction_array=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getsubjectevdeduction",
									id_subject: this.ID_SUBJECT,
                  id_event: this.ID_EVENT,
									token: localStorage.getItem("user_token"),
							},
					}
					that.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
								that.subject_ev_deduction_array = res.data.subjectevdeduction;
                that.subject_ev_deduction_array_length = that.subject_ev_deduction_array.length
								that.overlay = false 
                that.getAllContact()
                                      this.somma_amount()     
							} else {
								that.snackbar_color = "error";
								that.snackbar_test = "Table Data not loaded.";
								that.snackbar = true;
								that.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							this.overlay = false
					});
				},

        getAllContact(){
						//restituisce  l'elenco dei contatti ovvero i subject con  idrole = 3 o altri
						this.overlay = true
						let request = {
								controller: "Bellator",
								method: "POST",
								richiesta: {
										action: "getsubject",
										roleid_in: "(3)", //subject.id_role IN ..inserire ruoli separati da virgola
										token: localStorage.getItem("user_token"),
								},
						}
						this.$store.dispatch("api", request).then((res) => {   
								if (res.status == 200){
									this.all_contact = res.data.subject;
									this.overlay = false 
                  console.log ("BBB", res.data.subject)
                  this.valid_contact = this.alasql(
                      "SELECT * FROM ? where id_subject != " + this.ID_SUBJECT + " and validita = 1",
                      [res.data.subject]
                  );                  
								} else {
									this.snackbar_color = "error";
									this.snackbar_text = "Event contact not loaded.";
									this.snackbar = true;
									this.overlay = false
								}     
						})
						.catch((ex) => {
								console.log(ex)
								this.overlay = false
						});
				}, 
        
        getNamebyId(id_subject){
            if (this.valid_contact.length > 0 & id_subject != null)
            {
              let _name_surname = this.alasql(
                  "SELECT name, surname  FROM ?  where id_subject = " + id_subject ,
                  [this.valid_contact]
              );
              return _name_surname[0].name + ' ' + _name_surname[0].surname 
            }
        },

				deleteItem (item) {
						this.editedIndex_subject_ev_deduction = this.subject_ev_deduction_array.indexOf(item)
						this.edited_subject_ev_deduction = Object.assign({}, item)
						this.dialogDelete = true
				},

				deleteItemConfirm () {
					this.subject_ev_deduction_array.splice(this.editedIndex_subject_ev_deduction, 1)
					this.delete(this.edited_subject_ev_deduction)
					this.closeDelete()
          this.getSubjectEvDeduction()
				},

				closeDelete () {
					this.dialogDelete = false
					this.$nextTick(() => {
						this.edited_subject_ev_deduction = {}
						this.editedIndex_subject_ev_deduction = -1
					})
				},      

				add_item(){
            if (this.date == null){
              this.snackbar_color = "error";
              this.snackbar_text = "You have to Insert a date. ";
              this.snackbar_icon = "mdi-window-close";
              this.snackbar = true;
              return
            }    
            
            let _des_currency = this.alasql(
                "SELECT id, Cod, Country +  ' - ' + Currency AS  des FROM ?  where id = " + this.subject_ev_deduction_id_currency,
                [this.currency]
            );
            let new_subjectevdeduction = [{id_subject_ev_deduction:-1,  fk_id_subject: this.ID_SUBJECT, fk_id_event: this.ID_EVENT, date: this.date, id_currency: this.subject_ev_deduction_id_currency, des_currency:_des_currency[0].Cod, amount: this.subject_ev_deduction_amount,  id_subject_insert: this.subject_ev_deduction_id_subject_insert, description:this.subject_ev_deduction_description }]
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setsubjectevdeduction",
                    subjectevdeduction: new_subjectevdeduction,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {     
                  if (res.status == 200){
                      this.date = null
                      this.subject_ev_deduction_time = null 
                      this.subject_ev_deduction_deduction = null
                      this.date = null
                      this.overlay = false
                      this.snackbar_color = "success";
                      this.snackbar_text = "Entry saved successfully. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;                 
                      this.getSubjectEvDeduction();
                    } else {
                      this.snackbar_color = "error";
                      this.snackbar_text = "Data Saving Error. ";
                      this.snackbar = true;
                      this.overlay = false
                    }           
            });           
				},

				delete(item){
					this.overlay = true
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action: "delsubjectevdeduction",
									id_subject_ev_deduction: item.id_subject_ev_deduction,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
									this.overlay = false   
									this.snackbar_color = "success";
									this.snackbar_text = "Item deleted! ";
                  this.snackbar_icon = "mdi-window-close";
									this.snackbar = true;                    
							}
					});
				},				
				
        formatDate (date) {
          if (!date) return null
          const [year, month, day] = date.split('-')
          return `${month}/${day}/${year}`
        },      

        
        somma_amount () {
          let somma = this.alasql(
              "SELECT SUM (CAST(amount AS INT)) as Tot FROM ? ",
              [this.subject_ev_deduction_array]
          );
          this.total_amount = somma[0].Tot
        }

	}
}

</script>
