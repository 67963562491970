import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from '../router'
Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null,
    },

    permesso_accesso_modulo:null,

    versione: "3.9.4",
    testo_azienda: "",
    pagina_attuale: "",
    check_paginaAttuale: 0,
    task_validi:0,
    task_scaduti:0,
    conferma_cambio_rotta: false,
    rotta_to: null,
    rotta_from: null,
    show_admin_tools: localStorage.id_profilo_utente == 1 ? true : false,
    menu_sx: [],
    snackbar: {
      stato: false,
      messaggio: "",
      colore: "success",
    },
    token: localStorage.getItem("user_token") || "",
    config: {
      db_name: "demo",
      ip: "",
    },
    id_utente: localStorage.getItem("id") || "",
    status: "",
  },
  mutations: {
    check_accesso_modulo (state, value){
      state.permesso_accesso_modulo = value;
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    
    SET_USER(state, data) {
      state.user.data = data;
    },
    setInfo(state, data) {
      state.config = data.config;
    },
    api_request(state) {
      state.status = "loading";
    },
    close_snackbar(state) {
      setTimeout(() => {
        state.snackbar.stato = false;
      }, 3000);
    }
  },
  actions: {
    fetchUser({
      commit
    }, user) {
      // console.log("firebase us", user);
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          id: user.uid,
          creato: user.metadata.a,
        });
      } else {
        commit("SET_USER", null);
      }
    },


    setConfig({
      commit
    }, data) {
      commit("setInfo", data);
    },
    
    api({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        commit("api_request");
        axios({
            url: store.state.config.ip + "api/" + data.controller,
            data: data.richiesta,
            method: data.method,
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
          //  console.log("ENTRATO ERRROREEEE")

            if (err.response == undefined || (err.response.status == 401 || err.response.status == 403 || err.status == 401 || err.status == 403)) {
              localStorage.clear();
              router.push("/login").catch(() => {});
            }
            console.log(err);
            reject(err);
          });
      });
    },
    

    modulepermits({
      commit
    }, data) {
        //console.log(data)
        let modulo = data.richiesta.modulo
        //console.log ("modulo:",modulo)
        let moduli_attivi= []
        let moduli = JSON.parse(data.richiesta.moduli);
        moduli.forEach((elem) => {
          moduli_attivi.push ( elem.path)
        });
        //console.log(moduli_attivi)
        if (moduli_attivi.indexOf(modulo) == -1) {
            commit("check_accesso_modulo", false);
            return(false);
        }else{
          commit("check_accesso_modulo", true);
          return(true);
        }
    },


    api_blob({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        commit("api_request");
        axios({
            url: store.state.config.ip + "api/" + data.controller,
            data: data.richiesta,
            method: data.method,
            responseType: 'blob'
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
           // console.log("ENTRATO ERRROREEEE")
            if (err.response == undefined || (err.response.status == 401 || err.response.status == 403 || err.status == 401 || err.status == 403)) {
              localStorage.clear();
              router.push("/login").catch(() => {});
            }
            console.log(err);
            reject(err);
          });
      });
    },

    api_array_buffer({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        commit("api_request");
        axios({
            url: store.state.config.ip + "api/" + data.controller,
            data: data.richiesta,
            method: data.method,
            responseType: 'arraybuffer'
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
           // console.log("ENTRATO ERRROREEEE")
            if (err.response == undefined || (err.response.status == 401 || err.response.status == 403 || err.status == 401 || err.status == 403)) {
              localStorage.clear();
              router.push("/login").catch(() => {});
            }
            console.log(err);
            reject(err);
          });
      });
    },

  },
  modules: {},
});