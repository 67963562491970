<template>
    <div class="mt-4 ml-5 mr-5" >

      <v-card id="create" style="background-color: var(--main-bg-color);" elevation="0" >
          <v-row class="child-flex">

              <v-snackbar
                content-class="snackbar"
                :color="snackbar_color"
                right
                v-model="snackbar"
                :timeout="3000"
              >
                <div style="float: right;">
                    <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
                </div>
                <div>
                <h3 :style="{ color: snackbar_text_color + '!important' }">
                  {{ snackbar_text }}
                </h3>
                </div>
              </v-snackbar>

              <v-speed-dial
                  v-model="fab"
                  :top="top"
                  :bottom="bottom"
                  :right="right"
                  :left="left"
                  :direction="direction"
                  :open-on-hover="hover"
                  :transition="transition"
                >
                  <template v-slot:activator>
                    <v-btn
                      v-model="fab"
                      color="blue darken-2"
                      dark
                      fab
                    >
                      <v-icon v-if="fab" @click="toTop">
                         mdi-chevron-up
                      </v-icon>
                      <v-icon v-else>
                        mdi-account-circle
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-btn
                    fab
                    dark
                    small
                    @click="salva"
                    color="green"
                  >
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>

                  <v-btn
                    fab
                    dark
                    small
                    @click="closeEventDett"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-speed-dial>     


 

              <v-row  style="background-color:#ffffff;" >
                        <v-col cols="12" sm="12">
                            <p class="titleLabelForm mt-4 ml-2"> 
                                 DETAIL ADDRESS BOOK 
                            </p>
                        </v-col> 

                        <!-- Address Name--> 
                        <v-col cols="12" sm="4" md="4"  class="mt-n1 ml-2" > <!-- Address Name--> 
                          <v-text-field
                            v-model="editedItem.name"
                            style="color: var(--main-input-color); font-size: 18px;"                              
                            class="textFieldForm"    
                            color="var(--main-border-color)"            
                            outlined
                            label="Name"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" class="mt-n1 ml-n1"> <!--Type-->
                          <v-autocomplete
                            v-model="editedItem.id_type"
                            class="textFieldForm"
                            :items="address_book_type"
                            :loading="isLoading_adBook"
                            :search-input.sync="search_adBook"
                            item-color="var(--main-active-select-list-color)"
                            style=" color: var(--main-input-color); font-size: 18px;"
                            color="var(--main-border-color)"
                            clearable
                            clear-icon="mdi-close"
                            label="Type"
                            hide-details
                            item-text="des"
                            item-value="id"
                            outlined
                          ></v-autocomplete>     
                        </v-col>                                    

                        <v-col cols="12" sm="6" md="4" class="mt-n5 ml-n2">  <!--Selec Country-->
                          <p class="labelForm"> 
                          </p>
                              <v-autocomplete
                                v-model="editedItem.id_country"
                                class="textFieldForm"
                                :items="country_flags"
                                :loading="isLoading_country"
                                :search-input.sync="search_country"
                                item-color="var(--main-active-select-list-color)"
                                style=" color: var(--main-input-color); font-size: 18px;"
                                color="var(--main-border-color)"
                                clearable
                                clear-icon="mdi-close"
                                label="Country"
                                hide-details
                                item-text="name"
                                item-value="id"
                                outlined
                                @change="getUTC_city"
                              ></v-autocomplete>                
                        </v-col> 

                        <v-col cols="12" sm="6" md="2" class="mt-n5 ml-2">  <!--Text State-->
                          <p class="labelForm"> 
                            
                          </p>
                          <v-text-field
                            v-model="editedItem.state"
                            outlined
                            label="State - Region"
                            class="textFieldForm "
                            color="var(--main-border-color)"                             
                            style=" color: var(--main-input-color); font-size: 18px;"   
                          ></v-text-field>   
                        </v-col>
                        <v-col cols="12" sm="6" md="2" class="mt-n5 " >  <!--City-->
                          <p class="labelForm"> 
                          </p>
                          <v-text-field
                            v-model="editedItem.city"
                            label="City"
                            outlined
                            class="textFieldForm"
                            color="var(--main-border-color)"                                                         
                            style=" color: var(--main-input-color); font-size: 18px;"                          
                          ></v-text-field>   
                        </v-col>              
                        <v-col cols="12" sm="6" md="6" class="mt-n5 ml-n1"><!--Text Address -->
                          <p class="labelForm"> 
                          </p>
                          <v-text-field
                            v-model="editedItem.address"
                            label="Address"
                            outlined
                            style=" color: var(--main-input-color); font-size: 18px;"    
                            class="textFieldForm"
                            color="var(--main-border-color)"                                                 
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" class="mt-n5 ml-n1"><!--Text Zip Code-->
                          <p class="labelForm"> 
                          </p>
                          <v-text-field
                            v-model="editedItem.zip_code"
                            label="Zip Code"
                            outlined
                            style=" color: var(--main-input-color); font-size: 18px;"   
                            class="textFieldForm ml-n2"
                            color="var(--main-border-color)"                                                 
                          ></v-text-field>
                        </v-col>    

                        <v-col v-if="editedItem.id_type == '3' "  cols="12" sm="6" md="6" class="mt-n5 ml-2"> <!--UTC -City-->
                          <v-autocomplete
                            v-model="editedItem.id_UTC"
                            class="textFieldForm"
                            :items="array_city"
                            :loading="isLoading_city"
                            :search-input.sync="search_city"
                            item-color="var(--main-active-select-list-color)"
                            style=" color: var(--main-input-color); font-size: 18px;"
                            color="var(--main-border-color)"
                            clearable
                            clear-icon="mdi-close"
                            label="UTC City"
                            hide-details
                            item-text="city"
                            item-value="id"
                            outlined
                            @change="getUTC_des"
                          ></v-autocomplete>     
                        </v-col>
                        <v-col v-if="editedItem.id_type == '3'" cols="12" sm="6" md="6" class="mt-n5 ml-n2"><!--UTC-->
                          <v-text-field
                            v-model="editedItem.des_utc"
                            label="UTC"
                            outlined
                            style=" color: var(--main-input-color); font-size: 18px;"   
                            class="textFieldForm pr-2"
                            color="var(--main-border-color)"   
                            readonly
                          ></v-text-field>
                        </v-col>


                        <v-col cols="12" sm="6" md="3" class="mt-n5 ml-2"><!--Contact Name-->
                          <p class="labelForm"> 
                          </p>
                          <v-text-field
                            v-model="editedItem.contact_name"
                            label="Contact Name"
                            outlined
                            style=" color: var(--main-input-color); font-size: 18px;"   
                            class="textFieldForm"
                            color="var(--main-border-color)"   
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" class="mt-n5 ml-n2">  <!--Selec Int.Prefix-->
                          <p class="labelForm"> 
                          </p>
                          <v-autocomplete
                            v-model="editedItem.contact_phone_prefix"
                            class="textFieldForm"
                            :items="prefix"
                            :loading="isLoading_prefix"
                            :search-input.sync="search_prefix"
                            item-color="var(--main-active-select-list-color)"
                            style=" color: var(--main-input-color); font-size: 18px;"
                            color="var(--main-border-color)"
                            label="Prefix"
                            hide-details
                            item-text="Prefix"
                            item-value="id"
                            outlined

                          ></v-autocomplete> 
                        </v-col>         
                        <v-col cols="12" sm="6" md="3" class="mt-n5 ml-n2"><!--Mobile Phone-->
                          <p class="labelForm"> 
                          </p>
                          <v-text-field
                            v-model="editedItem.contact_mobile"
                            label="Contact Phone"
                            outlined
                            style=" color: var(--main-input-color); font-size: 18px;"   
                            class="textFieldForm"
                            color="var(--main-border-color)"   
                            type="Number"                  
                          ></v-text-field>
                        </v-col>  
                        <v-col cols="12" sm="6" md="4" class="mt-n5 ml-n1"><!--E-Mail-->
                          <p class="labelForm"> 
                          </p>
                          <v-text-field
                            v-model="email"
                            outlined
                            label="E-MAIL"
                            :rules="[rules.email, rules.counter]"
                            style=" color: var(--main-input-color); font-size: 18px;"   
                            class="textFieldForm"
                            color="var(--main-border-color)"   
                                              
                          ></v-text-field>
                        </v-col>        

                        <v-col cols="12" sm="12" md="12" class="mt-n5 ml-2"><!--Note-->
                          <p class="labelForm"> 
                          </p>
                          <v-text-field
                            v-model="editedItem.note"
                            label="Note"
                            outlined
                            style=" color: var(--main-input-color); font-size: 18px;"   
                            class="textFieldForm mr-5"
                            color="var(--main-border-color)"   
                          ></v-text-field>
                        </v-col>                                                                                
                                             
              </v-row>


              <!--Salva e Cancel-->
              <v-col cols="12" sm="12" >
                <v-row  class="mt-2"  >
                  <v-col cols="12" sm="12" >
                    <v-card class="mt-5" elevation="0" style="background-color: var(--main-bg-color);">
                      <v-row style=" var(--main-bg-color);">
                        <v-col cols="12" class="text-center" >
                          <v-btn
                            color="var(--main-primary-color)"
                            dark
                            @click="salva"
                            elevation="0"
                          >
                            Save
                          </v-btn>
                          <v-btn
                            class="ml-2"
                            color="trasparent"
                            elevation="0"
                            @click="closeEventDett"
                          >
                            Cancel
                          </v-btn>                        
                          
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>

          </v-row>
           <br> 
      </v-card> 

    </div>
</template>
<style>
  /* Style for Bottom menu  */
  #create .v-speed-dial {
    position: fixed;
    z-index: 2;
  }

  #create .v-btn--floating {
    position: relative;
  }

</style>


<script>
import MyLib from "@/components/Lib/Lib.js";


export default {
    components: {

    }, 
  data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",     
     
     //x Bottom menu
      direction: 'top',
      fab: false,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
      //x Bottom to Top
      scTimer: 0,
      scY: 0,

      array_city:[],

      //Country Autocomplete
      isLoading_country: false,
      search_country: null, 

      //Currency Autocomplete
      isLoading_adBook: false,
      search_adBook: null, 

      isLoading_prefix: false,
      search_prefix: null, 

      isLoading_city: false,
      search_city: null, 

      email:'',
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length > 0 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }, 
      },      
      
      overlay: false,  

      editedIndex: -1,
      editedItem:{},

    };
  },
  props: {
      ITEM: Object,
      TRIGGER: Number,
  },  
  computed: {
      formattedEventData() {
              return MyLib.formatDate(this.editedItem.date_event)
      }, 
  
      country_currency(){
         return this.alasql(
            "SELECT id, Country +  ' - ' + Cod AS  des FROM ? ",
            [this.currency]
        );    
      },                               
        
  },
  watch: {
    
    TRIGGER: {
        immediate: true,
        handler() {
          this.editedItem =  this.ITEM
          this.getArraCity()
          this.email = this.ITEM.email
          console.log (this.editedItem)
        }
    },
  },  
  mounted() {
    //Gestione Go Bottom to TOP-----------------
    window.addEventListener('scroll', this.handleScroll);
  },

  methods: {

      getArraCity(){
        if (this.editedItem.id_country != null){
          let codCountry = this.alasql(
              "SELECT  FROM ? WHERE id = '" + this.editedItem.id_country + "'",
              [this.country_flags]
          );
          this.array_city = this.alasql(
              "SELECT  FROM ? WHERE country = '" + codCountry[0].name + "'",
              [this.utc]
          );
          this.editedItem.id_UTC = this.editedItem.id_utc
        }
      },  

      salva(){

          if (this.editedItem.id_type == '3') {
            if (this.editedItem.des_utc == null  || this.editedItem.des_utc == "" ) {
                    this.snackbar_color = "error";
                    this.snackbar_text = "The UTC filed for Venue is mandatory. ";
                    this.snackbar_icon = "mdi-window-close";
                    this.snackbar = true;
                    return
            }
          }

          this.overlay = true
          let request = {
              controller: "Bellator",
              method: "PUT",
              richiesta: {
                  action: "setaddressbook",
                  address_book: [this.editedItem],
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {  
                 
                 if (res.status == 200){
                    this.overlay = false
                   // let item = res.data[0];
                    this.snackbar_color = "success";
                    this.snackbar_text = "data saved successfully. ";
                    this.snackbar_icon = "mdi-window-close";
                    this.snackbar = true;
                  } else {
                    this.snackbar_color = "error";
                    this.snackbar_text = "Error save data. ";
                    this.snackbar_icon = "mdi-window-close";
                    this.snackbar = true;
                    this.overlay = false
                  }           
          });
      },    
      
      closeEventDett(){
         this.$emit("closeDettEvent", "");
      },
  
      getUTC_city(){
        
        if (this.editedItem.id_country != null){
          let codCountry = this.alasql(
              "SELECT  FROM ? WHERE id = '" + this.editedItem.id_country + "'",
              [this.country_flags]
          );
          let Country_name = codCountry[0].name
          
          this.array_city = this.alasql(
              "SELECT  FROM ? WHERE country = '" + Country_name + "'",
              [this.utc]
          );
        
          if (this.array_city.length > 0 )
            this.editedItem.id_UTC = this.array_city[0].id      
            this.editedItem.des_utc = this.array_city[0].UTC     
            this.editedItem.utc_decimale = this.array_city[0].UTC_dec                 
        }

      },

      getUTC_des(){
        if (this.editedItem.id_UTC != null){
          let utc_array = this.alasql(
              "SELECT  FROM ? WHERE id = '" + this.editedItem.id_UTC + "'",
              [this.utc]
          );

           this.editedItem.des_utc = utc_array[0].UTC     
           this.editedItem.utc_decimale = utc_array[0].UTC_dec   
        }
      },

      //Gestione Go Bottom to TOP----------------------------

      handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
      toTop: function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },      

      //--------------------------------------------

     
  },
};
</script>