<template>
    <div class="mt-10 ml-6 mr-6" v-if="pagedetails" >
      <AddressBookDetail 
        :OBJ_EVENT=this.obj_event  
        :TRIGGER = this.modifica
        v-on:closeDettEvent="chiudi_dettaglio"
      /> 
    </div>
    <div class="mt-10 ml-6 mr-6" v-else >
      <v-row  style="position:fixed; z-index: 1; width:85%; "> <!--Create New Address Book--> 
            <v-col cols="12" md="12 " class="mt-n2"  style=" background-color: var(--main-bg-color);"  >
              <v-toolbar dense  elevation="0" style="height: 80px; padding-top: 20px;  background-color: var(--main-bg-color);">   
                 <div  v-if="$vuetify.breakpoint.sm ? style='width:100px; max-width:300px;' : style='width:55%;' "  >    
                  <v-tabs
                      v-model="modalTab_event"
                      grow
                      slider-size="3"
                      slider-color="red"
                      color="white"
                  >
                      <v-tab :href="`#newAddressBookfilter`" 
                              style= "max-width: 300px; font-size: 1.0rem;     
                                text-transform: Initial !important;
                                box-shadow: none;
                                outline: none;
                                padding-left: 6px;
                                padding-right: 6px;
                                border-radius: 10px;
                                cursor: pointer;
                                background-color: red;
                                color:white !important;
                                font-size: 20px !important; 
                                font-family: 'Titillium Web', sans-serif !important; 
                                font-weight: 700 !important; 
                              "
                              @click="create_address_book"
                      >Create New Address Book</v-tab >
                  </v-tabs>
                </div>
              </v-toolbar>
            </v-col>
      </v-row>                  
      <v-row >  <!--Gestione Filtri e Tabella dentro unico Tab -->
          <v-col cols="12" style="margin-top: 60px; "  >
                  <v-tabs-items v-model="modalTab_event" class="no-transition "  style="background-color:var(--main-bg-color);" touchless>

                      <!-- Tab Event Main -->
                      <v-tab-item
                        :value="`newAddressBookfilter`"
                        transition="false"
                        class="mt-3 no-transition"
                      >
                      <div class="mt-4" style="background-color:var(--main-bg-color); ">
                          <!-- Filter -->
                          <v-card  class="ml-4 mr-4" elevation="0" style="background-color: white;"  ><!-- Row Filtri-->
                            <v-row class="ml-4">  
                              <v-col cols="12" sm="12" class="mt-3">
                                <p class="titleLabelForm"> 
                                    FILTER AND FIND ADDRESS
                                </p>
                              </v-col> 
                              <v-col cols="12" sm="6" md="4" class="mt-n5"> <!-- Name  -->
                                  <v-text-field
                                    v-model="filter_name"
                                    label="Name"
                                    prepend-inner-icon="mdi-filter-variant"
                                    outlined
                                    color="var(--main-border-color)" 
                                    style=" color: var(--main-input-color); font-size: 18px;"   
                                    class="textFieldForm"                                    
                                  ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4" class="mt-n5">  <!-- Country -->
                                    <v-autocomplete
                                      class="textFieldForm ml-n2"
                                      v-model="filter_id_country"
                                      :items="country_flags"
                                      :loading="isLoading_country"
                                      :search-input.sync="search_country"
                                      prepend-inner-icon="mdi-filter-variant"
                                      item-color="var(--main-active-select-list-color)"
                                      style=" color: var(--main-input-color); font-size: 18px;"
                                      color="var(--main-border-color)"
                                      clearable
                                      label="Country"
                                      hide-details
                                      item-text="name"
                                      item-value="id"
                                      outlined
                                    ></v-autocomplete>
                              </v-col> 
                              <v-col cols="12" sm="6" md="4" class="mt-n5"> <!-- State -->
                                  <v-autocomplete
                                    v-model="filter_id_type"
                                    class="textFieldForm ml-n4"
                                    :items="address_book_type"
                                    :loading="isLoading_adBook"
                                    :search-input.sync="search_adBook"
                                    prepend-inner-icon="mdi-filter-variant"
                                    item-color="var(--main-active-select-list-color)"
                                    style=" color: var(--main-input-color); font-size: 18px;"
                                    color="var(--main-border-color)"
                                    clearable
                                    clear-icon="mdi-close"
                                    label="Type"
                                    hide-details
                                    item-text="des"
                                    item-value="id"
                                    outlined
                                  ></v-autocomplete>                                       
                              </v-col>       
                                                   
                              
                              <!-- pulsanti Filtro-->
                              <v-col cols="12" sm="12" md="12" >
                                <v-row class="mt-n12 mb-3">
                                  <!-- Apply Clear-->
                                  <v-col cols="12" sm="6"  align="left">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn  
                                              class="mt-3 btnForm"  
                                              elevation="0" color="var(--main-primary-color)" dark v-bind="attrs" v-on="on" 
                                              @click="getAddressBook()"
                                            > Apply</v-btn>
                                        </template>
                                        <span>Apply Selected Filter</span>
                                    </v-tooltip>
                                    
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-if="valid_address_book.length>0"
                                                class="mt-3 ml-1 btn_bg nohover " elevation="0" 
                                                color="white"   v-bind="attrs" v-on="on" @click="clear_filter"
                                            > Clear
                                            </v-btn>
                                            <v-btn v-else
                                                class="mt-3 ml-1 " elevation="0" 
                                                color="white" dark v-bind="attrs" v-on="on" @click="clear_filter"
                                                disabled
                                            > 
                                            </v-btn>
                                        </template>
                                        <span>Clear All Filter</span>
                                    </v-tooltip> 
                                  </v-col>



                                </v-row>                     
                              </v-col>

                              <!-- Fine Campi Filter -->
                            </v-row>
                          </v-card>

                          <!--  Gestione Tabella -->
                          <v-card  class=" ml-4 mr-4 mt-10" elevation="0"  > <!--Gestione Tabella-->
                             <v-row>
                              <v-col cols="12" style="background-color:var(--main-bg-color);"> 
                                <template>

                                  <v-data-table
                                    :headers="headers_address_book"
                                    :items="valid_address_book"
                                    item-key="id"
                                    color="var(--main-input-color)"
                                    sort-by="date_event"
                                    class="elevation-0 mt-n2"
                                    :footer-props="{
                                              'items-per-page-text':'Rows per page',
                                          }"
                                  >

                                    <template v-slot:[`header.id_type`]="{}">
                                      <a class="textHeader" style="color:var(--main-dth-color);"> Address Book Type </a>
                                    </template>
                                    <template v-slot:[`header.name`]="{}">
                                      <a class="textHeader" style="color:var(--main-dth-color);"> Name </a>
                                    </template>  
                                    <template v-slot:[`header.id_country`]="{}">
                                      <a class="textHeader" style="color:var(--main-dth-color);"> Country </a>
                                    </template>  
                                    <template v-slot:[`header.email`]="{}">
                                      <a class="textHeader" style="color:var(--main-dth-color);"> Email </a>
                                    </template>  

                                    <template v-slot:[`item.id_type`]="{ item }">
                                          {{getTypeAddessBook(item.id_type)}} 
                                    </template>  
                                    <template v-slot:[`item.id_country`]="{ item }">
                                          {{getCountry(item.id_country)}} 
                                    </template>  
                                    <template v-slot:no-data>
                                        Empty Result
                                    </template>                                                                                                                                               
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-icon color="var(--main-primary-color)" @click="open_address_book(item)"
                                        >mdi-eye
                                        </v-icon>
                                        <v-icon class="ml-4 " color="var(--main-primary-color)"  @click="deleteItem(item.id_address_book)"
                                          >mdi-delete
                                        </v-icon>                                        
                                    </template>

                                  </v-data-table>                                  


                                </template>
                                <v-overlay :value="overlay">
                                  <v-progress-circular indeterminate size="64"></v-progress-circular>
                                </v-overlay>          
                              </v-col>   
                            </v-row>  
                          </v-card>   


                      </div> 
                      </v-tab-item>                      

                  </v-tabs-items>
          </v-col>
      </v-row>
      <v-dialog v-model="dialogDelete" max-width="1024px" >
                    <v-card class="dialog-del">                      
                      <div class="alert-top">!</div>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color=" darken-1" class="btn_bg nohover" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color=" " class="btnForm" dark elevation="0" @click="deleteItemConfirm">Yes, proceed</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>  
    </div>
</template>
<style>

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    width: 120px;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    visibility: hidden;
    width: 120px;
    background-color: grey;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;


    /* Position the tooltip */
    position: absolute;
    z-index:0;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }


</style>
<script>
import AddressBookDetail from "./AddressBookDetail.vue";
import MyLib from "@/components/Lib/Lib.js";
  export default {
    components: {
       AddressBookDetail,
    },
    data () {
      return {

        snackbar: false,
        snackbar_color: "",
        snackbar_text: "",
        
        dialogDelete: false,
        dialog_new_Event:false,
        newAddressBook: false,

        overlay: false,   
        separatore:'-',
        
        pagedetails:false,

        modalTab: "data",
        modalTab_event:"Info",

        filter_name:"",
        filter_id_country:"",
        filter_id_type:"",
        
        filter_dateFrom: "", //rif datapicker (data non formattata)
        menu_dateFrom_filter: false, //apertura chiusura datapicker 

        filter_dateTo: "", //rif datapicker (data non formattata)
        menu_dateTo_filter: false, //apertura chiusura datapicker 
        
        //Menu Date 
        dateEventData: "", //rif datapicker (data non formattata)
        menueventdata: false, //apertura chiusura datapicker
        
        new_event:{id_event: '-1', event_name: null, city_event:'', date_event: '', country_event: '', state_event: 1, venue_name:null, venue_city:null, open_door_time:null, prelim_start_time:null , main_card_time:null},
        reset_new_event:{id_event: '-1', event_name: null, city_event:'', date_event: '', country_event: '', state_event: 1, venue_name:null, venue_city:null, open_door_time:null, prelim_start_time:null , main_card_time:null},

        obj_event:null,
        address_book_array:[],

        //Country Autocomplete
        isLoading_country: false,
        search_country: null, 

        isLoading_adBook: false,
        search_adBook: null,         

        modifica: 0, 

        headers_address_book: [
          { text: 'Type', value: 'id_type' },
          { text: 'Name', value: 'name' },
          { text: 'Country', value: 'id_country' },
          { text: 'Email', value: 'email' },
          { text: '', value: 'actions', sortable: false },
        ],
        
        footerProps: { "items-per-page-options": [15, 30, 50, 100] },  
       }
    },
    mounted() {


    }, 
    computed: {

      valid_address_book() {
        return this.address_book_array.filter((i) => {
            return (i.validita === null) || (i.validita === 1) || (i.validita === "");
        })
      },
      filter_formattedDateFrom() {
        return MyLib.formatDate(this.filter_dateFrom)
      },   
      filter_formattedDateTo() {
              return MyLib.formatDate(this.filter_dateTo)
      },
      formattedEventData() {
              return MyLib.formatDate(this.new_event.date_event)
      }      
    },
    
    watch: {
    },  

    methods: {

        getAddressBook(){
         // var filter_page =  ''
          this.overlay = true
          this.address_book_array=[]
          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "getaddressbook",
                  name:this.filter_name,
                  id_country: this.filter_id_country,  
                  id_type: this.filter_id_type,   
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                this.address_book_array = res.data.address_book;
                this.overlay = false 
              } else {
                this.snackbar_color = "error";
                this.messaggio_snackbar = "Address Book not loaded.";
                this.snackbar = true;
                this.overlay = false
              }     
          })
          .catch((ex) => {
            if (ex.response.status == 404 || ex.response.status == undefined) {
              this.overlay = false
              this.snackbar = true;
              this.snackbar_color = "error";
              this.snackbar_text = ex;
              this.overlay = false
            }
          });
        },

        clear_filter(){
          this.filter_name = ""
          this.filter_id_country = ""
          this.filter_id_type = ""
          this.address_book_array=[]
        },
        close_tab(){
          this.getAddressBook()
          this.pagedetails = false
        },

        open_address_book(item){
          this.modifica = Math.round(Math.random()*100);
          this.obj_event = item
          this.pagedetails=true
        },

        create_address_book(){
          this.modifica = Math.round(Math.random()*100);
          this.obj_event = {
              address: "",
              city: "",
              contact_mobile: "",
              contact_name: "",
              contact_phone_prefix: null,
              email: "",
              id_address_book: -1,
              id_country: null,
              id_type: null,
              name: "",
              note: "",
              state: "",
              validita: 1,
              zip_code: ""
          }
          this.pagedetails=true
        },

      
        chiudi_dettaglio(){
          this.getAddressBook()
          this.pagedetails =  false
        },

              
        Close_newAddressBook(){
          this.newAddressBook = false
        },
   
        formatDate (date) {
          if (!date) return null
          const [year, month, day] = date.split('-')
          return `${month}/${day}/${year}`
        },    
        
        getCountry(id_country){
            if (id_country != null){
              let codCountry = this.alasql(
                  "SELECT  FROM ? WHERE id = '" + id_country + "'",
                  [this.country_flags]
              );
              return codCountry[0].name
            } 
        },    
        
        getTypeAddessBook(id_adBook){
            if (id_adBook != null){
              let typeAdBook = this.alasql(
                  "SELECT  FROM ? WHERE id = '" + id_adBook + "'" ,
                  [this.address_book_type]
              );
              return typeAdBook[0].des
            } 
        }, 


    //  Delete  ----------------------------

      deleteItem (index) {
        this.editedIndex = index
        this.dialogDelete = true
      },
 
      deleteItemConfirm () {
        this.delete(this.editedIndex)
        this.closeDelete()
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = {}
          this.editedIndex = -1
        })
      },

      delete(id_address_book){
        this.overlay = true
        let request = {
            controller: "Bellator",
            method: "PUT",
            richiesta: {
                action: "deladdressbook",
                id_address_book: id_address_book,
                token: localStorage.getItem("user_token"),
            },
        }
        this.$store.dispatch("api", request).then((res) => {   
            if (res.status == 200){
                this.getAddressBook();
                this.overlay = false   
                this.snackbar_color = "success";
                this.snackbar_text = "Item deleted! ";
                this.snackbar_icon = "mdi-window-close";
                this.snackbar = true;     
            }
        });
      },	

      //----------------------------        
           

    }
  }
</script>
