<template>
  <div>

      <v-card elevation="0">
          <v-toolbar
              elevation="0"
              class="white--text"
              dense
              style="position: sticky; top: 0px; z-index: 1
                box-shadow: none;
                outline: none;
                padding-left: 6px;
                padding-right: 6px;
                font-size: 14px;
                background-color: #283046;
                color: #f6f6f2;
                font-weight: 700;
                background-image: linear-gradient(to left top ,#521f1c, #283046 );
                "                
              >

              <v-toolbar-title> Report Email </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon 
                      class="ml-n10"
                      v-on="on"
                      v-bind="attrs"
                      @click="stampa_evento"                        
                      color="white"
                    >mdi-file-eye-outline</v-icon>
                </template>
                <span>View Attachment</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                    v-on="on"
                    v-bind="attrs"
                    class="ml-2"
                    @click="SendEmail"                         
                      color="white"> mdi-email</v-icon>
                </template>
                <span>Send email with report</span>
              </v-tooltip>    
              <v-spacer></v-spacer>              
                  <v-icon color="white" @click="close_mail()">mdi-close</v-icon>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col lg="4" md="4" sm="4">
              </v-col>
              <v-col lg="4" md="4" sm="4">
              </v-col>
              <v-col lg="4" md="4" sm="4">
                <v-select
                  v-model="email_sel.id_smtp"
                  label="SMTP Server"
                  class="textFieldForm pl-2"
                  item-color="var(--main-active-select-list-color)"
                  style=" color: var(--main-input-color); font-size: 18px;"
                  color="var(--main-border-color)" 
                  :items="SmtpList"
                  item-text="des_smtp"
                  item-value="id_smtp"
                >
                </v-select>
              </v-col>
              <v-col lg="6" md="6" sm="6">
                <v-text-field
                  v-model="email_sel.mittente"
                  label="Sender Mail"
                  class="textFieldForm pl-2"   
                  color="var(--main-border-color)"             
                  outlined   
                  style=" color: var(--main-input-color); font-size: 18px;"
                ></v-text-field>
              </v-col>
              <v-col lg="6" md="6" sm="6">
                <v-text-field
                  v-model="email_sel.destinatari"
                  label="Mail Recipient"
                  class="textFieldForm pl-2"   
                  color="var(--main-border-color)"             
                  outlined   
                  style=" color: var(--main-input-color); font-size: 18px;"
                ></v-text-field>
              </v-col>
              <v-col lg="12" md="12" sm="12">
                <v-text-field
                  v-model="email_sel.oggetto"
                  class="textFieldForm pl-2 mt-n8"   
                  color="var(--main-border-color)"             
                  outlined   
                  style=" color: var(--main-input-color); font-size: 18px;"                    
                  label="Mail Subject"
                ></v-text-field>
              </v-col>

              <!-- <v-col lg="4" md="4" sm="4">
              <v-text-field
                v-model="uscita_smtp"
                label="SMTP di uscita"
                class="pr-2"
              ></v-text-field>
            </v-col> -->
              <div v-html="html"> </div>
              <v-col class="" md="12" lg="12" sm="12">
                <tiptap-vuetify
                style="text-align: left;"
                  v-model="email_sel.body"
                  :extensions="extensions"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-dialog  v-model="pdfdialog" width="unset">
              <v-card>
                  <v-toolbar
                      elevation="0"
                      class="white--text"
                      dense
                      style="position: sticky; top: 0px; z-index: 1
                        box-shadow: none;
                        outline: none;
                        padding-left: 6px;
                        padding-right: 6px;
                        font-size: 14px;
                        background-color: #283046;
                        color: #f6f6f2;
                        font-weight: 700;
                        background-image: linear-gradient(to left top ,#521f1c, #283046 );
                        "                
                      >
                      <v-toolbar-title> Event Print Viewer </v-toolbar-title>
                      <v-spacer></v-spacer>
                          <v-icon
                            color="white"
                            @click="pdfdialog = false"
                          >mdi-close</v-icon>
                  </v-toolbar>
                  <v-row>
                      <v-col cols="12" sm="6" md="12">
                          <iframe
                              :src="this.selectedPdf"
                              frameborder="0"
                              style="height: 90vh !important; width: 90vw !important"
                          >
                          </iframe>
                      </v-col>
                  </v-row>
              </v-card>
              
          </v-dialog>  

          <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>                             
      </v-card>


    
    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </div>
</template>

<script>
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  //Code,
  //Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  // HorizontalRule,
  History,

} from "tiptap-vuetify";

  class EmailObject {
    constructor(arg = undefined) {
      if (arg != undefined) {
        const campi = this.setCampi();
        campi.forEach((c) => {
          this[c.nome] = arg[c.nome] != undefined ? arg[c.nome] : c.default_val;
        });
      } else {
        const campi = this.setCampi();
        campi.forEach((c) => {
          this[c.nome] = c.default_val;
        });
      }
    }
    // Metodo per resettare tutte le campi
    reset() {
      const campi = this.setCampi();

      campi.forEach((c) => {
        this[c.nome] = c.default_val;
      });
    }

    setCampi() {
      const campi = [
        { nome: "body", default_val: "" },
        { nome: "des_modello", default_val: "" },
        { nome: "destinatari", default_val: null },
        { nome: "id_email_modello", default_val: -1 },
        { nome: "id_smtp", default_val: "" },
        { nome: "id_utente", default_val: localStorage.id },
        { nome: "mittente", default_val: "" },
        { nome: "oggetto", default_val: null },
        { nome: "riferimento", default_val: "" },
        { nome: "validita", default_val: 1 },
        { nome: "data_ins", default_val: "" },
        { nome: "data_mod", default_val: "" },
      ];

      return campi;
    }
  }


export default {
  name: "Email",

  components: {
    TiptapVuetify,
    SnackbarQV,
  },

  data() {
    return {

      body_mail:"",
      SmtpList: [],
      email_sel: new EmailObject(),

      pdfdialog:false,
      mailLista: [],

      overlay:false,

      extensions: [

        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        //Code,
        // HorizontalRule,
        //Paragraph,
        HardBreak,

      ],

      html: this.body_mail ,
      selectedPdf:"",

    };
  },
  props: {
      EMAIL: String,
      ID_SUBJECT: Number,
      ID_EVENT: Number,
      TRIGGER: Number,
  },   
  mounted() {

    this.getSMTPLIST();
    
  },

  watch: {
    TRIGGER: {
        immediate: true,
        handler() {
            this.initialize()
        }
    },
  },  


  methods: {

    initialize(){
      //console.log("aaaa", this.email_sel)

      this.email_sel.destinatari =  this.EMAIL
      this.email_sel.mittente = "noreply@bmmaops.com"
      this.email_sel.oggetto = "Event Information"
      this.email_sel.id_smtp = 3
      


      this.body_mail =  ` <p style="text-align: center;"><strong>Titolo AAA</strong></p>
                            <p style="text-align: center;"><strong>Tabella Dati:</strong></p>
                            <table style="width: 98%; margin-left: calc(2%);">
                                <thead>
                                    <tr>
                                        <th>evento</th>
                                        <th>test </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="width: 23.9389%; text-align: center;">pippo</td>
                                        <td style="width: 75.9762%; text-align: center;">pluto</td>
                                    </tr>
                                    <tr>
                                        <td style="width: 23.9389%; text-align: center;">paperino</td>
                                        <td style="width: 75.9762%; text-align: center;">aaaa</td>
                                    </tr>
                                </tbody>
                            </table>`
    },

    goBack() {
      this.$router.push("/home");
    },

    goToInvioEmail() {
      this.$router.push("/home/setupsmtp");
    },

    getSMTPLIST() {
      const request = {
        controller: "global",
        method: "POST",
        richiesta: {
          action: "getsmtp",
          token: localStorage.user_token,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.SmtpList = res.data;
        this.email_sel.id_smtp = res.data[0].id_smtp
      });
    },
    


    SendEmail() {
      
      this.overlay = true
      let email_data = new EmailObject(this.email_sel);
      const request = {
        controller: "Bellator",
        method: "POST",
        richiesta: {
          action: "setmailevents",
          token: localStorage.user_token,
          id_smtp: email_data.id_smtp,
          mittente: email_data.mittente,
          destinatario: email_data.destinatari,
          oggetto: email_data.oggetto,
          body: email_data.body,
          id_subject: this.ID_SUBJECT,
          id_event: this.ID_EVENT,
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          this.overlay = false
          if (res.status === 200) {
            this.$refs.SnackbarQV.print(
              "success",
              "Email inviata con successo."
            );
            this.$emit("closeEmail", "");
          }
        })
        .catch(() => {
          this.overlay = false
          this.$refs.SnackbarQV.print(
            "error",
            "Errore di comunicazione col server, Non è possibile inviare e-mail."
          );
        });
    },

    close_mail(){
      this.$emit("closeEmail", "");
    },

    async stampa_evento(){
        this.overlay= true
        await this.getStampaEvento();
        const byteCharacters = atob(this.file_b64);
        const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        this.selectedPdf = blobUrl;
        this.pdfdialog = true;
        this.overlay = false   
    },
    getStampaEvento() {
        return new Promise((resolve, reject) => {
            this.overlay = true;
            let disegnoReq = {
                controller: "Bellator",
                method: "POST",
                richiesta: {
                action: "geteventspdf",
                id_subject: this.ID_SUBJECT,
                id_event: this.ID_EVENT,                
                token: localStorage.getItem("user_token"),
                },
            };
            this.file_b64 = '';
            this.$store.dispatch("api", disegnoReq).then((res) => {
                this.file_b64 = res.data
                this.overlay = false;
                resolve(res);
                return;
            })
            .catch((e) => {
                this.overlay = false;
                reject(e);
                console.log(e);
            });
        });
    }, 

  },
};
</script>


<style scoped>
.tiptap-vuetify-editor .ProseMirror {
  height: 400px !important;
}
</style>
