<template>
      <v-card class="mt-5 ml-4 mr-4" elevation="0">
            <v-snackbar
              content-class="snackbar"
              :color="snackbar_color"
              class="snackbar-gen"
              left
              v-model="snackbar"
              :timeout="3000"
            >
              <div style="float: right;">
                  <v-icon :color="snackbar_icon_color"  @click="snackbar = false" >mdi-window-close</v-icon>
              </div>
              <div>
              <h3 :style="{ color: snackbar_text_color + '!important' }">
                {{ snackbar_text }}
              </h3>
              </div>
            </v-snackbar>
            <v-row > 
              <v-col cols="12" sm="12" md="12">
                  <p class="titleLabelForm"> 
                      APPOINTMENT
                  </p>
              </v-col> 
              <v-col cols="12" sm="6" md="3" class="mt-n5"> <!--DATE-->
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="subject_ev_appointment_date"
                      label="Date"
                      style="color: var(--main-input-color); font-size: 18px;"                              
                      class="textFieldForm"    
                      color="var(--main-border-color)"               
                      outlined
                      persistent-hint
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu = false"
                    locale="en"
                  ></v-date-picker>
                </v-menu> 
              </v-col>    
              <v-col cols="12" sm="6" md="3" class="mt-n5">  <!--Time-->
                  <v-text-field
                    v-model="subject_ev_appointment_time"
                    label="Time"
                    value="00:00"
                    style="color: var(--main-input-color); font-size: 18px;"                              
                    class="textFieldForm"    
                    color="var(--main-border-color)"            
                    outlined                                                
                    type="time"
                  ></v-text-field>                
              </v-col> 
  
              <v-col cols="12" sm="6" md="6" class="mt-n5 "> <!--Place-->
                <v-text-field
                  v-model="subject_ev_appointment_place"
                  label="Place"
                  style="color: var(--main-input-color); font-size: 18px;"                              
                  class="textFieldForm"    
                  color="var(--main-border-color)"            
                  outlined                                                
                ></v-text-field> 
              </v-col>    
              <v-col cols="12" sm="12" md="12" class="mt-n5 "> <!--Description-->
                <v-text-field
                  v-model="subject_ev_appointment_description"
                  label="Description"
                  style="color: var(--main-input-color); font-size: 18px;"                              
                  class="textFieldForm"    
                  color="var(--main-border-color)"            
                  outlined                                                
                ></v-text-field> 
              </v-col>                                       
              <v-col cols="12" sm="6" md="4" class="mt-n3 ">  <!--Selec Contact-->
                    <v-autocomplete
                      v-model="subject_ev_appointment_id_contact"
                      item-color="var(--main-active-select-list-color)"
                      class="textFieldForm mt-n2"
                      style=" color: var(--main-input-color); font-size: 18px;"   
                      color="var(--main-border-color)"                                  
                      :items="VALID_CONTACT"
                      :loading="isLoading_contact"
                      :search-input.sync="search_contact"
                      clearable
                      :item-text="(item) => item.name + ' ' + item.surname"
                      item-value="id_subject"
                      label="Contact"
                      @change="getIdContact()"
                      outlined
                    ></v-autocomplete>                

              </v-col>     


              <v-col cols="12" sm="6" md="1" class="mt-n2"> <!--Button Add-->
                  <v-btn
                    class="btn_plus"
                    color="var(--main-primary-color)"
                    style="color:var(--main-primary_text-color);"
                    @click="add_item"
                    elevation="0"
                  >
                    <v-icon dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn> 
              </v-col>   
      

          
              <!-- Table-->
              <v-col cols="12" sm="12"  class="mt-n5"  v-if="subject_ev_appointment_array_length>0">
 
                <template>
                  <v-data-table
                    id="virtual-scroll-table"
                    :headers="headers"
                    :items="subject_ev_appointment_array"
                    hide-default-footer
                    :items-per-page="12000"  
                     item-key="id_subject_ev_appointment"
                    :single-expand="singleExpand"
                    :expanded.sync="expanded" 
                    show-expand   
                    @item-expanded="onExpand"  
                    v-scroll:#virtual-scroll-table
                  >
                      <template v-slot:[`header.date`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Date </a>
                      </template>                                                       
                      <template v-slot:[`header.time`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Time </a>
                      </template>
                      <template v-slot:[`header.place`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Place </a>
                      </template>
                      <template v-slot:[`header.id_contact`]="{}">
                        <a class="textHeader" style="color:var(--main-dth-color);"> Contact </a>
                      </template>                                            

                    <template v-slot:[`item.date`]="{ item }">
                          {{formatDate(item.date)}} 
                    </template>   
                    <template v-slot:[`item.id_contact`]="{ item }">
                          {{getNamebyId(item.id_contact, item.organizer, item.fk_id_subject)}} 
                    </template>  
                    <template  v-slot:[`item.data-table-expand`]="{item, expand, isExpanded}" > 
                      <v-container v-if="item.description == '' || item.description == null">
                          <v-icon color="grey"  @click="expand(!isExpanded)" > mdi-layers-off</v-icon>
                      </v-container>
                        <v-container v-else> 
                          <v-icon color="indigo"  @click="expand(!isExpanded)" > mdi-layers-outline</v-icon>
                      </v-container>
                    </template>
                        <template v-slot:expanded-item="{ item ,headers}">
                            <td style="text-align:left;" class="pl-8" :colspan="headers.length">
                              {{item.description}}
                            </td>
                    </template>       
                    <template v-slot:[`item.actions`]="{ item }">  
                      <v-icon
                        color="var(--main-primary-color)"
                        @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>

                  </v-data-table>
                </template>

                 
                <v-dialog v-model="dialogDelete" max-width="1024px" >
                    <v-card class="dialog-del">                      
                      <div class="alert-top">!</div>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color=" darken-1" class="btn_bg nohover" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color=" " class="btnForm" dark elevation="0" @click="deleteItemConfirm">Yes, proceed</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>                
              </v-col>
              <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
              </v-overlay> 
            </v-row>
      </v-card>   
      
</template>
<style >
  #virtual-scroll-table {
    max-height: 450px;
    overflow: auto;
  }
</style>

<script>

export default {   
	data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      snackbar_icon: "",
      snackbar_icon_color: "", 
      snackbar_text_color:"",     

      subject_ev_appointment_array:[],
      subject_ev_appointment_array_length:0,
      edited_subject_ev_appointment: {},
      editedIndex_subject_ev_appointment: -1,

      all_contact:[],
      

      id_contact_to_save:null,
      
      isLoading_contact: false,
      search_contact: null,
      isLoading_currency: false,
      search_currency: null, 

      subject_ev_appointment_time:null,
      subject_ev_appointment_place:null,
      subject_ev_appointment_id_contact:null,
      subject_ev_appointment_description:null,

      date: null,
      menu:false,

      overlay: false,  

      dialogDelete: false,
      headers: [
        { text: '', value: 'data-table-expand' },
        { text: 'Date', value: 'date' },
        { text: 'Time', value: 'time' },
        { text: 'Place', value: 'place' },
        { text: 'Contact', value: 'id_contact' },
        { text: '', value: 'actions', sortable: false },
        
      ],
      expanded: [],
      singleExpand: false,
     
    };		
	},
  props: {
      ID_SUBJECT: Number,
      ID_EVENT: Number,
      VALID_CONTACT: Array,
  },
  computed: {
      subject_ev_appointment_date () {
        return this.formatDate(this.date)
      },
      country_currency(){
         return this.alasql(
            "SELECT id, Country +  ' - ' + Cod AS  des FROM ? ",
            [this.currency]
        );
      },
    },

  watch: {
        ID_EVENT: {
          immediate: true,
          handler() {
          this.getSubjectEvAppointment()
          },

      },
  },  	
  mounted() {
    
  },	
    
 
	methods:{


        onExpand() {
         // console.log("AAAA",this.expanded);
        },

				getSubjectEvAppointment(){
					let that = this
					that.overlay = true
					that.subject_ev_appointment_array=[]
					let request = {
							controller: "Bellator",
							method: "POST",
							richiesta: {
									action: "getsubjectevappointment",
									id_subject: this.ID_SUBJECT,
                  id_event: this.ID_EVENT,
									token: localStorage.getItem("user_token"),
							},
					}
					that.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
								that.subject_ev_appointment_array = res.data.subjectevappointment;
                that.subject_ev_appointment_array_length = that.subject_ev_appointment_array.length

                //Per Far partire l'expanded open devo precaricare l'array expande[] con tutti dati della tabella
                  res.data.subjectevappointment.forEach(element => {
                  this.expanded.push({  
                      date: element.date,
                      date_ins: element.date_ins,
                      date_mod: element.date_mod,
                      description: element.description,
                      fk_id_event: element.fk_id_event,
                      fk_id_subject: element.fk_id_subject,
                      id_contact: element.id_contact,
                      id_subject_ev_appointment: element.id_subject_ev_appointment,
                      id_utente: element.id_utente,
                      organizer: element.organizer,
                      place: element.place,
                      time: element.time,
                      validita: element.validita  
                    })
                  })

                console.log("expanded",this.expanded)
                
								that.overlay = false 
							} else {
								that.snackbar_color = "error";
								that.snackbar_test = "Table Data not loaded.";
								that.snackbar = true;
								that.overlay = false
							}     
					})
					.catch((ex) => {
							console.log(ex)
							this.overlay = false
					});
				},


        /*
        getAllContact(){
						//restituisce  l'elenco dei contatti ovvero i subject con  i quali organizzare un appuntamento
						this.overlay = true
						let request = {
								controller: "Bellator",
								method: "POST",
								richiesta: {
										action: "getsubject",
										roleid_in: "(2,3,4,5,6,7,8,9)", //subject.id_role IN ..inserire ruoli separati da virgola
										token: localStorage.getItem("user_token"),
								},
						}
						this.$store.dispatch("api", request).then((res) => {  
								if (res.status == 200){
									this.all_contact = res.data.subject;
                  this.overlay = false
                  let that = this
                  that.valid_contact = that.alasql(
                      "SELECT * FROM ? where id_subject != " + that.ID_SUBJECT,
                      [res.data.subject]
                  );
								} else {
									this.snackbar_color = "error";
									this.snackbar_text = "Event contact not loaded.";
									this.snackbar = true;
									this.overlay = false
								}     
						})
						.catch((ex) => {
								console.log(ex)
								this.overlay = false
						});
				}, 
        
        
        getSubjectEvent_(){ 
          //Tutti i soggetti che partecipano all'evento -- tutte le categorie escluso se stasso
          this.overlay = true
          this.events_Subject=[]
          
          let request = {
              controller: "Bellator",
              method: "POST",
              richiesta: {
                  action: "getsubjectevent",
                  //idsubject:this.ID_SUBJECT,
                  idevent:this.ID_EVENT,
                  token: localStorage.getItem("user_token"),
              },
          }
          this.$store.dispatch("api", request).then((res) => {   
              if (res.status == 200){
                this.valid_contact = this.alasql(
                    "SELECT FROM ? where fk_id_subject != " + this.ID_SUBJECT ,
                [res.data.subject_event]
                );
               
                this.overlay = false 
              } else {
                this.colore_snack = "error";
                this.messaggio_snackbar = "Events not loaded.";
                this.snackbar = true;
                this.overlay = false
              }     
          })
          .catch((ex) => {
              this.overlay = false
              this.snackbar = true;
              this.snackbar_background = "error";
              this.snackbar_text_color = "var(--main-primary_text-color)";
              this.snackbar_icon = "mdi-close-outline";
              this.snackbar_text = ex;
              this.snackbar_icon_color = "var(--main-primary_text-color)";
              this.overlay = false
          });
        },  

        */
        
        getIdContact(){
            const myArray = this.subject_ev_appointment_id_contact.split(" ");
            
              let item = this.alasql(
                  "SELECT  FROM ?  where name = '" + myArray[0] + "' and surname = '" + myArray[1] + "'" ,
                  [this.VALID_CONTACT]
              );            
            
            this.id_contact_to_save = item[0].fk_id_subject
            
        },

        getNamebyId(id_contact, organizer, id_subject  ){

            //Se l'utente è organizer = 1 allora id_subject coincide con id_subject altrimenti vuol dire che è stato associato all'appuntamento
            //per cui id_subject = id_contact
            let id = null
            if( organizer == 1 &&  id_subject == this.ID_SUBJECT) 
                id = id_contact ; 
                else id =  id_subject
            if (this.VALID_CONTACT.length > 0)
            {
              let _name_surname = this.alasql(
                  "SELECT name, surname  FROM ?  where fk_id_subject = " + id ,
                  [this.VALID_CONTACT]
              );
              if (_name_surname.length > 0)
                return _name_surname[0].name + ' ' + _name_surname[0].surname 
            }
        },

				deleteItem (item) {
						this.editedIndex_subject_ev_appointment = this.subject_ev_appointment_array.indexOf(item)
						this.edited_subject_ev_appointment = Object.assign({}, item)
						this.dialogDelete = true
				},

				deleteItemConfirm () {
					this.subject_ev_appointment_array.splice(this.editedIndex_subject_ev_appointment, 1)
					this.delete(this.edited_subject_ev_appointment)
					this.closeDelete()
				},

				closeDelete () {
					this.dialogDelete = false
					this.$nextTick(() => {
						this.edited_subject_ev_appointment = {}
						this.editedIndex_subject_ev_appointment = -1
					})
				},      

				add_item(){
            if (this.date == null){
              this.snackbar_color = "error";
              this.snackbar_text = "You have to Insert a date. ";
              this.snackbar_icon = "mdi-window-close";
              this.snackbar = true;
              return
            }    
            
            let new_subjectevappointment = [{id_subject_ev_appointment:-1,  fk_id_subject: this.ID_SUBJECT, fk_id_event: this.ID_EVENT, date: this.date, time: this.subject_ev_appointment_time,  place: this.subject_ev_appointment_place,  id_contact: this.id_contact_to_save, description :this.subject_ev_appointment_description, organizer: 1 }]
            this.overlay = true
            let request = {
                controller: "Bellator",
                method: "PUT",
                richiesta: {
                    action: "setsubjectevappointment",
                    subjectevappointment: new_subjectevappointment,
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {     
                  if (res.status == 200){
                      this.date = null
                      this.subject_ev_appointment_time = null
                      this.subject_ev_appointment_place = null 
                      this.subject_ev_appointment_time = null
                      this.subject_ev_appointment_description = null
                      this.subject_ev_appointment_id_contact = null
                      this.overlay = false
                      this.snackbar_color = "success";
                      this.snackbar_text = "Entry saved successfully. ";
                      this.snackbar_icon = "mdi-window-close";
                      this.snackbar = true;
                      this.getSubjectEvAppointment();
                    } else {
                      this.snackbar_color = "error";
                      this.snackbar_text = "Data Saving Error. ";
                      this.snackbar = true;
                      this.overlay = false
                    }           
            });           
				},

				delete(item){
					this.overlay = true
					let request = {
							controller: "Bellator",
							method: "PUT",
							richiesta: {
									action: "delsubjectevappointment",
									id_subject_ev_appointment: item.id_subject_ev_appointment,
									token: localStorage.getItem("user_token"),
							},
					}
					this.$store.dispatch("api", request).then((res) => {   
							if (res.status == 200){
									this.overlay = false 
                  this.getSubjectEvAppointment();  
									this.snackbar_color = "success";
									this.snackbar_text = "Item deleted! ";
                  this.snackbar_icon = "mdi-window-close";
									this.snackbar = true;                    
							}
					});
				},				
				
        formatDate (date) {
          if (!date) return null
          const [year, month, day] = date.split('-')
          return `${month}/${day}/${year}`
        },      

	}
}

</script>
