<template>
    <div style="padding:20px">
        <v-card  elevation="0">
            <v-toolbar dense color="white" elevation="0" dark align="center">
                <v-row>
                    <v-col cols="5" align="left">
                        <v-toolbar-title>Cambio Password</v-toolbar-title>
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                    <v-col cols="5" align="right">
                        <v-btn small light @click="update_pw" :disabled="!(checkLength(pw.new) && checkEqual(pw.new,pw.check))">
                        <v-icon color="success">mdi-floppy</v-icon>
                        </v-btn>                        
                    </v-col>
                </v-row>
            </v-toolbar>
            <v-container>
                <v-form ref="form">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="pw.old" required :rules="rules.pw" type="password" label="Vecchia password" />
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <v-col cols="6">
                            <v-text-field v-model="pw.new" required :rules="rules.pw" type="password" label="Nuova password" />
                        </v-col>
                        <v-col cols="1" v-if="!checkLength(pw.new)">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="red" v-bind="attrs" v-on="on">mdi-alert</v-icon>
                                </template>
                                <span>Lunghezza minima: 8 caratteri</span>
                            </v-tooltip>                            
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <v-col cols="6">
                            <v-text-field v-model="pw.check" required :rules="rules.pw" type="password" label="Ripeti nuova password" />
                        </v-col>
                        <v-col cols="1" v-if="!checkEqual(pw.new,pw.check)">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="red" v-bind="attrs" v-on="on">mdi-alert</v-icon>
                                </template>
                                <span>Password non coincidenti</span>
                            </v-tooltip>                            
                        </v-col>
                    </v-row> 
                </v-form>               
            </v-container>
        </v-card>
        <v-snackbar dark :color="(alert.error==true)?'error':'light-green darken-2'" v-model="alert.show">
            {{alert.message}}
            <template v-slot:action="{attrs}">
                <v-btn v-bind="attrs" text @click="alert.show=false">Chiudi</v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
const min_length = 8;
export default {
    name : 'GestioneAccount',
    data(){
        return {
            alert : {
                show : false,
                message : null,
                error : false
            },
            NETWORK : null,
            pw : { old:null, new:null, check:null },
            rules : {
                pw : [(val) => (val!=null&&val!=undefined?val.toString().length>0:false)||"Campo obbligatorio"]
            }
        }
    },
    mounted(){
        console.log("sono qui")
        this.NETWORK = this.$store.state.Network;
        console.log(this.NETWORK)
    },
    methods : {
        checkEqual(pw,check){ return pw == check; },
        checkLength(pw){ 
            if(pw == null){ return false; }
            return pw.length >= min_length;
        },
        update_pw(){
            if(!this.$refs.form.validate()){ return; }
            if(this.pw.new!=this.pw.check){ return; }
            if(this.pw.new!=this.pw.check){ return; }
            if(this.pw.new.length < min_length){ return; }
            
            const that = this;
            const request = {
                method : 'PUT',
                controller : 'User',
                body: {
                    action: "setPassword",
                    token: localStorage.user_token,
                    opw : this.pw.old,
                    npw : this.pw.new
                },
                on : {
                    load : function(){
                        that.alert = {
                            message : 'Dati salvati con successo',
                            show : true,
                            error : false
                        }
                    }
                }
            }
            this.NETWORK.AjaxRequest(request);
        }
    }
}
</script>